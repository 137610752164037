import { Component, OnInit } from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material';
import { Inject } from '@angular/core';
import { AngularFireAuth } from 'angularfire2/auth';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, ActivatedRoute , Router} from '@angular/router';
import {MatDialog, MatDialogRef} from '@angular/material';
import { AuthService } from '../services/auth.service';
import * as firebase from 'firebase/app';
import { HttpParams, HttpClient, HttpHeaders, HttpErrorResponse} from '@angular/common/http';
@Component({
  selector: 'app-dialog-for-endcall',
  templateUrl: './dialog-for-endcall.component.html',
  styleUrls: ['./dialog-for-endcall.component.css']
}) 
export class DialogForEndcallComponent implements OnInit {
  response;
  receivedCallIdFromQuery;
  fullName = localStorage.getItem("nurseFullName");
  loggedInUser;
  constructor(public dialogRef: MatDialogRef<DialogForEndcallComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, private _firebaseAuth: AngularFireAuth, private router: Router,
    public authService: AuthService, private http: HttpClient) {
      this.authService.getUserFromstatechange().then((val) => this.loggedInUser = val);
     }

  ngOnInit() {
  }
  closeDialog() {
    this.dialogRef.close();
  }
  closeCall(originatedFrom) {
    // 1.Hit end call webservice 2.Inform previous tab about session end 3.Delete node from videoCallActions

    if (this.data === originatedFrom) {
        this.authService.setStatus(originatedFrom);
        const url = window.location.search;
        const urlparam = url.split('&');
        const receivedCallId = (urlparam[0]).split('=');
        this.receivedCallIdFromQuery =  receivedCallId[1];
        if (window.location.pathname === '/emergency-call') {
          const params = new HttpParams()
          .set('userId', this.loggedInUser)
          .set('callId', this.receivedCallIdFromQuery)
          .set('type', 'ER');
          const headers =  new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
            this.http.post(this.authService.baseURL + '/EndEmergencyCall', params, {headers: headers}).subscribe(
            data => {
              console.log('end call successfull', data);
              if (data['Message'] === 'Success') {
                  if (window.location.pathname === '/emergency-call') {
                    const statusForEndCall: firebase.database.Reference =
                    firebase.database().ref('emergencyCall/stroke/' + this.receivedCallIdFromQuery + '/videoCallDetails/');
                    statusForEndCall.update({
                      endCallStatus: true
                    });

                    const status: firebase.database.Reference =
                    firebase.database().ref('emergencyCall/stroke/' + this.receivedCallIdFromQuery);
                    status.update({
                      status: 'ended'
                    });
                  }
                  const videoCallActionRef: firebase.database.Reference =
                  firebase.database().ref('/videoCallActions/stroke/' + this.receivedCallIdFromQuery  + '/actions/');
                  videoCallActionRef.push({
                    action: 'endedByNurse',
                    info: 'Call is ended by Nurse',
                    timestamp: firebase.database.ServerValue.TIMESTAMP,
                    user: this.loggedInUser
                  });
                  const videoCallActionStatusRef: firebase.database.Reference =
                  firebase.database().ref('/videoCallActions/stroke/' + this.receivedCallIdFromQuery);
                  videoCallActionStatusRef.update({
                    status: 'endedByNurse'
                  });
                  window.close();
              } else {
                alert('problem while ending call');
                window.close();
              }
          });
        } else {
          const params = new HttpParams()
          .set('userId', this.loggedInUser)
          .set('callId', this.receivedCallIdFromQuery)
          .set('nurseFullName', this.fullName)
          .set('type', 'ER');
          const headers =  new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
            this.http.post(this.authService.baseURL + '/EndCall', params, {headers: headers}).subscribe(
            data => {
              console.log('end call successfull', data);
              if (data['Message'] === 'Success') {
                if (window.location.pathname === '/video') {
                  const statusForEndCall: firebase.database.Reference =
                  firebase.database().ref('intakesData/stroke/' + this.receivedCallIdFromQuery + '/videoCallDetails/');
                  statusForEndCall.update({
                  endCallStatus: true
                  });
                }
                const videoCallActionRef: firebase.database.Reference =
                firebase.database().ref('/videoCallActions/stroke/' + this.receivedCallIdFromQuery  + '/actions/');
                videoCallActionRef.push({
                  action: 'endedByNurse',
                  info: 'Call is ended by Nurse',
                  timestamp: firebase.database.ServerValue.TIMESTAMP,
                  user: this.loggedInUser
                });
                const videoCallActionStatusRef: firebase.database.Reference =
                firebase.database().ref('/videoCallActions/stroke/' + this.receivedCallIdFromQuery);
                videoCallActionStatusRef.update({
                  status: 'endedByNurse'
                });
                window.close();
              } else {
                alert('problem while ending call');
                window.close();
              }
          });
        }

    } else {
        window.close();
    }
  }
}

import { Component, OnInit } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, ActivatedRoute , Router, NavigationEnd} from '@angular/router';
import { AuthService } from '../services/auth.service';
import { AngularFireAuth } from 'angularfire2/auth';
import {FormControl, FormGroupDirective, NgForm, Validators, FormGroup, FormBuilder, AbstractControl} from '@angular/forms';
import { HttpParams, HttpClient, HttpHeaders, HttpErrorResponse} from '@angular/common/http';
import {MatDialog} from '@angular/material';
import { SuccessDialogComponent } from '../success-dialog/success-dialog.component';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { ErrorDialogComponent } from '../error-dialog/error-dialog.component';
import { DialogForProfileComponent } from '../dialog-for-profile/dialog-for-profile.component';
import 'rxjs/add/operator/filter';
import { PlatformLocation } from '@angular/common';
import * as firebase from 'firebase/app';
@Component({
  selector: 'app-nurse-profile',
  templateUrl: './nurse-profile.component.html',
  styleUrls: ['./nurse-profile.component.css']
})
export class NurseProfileComponent implements OnInit {

  showProfileInfoDiv = true; // profile info div is displayed bydefault
  ShowEditProfileDiv = false; // Edit profile div is hidden bydefault
  ShowResetPasswordDiv = false; // Reset Password Div
  fullName;
  userName;
  userEmail;
  loggedInUser;
  wrongPassword = false;
  responseReceived;
  showMatchError = false;
  showValidationMsg = false;
  disabledVar;
  profilePic;
  contactNo;
  previousUrl;
  currentUrl = this.router.url;
  patientForm: FormGroup;
  passwordForm: FormGroup;
  updatedFullName;
  updatedUserName;
  updatedEmail;
  callIdReceivedFromQuery;
  patientFormResetPassword: FormGroup;
  emailFormControl = new FormControl('', [
    Validators.required,
    Validators.email
  ]);
  fullNameControl = new FormControl('', [
    Validators.required
  ]);

  constructor(private _firebaseAuth: AngularFireAuth, private router: Router, private authService: AuthService, private http: HttpClient,
    public dialog: MatDialog , public errorDialog: MatDialog , public successDialog: MatDialog , fb: FormBuilder,
    public loader: LoadingBarService, public dialogForProfile: MatDialog, location: PlatformLocation, )  {
    this.passwordForm = fb.group({
      currentPassword: fb.control('', [Validators.required]),
      newPassword: fb.control('', [Validators.required,
        Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&#])[A-Za-z\d$@$!%*?&#].{7,}')]),
        confirmPassword: fb.control('', [Validators.required,
      Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&#])[A-Za-z\d$@$!%*?&#].{7,}')]),
    }, {
    validator: this.matchPassword,
    });
    this.authService.getUserFromstatechange().then((val) => this.loggedInUser = val);
    location.onPopState(() => {
      if (this.ShowEditProfileDiv === true) {
        window.history.forward();
        this.showProfileInfoDiv = true;
        this.ShowEditProfileDiv = false;
        this.ShowResetPasswordDiv = false;
      }
      if (this.ShowResetPasswordDiv === true) {
        window.history.forward();
        this.showProfileInfoDiv = true;
        this.ShowEditProfileDiv = false;
        this.ShowResetPasswordDiv = false;
      }
    });
    if (window.location.search !== '') {
      const url = window.location.search;
      const urlparam = url.split('&');
      const receivedCallId = (urlparam[0]).split('=');
      this.callIdReceivedFromQuery =  receivedCallId[1];
      const callIdRef: firebase.database.Reference =
      firebase.database().ref('intakesData/stroke/' + this.callIdReceivedFromQuery + '/videoCallDetails/');
      callIdRef.on('value', snapshot => {
        if (window.location.pathname === '/nurse-profile') {
          if (snapshot.val()!==null && snapshot.val()['endCallStatus'] === true) {
              alert('This session is ended');
              this.router.navigate(['/stroke-code']);
          }
        }
      });
    }
  }
  ngOnInit() {
    this.getProfileDetails();
  }
  private matchPassword(AC: AbstractControl) {
        // tslint:disable-next-line:max-line-length
    return AC.get('newPassword').value === AC.get('confirmPassword').value ? null : AC.get('confirmPassword').setErrors( {mailmismatch: true} );
}
  displayEditProfile() {
    this.showProfileInfoDiv = false;
    this.ShowEditProfileDiv = true;
  }
  viewProfileInfo() {
    this.ShowEditProfileDiv = false;
    this.showProfileInfoDiv = true;
  }
  goBack(form: NgForm) {
    if (this.ShowEditProfileDiv === true) {
        this.showProfileInfoDiv = true;
        this.ShowEditProfileDiv = false;
        this.ShowResetPasswordDiv = false;
        return;
    }
    if (this.ShowResetPasswordDiv === true) {
      this.passwordForm.get('currentPassword').reset();
      this.passwordForm.get('newPassword').reset();
      this.passwordForm.get('confirmPassword').reset();
      this.showProfileInfoDiv = true;
      this.ShowEditProfileDiv = false;
      this.ShowResetPasswordDiv = false;
      return;
    }
    if (this.showProfileInfoDiv === true) {
      // alert('previous move back' + this.previousUrl);
      // this.router.navigate(['stroke-code']);
      window.history.back();
      return;
    }
  }
  changePasswordDiv() {
    this.showProfileInfoDiv = false;
    this.ShowEditProfileDiv = false;
    this.ShowResetPasswordDiv = true;
  }
  checkCurrentPassword(currentPassword) {
    // alert(this.passwordFormControl.value);
    if (currentPassword) {
    if (currentPassword.length > 0) {
    const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
    const param = new HttpParams()
    .set('userName', this.loggedInUser)
    .set('currentPassword', currentPassword);
    this.http.post(this.authService.baseURL + '/CheckCurrentPassword' , param , {headers: headers}).subscribe(response => {
      this.responseReceived = response['message'];
        if (response['status'] === true) {
          // this.authService.DoctorAvailableFunction(response['isAvailable']);
        } else {
          // alert('true');
          this.wrongPassword = false;
          this.passwordForm.get('currentPassword').setErrors(Validators.required);
        }
    });
  }
}
  }
  checkPassword(value) {
    this.showValidationMsg = value;
  }
  checkMatch(newPassword, confirmPassword) {
    if (newPassword === confirmPassword) {
      return false;
    } else {
      return true;
    }
  }
  // resetPassword(newPassword, confirmPassword) {
  //   if (newPassword === confirmPassword) {
  //     this.loader.start();
  //   this.loader.set(10);
  //     const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
  //     const param = new HttpParams()
  //     .set('userName', this.loggedInUser)
  //     .set('password', newPassword);
  //     this.http.post(this.authService.baseURL + '/ChangePassword' , param , {headers: headers}).subscribe(response => {
  //       if (response['message'] === 'success') {
  //         this.loader.complete();
  //        this.opendialogForProfile('Password is changed successfully! Please login again.');
  //       } else {
  //         this.loader.complete();
  //         this.openErrorDialog('Some error occurred, please try again.');
  //       }
  //     }, err => {
  //       this.loader.complete();
  //       this.openErrorDialog('Some error occurred, please try again.');
  //      });
  //     }
  // }
   resetPassword(newPassword, confirmPassword, currentPassword) {
    if (newPassword === confirmPassword) {
      if (currentPassword) {
        if (currentPassword.length > 0) {
        const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
        const param = new HttpParams()
        .set('userName', this.loggedInUser)
        .set('currentPassword', currentPassword);
        this.http.post(this.authService.baseURL + '/CheckCurrentPassword' , param , {headers: headers}).subscribe(response => {
          // this.responseReceived = response['message'];
            if (response['status'] === true) {
            this.loader.start();
            this.loader.set(10);
              const params = new HttpParams()
              .set('userName', this.loggedInUser)
              .set('password', newPassword);
              this.http.post(this.authService.baseURL + '/ChangePassword' , params , {headers: headers}).subscribe(res => {
                if (res['message'] === 'success') {
                  this.loader.complete();
                  this.opendialogForProfile('Password is changed successfully! Please login again.');
                } else {
                  this.loader.complete();
                  this.openErrorDialog('Some error occurred, please try again.');
                }
              }, err => {
                this.loader.complete();
                this.openErrorDialog('Some error occurred, please try again.');
              });
            } else {
              this.wrongPassword = false;
              this.passwordForm.get('currentPassword').setErrors(Validators.required);
            }
        });
      }
    }
      }
  }
  logout() {
    this._firebaseAuth.auth.signOut().then(res => {
      setTimeout(() => { this.router.navigate(['/']); } , 1000);
    });
  }
  getProfileDetails() {
    const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
      const param = new HttpParams().set('userName', this.loggedInUser);
      this.http.post(this.authService.baseURL + '/GetProfileInfo' , param , {headers: headers}).subscribe(response => {
        if (response['message'] === 'success') {
          this.userName = response['userName'];
          this.userEmail = response['Email'];
          this.contactNo = response['contactNo'];
          this.fullName = response['fullName'];
          this.updatedFullName = this.fullName;
          this.updatedEmail = this.userEmail;
          this.updatedUserName = this.userName;
        }
    });
  }
  getLoggedInUser() {
    alert('control received in logge in user');
    return new Promise((resolve, reject) => {
    this.authService.getUserFromstatechange().then((val) => this.loggedInUser = val);
    alert('control out from logged in user' + this.loggedInUser);
    resolve();
    });
  }
  getProfile() {
    this.getLoggedInUser().then(res => this.getProfileDetails());
  }
  editProfile(fullName, userName, userEmail) {
    // const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
    // const param = new HttpParams()
    // .set('fullName', '')
    // .set('userName', userName)
    // .set('userEmail', this.loggedInUser);
    this.loader.start();
    this.loader.set(10);
    const formData: FormData = new FormData();
    formData.append('userName', userName);
    formData.append('fullName', fullName);
    formData.append('userEmail', userEmail);
  //   this.http.post(this.authService.baseURL + '/EditProfile' , param , {headers: headers}).subscribe(response => {
  //    console.log(response);
  // });
  this.http.post(this.authService.baseURL + '/EditProfile', formData).subscribe( response => {
    console.log(response);
    if (response['Message'] === 'success')  {
        this.loader.complete();
        this.openSuccessDialog('Profile updated successfully.');
        this.ShowEditProfileDiv = false;
        this.showProfileInfoDiv = true;
        this.updatedFullName = fullName;
        this.updatedEmail = userEmail;
    } else if (response['Message'] === 'email already present') {
      this.loader.complete();
      this.openErrorDialog('Email address already exists.');
    } else {
      this.loader.complete();
      this.openErrorDialog('Some error occurred, please try again.');
    }
  }, err => {
    this.loader.complete();
    this.openErrorDialog('Some error occurred, please try again.');
  });
  }
  openErrorDialog(message) {
    const dialogRef = this.errorDialog.open(ErrorDialogComponent , {
      height: '72px',
      data : message
    });
  }

  openSuccessDialog(message) {
    const dialogRef = this.successDialog.open(SuccessDialogComponent , {
      height: '72px',
      data : message
    });
  }
  opendialogForProfile(message) {
    const dialogRef = this.dialogForProfile.open(DialogForProfileComponent , {
      height: '124px',
      data : message ,
      disableClose: true,
      hasBackdrop: true
    });
  }
}

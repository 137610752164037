import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';
import {Router } from '@angular/router';
import { AngularFireAuth } from 'angularfire2/auth';
import * as firebase from 'firebase/app';
import 'rxjs/add/operator/do';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/take';

@Injectable({
  providedIn: 'root'
})
export class SigninGuard implements CanActivate {
  constructor(private router: Router , private authService: AuthService, private _firebaseAuth: AngularFireAuth) {

  }
   canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> {

      return this._firebaseAuth.authState
       .take(1)
       .map(user => {
         console.log(user);
         if (user != null) {
          console.log('user is logged in');
          this.router.navigate(['/stroke-code']);
          return !!user;
         } else {
          console.log('access denied');
          // this.router.navigate(['/']);
          return true;
         }
       })
       .do(loggedIn => {
        //  if (!loggedIn) {
        //    console.log('access denied');
        //    // this.router.navigate(['/']);
        //  }
     });
   }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes, CanActivate } from '@angular/router';
import { SignUpComponent } from './sign-up/sign-up.component';
import { HeaderComponent } from './header/header.component';
import { InitiateStrokeCodeComponent } from './initiate-stroke-code/initiate-stroke-code.component';
import { NewPatientComponent } from './new-patient/new-patient.component';
import { SidebarUIComponent } from './sidebar-ui/sidebar-ui.component';
import { SearchPatientComponent } from './search-patient/search-patient.component';
import { VideoComponent } from './video/video.component';
import { NursePatientRecordsComponent } from './nurse-patient-records/nurse-patient-records.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { SigninGuard } from './services/signin.guard';
import { AuthGuard } from './services/auth.guard';
import { CanDeactivateGuard } from './services/can-deactivate.guard';
import { NurseProfileComponent } from './nurse-profile/nurse-profile.component';
import { TelemedicineConsultComponent} from './telemedicine-consult/telemedicine-consult.component';
import { IntakeDeactivateGuard } from './services/intake-deactivate.guard';
import { ActivateSearchGuard } from './services/activate-search.guard';
import { DirectcallComponent } from './directcall/directcall.component';
//
const routes: Routes = [
  { path: '', component: SignUpComponent, canActivate: [SigninGuard]},
  { path: 'stroke-code', component: InitiateStrokeCodeComponent , canActivate: [AuthGuard]},
  { path: 'create-patient', component: NewPatientComponent , canActivate: [AuthGuard], canDeactivate: [IntakeDeactivateGuard]},
  { path: 'intake-form', component: SidebarUIComponent , canActivate: [AuthGuard], canDeactivate: [IntakeDeactivateGuard]},
  { path: 'search-patient', component: SearchPatientComponent , canActivate: [AuthGuard], canDeactivate: [IntakeDeactivateGuard]},
  { path: 'video', component: VideoComponent, canDeactivate: [CanDeactivateGuard]},
  { path: 'charts-records', component: NursePatientRecordsComponent , canActivate: [AuthGuard], canDeactivate: [IntakeDeactivateGuard]},
  { path: 'forgot-password', component: ForgotPasswordComponent},
  { path: 'reset-password', component: ResetPasswordComponent},
  { path: 'nurse-profile', component: NurseProfileComponent, canActivate: [AuthGuard], canDeactivate: [IntakeDeactivateGuard]},
  { path: 'telemedicine-consult', component: TelemedicineConsultComponent},
  { path: 'emergency-call', component: DirectcallComponent, canDeactivate: [CanDeactivateGuard]}
];
@NgModule({
  exports: [ RouterModule ],
  imports: [
    CommonModule,
    RouterModule.forRoot(routes)
  ],
  declarations: []
})
export class AppRoutingModule { }

import { Component, OnInit , ChangeDetectorRef} from '@angular/core';
import {FormControl, FormGroupDirective, NgForm, Validators} from '@angular/forms';
import {ErrorStateMatcher} from '@angular/material/core';
import { SuccessDialogComponent } from '../success-dialog/success-dialog.component';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { ErrorDialogComponent } from '../error-dialog/error-dialog.component';
import { HttpParams, HttpClient, HttpHeaders, HttpErrorResponse} from '@angular/common/http';
import { AuthService } from '../services/auth.service';
import {MatDialog} from '@angular/material';




@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent implements OnInit {
  recoveryEmail;
  showResendBtn = false;
  constructor(public loader: LoadingBarService , public http: HttpClient , private ref: ChangeDetectorRef,
     public authService: AuthService, public errorDialog: MatDialog , public successDialog: MatDialog) { }
  // form control validation
  emailFormControl = new FormControl('', [
    Validators.required,
    Validators.email,
  ]);

  ngOnInit() {
  }

  forgotPassword(type) {
    this.loader.start();
    this.loader.set(10);
    const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
    const param =  new HttpParams().set('email', this.recoveryEmail);
    this.http.post(this.authService.baseURL + '/ForgotPassword', param, {headers: headers}).subscribe(
    response => {
      if (response['message'] === 'success') {

          this.loader.complete();
          this.openSuccessDialog();
          /*if (type === 'sendLinkBtn') {
            this.showResendBtn = true;
            // this.ref.checkNoChanges();
          }*/

      } else {

        this.loader.complete();
        // this.openErrorDialog();
        this.openSuccessDialog();
      }
    },
    err => {

      this.openErrorDialog();
      this.loader.complete();
      console.log(err);

    });
    if (type === 'sendLinkBtn') {
      this.showResendBtn = true;
    }
  }

  openErrorDialog() {
    const dialogRef = this.errorDialog.open(ErrorDialogComponent , {
      height: '72px',
      data : 'Some error occurred, please try again.'
    });
  }

  openSuccessDialog() {
    const dialogRef = this.successDialog.open(SuccessDialogComponent , {
      height: '72px',
      data : 'Reset password link has been sent to your email address.'
    });
  }

}

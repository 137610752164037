import { Component, OnInit, HostListener } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { HttpHeaders, HttpParams, HttpClient } from '@angular/common/http';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { ErrorDialogComponent } from '../error-dialog/error-dialog.component';
import {MatDialog} from '@angular/material';
import { CommonFunctionsService } from '../services/common-functions.service';
import * as firebase from 'firebase/app';

@Component({
  selector: 'app-initiate-stroke-code',
  templateUrl: './initiate-stroke-code.component.html',
  styleUrls: ['./initiate-stroke-code.component.css']
})
export class InitiateStrokeCodeComponent implements OnInit {

  // Local variables
  nurseFullName = localStorage.getItem('nurseFullName');
  public loggedInUser;
  public loggedIn;
  public user;
  public disableStrokeBtn: Boolean = false;
  isloading = false; // loader false
  video = {'token': '', 'sessionId': ''};
  callIdReceivedFromQuery;
  
   // methods
  constructor(private authService: AuthService, private router: Router, private http: HttpClient ,
    private loader: LoadingBarService , public errorDialog: MatDialog,  public commonFunctionsService: CommonFunctionsService) {
    // function to get Current Logged In User
    this.authService.getUserFromstatechange().then((val) => this.loggedInUser = val);
    this.authService.setActiveStatus('');
    localStorage.removeItem('patientSentTOCT');
    localStorage.removeItem('initiateStrokCode');
    localStorage.removeItem('patientReadyForVideoNotify');
    
  }
  ngOnInit() {}

  @HostListener('window:popstate', ['$event'])
  onPopState(event) {   // window.history.forward();

    // window.history.forward();
   // this.router.navigate(['/stroke-code']);
    // this.authService.setToggle(false);
    // window.location.assign('stroke-code');
  }

  initiateStrokCode() {
    
    this.disableStrokeBtn = true;
    this.loader.start();
    this.loader.set(10);
    const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
    const params = new HttpParams().set('userId', this.loggedInUser)
    .set('fullName', this.nurseFullName)
    .set('type' , 'initiateStrokeCode');
    // this.isloading = true; // Loader Start
    this.http.post(this.authService.baseURL + '/IntiateStrokeNotification', params, {headers: headers}).subscribe(
      response => {
        console.log('response ' +response);
        if (response['message'] === 'success') {
          var firebaseFormDetails = firebase.database().ref('intakesData/stroke/' + response['callId'] + '/videoCallDetails/');
          firebaseFormDetails.set({
            sessionId: '',
            videoToken: '',
            endCallStatus: '',
            emergencyCall: false
          });
          this.router.navigate(['/create-patient']);
          this.callIdReceivedFromQuery = response['callId'];
          window.open('/video?callId=' + this.callIdReceivedFromQuery + '&initiated=true');
          this.router.navigate(['/create-patient'], { queryParams: { callId: this.callIdReceivedFromQuery , initiated: true } });
          this.disableStrokeBtn = true;
        } else {
          this.disableStrokeBtn = false;
          this.openErrorDialog();
        }
        this.loader.complete();
    },
    err => {
      this.disableStrokeBtn = false;
      this.openErrorDialog();
      this.loader.complete();
    });
  }
  openErrorDialog() {
    const dialogRef = this.errorDialog.open(ErrorDialogComponent , {
      height: '72px',
      data : 'Some error occurred, please try again.'
    });
  }

  emergencyCall() {
    this.loader.start();
    this.loader.set(10);
    const headers =  new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
    const params = new HttpParams()
    .set('userId', this.loggedInUser);
    this.http.post(this.authService.baseURL + '/placeEmergencyCall', params, {headers: headers}).subscribe(
      data => {
        console.log('register', data);
        // this.isloading = false; // Loader stop
        if (data['Message'] === 'Success') {
          this.commonFunctionsService.CallIdForOnGoingCall = data['callId'];
          this.commonFunctionsService.setPatientNameBlankForDirectCall();

          var emergencyCallDetails = firebase.database().ref('emergencyCall/stroke/' + data['callId']);
          emergencyCallDetails.set({
            nurse : this.loggedInUser,
            fullName: this.nurseFullName,
            timeStamp : firebase.database.ServerValue.TIMESTAMP,
            status: 'initiated',
          });

          var videoCallDetails = firebase.database().ref('emergencyCall/stroke/' + data['callId'] + '/videoCallDetails/');
          videoCallDetails.set({
            sessionId: '',
            videoToken: '',
            endCallStatus: '',
            emergencyCall: true
          });

          window.open('/emergency-call?callId=' + data['callId'] + '&initiated=true');
          this.loader.complete();

        } else {

          this.openErrorDialog();
          this.loader.complete();

        }
      },
      err => {
        this.openErrorDialog();
        this.loader.complete();
      });
    }
}

import { Injectable } from '@angular/core';
import { AngularFireDatabase } from 'angularfire2/database';
import { AngularFireAuth } from 'angularfire2/auth';
import * as firebase from 'firebase';

import 'rxjs/add/operator/take';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { HttpParams, HttpClient, HttpHeaders, HttpErrorResponse} from '@angular/common/http';
import { Http, Response, Headers, URLSearchParams, RequestOptions } from '@angular/http';
@Injectable()
export class MessagingService {

  messaging = firebase.messaging();
  currentMessage = new BehaviorSubject(null);
  userid: any;
  msgServiceToken: any;
  constructor(private db: AngularFireDatabase, private afAuth: AngularFireAuth, private http: HttpClient) { }


  updateToken(token) {
    this.afAuth.authState.take(1).subscribe(user => {
      if (!user) { return; }

      const data = { [user.uid]: token };
      this.db.object('fcmTokens/').update(data);
    });
  }

  getPermission() {
      this.messaging.requestPermission()
      .then(() => {
        console.log('Notification permission granted.');
        return this.messaging.getToken();
      })
      .then(token => {
        console.log(token);
        this.msgServiceToken = token;
        this.updateToken(token);
      })
      .catch((err) => {
        console.log('Unable to get permission to notify.', err);
      });
    }

   receiveMessage() {
       this.messaging.onMessage((payload) => {
        console.log('Message received. ', payload);
        this.currentMessage.next(payload);
        const title = payload.notification.title;
          const options = {
              body: payload.notification.body || {},
              icon: payload.notification.icon || 'https://doctorwebapp.appspot.com/favicon.ico',
              click_action: payload.data.click_action || ''
          };
          const notification = new Notification(title, options);
          console.log(payload.data.click_action);
          notification.onclick = function(event) {

              window.open(payload.data.click_action, '_blank');
          };
          setTimeout(() => {  notification.close(); }, 10000 );
      });
    }
}


// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  firebase: {
    // apiKey: "AIzaSyCO4aPJXFZ99ZR1IOD949g2mtGqDgwgvcc",
    // authDomain: "sevaro-stroke.firebaseapp.com",
    // databaseURL: "https://sevaro-stroke.firebaseio.com",
    // projectId: "sevaro-stroke",
    // storageBucket: "sevaro-stroke.appspot.com",
    // messagingSenderId: "396246893655",
    // appId: "1:396246893655:web:bd00f86d46e936602dcf12",
    // measurementId: "G-L49075QZF6",
    // apiKey: "AIzaSyDLjcNDTmSMoedwOc2jlD8ARWUt7IGoAGA",
    // authDomain: "sevaro-stroke-dev.firebaseapp.com",
    // projectId: "sevaro-stroke-dev",
    // storageBucket: "sevaro-stroke-dev.appspot.com",
    // messagingSenderId: "456195313933",
    // appId: "1:456195313933:web:45973090319e5a2624a044",
    // measurementId: "G-KNGFKCKF7K"
    apiKey: "AIzaSyB9kHbvOTrY-FIeDBGHkGRRAVkNPMZ4-AY",
    authDomain: "telestroke-sevaro.firebaseapp.com",
    databaseURL: "https://telestroke-sevaro-default-rtdb.firebaseio.com",
    projectId: "telestroke-sevaro",
    storageBucket: "telestroke-sevaro.appspot.com",
    messagingSenderId: "951002235259",
    appId: "1:951002235259:web:493876111bde23b95e3dde",
    measurementId: "G-29W6HD4VTD"
  },
  // firebase: {
  //   apiKey: "AIzaSyAgYxytAkIAudYqgv_VsT5GHzhqSOMxlcE",
  //   authDomain: "sevaro-usa-backend.firebaseapp.com",
  //   databaseURL: "https://sevaro-usa-backend.firebaseio.com",
  //   projectId: "sevaro-usa-backend",
  //   storageBucket: "sevaro-usa-backend.appspot.com",
  //   messagingSenderId: "179463481249",
  //   appId: "1:179463481249:web:e563196f30e9bd4c"
  // }
};

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.

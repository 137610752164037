import { Component, OnInit } from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material';
import { Inject } from '@angular/core';
import { AngularFireAuth } from 'angularfire2/auth';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, ActivatedRoute , Router} from '@angular/router';
@Component({
  selector: 'app-success-dialog',
  templateUrl: './success-dialog.component.html',
  styleUrls: ['./success-dialog.component.css']
})
export class SuccessDialogComponent implements OnInit {
  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private _firebaseAuth: AngularFireAuth, private router: Router) {
  }

  ngOnInit() {
  }
}

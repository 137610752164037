import { Component, OnInit } from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material';
import { Inject } from '@angular/core';
import { AngularFireAuth } from 'angularfire2/auth';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, ActivatedRoute , Router} from '@angular/router';
import {MatDialog, MatDialogRef} from '@angular/material';
@Component({
  selector: 'app-dialog-for-profile',
  templateUrl: './dialog-for-profile.component.html',
  styleUrls: ['./dialog-for-profile.component.css']
})
export class DialogForProfileComponent implements OnInit {
  closeDialog: Boolean = true;
  constructor( public dialogRef: MatDialogRef<DialogForProfileComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, private _firebaseAuth: AngularFireAuth, private router: Router) { }

  ngOnInit() {
  }
  logout() {
    this._firebaseAuth.auth.signOut().then(res => {
      setTimeout(() => { this.router.navigate(['/']); } , 200);
      this.dialogRef.close();
    });
  }
  closeDialogOnOk() {
    this.dialogRef.close();
  }
}

import { Component, ElementRef, AfterViewInit, ViewChild, Input } from '@angular/core';
import * as OT from '@opentok/client';
import 'rxjs/add/operator/map';
import { BehaviorSubject } from 'rxjs';
import { OpentokService } from '../opentok.service';
import { Observable } from 'rxjs/Observable';
import { AuthService } from '../services/auth.service';
@Component({
  selector: 'app-subscriber',
  templateUrl: './subscriber.component.html',
  styleUrls: ['./subscriber.component.css']
})

export class SubscriberComponent implements AfterViewInit {
  @ViewChild('subscriberDiv') subscriberDiv: ElementRef;
  @Input() session: OT.Session;
  @Input() stream: OT.Stream;
  show: any;
  subscriber;
  constructor(public authService: AuthService) {

 }

  ngAfterViewInit() {
     this.subscriber = this.session.subscribe(this.stream, this.subscriberDiv.nativeElement, {}, (err) => {
      if (err) {
        alert(err.message);
      } else {
      }
    });
  }
}

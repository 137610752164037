import { Injectable, OnInit } from '@angular/core';
import { AngularFireAuth } from 'angularfire2/auth';
import * as firebase from 'firebase/app';
import { HttpParams, HttpClient, HttpHeaders} from '@angular/common/http';
import { BehaviorSubject, Observable, Subject} from 'rxjs';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { MessagingService } from '../messaging.service';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { ErrorDialogComponent } from '../error-dialog/error-dialog.component';
import {MatDialog} from '@angular/material';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

// Local variables
nurseFullName = '';
public baseURL = 'https://telestroke-sevaro.uc.r.appspot.com/';
// public baseURL = 'https://sevaro-stroke.uc.r.appspot.com/';
// public baseURL = 'https://stathealthmd.appspot.com/';
// public baseURL = 'https://stathealthmd-test.appspot.com';
// public baseURL = 'https://sevaro-usa-backend.appspot.com/';
public headers;
public errorCode;
public errorMessage;
public userid;
public createPatientDataObj;
public userName;
public userEmail;
public fullName;
public status: Boolean = true;
// Observables
// Login Message Display
private messageSource = new BehaviorSubject(false);
currentMessage = this.messageSource.asObservable();
private messageSrc = new BehaviorSubject(true);
currentMsg = this.messageSrc.asObservable();
private valueObs = new BehaviorSubject('');
currentvalue = this.valueObs.asObservable();
// send hospitalCampus
private sendCampusName = new BehaviorSubject('');
receiveCampusName = this.sendCampusName.asObservable();
// Call has ended on button
private valueStatus = new BehaviorSubject('');
currentvalueStatus = this.valueStatus.asObservable();
// Guard tells publisher to stop through me
private disconnect = new BehaviorSubject(false);
disconnectStatus = this.valueStatus.asObservable();
private endCallPopup = new BehaviorSubject('');
endCallPopupStatus = this.endCallPopup.asObservable();
private activateSearchStatus = new BehaviorSubject('');
activateSearch = this.activateSearchStatus.asObservable();
private doctorNameForAction = new BehaviorSubject('');
doctorNameForActionVar = this.doctorNameForAction.asObservable();
private setFalseFromStrokeCode = new BehaviorSubject('true');
setFalseFromStrokeCodeVar = this.doctorNameForAction.asObservable();
private toggle = new Subject<boolean>();
public $toggle = this.toggle.asObservable();
private statusForSessionEnd = new BehaviorSubject('false');
statusForSessionEndVar = this.statusForSessionEnd.asObservable();
doctorName;
public user;
public hospitalCampusName;

isloading = false; // loader object
loggedInUser;
constructor(private _firebaseAuth: AngularFireAuth, private http: HttpClient,
   private router: Router , private msgService: MessagingService , private loader: LoadingBarService ,
   public errorDialog: MatDialog, public openDialogForEndCall: MatDialog) {
    this.currentMsg = this.messageSrc.asObservable();
}
// check login credentials
  login(username, password) {
    
    this.loader.start();
    this.loader.set(10);
    const params = new HttpParams()
    .set('Type', 'ER')
    .set('userName', username)
    .set('Password', password);
    this.headers =  new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
    // this.isloading = true; // loader start
    this.http.post(this.baseURL + '/LoginData' , params, {headers: this.headers}).subscribe(
    data => {
      console.log('this is me', data);
      if ( data['Message'] === 'Success') {
        this.hospitalCampusName = String(data['hospitalCampus']);
        this.userid = username;
        this.nurseFullName = data['fullName'];
        localStorage.setItem('nurseFullName', this.nurseFullName);
        // On login success remove error
        this.messageSource.next(true);
        this.setCampusName(this.hospitalCampusName);
        // alert('authh service' + this.hospitalCampusName);
         // Update Device Token for Notification
         const deviceTokenParam = new HttpParams().set('userId', username).set('deviceToken', this.msgService.msgServiceToken);
         this.http.post(this.baseURL + '/UpdateDeviceToken', deviceTokenParam, { headers: this.headers}).subscribe(
           response => {
           console.log('this is messaging service data', response);
         });

        // this.isloading = false; // Stop loader
        this.loader.complete();
        // on login successs get token from firebase and  navigate to next component
        // firebase.auth().signInWithCustomToken(data['Token']).then((res) => this.router.navigate(['/stroke-code']))
        firebase.auth().signInWithCustomToken(data['Token']).then((res) => {
          this.router.navigate(['/stroke-code']);
          this.messageSource.next(false);
        })
        .catch(function(error) {
          var errorCode = error.code;
          var errorMessage = error.message;
          console.log(errorMessage);
          console.log(errorCode);
        });
      } else {
        // on login fail
        this.messageSource.next(false);
        this.openErrorDialog();
        this.loader.complete();
      }
    },
    err => {
        this.openErrorDialog();
        this.loader.complete();
    });
  }
  getUserFromstatechange() {
    // get current logged in user
    var promise = new Promise((resolve, reject) => {
        firebase.auth().onAuthStateChanged(function(user) {
          if(user){
          resolve(user.uid);
          }
        });
    });
    return promise;
  }

  public setValue(value: any): void {
    this.valueObs.next(value);
  }

  public getValue(): Observable<any> {
    return this.valueObs;
  }
  openErrorDialog() {
    const dialogRef = this.errorDialog.open(ErrorDialogComponent , {
      height: '72px',
      data : 'Please enter valid username and password.'
    });
  }
  public setStatus(value: any): void {
    this.valueStatus.next(value);
  }
  public getStatus(): Observable<any> {
    return this.valueStatus;
  }

  public setDisconnectStatus(value: any): void {
    this.disconnect.next(value);
  }
  public getDisconnectStatus(): Observable<any> {
    return this.disconnect;
  }
  public setCampusName(value: any): void {
    this.sendCampusName.next(value);
  }
  public getCampusName(): any {
    return this.sendCampusName;
  }
  public setPopupValue(value: any): void {
    this.endCallPopup.next(value);
  }

  public getPopupValue(): Observable<any> {
    return this.endCallPopup;
  }
  public setActiveStatus(value: any): void {
    this.activateSearchStatus.next(value);
  }

  public getActiveStatus(): Observable<any> {
    return this.activateSearchStatus;
  }
  public setDoctorNameForAction(value: any): void {
    this.doctorNameForAction.next(value);
  }

  public getDoctorNameForAction(): Observable<any> {
    return this.doctorNameForAction;
  }
  public setFalseToDeactivate(value: any): void {
    this.setFalseFromStrokeCode.next(value);
  }

  public getFalseToDeactivate(): Observable<any> {
    return this.setFalseFromStrokeCode;
  }

  public setTrueForSessionEnd(value: any): void {
    this.statusForSessionEnd.next(value);
  }

  public getTrueForSessionEnd(): Observable<any> {
    return this.statusForSessionEnd;
  }
  setToggle(val: boolean) {
    this.toggle.next(val);
  }

  EndCallFromCanDeactive(callId) {
    const callIdRef: firebase.database.Reference =
    firebase.database().ref('videoCallActions/stroke/' + callId);
    callIdRef.update({
      status : 'endedByNurse'
    });

    const endCallRef: firebase.database.Reference =
    firebase.database().ref('intakesData/stroke/' + callId + '/videoCallDetails/');
    endCallRef.update({
      endCallStatus : true
    });

    this.getUserFromstatechange().then((val) => this.loggedInUser = val).then(() => this.endCallFunction(callId));

  }

  endCallFunction(callId) {
    const params = new HttpParams()
    .set('userId', this.loggedInUser)
    .set('callId', callId)
    .set('nurseFullName', localStorage.getItem('nurseFullName'))
    .set('type', 'ER');
    const headers =  new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
      this.http.post(this.baseURL + '/EndCall', params, {headers: headers}).subscribe(
      data => {
      console.log('end call successfull', data);
    });
  }
}




import { Component, OnInit, Pipe, ViewChild, AfterViewInit, ViewChildren, HostListener } from '@angular/core';
import {FormControl, FormGroupDirective, FormGroup, NgForm, Validators} from '@angular/forms';
import { CommonFunctionsService } from '../services/common-functions.service';
import { AuthService } from '../services/auth.service';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE, ErrorStateMatcher} from '@angular/material/core';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { isMoment, Moment } from 'moment';
import * as moment from 'moment';
import { getLocaleDateTimeFormat } from '@angular/common';
import { DialogForProfileComponent } from '../dialog-for-profile/dialog-for-profile.component';
import {MatDialog} from '@angular/material';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, ActivatedRoute } from '@angular/router';
import * as firebase from 'firebase/app';

export const MY_FORMATS = {
  parse: {
    dateInput: 'MM/DD/YYYY',
    // dateInput: {month: 'short', year: 'numeric', day: 'numeric'},
},
display: {
    dateInput: 'MM/DD/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
},
};

export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}


@Component({
  selector: 'app-new-patient',
  templateUrl: './new-patient.component.html',
  styleUrls: ['./new-patient.component.css'],
  providers: [
    {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},
    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
  ]
})

export class NewPatientComponent implements OnInit {
  @ViewChildren('input') vc;
  // local variables
   public dobstring;
   public dobArrangedString;
   public convertedDob;
   loggedInUser;
   date;
   month;
   year;
   IAgree:any;
   today = new Date();
   
  matcher = new MyErrorStateMatcher();
   callIdReceivedFromQuery;
   // form control validation
   public createPatientData = {firstName: '', gender: 'Male', dob: '', IAgree: '', hospital_id: '123'};
   firstNameFormControl = new FormControl('', [
    Validators.required
  ]);
  lastNameFormControl = new FormControl('', [
    Validators.required
  ]);
  dobFormControl = new FormControl('', [
    Validators.required
  ]);
  IAgreeFormControl = new FormControl('', [
    Validators.required
  ]);
  genderOptionsformControl = new FormControl('', [
    Validators.required
  ]);

  constructor(public commonFunctionsService: CommonFunctionsService, private authService: AuthService,
    public dialogForProfile: MatDialog, private router: Router, private route: ActivatedRoute) {
    // function to get Current Logged In User
    this.authService.getUserFromstatechange().then((val) => this.loggedInUser = val).then(() => this.getHospitalId());
    const url = window.location.search;
    const urlparam = url.split('&');
    const receivedCallId = (urlparam[0]).split('=');
    this.callIdReceivedFromQuery =  receivedCallId[1];
    const callIdRef: firebase.database.Reference =
    firebase.database().ref('intakesData/stroke/' + this.callIdReceivedFromQuery + '/videoCallDetails/');
    callIdRef.on('value', snapshot => {
      if (window.location.pathname === '/create-patient') {
        if (snapshot.val()['endCallStatus'] === true) {
            alert('This session is ended');
            this.router.navigate(['/stroke-code']);
        }
      }
    });

  }

  ngOnInit() {
  }
  // on back press navigate to stroke code page by Maulik
  @HostListener('window:popstate', ['$event'])
  onPopState(event) {
   const res = confirm('Do you want to end the current session?');
   if (res === true) {
     this.authService.EndCallFromCanDeactive(this.callIdReceivedFromQuery);
     window.location.assign('/stroke-code');
   } else {
    this.authService.setToggle(false);
    window.history.forward();
    // this.authService.setToggle(false);
    // window.location.assign('/create-patient?callId=' + this.callIdReceivedFromQuery + '&initiated=true');
    // this.router.navigate(['/create-patient'], { queryParams: { callId: this.callIdReceivedFromQuery , initiated: true } });
   }
  }


   // tslint:disable-next-line:use-life-cycle-interface
  ngAfterViewInit() {
    // this.vc.first.nativeElement.focus();
  }
  getHospitalId() {
    firebase.database().ref('hospitalCampus/' + this.loggedInUser.toLowerCase() + '/campus/hospitalid/')
    .once('value', snapshot => {
        console.log('hospital Id', snapshot.val());
        this.createPatientData.hospital_id = snapshot.val();
    });
  }
  createPatient(firstName, gender, dob) {
    // steps to invert DOB Format
    console.log('hahaa', dob);
    console.log(dob);
    let d = new Date(dob);
    console.log(d.getDate());
    console.log(d.getMonth() + 1 );
    console.log(d.getFullYear());
    this.date = d.getDate();
    this.month = d.getMonth() + 1;
    this.year = d.getFullYear();
    console.log(String(this.date).length);
    if (String(this.date).length === 1) {
        this.date = '0' + this.date;
    }
    if (String(this.month).length === 1) {
      this.month = '0' + this.month;
    }
    console.log(this.date);
    var completeDate = this.month + '/' + this.date + '/' + this.year;
    // console.log('arranged', this.dobArrangedString.join('-'));
    // this.convertedDob = this.dobArrangedString.join('/');
    // this.convertedDob.format('dd-MM-yyyy');
    this.commonFunctionsService.createPatientRequest(firstName, gender, completeDate, this.createPatientData.hospital_id,
    this.callIdReceivedFromQuery);
  }
  openDialog() {
    this.opendialogForProfile('teleConsent');
  }
  opendialogForProfile(message) {
    const dialogRef = this.dialogForProfile.open(DialogForProfileComponent , {
      height: '420px',
      data : message
    });
  }
  navigateToSearch() {
    this.router.navigate(['/search-patient'], { queryParams: { callId: this.callIdReceivedFromQuery , initiated: true } });
  }

  valdidateFunction(event) {
    const fullDate = new Date(this.createPatientData.dob);
    const fullDay = fullDate.getDate();
    const fullMonth = fullDate.getMonth();
    const fullYear = fullDate.getFullYear();
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    console.log('date fullyear', fullYear);
    if (fullYear.toString().length === 2 || fullYear.toString().length === 4 ) {
      if (fullYear > currentYear) {
        const newYear = fullYear - 100;
        this.createPatientData.dob = (new Date(newYear, fullMonth, fullDay)).toISOString();
      }
    } else {
      this.createPatientData.dob = '';
    }
  }

}



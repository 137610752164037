import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, NavigationEnd } from '@angular/router';
import { Observable } from 'rxjs';
import { CanDeactivate } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { AngularFireAuth } from 'angularfire2/auth';
import {MatDialog} from '@angular/material';

export interface CanComponentDeactivate {
  canDeactivate: () => Observable<boolean> | Promise<boolean> | boolean;
 }
 @Injectable({
   providedIn: 'root',
 })
 export class CanDeactivateGuard implements CanDeactivate<CanComponentDeactivate> {
   status;
   response;
   constructor(public authService: AuthService, private _firebaseAuth: AngularFireAuth,
    private router: Router) {
   }
   canDeactivate(component: CanComponentDeactivate) {
    this.status = this.authService.getStatus();
    if (this.status._value === '') {
      var txt;
      var r = confirm('Are you sure you want to end call?');
      if (r === true) {
        return true;
      } else {
        return false;
      }
  } else {
    if (this.status._value === 'fromEndCallButton') {
    // var txt;
    // var r = confirm('Are you sure you want to end call?');
    // if (r === true) {
    //   this.authService.setStatus('');
    //   return true;
    // } else {
    //   return false;
    // }
     return true;
  } else {
    var txt;
    var r = confirm('Are you sure you want to end call?');
    if (r === true) {
      this.authService.setStatus('');
       this._firebaseAuth.auth.signOut().then(res => {
        // setTimeout(() => { this.router.navigate(['/']); } , 1000);
      });
      return true;
    } else {
      return false;
    }
  }
  }
  }
}


import { Component, ElementRef, AfterViewInit, ViewChild, Input, ChangeDetectorRef} from '@angular/core';
import { OpentokService } from '../opentok.service';
import { Observable } from 'rxjs/Observable';
import { AuthService } from '../services/auth.service';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { HttpParams, HttpClient, HttpHeaders, HttpErrorResponse} from '@angular/common/http';
import { Http, Response, Headers, URLSearchParams, RequestOptions } from '@angular/http';
import {MatDialog} from '@angular/material';
import {DialogForEndcallComponent} from '../dialog-for-endcall/dialog-for-endcall.component';
import * as firebase from 'firebase/app';
import {
  trigger,
  state,
  style,
  animate,
  transition
} from '@angular/animations';

declare var $: any;
const publish = () => {

};

@Component({
  selector: 'app-publisher',
  templateUrl: './publisher.component.html',
  styleUrls: ['./publisher.component.css'],
})

export class PublisherComponent implements AfterViewInit {
  show: Boolean = false;
  hideCallDiv: Boolean = false;
  @ViewChild('publisherDiv') publisherDiv: ElementRef;
  @Input() session: OT.Session;
  publisher: OT.Publisher;
  publishing: Boolean;
  videoPublishing: Boolean; // new
  AudioPublishing: Boolean; // new
  loggedInUser;
  callId;
  status;
  changeDetectorRef: ChangeDetectorRef;
  showHide: Boolean = false;
  hideVisibility: Boolean = true;
  minimizeStatus: Boolean = true;
  hideVisibiltyWhenMinimized;
  receivedCallIdFromQuery;
  constructor(private opentokService: OpentokService, public authService: AuthService,
     private router: Router, private http: HttpClient, private ref: ChangeDetectorRef, public dialogForEndCall: MatDialog) {
 // this.authService.subscriberStatus.subscribe(message => this.show = message)
    this.authService.getUserFromstatechange().then((val) => this.loggedInUser = val);
    const url = window.location.search;
    const urlparam = url.split('&');
    const receivedCallId = (urlparam[0]).split('=');
    const isInitiated = (urlparam[1]).split('=');
    this.receivedCallIdFromQuery =  receivedCallId[1];
    const callIdRef: firebase.database.Reference =
    firebase.database().ref('intakesData/stroke/' + this.receivedCallIdFromQuery + '/videoCallDetails/');
    callIdRef.on('value', snapshot => {
      if (window.location.pathname === '/video') {
        if (snapshot.val()!==null && snapshot.val()['endCallStatus'] === true) {
            window.close();
        }
      }
    });
    this.publishing = false;
    this.videoPublishing = false; // new
    this.AudioPublishing = false; // new
    this.status = this.authService.getDisconnectStatus();
    if (this.status === true) {
       this.changeDetectorRef = ref;
      this.session.disconnect();
      this.show = false;
    }
  }

  ngAfterViewInit() {
    const OT = this.opentokService.getOT();
    this.publisher = OT.initPublisher(this.publisherDiv.nativeElement, {insertMode: 'append'});
    this.show = true;
    if (this.session) {
      if (this.session['isConnected']()) {
        this.publish();
      }
      this.session.on('sessionConnected', () => this.publish());
    }
    $( function() {
      // $( '#publisher' ).draggable({ containment: '#subscriber' });
      $( '#draggableContainer' ).draggable({ containment: '#subscriber' });
    });
  }

  publish() {
    this.session.publish(this.publisher, (err) => {
      if (err) {
        alert(err.message);
      } else {
        this.publishing = true;
        this.hideCallDiv = true;
        this.videoPublishing = true; // new
        this.AudioPublishing = true; // new
      }
    });
  }

  stopVideo() {
      if (this.videoPublishing === true) {
      this.publisher.publishVideo(false);
      this.videoPublishing = false;
    } else {
        this.publisher.publishVideo(true);
        this.videoPublishing = true;
    }
  }

  stopAudio() {
    if (this.AudioPublishing === true) {
      this.publisher.publishAudio(false);
      this.AudioPublishing = false;
    } else {
      this.publisher.publishAudio(true);
      this.AudioPublishing = true;
    }
  }
  sessionDisconnect() {
    // const url = window.location.search;
    // const urlparam = url.split('&');
    // const receivedCallId = (urlparam[0]).split('=');
    // this.callId =  receivedCallId[1];
    // const params = new HttpParams()
    // .set('userId', this.loggedInUser)
    // .set('callId', this.callId)
    // .set('type', 'ER');
    // const headers =  new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
    //   this.http.post(this.authService.baseURL + '/EndCall', params, {headers: headers}).subscribe(
    //   data => {
    //   console.log('end call successfull', data);
    // });
    const dialogRef = this.dialogForEndCall.open(DialogForEndcallComponent, {
      height: '125px',
      data: 'fromEndCallButton'
    });
  }
  redirectTo(uri: string) {
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(() =>
    this.router.navigate([uri]));
  }
  showHidePublisher() {
    if (this.showHide === true) {
      this.showHide = false;
      this.hideVisibility = false;
    } else {
      this.hideVisibility = true;
      this.showHide = true;
    }
}
minimize() {
  var el = document.getElementById('iconDiv');
  if (this.minimizeStatus === true) {
  el.style.bottom = '0';
  el.style.left = '0';
  el.style.position = 'fixed';
  this.showHide = true;
  this.minimizeStatus = false;
  this.hideVisibiltyWhenMinimized = true;
  } else {
    this.showHide = false;
    el.style.position = 'relative';
    this.hideVisibility = true;
    this.minimizeStatus = true;
    this.hideVisibiltyWhenMinimized = false;
  }
}
}


import { Component, OnInit, ViewChildren, AfterViewInit, HostListener} from '@angular/core';
import { HttpParams, HttpClient, HttpHeaders } from '@angular/common/http';
import { Http, Headers, Response, RequestOptions,  } from '@angular/http';
import { AuthService } from '../services/auth.service';
import { MatRadioChange } from '@angular/material';
import { CommonFunctionsService } from '../services/common-functions.service';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { LoadingBarService } from '@ngx-loading-bar/core';
import {FormControl, FormGroupDirective, NgForm, Validators} from '@angular/forms';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE, ErrorStateMatcher} from '@angular/material/core';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import {MatDatepickerInputEvent} from '@angular/material/datepicker';
import * as firebase from 'firebase/app';

export const MY_FORMATS = {
  parse: {
    dateInput: 'MM/DD/YYYY',
    // dateInput: {month: 'short', year: 'numeric', day: 'numeric'},
},
display: {
    dateInput: 'MM/DD/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
},
};

export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}

@Component({
  selector: 'app-search-patient',
  templateUrl: './search-patient.component.html',
  styleUrls: ['./search-patient.component.css'],
  providers: [
    {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},
    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
  ]
})
export class SearchPatientComponent implements OnInit {
  fullName = localStorage.getItem('nuserFullName');
  @ViewChildren('input') vc;
  events: string[] = [];
  searchType = 'name';
  searchValue: any = '';
  PatientDOB: any =  '';
  isValidSearch: boolean;
  modifyList;
  resultDiv = false;
  public resultList: string[] = [];
  public resultMaleList: string[] = [];
  public resultFemaleList: string[] = [];
 
  matcher = new MyErrorStateMatcher();

  dobstring;
  dobArrangedString;
  convertedDob;
  loggedInUser;
  showingResultForDOB;
  showDataNotFound;
  searchCategory;
  showSearchResult = false;
  showMalePatientList = false;
  showFemalePatientList = false;
  today = new Date();
  isloading: boolean;
  date;
  month;
  year;
  totalHeight;
  upperSpace;
  middleHeight;
  remainingheight;
  completeDate;
  showResults;
  callIdReceivedFromQuery;
  isSessionEndedPopupShown =  false;
  hospital_id;
  dateFormControl = new FormControl('', [
   Validators.required
  ]);

  constructor(private http: HttpClient, public authService: AuthService,
    public commonFunctionsService: CommonFunctionsService, private router: Router , private loader: LoadingBarService) {
      this.authService.getUserFromstatechange().then((val) => this.loggedInUser = val).then(() => this.getHospitalId());
      const url = window.location.search;
      const urlparam = url.split('&');
      const receivedCallId = (urlparam[0]).split('=');
      this.callIdReceivedFromQuery =  receivedCallId[1];
      const callIdRef: firebase.database.Reference =
      firebase.database().ref('intakesData/stroke/' + this.callIdReceivedFromQuery + '/videoCallDetails/');
      callIdRef.on('value', snapshot => {
        if (window.location.pathname === '/search-patient') {
          if (snapshot.val()['endCallStatus'] === true) {
              alert('This session is ended');
              this.router.navigate(['/stroke-code']);
            }
          }
      });
     }

  ngOnInit() {
  }
  // tslint:disable-next-line:use-life-cycle-interface
  ngAfterViewInit() {
    this.vc.first.nativeElement.focus();
  }
  // on back press navigate to stroke code page by Maulik
  @HostListener('window:popstate', ['$event'])
  onPopState(event) {
    const res = confirm('Do you want to end the current session?');
    if (res) {
    this.authService.EndCallFromCanDeactive(this.callIdReceivedFromQuery);
     window.location.assign('/stroke-code');
    } else {
     // history.pushState(null, null, null);
     this.authService.setToggle(false);
     window.history.forward();
    }
  }
  focusOnSearch() {
    this.PatientDOB = '';
    this.showingResultForDOB = false;
    this.dateFormControl.setValue('');
    this.dateFormControl.clearValidators();
    this.dateFormControl.updateValueAndValidity();
  }
  focusOnDOB() {

    this.searchValue = '';
    this.showingResultForDOB = true;
    this.dateFormControl.setValidators(Validators.required);
    this.dateFormControl.updateValueAndValidity();
  }

  getHospitalId() {
    firebase.database().ref('hospitalCampus/' + this.loggedInUser.toLowerCase() + '/campus/hospitalid/')
    .once('value', snapshot => {
        console.log('hospital Id', snapshot.val());
        this.hospital_id = snapshot.val();
    });
  }

  SearchPatients() {
    this.showDataNotFound = false;
    this.isValidSearch = false;
    if (this.showingResultForDOB) {
      this.searchCategory = 'dob';
    } else {
      if (isNaN(this.searchValue)) {
        this.searchCategory = 'name';
      } else {
        this.searchCategory = 'mrn';
      }
    }
    this.resultDiv = true;
    this.showSearchResult = true;
    // this.isValidSearch = false;
    let params;
    this.resultList = [];
    this.showSearchResult = false;
    if (this.searchCategory === 'name' && this.searchValue !== '') {
      this.isValidSearch = true;
      this.completeDate = '';
      params = new HttpParams().set('category', this.searchCategory)
      .set('name', this.searchValue).set('hospital_id', this.hospital_id).set('type', 'ER');
    }

    if (this.searchCategory === 'mrn' && this.searchValue !== '') {
      this.isValidSearch = true;
      params = new HttpParams().set('category', this.searchCategory)
      .set('mrn', this.searchValue).set('hospital_id', this.hospital_id).set('type', 'ER');
    } else if (this.searchCategory === 'dob' && !this.dateFormControl.invalid) {
      // if (this.PatientDOB.length === 10) {
         // this.isValidSearch = true;
        // this.dobstring = String(this.PatientDOB).split('-');
        // this.dobArrangedString = this.dobstring.reverse();
        // console.log('arranged', this.dobArrangedString.join('-'));
        // this.convertedDob = this.dobArrangedString.join('/');
          let d = new Date(this.PatientDOB);
          console.log(d.getDate());
          console.log(d.getMonth() + 1 );
          console.log(d.getFullYear());
          this.date = d.getDate();
          this.month = d.getMonth() + 1;
          this.year = d.getFullYear();
          console.log(String(this.date).length);
          if (String(this.date).length === 1) {
              this.date = '0' + this.date;
          }
          if (String(this.month).length === 1) {
            this.month = '0' + this.month;
          }
          console.log(this.date);
          this.completeDate = this.month + '/' + this.date + '/' + this.year;
          if (!isNaN(this.date) &&  this.completeDate !== '01/01/1970') {
            this.isValidSearch = true;
          }
        params = new HttpParams().set('category', this.searchCategory)
        .set('dob', this.completeDate).set('hospital_id', this.hospital_id).set('type', 'ER');

      // }
    }
    // this.loader.set(60);
    if (this.isValidSearch) {
      this.showResults = this.searchValue;
      this.loader.start();
      this.loader.set(10);
      const headers =  new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
      this.http.post(this.authService.baseURL + '/PatientSearch', params, {headers: headers}).subscribe(
        response => {
          if (response['Message'] === 'Success') {
            this.loader.complete();
            this.showSearchResult = true;
            this.showResults = this.searchValue;
            console.log(response['Data']);
            this.resultList = response['Data'];
            // this.resultMaleList = response['Data'].filter(user => user.gender === 'Male'); // store only male data
            // this.resultFemaleList = response['Data'].filter(user => user.gender === 'Female'); // store only Female data
          } else {
            this.loader.complete();
            this.showDataNotFound = true;
          }
        },
        err => {
          this.loader.complete();
          console.log(err);
        });
    }
  }

  navigateToRegister() {
    // On click of Register Button navigate to Create Patient
    // this.router.navigate(['create-patient']);
    this.router.navigate(['/create-patient'], { queryParams: { callId: this.callIdReceivedFromQuery , initiated: true } });
  }
  passSearchedPatientData(name, PatientId, gender, dob) {
    // Pass Searched Patient's Data to Service In order to show Patiet Details on SidebarUI Comp
    this.commonFunctionsService.passDataToService(name, PatientId, gender, dob);
    this.associatePatientToCall(PatientId, this.callIdReceivedFromQuery);
    var setPatientInfo = firebase.database().ref('patientInfo/stroke/' + this.callIdReceivedFromQuery);
    setPatientInfo.set({
        name : name,
        PatientId: PatientId,
        dob: dob,
        gender: gender,
        userId: this.loggedInUser
    })
     .then(resolve => {
      this.router.navigate(['/intake-form'], { queryParams: { callId: this.callIdReceivedFromQuery , initiated: true } });
    }, reject => {
    })
    .catch(reject => {
    });
  }

  addEvent(event: MatDatepickerInputEvent<Date>) {
    this.focusOnDOB();
  }

  associatePatientToCall(patientId, callId) {
    const params = new HttpParams()
    .set('patientId', patientId)
    .set('callId', callId).set('nurseFullName', this.fullName);
    const headers =  new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
    this.http.post(this.commonFunctionsService.baseURL + '/SetPatientData', params, {headers: headers}).subscribe(
      resp => {
        console.log('this is createPatient data', resp);
        if (resp['Message'] === 'Success') {

        }
    });
  }
}

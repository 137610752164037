import { Component, OnInit, ChangeDetectorRef , HostListener, OnDestroy} from '@angular/core';
import { OpentokService } from '../opentok.service';
import { HttpParams, HttpClient, HttpHeaders, HttpErrorResponse} from '@angular/common/http';
import * as OT from '@opentok/client';
import { AuthService } from '../services/auth.service';
import { CommonFunctionsService } from '../services/common-functions.service';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, NavigationEnd, ActivatedRoute} from '@angular/router';
import { PlatformLocation } from '@angular/common';
import { Location } from '@angular/common';
import {MatDialog} from '@angular/material';
import {DialogForEndcallComponent} from '../dialog-for-endcall/dialog-for-endcall.component';
import * as firebase from 'firebase/app';
import {MatSnackBar} from '@angular/material';

@Component({
  selector: 'app-directcall',
  templateUrl: './directcall.component.html',
  styleUrls: ['./directcall.component.css']
})
export class DirectcallComponent implements OnInit {
  
  session: OT.Session;
  streams: Array<OT.Stream> = [];
  changeDetectorRef: ChangeDetectorRef;
  video = {'token': '', 'sessionId': ''};
  callId;
  isCallInitiated;
  loggedInUser;
  getStatus;
  receivedCallIdFromQuery;
  constructor(private ref: ChangeDetectorRef, private opentokService: OpentokService,
    private http: HttpClient, public authService: AuthService, public commonFunctionsService: CommonFunctionsService,
    private router: Router,  private route: ActivatedRoute, public openDialogForEndCall: MatDialog,
    public dialogForEndCall: MatDialog, public snackBar: MatSnackBar) {
    const url = window.location.search;
    const urlparam = url.split('&');
    const receivedCallId = (urlparam[0]).split('=');
    this.receivedCallIdFromQuery =  receivedCallId[1];
    this.getStatus = this.authService.getStatus();
    this.changeDetectorRef = ref;
    this.router.events
    .filter(event => (event instanceof NavigationEnd))
    .subscribe((routeData: any) => {
      if (routeData.urlAfterRedirects === '/') {
        this.session.disconnect();
      }
      if (routeData.urlAfterRedirects === '/stroke-code') {
          this.session.disconnect();
          // alert('stop');
      }
      if (routeData.urlAfterRedirects === '/create-patient') {
         this.session.disconnect();
        //  alert('stop');
      }
      if (routeData.urlAfterRedirects === '/intake-form') {
         this.session.disconnect();
        //  alert('stop');
      }
      if (routeData.urlAfterRedirects === '/search-patient') {
        this.session.disconnect();
        // alert('stop');
      }
      if (routeData.urlAfterRedirects === '/charts-records') {
        this.session.disconnect();
        // alert('stop');
      }
      if (routeData.urlAfterRedirects === '/nurse-profile') {
         this.session.disconnect();
      }
      });
  }

  ngOnInit () {
    this.authService.getUserFromstatechange().then((val) => this.loggedInUser = val).then(() =>
    this.checkISCallInitiated());
    this.getDoctorsVideoActions();
  }
  @HostListener('window:beforeunload', ['$event'])
  onPopState(event) {
    if (this.getStatus._value !== 'fromEndCallButton' && this.getStatus._value !== 'callRejected'
    && this.getStatus._value !== 'callEnded') {
      var confirmationMessage = '\o/';
      (event || window.event).returnValue = confirmationMessage;
      return confirmationMessage;
    }
  }
  /*================== Start Session Video Call =============*/
  StartVideoSession() {
    return new Promise((resolve, reject) => {
        this.opentokService.initSession()
          .then((session: OT.Session) => {
            this.session = session;
            // alert("received session Details");
            console.log('came', session);
            this.video.token = session['token'];
            this.video.sessionId = session['sessionId'];
          // alert(this.session["sessionId"]);
          // alert(this.session["token"]);
            this.session.on('streamCreated', (event) => {
              this.streams.push(event.stream);
              this.changeDetectorRef.detectChanges();
            });
            this.session.on('streamDestroyed', (event) => {
              const idx = this.streams.indexOf(event.stream);
              if (idx > -1) {
                this.streams.splice(idx, 1);
                this.changeDetectorRef.detectChanges();
              }
            });
            resolve();
        })
        .then(() => this.opentokService.connect())
        .catch((err) => {
          console.error(err);
          alert('Unable to connect. Make sure you have updated the config.ts file with your OpenTok details.');

        });
    });
  }

  updateVideoToken(callId) {
    const headers =  new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
    const params = new HttpParams().set('callId', this.callId)
    .set('sessionId', this.video.sessionId)
    .set('videoToken', this.video.token)
    .set('type', 'emergencyCall');
    this.http.post( this.authService.baseURL + '/UpdateVideoToken', params, {headers: headers}).subscribe(
    data => {
      console.log('receive call', data);
      if (data['Message'] === 'Success' ) {
        const callIdRef: firebase.database.Reference = firebase.database().ref('emergencyCall/stroke/' + callId + '/videoCallDetails/' );
        callIdRef.update({
          sessionId: this.video.sessionId,
          videoToken: this.video.token,
          time: firebase.database.ServerValue.TIMESTAMP
        });
        var rejectedByArr = [];
        const videoCallActionStatusRef: firebase.database.Reference = firebase.database().ref('/videoCallActions/stroke/' + callId);
        videoCallActionStatusRef.set({
          status: 'initiated',
          rejectedBy: JSON.stringify(rejectedByArr)
        });
        const videoCallActionRef: firebase.database.Reference = firebase.database().ref('/videoCallActions/stroke/' + callId + '/actions/');
        videoCallActionRef.push({
          action: 'initiated',
          info: 'Call is initiated by Nurse',
          timestamp: firebase.database.ServerValue.TIMESTAMP,
          user: this.loggedInUser
        });
      } else {
        const callIdRef: firebase.database.Reference = firebase.database().ref('emergencyCall/stroke/' + callId + '/videoCallDetails/' );
        callIdRef.update({
          sessionId: this.video.sessionId,
          videoToken: this.video.token,
          time: firebase.database.ServerValue.TIMESTAMP
        });
        var rejectedByArr = [];
        const videoCallActionStatusRef: firebase.database.Reference = firebase.database().ref('/videoCallActions/stroke/' + callId);
        videoCallActionStatusRef.set({
          status: 'initiated',
          rejectedBy: JSON.stringify(rejectedByArr)
        });
        const videoCallActionRef: firebase.database.Reference = firebase.database().ref('/videoCallActions/stroke/' + callId + '/actions/');
        videoCallActionRef.push({
          action: 'initiated',
          info: 'Call is initiated by Nurse',
          timestamp: firebase.database.ServerValue.TIMESTAMP,
          user: this.loggedInUser
        });
      }
    });
  }
  getVideoTokenDetails(callId) {
    return new Promise((resolve, reject) => {
      const params = new HttpParams()
      .set('callId', callId);
      const headers =  new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
      this.http.post(this.authService.baseURL + '/GetVideoTokenDetailsTest', params, {headers: headers}).subscribe(
      data => {
        if (data['Message'] === 'Success') {
          console.log('this is query params data', data);
          this.video.token = data['data']['videoToken'];
          this.video.sessionId = data['data']['videoSessionId'];
          // alert('Token' + this.video.token + 'Session' + this.video.sessionId);
          // set received Patient Id to set Charts and records for Ongoing call
          this.commonFunctionsService.PatientId = data['data']['patientId'];
          this.getPatientInfo(this.commonFunctionsService.PatientId).then(() => this.ReceivedCall());
          resolve();
        }
      });
    });
  }

  startCallBackVideoSession() {
    // this.opentokService.joinSession(sid, token)
    // alert('Token' + this.video.token + 'Session' + this.video.sessionId);
    this.opentokService.joinSession(this.video.sessionId, this.video.token)
    .then((session: OT.Session) => {
        this.session = session;
        this.session.on('streamCreated', (event) => {
          this.streams.push(event.stream);
          this.changeDetectorRef.detectChanges();
        });
        this.session.on('streamDestroyed', (event) => {
          const idx = this.streams.indexOf(event.stream);
          if (idx > -1) {
            this.streams.splice(idx, 1);
            this.changeDetectorRef.detectChanges();
          }
        });
      })
      .then(() =>
        this.opentokService.connect())
      .catch((err) => {
        console.error(err);
        alert('Unable to connect. Make sure you have updated the config.ts file with your OpenTok details.');
      });
  }

  checkISCallInitiated() {
    const url = window.location.search;
    const urlparam = url.split('&');
    const receivedCallId = (urlparam[0]).split('=');
    const isInitiated = (urlparam[1]).split('=');
    this.callId =  receivedCallId[1];
    this.isCallInitiated = isInitiated[1];
    console.log('callId', this.callId);
    if (this.isCallInitiated === 'true') {

      this.StartVideoSession().then(res => this.updateVideoToken(this.callId));

    } else {
      this.getVideoTokenDetails(this.callId).then(res => this.startCallBackVideoSession());
    }
  }

  ReceivedCall() {
    const headers =  new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
    const params = new HttpParams().set('callId', this.callId)
    .set('userId', this.loggedInUser);
    this.http.post( this.authService.baseURL + '/ReceiveCall', params, {headers: headers}).subscribe(
    data => {
      console.log('receive call', data);
      if (data['Message'] === 'Success' ) {
        // alert('video Session Started');
      }
    });
  }

  getPatientInfo(patientId) {
    return new Promise((resolve, reject) => {
      const headers =  new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
      const params = new HttpParams()
      .set('mrn', patientId );
      this.http.post(this.authService.baseURL + '/GetPatientInfo', params, {headers: headers}).subscribe(
      data => {
      console.log('get info', data);
      if (data['message'] === 'success') {
        this.commonFunctionsService.PatientName = data['data']['name'];
      }
      resolve();
      });
    });
  }
  // Notify user when doctor accepts or rejects call
  getDoctorsVideoActions() {
    const url = window.location.search;
    const urlparam = url.split('&');
    const receivedCallId = (urlparam[0]).split('=');
    const isInitiated = (urlparam[1]).split('=');
    this.callId =  receivedCallId[1];
    const callIdRef: firebase.database.Reference = firebase.database().ref('videoCallActions/stroke/' + this.callId + '/actions/');
    callIdRef.on('child_added', snapshot => {
        if (snapshot.val()['action'] === 'accepted') {
          // this.openSnackBar('Call is received by ' + snapshot.val()['doctorName']);
          // this.authService.setDoctorNameForAction(snapshot.val()['doctorName']);
          this.authService.doctorName = snapshot.val()['user'];
          const dialogRef = this.dialogForEndCall.open(DialogForEndcallComponent, {
            height: '125px',
            data: 'callAccepted'
          });
        } else {
          if (snapshot.val()['action'] === 'rejected') {
            // this.openSnackBar('Call is rejected by ' + snapshot.val()['doctorName']);
            // this.authService.setDoctorNameForAction(snapshot.val()['doctorName']);
            this.authService.doctorName = snapshot.val()['user'];
            const dialogRef = this.dialogForEndCall.open(DialogForEndcallComponent, {
              height: '135px',
              data: 'callRejected'
            });
          } else {
            if (snapshot.val()['action'] === 'endedByDoctor') {
              // this.openSnackBar('Call is rejected by ' + snapshot.val()['doctorName']);
              // this.authService.setDoctorNameForAction(snapshot.val()['doctorName']);
              this.authService.doctorName = snapshot.val()['user'];
              const dialogRef = this.dialogForEndCall.open(DialogForEndcallComponent, {
                height: '125px',
                data: 'callEnded'
              });
            }
          }
        }
    });
  }
  openSnackBar(message: string) {
    this.snackBar.open(message, '', {
      duration: 15000,
      panelClass: ['blue-snackbar']
    });
  }
}

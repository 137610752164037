import { Injectable } from '@angular/core';
import { HttpParams, HttpClient, HttpHeaders} from '@angular/common/http';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import {BehaviorSubject} from 'rxjs';
import { ErrorDialogComponent } from '../error-dialog/error-dialog.component';
import {MatDialog} from '@angular/material';
import { LoadingBarService } from '@ngx-loading-bar/core';
import * as firebase from 'firebase/app';
import {AuthService} from './auth.service';
@Injectable({
  providedIn: 'root'
})
export class CommonFunctionsService {
  // public baseURL = 'https://stathealthmd.appspot.com/';
  // public baseURL = 'https://stathealthmd-test.appspot.com';
  // public baseURL = 'https://sevaro-usa-backend.appspot.com/';
  // public baseURL = 'https://sevaro-usa-backend.appspot.com/';
  fullName = localStorage.getItem('nurseFullName');
  public baseURL = 'https://telestroke-sevaro.uc.r.appspot.com/';
  public PatientId = new BehaviorSubject('');
  public PatientName;
  public PatientGender;
  public PatientDOB;
  public CallIdForOnGoingCall;
  dobstring;
  dobArrangedString;
  convertedDob;
  searchedPatientId = this.PatientId.asObservable();
  loggedInUser;
  isloading = false; // Loader variable

  constructor(private http: HttpClient, private router: Router, public errorDialog: MatDialog ,
    public loader: LoadingBarService, private authService: AuthService) {
      this.authService.getUserFromstatechange().then((val) => this.loggedInUser = val);
    }
  // create new patient
  createPatientRequest(name, gender, dob, hospital_id, callIdReceivedFromQuery) {
    
    this.loader.start();
    this.loader.set(10);
    const params = new HttpParams()
      .set('name', name)
      .set('gender', gender)
      .set('dob', dob)
      .set('hospital_id', hospital_id);
      const headers =  new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
      this.http.post(this.baseURL + '/PatientData', params, {headers: headers}).subscribe(
        data => {
          console.log('this is createPatient data', data);
          if (data['Message'] === 'Success') {
            // this.PatientId.next(data['PatientId']);

            this.PatientId = data['PatientId'];
            this.PatientName = name;
            this.PatientGender = gender;
            this.dobstring = String(dob).split('-');
            this.dobArrangedString = this.dobstring.reverse();
            console.log('arranged', this.dobArrangedString.join('-'));
            this.convertedDob = this.dobArrangedString.join('/');
            this.PatientDOB = this.convertedDob;
            console.log('DOB from create Patient' , this.PatientDOB);
            console.log('Common service', this.PatientDOB);
            this.associatePatientToCall(this.PatientId , callIdReceivedFromQuery);
            this.isloading = false; // Loader stop
            var setPatientInfo = firebase.database().ref('patientInfo/stroke/' + callIdReceivedFromQuery);
            setPatientInfo.set({
                name : this.PatientName,
                PatientId: this.PatientId,
                dob: this.PatientDOB,
                gender: this.PatientGender,
                userId: this.loggedInUser
            })
            .then(resolve => {
              this.router.navigate(['/intake-form'], { queryParams: { callId: callIdReceivedFromQuery , initiated: true,patientId: this.PatientId } });
            }, reject => {
            })
            .catch(reject => {
            });
          } else {
            // this.isloading = false; // Loader stop
            this.openErrorDialog();
          }
          this.loader.complete();
        },
        err => {
          this.openErrorDialog();
          this.loader.complete();
        });
  }

  associatePatientToCall(patientId, callId) {
    const params = new HttpParams()
    .set('patientId', patientId)
    .set('callId', callId).set('nurseFullName', this.fullName);
    const headers =  new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
    this.http.post(this.baseURL + '/SetPatientData', params, {headers: headers}).subscribe(
      resp => {
        console.log('this is createPatient data', resp);
        if (resp['Message'] === 'Success') {

        }
    });
  }
  // pass patient details
  passDataToService(name, PatientId, gender, dob) {
    // this.PatientId.next(PatientId);
    this.PatientId = PatientId;
    this.PatientName = name;
    console.log('name', this.PatientName);
    this.PatientGender = gender;
    this.PatientDOB = dob;
    console.log('DOB from Search Patient' , this.PatientDOB);
    console.log(this.PatientId);
    console.log('Patient name', this.PatientName);
    // this.router.navigate(['/intake-form'], { queryParams: { callId: callIdReceivedFromQuery , initiated: true } });
  }
  setPatientNameBlankForDirectCall() {
    this.PatientName = '';
  }
  CalculateAge(patientDOB) {
    if (patientDOB) {
      const today = new Date();
      const birthDate = new Date(patientDOB);
      let age = today.getFullYear() - birthDate.getFullYear();
      const m = today.getMonth() - birthDate.getMonth();
      if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
    }
    if (age < 0 ) {
      age = 0;
    }
    return age;
    }
  }
  openErrorDialog() {
    const dialogRef = this.errorDialog.open(ErrorDialogComponent , {
      height: '72px',
      data : 'Some error occurred, please try again.'
    });
  }

}

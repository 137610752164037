import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class ActivateSearchGuard implements CanActivate {
  getStatus;
  callIdReceivedFromQuery;
  constructor (public authService: AuthService, private router: Router) {
   this.getStatus =  this.authService.getActiveStatus();
  }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    if (this.getStatus._value === '') {
        return true;
    } else {
      var resp = confirm('Are you sure you want to end this session?');
      if (resp === true) {
      this.router.navigate(['stroke-code']);
      return false;
      } else {
        // this.router.navigate(['intake-form']);
        // history.pushState(null, null);
        window.addEventListener('popstate', function () {
            history.pushState(null, null, document.URL);
        });
        return false;
      }
    }
  }
}

import { Injectable, OnInit } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';
import {Router } from '@angular/router';
import { AngularFireAuth } from 'angularfire2/auth';
import * as firebase from 'firebase/app';
import 'rxjs/add/operator/do';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/take';
@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate, OnInit {
 constructor(private router: Router , private authService: AuthService, private _firebaseAuth: AngularFireAuth) {
 }
 ngOnInit() {
 }
 canActivate(
  next: ActivatedRouteSnapshot,
  state: RouterStateSnapshot): Observable<boolean> {


    return this._firebaseAuth.authState
     .take(1)
     .map(user => {
       return !!user;
     })
     .do(loggedIn => {
       if (!loggedIn) {
         console.log('access denied');
         this.router.navigate(['/']);
       } else {
        // firebase.auth().onAuthStateChanged(function(user) {
        //   if (user == null) {
        //     // alert("State Changed Logged Out");
        //     this.router.navigate(['/']);
        //   }
        //   else {
        //     // alert("State Changed Logged In");
        //   }
       }
        });
       }
}


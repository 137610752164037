import { element } from "protractor";
import {
  Component,
  OnInit,
  ChangeDetectorRef,
  ElementRef,
  ViewChild,
  ViewChildren,
  AfterViewInit,
  HostListener,
  OnDestroy,
} from "@angular/core";
import {
  FormControl,
  Validators,
  FormBuilder,
  FormGroup,
  FormGroupDirective,
  NgForm,
} from "@angular/forms";
import {
  HttpParams,
  HttpClient,
  HttpHeaders,
  HttpErrorResponse,
} from "@angular/common/http";
import {
  Http,
  Response,
  Headers,
  URLSearchParams,
  RequestOptions,
} from "@angular/http";
import { AuthService } from "../services/auth.service";
import { CommonFunctionsService } from "../services/common-functions.service";
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
  ActivatedRoute,
  NavigationEnd,
} from "@angular/router";
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
  ErrorStateMatcher,
} from "@angular/material/core";
import { MomentDateAdapter } from "@angular/material-moment-adapter";
import { ErrorDialogComponent } from "../error-dialog/error-dialog.component";
import { SuccessDialogComponent } from "../success-dialog/success-dialog.component";

import { EditintakeDialogComponent } from "../editintake-dialog/editintake-dialog.component";

import { getMatIconFailedToSanitizeLiteralError, MatDialog } from "@angular/material";
import { LoadingBarService } from "@ngx-loading-bar/core";
import * as moment from "moment";
import { MatDatepickerInputEvent } from "@angular/material/datepicker";
import { MatSnackBar } from "@angular/material";
import { PlatformLocation } from "@angular/common";
import * as firebase from "firebase/app";
import { Observable } from "rxjs";
import * as $ from "jquery";

declare var $: any;
export const MY_FORMATS = {
  parse: {
    dateInput: "MM/DD/YYYY",
  },
  display: {
    dateInput: "MM/DD/YYYY",
    monthYearLabel: "MMM YYYY",
    dateA11yLabel: "LL",
    monthYearA11yLabel: "MMMM YYYY",
  },
};
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(
    control: FormControl | null,
    form: FormGroupDirective | NgForm | null
  ): boolean {
    const isSubmitted = form && form.submitted;
    return !!(
      control &&
      control.invalid &&
      (control.dirty || control.touched || isSubmitted)
    );
  }
}

@Component({
  selector: "app-sidebar-ui",
  templateUrl: "./sidebar-ui.component.html",
  styleUrls: ["./sidebar-ui.component.css"],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
  /*providers: [ OpentokService ]*/
})
export class SidebarUIComponent implements OnInit, AfterViewInit {
  // -----------Firebase variables---------
  items: Observable<any[]>;
  receivedCallIdFromQuery;
  patientId;
  statusToSaveData;
  // video = { token: "", sessionId: "" };
  @ViewChild("physician") inputEl: ElementRef;
  @ViewChildren("armOrLegWeakness") vc;
  /*session: OT.Session;
  streams: Array<OT.Stream> = [];
  changeDetectorRef: ChangeDetectorRef;*/
  // local variables
  allergiesCustomVariable = "";
  pastSurgicalHistoryCustomVariable = "";
  antiPlateletTherapyMenu = false;
  antiCoagulationTherapyMenu = false;
  meansOfArrivalChecked = false;
  today = new Date();
  yesterday = new Date();
  momentDate = moment(new Date());
  defaultDate: Date = new Date();
  offset =(new Date()).getTimezoneOffset();
  matcher = new MyErrorStateMatcher();
  displayErrorsReason;
  displayCheckReason;
  displayErrorsMeansOfArrival = false;
  displayCheckMeansOfArrival = false;
  displayErrorsSymptoms = false;
  displayCheckSymptoms = false;
  displayErrorTpaEligibility = false;
  displayCheckTpaEligibility = false;
  displayErrorVitalSigns = false;
  displayCheckVitalSigns = false;
  displayErrorPastMedical = false;
  displayCheckPastMedical = false;
  displayErrorSurgicalHistory = false;
  displayCheckSurgicalHistory = false;
  displayErrorMedicatons = false;
  displayCheckMedications = false;
  displayErrorFamily = false;
  displayCheckFamily = false;
  reasonStatus = false;
  symptomsStatus = false;
  tpaEligibilityStatus = false;
  vitalSignsStatus = false;
  pastMedicalStatus = false;
  medicationsStatus = false;
  familyStatus = false;
  dateString = "1997-06-28";
  retrievedData;
  loggedInUser;
  retrieveArrayForReasonForConsult;
  receivedPatientId;
  receivedPatientName;
  receivedPatientGender;
  receivedPatientDOB;
  age;
  receivedDate;
  receivedMonth;
  receivedYear;
  date;
  month;
  year;
  cigaretteCheck: Boolean = false;
  cigaretteConsumption: any;
  editStatus:Boolean =true; // add this
  displayError: Boolean = true;
  showError: Boolean = false;
  setLastKnownNormalVar: Boolean;
  statusForSentToCt: Boolean = false;
  statusForCtDone: Boolean = false;
  statusForReadyForVideo: Boolean = false;
  statusLastKnownNormal: Boolean = false;
  arrivalToEdDateFormControl = new FormControl("", [Validators.required]);
  arrivalToEdTimeControl = new FormControl("", [Validators.required]);
  lastKnownNormalDateFormControl = new FormControl("", [Validators.required]);
  // lastKnownNormalDateFormControl = new FormControl((new Date()).toISOString());
  dateNormal = new FormControl(new Date());
  lastKnownNormalTimeControl = new FormControl("", [Validators.required]);
  weightControl = new FormControl("", [Validators.required]);
  weightUnitFormControl = new FormControl("", [Validators.required]);
  bloodPressureControl = new FormControl("", [Validators.required]);
  heartRateControl = new FormControl("", [Validators.required]);
  oxygenSaturationControl = new FormControl("", [Validators.required]);
  tempControl = new FormControl("", [Validators.required]);
  tempUnitFormControl = new FormControl("", [Validators.required]);
  possibleStrokeControl = new FormControl("", [Validators.requiredTrue]);
  rrControl = new FormControl("", [Validators.required]);
  bloodGlucoseControl = new FormControl("", [Validators.required]);
  medicationsOptionsControl = new FormControl("", [Validators.required]);
  allergiesOptionsControl = new FormControl("", [Validators.required]);
  surgicalHistoryControl = new FormControl("", [Validators.required]);
  meansOfArrivalOptionsControl = new FormControl("", [Validators.required]);
  meansOfArrivalCustom = new FormControl("", [Validators.required]);
  familyContactNumberControl = new FormControl("", [Validators.required]);
  patientOnAntiPlateletTherapyControl = new FormControl("", [Validators.required]);
  alcoholConsumptionControl = new FormControl("", [Validators.required]);
  cigarettesPerDayControl = new FormControl("", [Validators.required]);
  cigarettesPerDayQuantityControl = new FormControl("", [Validators.required]);
  patientOnAntiCoagulationTherapyControl = new FormControl("", [Validators.required]);
  preArrivalOptionsControl = new FormControl("", [Validators.required]);
  cigaretteSection: Boolean = false;
  ambulance_option : Boolean = false;
  
  meansOfArrivalObj = {};
  meansOfArrivalObjDup;
  socialHistoryObj = {};
  
  normalDate: Date;
  nurseFullName = localStorage.getItem('nurseFullName');
  // Patient Settings form
  dm: boolean;
  patientForm: FormGroup;
  public patientSettings = {
    familyContactNumber: "",
    meansOfArrivalOptions: "",
    meansOfArrivalCustom: "",
    preArrivalOption: "",
    patientOnAntiplatelet: "",
    patientOnAnticoagulation: "",
    alcoholConsumption: "",
    cigarettesPerDay: "",
    cigarettesPerDayQuantity: "",
    nurse: "",
    nurseFullName: localStorage.getItem('nurseFullName'),
    physician: "",
    historian: "",
    hospitalCampus: "",
    setLastKnownNormal: "false",
    arrivalToEdDate: this.defaultDate,
    arrivalToEdTime: "",
    lastKnownNormalDate: this.defaultDate,
    lastKnownNormalTime: "",
    tpaEligibilityUnknown: false,
    familyAtBedside: "",
    bloodPressure: "",
    weight: "",
    oxygenSat: "",
    heartRate: "",
    temp: "",
    rr: "",
    weightUnit: "",
    tempUnit: "",
    bloodGlucose: "",
    medicationsCustom: "",
    miInPrevious3Weeks: "",
    strokeInPrevious3Months: "",
    historyOfIch: "",
    tookCEPX: "",
    historyOfNeoplasm: "",
    isIntracranial: "",
  };
  // Object of checkboxes and other for reason for consult
  public reasonForConsult = {
    cardiacArrest: "",
    possibleStroke: "",
    other: "Possible Stroke",
  };

  // Object of checkboxes and other for reason for consult
  public symptoms = {
    rightSidedWeakness: "",
    leftSidedWeakness: "",
    rightSidedNumbness: "",
    leftSidedNumbness: "",
    leftGazePreference: "",
    rightGazePreference: "",
    leftFacialDroop: "",
    rightFacialDroop: "",
    aphasia: "",
    slurredSpeech: "",
    blurryVision: "",
    doubleVision: "",
    peripheralVisionLoss: "",
    alteredMentalStatus: "",
    headache: "",
    dizziness: "",
    unsteadyGait: "",
    vertigo: "",
    visionLoss: "",
    seizure: "",
    other: "",
  };

  // Object of checkboxes for Anti Platelet therapy
  public antiPlateletTherapyMedications = {
    aspirin: false,
    clopidogrel: false,
    aspirinAndClopidogrel: false,
    ticagrelor: false,
    unableToDetermine: false,
    none: false,
    unknown: false,
    other: ""
  }

  // Object of checkboxes for Anti Coagulation therapy
  public antiCoagulationTherapyMedications = {
    warfarin: false,
    apixaban: false,
    dabigatran: false,
    rivaroxaban: false,
    enoxaparin: false,
    heparin: false,
    unknown: false,
    other: ""
  }

  // Object of checkboxes for past medical history
  public pastMedicalHistory = {
    unknown: false,
    none: false,
    htn: false,
    hld: false,
    atrialFibrillation: false,
    giBleed: false,
    cerebralAneurysm: false,
    brainTumor:false,
    congestiveHeartFailure:false,
    mi: false,
    dm: false,
    priorStroke: false,
    dementia: false,
    ckd: false,
    seizure: false,
    migraine: false,
    cancer: false,
    other: "",
  };

  
  // Object of checkboxes for past medical history
  public pastSurgicalHistory = { surgicalHistory: "", other: "" };

  // Object of checkboxes for medications
  public medications = { medicationsCustom: "", medicationsOptions: "" };

  // Object of checkboxes for allergies
  public allergies = { allergiesCustom: "", allergiesOptions: "" };

  // Object of checkboxes for social history
  public socialHistory = {
    none: false,
    alcohol: false,
    drugs: false,
    tobacco: false,
  };

  // Object of checkboxes for family history
  public familyHistory = {
    none: false,
    unknown: false,
    mi: false,
    stroke: false,
    cancer: false,
    seizure: false,
    other: ""
  };

  // Object of checkboxes for tobacco
  public tobacco = {
    none: false,
    chewing: false,
    vapepen: false,
    unknown: false,
    cigarettes: false,
    other: ""
  };

  
  // Object of checkboxes for drugs
  public drugs = {
    none: false,
    cocaine: false,
    lsd: false,
    marijuana: false,
    heroine: false,
    methamphetamine: false,
    unknown: false, 
    other: ""
  }

  // Form details to be sent as array
  // array for reason for consult(checkbox and other)
  reasonForConsultArray: any[] = [];

  // array checkboxes for symptoms(checkbox and other)
  symptomsArray: any[] = [];

  // array of checkboxes for past medical history
  pastMedicalHistoryArray: any[] = [];

  // array of checkboxes for antiplatelet therapy
  medicationsForAntiplateletTherapyArray: any[] = [];

  // array of checkboxes for anticoagulation therapy
  medicationsForAnticoagulationTherapyArray: any[] = [];

  // array of checkboxes for past surgical history
  pastSurgicalHistoryArray: any[] = [];

  // array of checkboxes for allergies
  allergiesArray: any[] = [];

  // array of checkboxes for medications
  medicationsArray: any[] = [];

  // array of checkboxes for family history
  familyHistoryArray: any[] = [];

  // array of checkboxes for tobacco
  tobaccoArray: any[] = [];

  // array of checkboxes for drugs
  drugsArray: any[] = [];

  // array of checkboxes for social history
  socialHistoryArray: any[] = [];

  // Loader
  isloading = false;
  setCampusNameFromService;
  previousUrl: string;
  subscription;
  isFirst = true;

  


  // whether the subcheckbox of pastMedicalHistroy/familyhistory/symptmos/tobacco/drugs check

  isTobaccoNoneCheck: boolean = false;
  isTobaccoChewingCheck: boolean = false;
  isTobaccoVapepenCheck: boolean = false;
  isTobaccoUnknownCheck: boolean = false;
  isTobaccoCigarettesCheck: boolean = false;
  isDrugsNoneCheck: boolean = false;
  isDrugsCocaineCheck: boolean = false;
  isDrugsLsdCheck: boolean = false;
  isDrugsMarijuanaCheck: boolean = false;
  isDrugsHeroineCheck: boolean = false;
  isDrugsMethamphetamineCheck: boolean = false;
  isDrugsUnknownCheck: boolean = false;
  isNoneCheck: boolean = false;
  isAlcoholCheck: boolean = false;
  isTobaccoCheck: boolean = false;
  isDrugCheck: boolean = false;
  isStrokeCheck: boolean = false;
  isCancerCheck: boolean = false;
  isMiCheck: boolean = false;
  isUnknownCheck: boolean = false;
  isSeizureCheck: boolean = false;
  isFamilyNoneCheck: boolean = false;
  // isTobaccoNoneCheck: boolean = false;
  // isDrugsNoneCheck: boolean = false;
  isMedicalUnknownCheck : boolean = false;
  isMedicalCancerCheck : boolean = false;
  isMedicalSeizureCheck : boolean = false;
  isMedicalMiCheck : boolean = false;
  isMedicalhtnCheck : boolean = false;
  isMedicalDmCheck : boolean = false;
  isMedicalCkdCheck : boolean = false;
  isMedicalPriorStrokeCheck : boolean = false;
  isMedicalgiBleedCheck : boolean = false;
  isMedicalDementiaCheck : boolean = false;
  isMedicalCerebralAneurysmCheck : boolean = false;
  isMedicalMigraineCheck : boolean = false;
  isMedicalHldCheck : boolean = false;
  isMedicalAtrialFibrillationCheck : boolean = false;
  isMedicaNoneCheck : boolean = false;
  isMedicalCongestiveHeartFailureCheck:boolean=false;    //new
  isMedicalBrainTumorCheck:boolean=false;                    //new
  
  
  isAspirinCheck: boolean = false;
  isClopidogrelCheck: boolean = false;
  isAspirinAndClopidogrelCheck: boolean = false;
  isTicagrelorCheck: boolean = false;
  isUnableToDetermineCheck: boolean= false;
  isAntiPlateletTherapyNoneCheck: boolean = false;
  isAntiPlateletTherapyUnknownCheck: boolean = false;

  isWarfarinCheck: boolean = false;
  isApixabanCheck: boolean = false;
  isDabigatranCheck: boolean = false;
  isRivaroxabanCheck: boolean = false;
  isEnoxaparinCheck: boolean = false;
  isHeparinCheck: boolean = false;
  isAntiCoagulationTherapyUnknownCheck: boolean = false;

  // --------------------------------methods-------------------------------------------
  constructor(
    private fb: FormBuilder,
    private http: HttpClient,
    public authService: AuthService,
    public commonFunctionsService: CommonFunctionsService,
    private router: Router,
    public errorDialog: MatDialog,
    public loader: LoadingBarService,
    public successDialog: MatDialog,
    public editintakeDialog: MatDialog,
    private ref: ChangeDetectorRef,
    public snackBar: MatSnackBar,
    private location: PlatformLocation,
    private route: ActivatedRoute,
    private el: ElementRef
  ) {
    this.createPatientForm();
    this.authService
      .getUserFromstatechange()
      .then((val) => (this.loggedInUser = val))
      .then(() => (this.patientSettings.nurse = this.loggedInUser))
      .then(() => this.getHospitalCampus());
    // this.setCampusNameFromService = this.authService.getCampusName();
    // this.patientSettings.hospitalCampus = this.setCampusNameFromService._value;
    // get callId from query params
    this.authService.setActiveStatus("true");
    const url = window.location.search;
    const urlparam = url.split("&");
    const receivedCallId = urlparam[0].split("=");
    const isInitiated = urlparam[1].split("=");
    this.receivedCallIdFromQuery = receivedCallId[1];
    const callIdRef: firebase.database.Reference = firebase
      .database()
      .ref(
        "intakesData/stroke/" +
          this.receivedCallIdFromQuery +
          "/videoCallDetails/"
      );
    callIdRef.on("value", (snapshot) => {
      if (window.location.pathname === "/intake-form") {
        if ( snapshot.val()!==null && snapshot.val()["endCallStatus"] === true) {
          alert("This session is ended");
          this.router.navigate(["/stroke-code"]);
        }
      }
    });

      /*============== listner for Pre Arrival  ================*/
      firebase
      .database()
      .ref(
        "intakeData/stroke/" +
          this.receivedCallIdFromQuery +
          "/preArrival"
      )
      .on("value", (Snapshot) => {
      if(Snapshot.val()!==null){
        if(localStorage.getItem('patientSetting_preArrival')===null )
        {  
          this.patientSettings.preArrivalOption = Snapshot.val();
          localStorage.setItem('patientSetting_preArrival',this.patientSettings.preArrivalOption );
          // this.radioChangePreArrival(event);
         
        }
       else 
       {
         if(this.editStatus===false){
          
        this.patientSettings.preArrivalOption = localStorage.getItem('patientSetting_preArrival');
            //  this.radioChangePreArrival(event);
          }
          else{
            this.patientSettings.preArrivalOption= Snapshot.val();
          }
        }
          this.editStatus=true;
          ref.detectChanges();
      }
      });


          /*============== listner for patient on Anti Platelet Therapy ============*/
     firebase
     .database()
     .ref("intakeData/stroke/" + this.receivedCallIdFromQuery + "/patientOnAntiplatelet")
     .on("value", (Snapshot) => {
       if (Snapshot.val() !== null) {
         this.patientSettings.patientOnAntiplatelet = Snapshot.val();
         if(this.patientSettings.patientOnAntiplatelet == "Yes"){
          this.antiPlateletTherapyMenu = true;
         }
         if(this.patientSettings.patientOnAntiplatelet == "No"){
           this.antiPlateletTherapyMenu = false;
        }
        if(this.patientSettings.patientOnAntiplatelet == "Unknown"){
           this.antiPlateletTherapyMenu = false;
        }
         this.ref.detectChanges();
       }
     });

      /*============== listner for cigarette check box from social history tobacco section  ================*/
      firebase
      .database()
      .ref("intakeData/stroke/" + this.receivedCallIdFromQuery + "/cigaretteCheck")
      .on("value", (Snapshot) => {
        if (Snapshot.val() !== null) {
          this.cigaretteCheck = Snapshot.val();
          if(this.cigaretteCheck ==true){
            this.cigaretteSection = true;
            this.patientSettings.cigarettesPerDayQuantity="Individual Cigarette"
          }
          if(this.cigaretteCheck ==false){
            this.cigaretteSection = false;
            this.patientSettings.cigarettesPerDayQuantity="";
            this.patientSettings.cigarettesPerDay="";
          }
          this.ref.detectChanges();
        }
      });
     
          /*============== listner for patient on alcohol consumption ============*/
          firebase
          .database()
          .ref("intakeData/stroke/" + this.receivedCallIdFromQuery + "/alcoholConsumption")
          .on("value", (Snapshot) => {
            if (Snapshot.val() !== null) {
              this.patientSettings.alcoholConsumption = Snapshot.val();
         
              this.ref.detectChanges();
            }
          });

         

       /*============== listner for patient on Anti Coagulation Therapy ============*/
       firebase
       .database()
       .ref("intakeData/stroke/" + this.receivedCallIdFromQuery + "/patientOnAnticoagulation")
       .on("value", (Snapshot) => {
         if (Snapshot.val() !== null) {
           this.patientSettings.patientOnAnticoagulation = Snapshot.val();
           if(this.patientSettings.patientOnAnticoagulation == "Yes"){
            this.antiCoagulationTherapyMenu = true;
           }
           if(this.patientSettings.patientOnAnticoagulation == "No"){
             this.antiCoagulationTherapyMenu = false;
          }
          if(this.patientSettings.patientOnAnticoagulation == "Unknown"){
             this.antiCoagulationTherapyMenu = false;
          }
           this.ref.detectChanges();
         }
       });

       /*============== listner for patient smoking cigarettes per day ============*/
       firebase
       .database()
       .ref("intakeData/stroke/" + this.receivedCallIdFromQuery + "/cigarettesPerDay")
       .on("value", (Snapshot) => {
         if (Snapshot.val() !== null) {
           this.patientSettings.cigarettesPerDay = Snapshot.val();
      
           this.ref.detectChanges();
         }
       });
    

       /*============== listner for patient smoking cigarettes per day quantity ============*/
       firebase
       .database()
       .ref("intakeData/stroke/" + this.receivedCallIdFromQuery + "/cigarettesPerDayQuantity")
       .on("value", (Snapshot) => {
         if (Snapshot.val() !== null) {
           this.patientSettings.cigarettesPerDayQuantity = Snapshot.val();
       
           this.ref.detectChanges();
         }
       });
        /*============== listner for Patient On Anti Platelet Therapy  ================*/
        firebase
        .database()
        .ref(
          "intakeData/stroke/" +
            this.receivedCallIdFromQuery +
            "/patientOnAntiplatelet"
        )
        .on("value", (Snapshot) => {
          if(Snapshot.val()!==null){
          if(localStorage.getItem('patientSetting_patientOnAntiplateletTherapy')===null )
          {  
            this.patientSettings.patientOnAntiplatelet = Snapshot.val();
            if(this.patientSettings.patientOnAntiplatelet == "Yes"){
              this.antiPlateletTherapyMenu = true;
            }
            if(this.patientSettings.patientOnAntiplatelet == "No"){
              this.antiPlateletTherapyMenu = false;
            }
            if(this.patientSettings.patientOnAntiplatelet == "Unknown"){
              this.antiPlateletTherapyMenu = false;
            }
            localStorage.setItem('patientSetting_patientOnAntiplateletTherapy',this.patientSettings.patientOnAntiplatelet );
             //this.radioChangeForPatientOnAntiplateletTherapy(event);
           
          }
         else 
         {
           if(this.editStatus===false){
            
          this.patientSettings.patientOnAntiplatelet = localStorage.getItem('patientSetting_patientOnAntiplateletTherapy');
              this.radioChangeForPatientOnAntiplateletTherapy(event);
            }
            else{
              this.patientSettings.patientOnAntiplatelet= Snapshot.val();
            }
          }
            this.editStatus=true;
            ref.detectChanges();
        }
        });

        /*============== listner for Patient On Anti Platelet Therapy  ================*/
        firebase
        .database()
        .ref(
          "intakeData/stroke/" +
            this.receivedCallIdFromQuery +
            "/alcoholConsumption"
        )
        .on("value", (Snapshot) => {
        if(Snapshot.val()!==null){
          if(localStorage.getItem('patientSetting_alcoholConsumption')===null )
          {  
            this.patientSettings.alcoholConsumption = Snapshot.val();
       
            localStorage.setItem('patientSetting_alcoholConsumption',this.patientSettings.alcoholConsumption );
            this.radioChangeForAlcoholConsumption(event);
           
          }
         else 
         {
           if(this.editStatus===false){
            
          this.patientSettings.alcoholConsumption = localStorage.getItem('patientSetting_alcoholConsumption');
               this.radioChangeForAlcoholConsumption(event);
            }
            else{
              this.patientSettings.alcoholConsumption= Snapshot.val();
            }
          }
            this.editStatus=true;
            ref.detectChanges();
        }
        });


        /*============== listner for Patient On Anti Coagulation Therapy  ================*/
        firebase
        .database()
        .ref(
          "intakeData/stroke/" +
            this.receivedCallIdFromQuery +
            "/patientOnAnticoagulation"
        )
        .on("value", (Snapshot) => {
    
          if(Snapshot.val()!==null){
          if(localStorage.getItem('patientSetting_patientOnAnticoagulationTherapy')===null )
          {  
            this.patientSettings.patientOnAnticoagulation = Snapshot.val();
            if(this.patientSettings.patientOnAnticoagulation == "Yes"){
              this.antiCoagulationTherapyMenu = true;
            }
            if(this.patientSettings.patientOnAnticoagulation == "No"){
              this.antiCoagulationTherapyMenu = false;
            }
            if(this.patientSettings.patientOnAnticoagulation == "Unknown"){
              this.antiCoagulationTherapyMenu = false;
            }
            localStorage.setItem('patientSetting_patientOnAnticoagulationTherapy',this.patientSettings.patientOnAnticoagulation );
            // this.radioChangeForPatientOnAnticoagulationTherapy(event);
           
          }
         else 
         {
           if(this.editStatus===false){
            
          this.patientSettings.patientOnAnticoagulation = localStorage.getItem('patientSetting_patientOnAnticoagulationTherapy');
              //  this.radioChangeForPatientOnAnticoagulationTherapy(event);
            }
            else{
              this.patientSettings.patientOnAnticoagulation= Snapshot.val();
            }
          }
            this.editStatus=true;
            ref.detectChanges();
        }
        });
  
        /*============== listner for Patient smoking cigarettes per day  ================*/
        firebase
        .database()
        .ref(
          "intakeData/stroke/" +
            this.receivedCallIdFromQuery +
            "/cigarettesPerDay"
        )
        .on("value", (Snapshot) => {
    
          if(Snapshot.val()!==null){
          if(localStorage.getItem('patientSetting_cigarettesPerDay')===null )
          {  
            this.patientSettings.cigarettesPerDay = Snapshot.val();
          
            // }
            localStorage.setItem('patientSetting_cigarettesPerDay',this.patientSettings.cigarettesPerDay );
            // this.radioChangeForCigarettePerDay(event);
           
          }
         else 
         {
           if(this.editStatus===false){
            
          this.patientSettings.cigarettesPerDay = localStorage.getItem('patientSetting_cigarettesPerDay');
              //  this.radioChangeForCigarettePerDay(event);
            }
            else{
              this.patientSettings.cigarettesPerDay= Snapshot.val();
            }
          }
            this.editStatus=true;
            ref.detectChanges();
        }
        });
  
        /*============== listner for Patient smoking cigarettes per day quantity ================*/
        firebase
        .database()
        .ref(
          "intakeData/stroke/" +
            this.receivedCallIdFromQuery +
            "/cigarettesPerDayQuantity"
        )
        .on("value", (Snapshot) => {
       if(Snapshot.val()!==null){
          if(localStorage.getItem('patientSetting_cigarettesPerDayQuantity')===null )
          {  
            this.patientSettings.cigarettesPerDayQuantity = Snapshot.val();
         
            localStorage.setItem('patientSetting_cigarettesPerDayQuantity',this.patientSettings.cigarettesPerDayQuantity );
            this.radioChangeForCigarettePerDayQuantity(event);
           
          }
         else 
         {
           if(this.editStatus===false){
            
          this.patientSettings.cigarettesPerDayQuantity = localStorage.getItem('patientSetting_cigarettesPerDayQuantity');
               this.radioChangeForCigarettePerDayQuantity(event);
            }
            else{
              this.patientSettings.cigarettesPerDayQuantity= Snapshot.val();
            }
          }
            this.editStatus=true;
            ref.detectChanges();
        }
        });

         /*============== listner for means of arrival checked ============*/
         firebase
         .database()
         .ref("intakeData/stroke/" + this.receivedCallIdFromQuery + "/meansOfArrivalChecked")
         .on("value", (Snapshot) => {
           
           if (Snapshot.val() !== null) {
             this.meansOfArrivalChecked = Snapshot.val();
        
             this.ref.detectChanges();
           }
         });
  

        /*============== listner for Means Of Arrival  ================*/
      firebase
      .database()
      .ref(
        "intakeData/stroke/" +
          this.receivedCallIdFromQuery +
          "/meansOfArrival"
      )
      .on("value", (Snapshot) => {
        let a = JSON.parse(Snapshot.val());
        if(Snapshot.val()!==null && this.meansOfArrivalChecked == true){
        this.meansOfArrivalObj = JSON.parse(Snapshot.val());
        if(this.meansOfArrivalObj["privateVehicle"] !== ""){
          this.ambulance_option = false;
          this.patientSettings.meansOfArrivalOptions = this.meansOfArrivalObj["privateVehicle"];
          this.patientSettings.meansOfArrivalCustom = "";
        }
        if(this.meansOfArrivalObj["inHouseAttack"] !== ""){
          this.ambulance_option = false;
          this.patientSettings.meansOfArrivalOptions = this.meansOfArrivalObj["inHouseAttack"];
          this.patientSettings.meansOfArrivalCustom = "";
        }
        if(this.meansOfArrivalObj["privateVehicle"] == "" && this.meansOfArrivalObj["inHouseAttack"] == "" && this.meansOfArrivalChecked == true){
          this.ambulance_option = true;
          this.patientSettings.meansOfArrivalOptions = "Ambulance";
          this.patientSettings.meansOfArrivalCustom = this.meansOfArrivalObj["Ambulance"];
        }
      }
      if(Snapshot.val()!==null){
        if(localStorage.getItem('patientSetting_meansOfArrival')===null )
        {  
      //     this.patientSettings.meansOfArrivalOptions = Snapshot.val();
      //     if(this.patientSettings.meansOfArrivalOptions == "Ambulance"){
      //       this.patientSettings.meansOfArrivalCustom = this.patientSettings.meansOfArrivalOptions["Ambulance"];
      //     }
          localStorage.setItem('patientSetting_meansOfArrival',JSON.stringify(this.meansOfArrivalObj ));
          this.radioChangeMeansOfArrival(this.patientSettings.meansOfArrivalOptions);
         
        }
       else 
       {
         if(this.editStatus===false){
          
        this.meansOfArrivalObj = JSON.parse(localStorage.getItem('patientSetting_meansOfArrival'));
             this.radioChangeMeansOfArrival(this.patientSettings.meansOfArrivalOptions);
          }
          else{
            // this.meansOfArrival= JSON.parse(Snapshot.val());
          }
        }
      //     this.editStatus=true;
          ref.detectChanges();
      }
      });
  
  
  
            /*============== listner for family Contact Number  ================*/
      firebase
      .database()
      .ref(
        "intakeData/stroke/" + this.receivedCallIdFromQuery + "/familyContactNumber"
      )
      .on("value", (Snapshot) => {
      if(Snapshot.val()!==null){
        if(localStorage.getItem('patientSetting_familyContactNumber')===null )
        {
          
          this.patientSettings.familyContactNumber= Snapshot.val();
          localStorage.setItem('patientSetting_familyContactNumber',this.patientSettings.familyContactNumber);
          this.focusOutFunctionForTextField('familyContactNumber');
        }
       else 
       {
         if(this.editStatus===false){
          this.patientSettings.familyContactNumber= localStorage.getItem('patientSetting_familyContactNumber');
          this.focusOutFunctionForTextField('familyContactNumber');
        }
        else{
          this.patientSettings.familyContactNumber= Snapshot.val();
        }
      }
  
        this.editStatus=true;
        ref.detectChanges();
    }
      });
  

    /*============== listner for physician ============*/
    firebase
      .database()
      .ref("intakeData/stroke/" + this.receivedCallIdFromQuery + "/physician")
      .on("value", (Snapshot) => {
        // this.patientSettings.physician = Snapshot.val();
        // ref.detectChanges();
      if(Snapshot.val()!==null){
        if(localStorage.getItem('patientSetting_physician')===null )
        {
          this.patientSettings.physician = Snapshot.val();
          localStorage.setItem('patientSetting_physician',this.patientSettings.physician);
          localStorage.setItem('nurseFullName', this.patientSettings.nurseFullName);
          this.focusOutFunctionForTextField('physician');
        }
       else 
       {
         if(this.editStatus===false){
          this.patientSettings.physician= localStorage.getItem('patientSetting_physician');
          this.focusOutFunctionForTextField('physician');
        }

        else{
          this.patientSettings.physician= Snapshot.val();
        }
      }
       this.editStatus=true;
       ref.detectChanges();
    }
      });

   var fbRef= firebase.database().ref('intakeData/stroke/' + this.receivedCallIdFromQuery)
          fbRef.set({
            nurseFullName : this.nurseFullName,   
          });

    /*============== listner for Nurse ================*/
    // firebase
    //   .database()
    //   .ref("intakeData/stroke/" + this.receivedCallIdFromQuery + "/nurse")
    //   .on("value", (Snapshot) => {
    //     this.patientSettings.nurse = Snapshot.val();
    //     ref.detectChanges();
    //   });

    /*============== listner for Nurse ================*/
    firebase
      .database()
      .ref("intakeData/stroke/" + this.receivedCallIdFromQuery + "/reason")
      .on("value", (Snapshot) => {
        if (Snapshot.val() !== null) {
          if (JSON.parse(Snapshot.val()).length !== 0) {
            var reasonForConultArr = JSON.parse(Snapshot.val());
            for (let reason of reasonForConultArr) {
              this.reasonForConsult.other = reason;
            }
            ref.detectChanges();
          }
        }
      });

    /*============== listner for Historian ================*/
    firebase
      .database()
      .ref("intakeData/stroke/" + this.receivedCallIdFromQuery + "/historian")
      .on("value", (Snapshot) => {
        // this.patientSettings.historian = Snapshot.val();
        // ref.detectChanges();
      if(Snapshot.val()!==null){
        if(localStorage.getItem('patientSetting_historian')===null )
        {
          
          this.patientSettings.historian = Snapshot.val();
          localStorage.setItem('patientSetting_historian',this.patientSettings.historian);
          this.focusOutFunctionForTextField('historian');
        }
       else 
       {
         if(this.editStatus===false){
          

          this.patientSettings.historian= localStorage.getItem('patientSetting_historian');
          this.focusOutFunctionForTextField('historian');
        }
        else{
          this.patientSettings.historian= Snapshot.val();
        }
      }
       this.editStatus=true;
       ref.detectChanges();
    }


      });

    /*============== listner for Hospital Campus ================*/
    firebase
      .database()
      .ref(
        "intakeData/stroke/" + this.receivedCallIdFromQuery + "/hospitalCampus"
      )
      .on("value", (Snapshot) => {
      if(Snapshot.val()!==null){
        this.patientSettings.hospitalCampus = Snapshot.val();
        ref.detectChanges();
      }
      });

    /*============== listner for Blood Pressure ================*/
    firebase
      .database()
      .ref(
        "intakeData/stroke/" + this.receivedCallIdFromQuery + "/bloodPressure"
      )
      .on("value", (Snapshot) => {
        // this.patientSettings.bloodPressure = Snapshot.val();
     if(Snapshot.val()!==null){
        if(localStorage.getItem('patientSetting_bloodPressure')===null )
        { 
          
          this.patientSettings.bloodPressure = Snapshot.val();
          localStorage.setItem('patientSetting_bloodPressure',this.patientSettings.bloodPressure);
          this.focusOutFunctionForTextField('bloodPressure');
        }
       else 
       {
         if(this.editStatus===false){
          

          this.patientSettings.bloodPressure= localStorage.getItem('patientSetting_bloodPressure');
          this.focusOutFunctionForTextField('bloodPressure');
        }
        else{
          this.patientSettings.bloodPressure= Snapshot.val();
        }
      }

        this.editStatus=true;
        ref.detectChanges();
    }



      });

    /*============== listner for Pulse/ heartRate ================*/
    firebase
      .database()
      .ref("intakeData/stroke/" + this.receivedCallIdFromQuery + "/heartRate")
      .on("value", (Snapshot) => {
        // this.patientSettings.heartRate = Snapshot.val();
        // ref.detectChanges();
      if(Snapshot.val()!==null){
        if(localStorage.getItem('patientSetting_heartRate')===null )
        {
          
          this.patientSettings.heartRate = Snapshot.val();
          localStorage.setItem('patientSetting_heartRate',this.patientSettings.heartRate);
          this.focusOutFunctionForTextField('heartRate');
        }
       else 
       {
         if(this.editStatus===false){
          

          this.patientSettings.heartRate= localStorage.getItem('patientSetting_heartRate');
          this.focusOutFunctionForTextField('heartRate');
        }
        else{
          this.patientSettings.heartRate= Snapshot.val();
        }
      }
        this.editStatus=true;
        ref.detectChanges();
    }
      });

    /*============== listner for Oxygen Saturation ================*/
    firebase
      .database()
      .ref("intakeData/stroke/" + this.receivedCallIdFromQuery + "/oxygenSat")
      .on("value", (Snapshot) => {
        // this.patientSettings.oxygenSat = Snapshot.val();
        // ref.detectChanges();
     if(Snapshot.val()!==null){
        if(localStorage.getItem('patientSetting_oxygenSat')===null )
        {
          
          this.patientSettings.oxygenSat = Snapshot.val();
          localStorage.setItem('patientSetting_oxygenSat',this.patientSettings.oxygenSat);
          this.focusOutFunctionForTextField('oxygenSat');
        }
       else 
       {
         if(this.editStatus===false){
          

          this.patientSettings.oxygenSat= localStorage.getItem('patientSetting_oxygenSat');
          this.focusOutFunctionForTextField('oxygenSat');
        }
        else{
          this.patientSettings.oxygenSat= Snapshot.val();
        }
      }
        ref.detectChanges();
    }

      });

    /*============== listner for RR ================*/
    firebase
      .database()
      .ref("intakeData/stroke/" + this.receivedCallIdFromQuery + "/rr")
      .on("value", (Snapshot) => {
        // this.patientSettings.rr = Snapshot.val();
        // ref.detectChanges();
      if(Snapshot.val()!==null){
        if(localStorage.getItem('patientSetting_rr')===null )
        {
          
          this.patientSettings.rr = Snapshot.val();
          localStorage.setItem('patientSetting_rr',this.patientSettings.rr);
          this.focusOutFunctionForTextField('rr');
        }
       else 
       {
         if(this.editStatus===false){
          this.patientSettings.rr= localStorage.getItem('patientSetting_rr');
          this.focusOutFunctionForTextField('rr');
        }
        else{
          this.patientSettings.rr= Snapshot.val();
        }
      }
        ref.detectChanges();
    }

      });

    /*============== listner for Weight Unit ================*/
    firebase
      .database()
      .ref("intakeData/stroke/" + this.receivedCallIdFromQuery + "/weightUnit")
      .on("value", (Snapshot) => {
        // this.patientSettings.weightUnit = Snapshot.val();
        // ref.detectChanges();
      if(Snapshot.val()!==null){
        if(localStorage.getItem('patientSetting_weightUnit')===null )
        {
          this.patientSettings.weightUnit = "lbs";
          localStorage.setItem('patientSetting_weightUnit',this.patientSettings.weightUnit );
          this.radioChangeWeightUnit(event);
        }
         else
         {
          if(this.editStatus===false){
          this.patientSettings.weightUnit = localStorage.getItem('patientSetting_weightUnit');
          this.radioChangeWeightUnit(event);
        }
        else{
          this.patientSettings.weightUnit= Snapshot.val();
        }
      }
         this.editStatus=true;
        ref.detectChanges();
    }
        
      });

    /*============== listner for Temp Unit ================*/
    firebase
      .database()
      .ref("intakeData/stroke/" + this.receivedCallIdFromQuery + "/tempUnit")
      .on("value", (Snapshot) => {
        // this.patientSettings.tempUnit = Snapshot.val();
        // ref.detectChanges();

      if(Snapshot.val()!==null){
        if(localStorage.getItem('patientSetting_tempUnit')===null )
        {
          
          this.patientSettings.tempUnit = "fahrenheit";
          localStorage.setItem('patientSetting_tempUnit',this.patientSettings.tempUnit);
          this.radioChangeTempUnit(event);
        }
       else
       {
          if(this.editStatus===false){
          this.patientSettings.tempUnit = localStorage.getItem('patientSetting_tempUnit');
          this.radioChangeTempUnit(event);
       }
       else{
        this.patientSettings.tempUnit= Snapshot.val();
      }
    }
        this.editStatus=true; 
        ref.detectChanges();
  
  }
      });

    /*============== listner for Weight ================*/
    firebase
      .database()
      .ref("intakeData/stroke/" + this.receivedCallIdFromQuery + "/weight")
      .on("value", (Snapshot) => {
        // this.patientSettings.weight = Snapshot.val();
        // ref.detectChanges();
        if(Snapshot.val()!==null){
        if(localStorage.getItem('patientSetting_weight')===null )
        {
          
          this.patientSettings.weight= Snapshot.val();
          localStorage.setItem('patientSetting_weight',this.patientSettings.weight);
          this.focusOutFunctionForTextField('weight');
        }
       else 
       {
         if(this.editStatus===false){
          

          this.patientSettings.weight= localStorage.getItem('patientSetting_weight');
          this.focusOutFunctionForTextField('weight');
        }
        else{
          this.patientSettings.weight= Snapshot.val();
        }
      }

        this.editStatus=true;
        ref.detectChanges();
    }
      });

    /*============== listner for Temp ================*/
    firebase
      .database()
      .ref("intakeData/stroke/" + this.receivedCallIdFromQuery + "/temp")
      .on("value", (Snapshot) => {
        // this.patientSettings.temp = Snapshot.val();
        // ref.detectChanges();
        if(Snapshot.val()!==null){
        if(localStorage.getItem('patientSetting_temp')===null)
        {
          
          this.patientSettings.temp = Snapshot.val();
          localStorage.setItem('patientSetting_temp',this.patientSettings.temp);
          this.focusOutFunctionForTextField('temp');
        }
       else 
       {
         if(this.editStatus===false){
         this.patientSettings.temp= localStorage.getItem('patientSetting_temp');
          this.focusOutFunctionForTextField('temp');
        }
        else{
          this.patientSettings.temp= Snapshot.val();
        }
      }

        this.editStatus=true;
        ref.detectChanges();
    }

      });

    /*============== listner for Symptoms ================*/
    firebase
      .database()
      .ref("intakeData/stroke/" + this.receivedCallIdFromQuery + "/symptoms")
      .on("value", (Snapshot) => {
        // reinitialize symptoms
        this.symptoms = {
          rightSidedWeakness: "",
          leftSidedWeakness: "",
          rightSidedNumbness: "",
          leftSidedNumbness: "",
          leftGazePreference: "",
          rightGazePreference: "",
          leftFacialDroop: "",
          rightFacialDroop: "",
          aphasia: "",
          slurredSpeech: "",
          blurryVision: "",
          doubleVision: "",
          peripheralVisionLoss: "",
          alteredMentalStatus: "",
          headache: "",
          dizziness: "",
          unsteadyGait: "",
          vertigo: "",
          visionLoss: "",
          seizure: "",
          other: "",
        };

       if (Snapshot.val() !== null) {
        if (JSON.parse(Snapshot.val()).length !== 0) {
          for (let symptom of JSON.parse(Snapshot.val())) {
            if (symptom === "Right sided weakness") {
              this.symptoms.rightSidedWeakness = "true";
            }
            if (symptom === "Left sided weakness") {
              this.symptoms.leftSidedWeakness = "true";
            }
            if (symptom === "Right sided numbness") {
              this.symptoms.rightSidedNumbness = "true";
            }
            if (symptom === "Left sided numbness") {
              this.symptoms.leftSidedNumbness = "true";
            }
            if (symptom === "Left gaze preference") {
              this.symptoms.leftGazePreference = "true";
            }
            if (symptom === "Right gaze preference") {
              this.symptoms.rightGazePreference = "true";
            }
            if (symptom === "Left facial droop") {
              this.symptoms.leftFacialDroop = "true";
            }
            if (symptom === "Right facial droop") {
              this.symptoms.rightFacialDroop = "true";
            }
            if (symptom === "Aphasia") {
              this.symptoms.aphasia = "true";
            }
            if (symptom === "Slurred speech") {
              this.symptoms.slurredSpeech = "true";
            }
            if (symptom === "Blurry vision") {
              this.symptoms.blurryVision = "true";
            }
            if (symptom === "Double vision") {
              this.symptoms.doubleVision = "true";
            }
            if (symptom === "Peripheral vision loss") {
              this.symptoms.peripheralVisionLoss = "true";
            }
            if (symptom === "Altered mental status") {
              this.symptoms.alteredMentalStatus = "true";
            }
            if (symptom === "Headache") {
              this.symptoms.headache = "true";
            }
            if (symptom === "Dizziness") {
              this.symptoms.dizziness = "true";
            }
            if (symptom === "Unsteady gait") {
              this.symptoms.unsteadyGait = "true";
            }
            if (symptom === "Vertigo") {
              this.symptoms.vertigo = "true";
            }
            if (symptom === "Vision loss") {
              this.symptoms.visionLoss = "true";
            }
            if (symptom === "Seizure") {
              this.symptoms.seizure = "true";
            }
            if (
              symptom !== "Right sided weakness" &&
              symptom !== "Left sided weakness" &&
              symptom !== "Right sided numbness" &&
              symptom !== "Left sided numbness" &&
              symptom !== "Left gaze preference" &&
              symptom !== "Right gaze preference" &&
              symptom !== "Left facial droop" &&
              symptom !== "Right facial droop" &&
              symptom !== "Aphasia" &&
              symptom !== "Slurred speech" &&
              symptom !== "Blurry vision" &&
              symptom !== "Double vision" &&
              symptom !== "Peripheral vision loss" &&
              symptom !== "Altered mental status" &&
              symptom !== "Headache" &&
              symptom !== "Dizziness" &&
              symptom !== "Unsteady gait" &&
              symptom !== "Vertigo" &&
              symptom !== "Vision loss" &&
              symptom !== "Seizure"
            ) {
              this.symptoms.other = symptom;
            }
          }
        }
        ref.detectChanges();
      }
      });

    /*============== listner for Arrival To ED Date ================*/
    firebase
      .database()
      .ref(
        "intakeData/stroke/" + this.receivedCallIdFromQuery + "/arrivalToEdDate"
      )
      .on("value", (Snapshot) => {
        if(Snapshot.val()!==null){
        this.patientSettings.arrivalToEdDate = this.reverseDate(Snapshot.val());
        ref.detectChanges();
        }
      });

    /*============== listner for Arrival To Time  ================*/
    firebase
      .database()
      .ref(
        "intakeData/stroke/" + this.receivedCallIdFromQuery + "/arrivalToEdTime"
      )
      .on("value", (Snapshot) => {
        // this.patientSettings.arrivalToEdTime = Snapshot.val();
        // ref.detectChanges();
        if(Snapshot.val()!==null){
        if(localStorage.getItem('patientSetting_arrivalToEDTime')===null )
        {
          
          this.patientSettings.arrivalToEdTime= Snapshot.val();
          localStorage.setItem('patientSetting_arrivalToEDTime',this.patientSettings.arrivalToEdTime);
          this.focusOutFunctionForTextField('arrivalTOEDTime');
        }
       else 
       {
         if(this.editStatus===false){
          

          this.patientSettings.arrivalToEdTime= localStorage.getItem('patientSetting_arrivalToEDTime');
          this.focusOutFunctionForTextField('arrivalTOEDTime');
        }

        else{
          this.patientSettings.arrivalToEdTime= Snapshot.val();
        }
      }
        this.editStatus=true;
        ref.detectChanges();
    }

      });

    /*============== listner for TPA Eligibility Unkown ================*/
    firebase
      .database()
      .ref(
        "intakeData/stroke/" +
          this.receivedCallIdFromQuery +
          "/tpaEligibilityUnknown"
      )
      .on("value", (Snapshot) => {
        if (Snapshot.val() !== null) {
          if (Snapshot.val() === "true" || Snapshot.val() === true) {
            this.patientSettings.tpaEligibilityUnknown = true;
            this.statusLastKnownNormal = true;
            this.patientSettings.lastKnownNormalDate = this.defaultDate;
            this.patientSettings.lastKnownNormalTime = "";
          } else {
            this.patientSettings.tpaEligibilityUnknown = false;
            this.statusLastKnownNormal = false;
            // this.patientSettings.lastKnownNormalDate = null;
          }
          ref.detectChanges();
        }
      });

    /*============== listner for LastKnownNormalDate ================*/
    firebase
      .database()
      .ref(
        "intakeData/stroke/" +
          this.receivedCallIdFromQuery +
          "/lastKnownNormalDate"
      )
      .on("value", (Snapshot) => {
        if (Snapshot.val() !== null ) {
          this.patientSettings.lastKnownNormalDate = this.reverseDate(Snapshot.val());
          ref.detectChanges();
        }
      });

    /*============== listner for LastKnownNormalTime  ================*/
    firebase
      .database()
      .ref(
        "intakeData/stroke/" +
          this.receivedCallIdFromQuery +
          "/lastKnownNormalTime"
      )
      .on("value", (Snapshot) => {
        // if (Snapshot.val() !== null && Snapshot.val() !== 'Unknown') {
        //   this.patientSettings.lastKnownNormalTime = Snapshot.val();
        //   ref.detectChanges();
        // }
        if(Snapshot.val()!==null){
        if(localStorage.getItem('patientSetting_lastKnownNormalTime')===null )
        {
          
          this.patientSettings.lastKnownNormalTime= Snapshot.val();
          localStorage.setItem('patientSetting_lastKnownNormalTime',this.patientSettings.lastKnownNormalTime);
          this.focusOutFunctionForTextField('lastKnownNormalTime');
        }
       else 
       {
         if(this.editStatus===false){
          this.patientSettings.lastKnownNormalTime= localStorage.getItem('patientSetting_lastKnownNormalTime');
          this.focusOutFunctionForTextField('lastKnownNormalTime');
        }
        else{
          this.patientSettings.lastKnownNormalTime= Snapshot.val();
        }
      }


          this.editStatus=true;
          ref.detectChanges();
    }
        
  
      });

    /*============== listner for MIIn Previous 3 Weeks  ================*/
    firebase
      .database()
      .ref(
        "intakeData/stroke/" +
          this.receivedCallIdFromQuery +
          "/miInPrevious3Weeks"
      )
      .on("value", (Snapshot) => {
        // this.patientSettings.miInPrevious3Weeks = Snapshot.val();
        // ref.detectChanges();
        if(Snapshot.val()!==null){
        if(localStorage.getItem('patientSetting_miInPrevious3Weeks')===null )
        {
          this.patientSettings.miInPrevious3Weeks = Snapshot.val();
          localStorage.setItem('patientSetting_miInPrevious3Weeks',this.patientSettings.miInPrevious3Weeks);
          this.handleChangeForMI(event);
         
        }
       else 
       {
         if(this.editStatus===false){
          this.patientSettings.miInPrevious3Weeks= localStorage.getItem('patientSetting_miInPrevious3Weeks');
             this.handleChangeForMI(event);
          }
          else{
            this.patientSettings.miInPrevious3Weeks= Snapshot.val();
          }
        }
        
        this.editStatus=true;
        ref.detectChanges();
      }
      });

    /*============== listner for Stroke  InPrevious3  Months  ================*/
    firebase
      .database()
      .ref(
        "intakeData/stroke/" +
          this.receivedCallIdFromQuery +
          "/strokeInPrevious3Months"
      )
      .on("value", (Snapshot) => {
        // this.patientSettings.strokeInPrevious3Months = Snapshot.val();
        // ref.detectChanges();
        if(Snapshot.val()!==null){
        if(localStorage.getItem('patientSetting_strokeInPrevious3Months')===null )
        {  
          this.patientSettings.strokeInPrevious3Months = Snapshot.val();
          localStorage.setItem('patientSetting_strokeInPrevious3Months',this.patientSettings.strokeInPrevious3Months );
          this.handleChangeForStroke(event);
         
        }
       else 
       {
         if(this.editStatus===false){
          
        this.patientSettings.strokeInPrevious3Months = localStorage.getItem('patientSetting_strokeInPrevious3Months');
             this.handleChangeForStroke(event);
          }
          else{
            this.patientSettings.strokeInPrevious3Months= Snapshot.val();
          }
        }
          this.editStatus=true;
          ref.detectChanges();
      }
      });

    /*============== listner for history OF Ich  ================*/
    firebase
      .database()
      .ref(
        "intakeData/stroke/" + this.receivedCallIdFromQuery + "/isICHHistory"
      )
      .on("value", (Snapshot) => {
        // this.patientSettings.historyOfIch = Snapshot.val();
        // ref.detectChanges();
        if(Snapshot.val()!==null){
        if(localStorage.getItem('patientSetting_historyOfIch')===null )
        {  
         this.patientSettings.historyOfIch = Snapshot.val();
          localStorage.setItem('patientSetting_historyOfIch', this.patientSettings.historyOfIch );
          this.handleChangeForHistoryOfICH(event);
         
        }
       else 
       {
         if(this.editStatus===false){
          
        
            this.patientSettings.historyOfIch = localStorage.getItem('patientSetting_historyOfIch');
             this.handleChangeForHistoryOfICH(event);
          }
          else{
            this.patientSettings.historyOfIch= Snapshot.val();
          }
        }

        this.editStatus=true;
        ref.detectChanges();
      }

      });

    /*============== listner for tookCEPX ================*/
    firebase
      .database()
      .ref("intakeData/stroke/" + this.receivedCallIdFromQuery + "/tookCEPX")
      .on("value", (Snapshot) => {
        // this.patientSettings.tookCEPX = Snapshot.val();
        // ref.detectChanges();

        if(Snapshot.val()!==null){
        if(localStorage.getItem('patientSetting_tookCEPX')===null )
        {  
          this.patientSettings.tookCEPX = Snapshot.val();
          localStorage.setItem('patientSetting_tookCEPX',  this.patientSettings.tookCEPX );
          this.handleChangeForTookCEPX(event);
         
        }
       else 
       {
         if(this.editStatus===false){
             this.patientSettings.tookCEPX  = localStorage.getItem('patientSetting_tookCEPX');
             this.handleChangeForTookCEPX(event);
          }
          else{
            this.patientSettings.tookCEPX= Snapshot.val();
          }
        }
        this.editStatus=true;
        ref.detectChanges();
      }
      });

    /*============== listner for tookCEPX ================*/
    firebase
      .database()
      .ref("intakeData/stroke/" + this.receivedCallIdFromQuery + "/isNeoplasm")
      .on("value", (Snapshot) => {
        // this.patientSettings.historyOfNeoplasm = Snapshot.val();
        // ref.detectChanges();
      if(Snapshot.val()!==null){
        if(localStorage.getItem('patientSetting_historyOfNeoplasm')===null )
        {  
          this.patientSettings.historyOfNeoplasm = Snapshot.val();
          localStorage.setItem('patientSetting_historyOfNeoplasm', this.patientSettings.historyOfNeoplasm );
          this.handleChangeForHistoryOfNeoplasm(event);
         
        }
       else 
       {
         if(this.editStatus===false){
            this.patientSettings.historyOfNeoplasm  = localStorage.getItem('patientSetting_historyOfNeoplasm');
             this.handleChangeForHistoryOfNeoplasm(event);
          }
          else{
            this.patientSettings.historyOfNeoplasm= Snapshot.val();
          }
        }
        this.editStatus=true; 
        ref.detectChanges();
      }
      });

    /*============== listner for bloodGlucose  ================*/
    firebase
      .database()
      .ref(
        "intakeData/stroke/" + this.receivedCallIdFromQuery + "/bloodGlucose"
      )
      .on("value", (Snapshot) => {
        // this.patientSettings.bloodGlucose = Snapshot.val();
        // ref.detectChanges();
     if(Snapshot.val()!==null){
        if(localStorage.getItem('patientSetting_bloodGlucose')===null )
        {
          
          this.patientSettings.bloodGlucose= Snapshot.val();
          localStorage.setItem('patientSetting_bloodGlucose',this.patientSettings.bloodGlucose);
          this.focusOutFunctionForTextField('bloodGlucose');
        }
       else 
       {
         if(this.editStatus===false){
          

          this.patientSettings.bloodGlucose= localStorage.getItem('patientSetting_bloodGlucose');
          this.focusOutFunctionForTextField('bloodGlucose');
        }
        else{
          this.patientSettings.bloodGlucose= Snapshot.val();
        }
      }

        this.editStatus=true;
        ref.detectChanges();
    }

      });

    /*============== Listner for current medication ============*/
    firebase
      .database()
      .ref(
        "intakeData/stroke/" +
          this.receivedCallIdFromQuery +
          "/currentMedications"
      )
      .on("value", (Snapshot) => {
        if (Snapshot.val() !== null) {
          if (JSON.parse(Snapshot.val()).length !== 0) {
            for (let currentMedicationsItem of JSON.parse(Snapshot.val())) {
              if (currentMedicationsItem === "Unknown") {
                this.medications.medicationsOptions = "Unknown";
                this.medications.medicationsCustom = "";
              }
              if (currentMedicationsItem === "None") {
                this.medications.medicationsOptions = "None";
                this.medications.medicationsCustom = "";
              }
              if (
                currentMedicationsItem !== "Unknown" &&
                currentMedicationsItem !== "None"
              ) {
                this.medications.medicationsCustom = currentMedicationsItem;
                this.medicationsOptionsControl.setValue("false");
              }
            }
          } else {
            this.medications.medicationsCustom = "";
            this.medicationsOptionsControl.setValue("false");
          }
          ref.detectChanges();
        }
      });

    /*============== Listner for  Allergies ============*/
    firebase
      .database()
      .ref("intakeData/stroke/" + this.receivedCallIdFromQuery + "/allergies")
      .on("value", (Snapshot) => {
        if (Snapshot.val() !== null) {
          if (JSON.parse(Snapshot.val()).length !== 0) {
            for (let allergiesItem of JSON.parse(Snapshot.val())) {
              if (allergiesItem === "Unknown") {
                this.allergies.allergiesOptions = "Unknown";
                this.allergies.allergiesCustom = "";
              }
              if (allergiesItem === "NKDA") {
                this.allergies.allergiesOptions = "NKDA";
                this.allergies.allergiesCustom = "";
              }
              if (
                allergiesItem !== "Unknown" &&
                allergiesItem !== "NKDA" &&
                allergiesItem !== "false"
              ) {
                this.allergies.allergiesCustom = allergiesItem;
                this.allergiesOptionsControl.setValue("false");
              }
            }
          } else {
            this.allergies.allergiesCustom = "";
            this.allergiesOptionsControl.setValue("false");
          }
          ref.detectChanges();
        }
      });

    /*============== Listner for  Medications For Anti Platelet Therapy  ============*/
    firebase
      .database()
      .ref(
        "intakeData/stroke/" + this.receivedCallIdFromQuery + "/medicationsForAntiplateletTherapy"
      )
      .on("value", (Snapshot) => {
        
        this.antiPlateletTherapyMedications = {
          aspirin: false,
          clopidogrel: false,
          aspirinAndClopidogrel: false,
          ticagrelor: false,
          unableToDetermine: false,
          none: false,
          unknown: false,
          other: ""
        }

        if (Snapshot.val() !== null ) {
          if (JSON.parse(Snapshot.val()).length !== 0){
            for (let medicationsForAntiplateletTherapyItem of JSON.parse(Snapshot.val())) {
              if (medicationsForAntiplateletTherapyItem === "Aspirin") {
                this.antiPlateletTherapyMedications.aspirin = true;
              }
              if (medicationsForAntiplateletTherapyItem === "Clopidogrel") {
                this.antiPlateletTherapyMedications.clopidogrel = true;
              }
              if (medicationsForAntiplateletTherapyItem === "Aspirin & Clopidogrel") {
                this.antiPlateletTherapyMedications.aspirinAndClopidogrel = true;
              }
              if (medicationsForAntiplateletTherapyItem === "Ticagrelor") {
                this.antiPlateletTherapyMedications.ticagrelor = true;
              }
              if (medicationsForAntiplateletTherapyItem === "Unable to determine") {
                this.antiPlateletTherapyMedications.unableToDetermine = true;
              }
              if (medicationsForAntiplateletTherapyItem === "None") {
                this.antiPlateletTherapyMedications.none = true;
              }
              if (medicationsForAntiplateletTherapyItem === "Unknown") {
                this.antiPlateletTherapyMedications.unknown = true;
              }
             
              if (
                medicationsForAntiplateletTherapyItem !== "Aspirin" &&
                medicationsForAntiplateletTherapyItem !== "Clopidogrel" &&
                medicationsForAntiplateletTherapyItem !== "Aspirin & Clopidogrel" &&
                medicationsForAntiplateletTherapyItem !== "Ticagrelor" &&
                medicationsForAntiplateletTherapyItem !== "Unable to determine" &&
                medicationsForAntiplateletTherapyItem !== "None" &&
                medicationsForAntiplateletTherapyItem !== "Unknown"
              ) {
                this.antiPlateletTherapyMedications.other = medicationsForAntiplateletTherapyItem;
              }
            }
          }
          ref.detectChanges();
        }
        // }
      });


    /*============== Listner for  Medications For Anti Coagulation Therapy  ============*/
    firebase
      .database()
      .ref(
        "intakeData/stroke/" + this.receivedCallIdFromQuery + "/medicationsForAnticoagulationTherapy"
      )
      .on("value", (Snapshot) => {
        
        this.antiCoagulationTherapyMedications = {
          warfarin: false,
          apixaban: false,
          dabigatran: false,
          rivaroxaban: false,
          enoxaparin: false,
          heparin: false,
          unknown: false,
          other: ""
        }

        if (Snapshot.val()!== null && Snapshot.val()!=="") {
          if (JSON.parse(Snapshot.val()).length !== 0) {
            for (let medicationsForAnticoagulationTherapyItem of JSON.parse(Snapshot.val())) {
              if (medicationsForAnticoagulationTherapyItem === "Warfarin") {
                this.antiCoagulationTherapyMedications.warfarin = true;
              }
              if (medicationsForAnticoagulationTherapyItem === "Apixaban") {
                this.antiCoagulationTherapyMedications.apixaban = true;
              }
              if (medicationsForAnticoagulationTherapyItem === "Dabigatran") {
                this.antiCoagulationTherapyMedications.dabigatran = true;
              }
              if (medicationsForAnticoagulationTherapyItem === "Rivaroxaban") {
                this.antiCoagulationTherapyMedications.rivaroxaban = true;
              }
              if (medicationsForAnticoagulationTherapyItem === "Enoxaparin") {
                this.antiCoagulationTherapyMedications.enoxaparin = true;
              }

              if (medicationsForAnticoagulationTherapyItem === "Heparin") {
                this.antiCoagulationTherapyMedications.heparin = true;
              }
              if (medicationsForAnticoagulationTherapyItem === "Unknown") {
                this.antiCoagulationTherapyMedications.unknown = true;
              }
             
              if (
                medicationsForAnticoagulationTherapyItem !== "Warfarin" &&
                medicationsForAnticoagulationTherapyItem !== "Apixaban" &&
                medicationsForAnticoagulationTherapyItem !== "Dabigatran" &&
                medicationsForAnticoagulationTherapyItem !== "Rivaroxaban" &&
                medicationsForAnticoagulationTherapyItem !== "Enoxaparin" &&
                medicationsForAnticoagulationTherapyItem !== "Heparin" &&
                medicationsForAnticoagulationTherapyItem !== "Unknown"
              ) {
                this.antiCoagulationTherapyMedications.other = medicationsForAnticoagulationTherapyItem;
              }
            }
          }
          ref.detectChanges();
        }
      });


    /*============== Listner for  Past Medical History  ============*/
    firebase
      .database()
      .ref(
        "intakeData/stroke/" + this.receivedCallIdFromQuery + "/medicalHistory"
      )
      .on("value", (Snapshot) => {
        
        this.pastMedicalHistory = {
              ckd: false,
              unknown: false,
              none: false,
              htn: false,
              hld: false,
              atrialFibrillation: false,
              giBleed: false,
              congestiveHeartFailure: false,
              cerebralAneurysm: false,
              mi: false,
              dm: false,
              priorStroke: false,
              dementia: false,
              brainTumor: false,
              seizure: false,
              migraine: false,
              cancer: false,
              other: "",
        };
        if (Snapshot.val() !== null) {
          if (JSON.parse(Snapshot.val()).length !== 0) {
            for (let pastMedicalHistoryItem of JSON.parse(Snapshot.val())) {
              if (pastMedicalHistoryItem === "Unknown") {
                this.pastMedicalHistory.unknown = true;
              }
              if (pastMedicalHistoryItem === "None") {
                this.pastMedicalHistory.none = true;
              }
              if (pastMedicalHistoryItem === "HTN") {
                this.pastMedicalHistory.htn = true;
              }
              if (pastMedicalHistoryItem === "HLD") {
                this.pastMedicalHistory.hld = true;
              }
              if (pastMedicalHistoryItem === "Atrial Fibrillation") {
                this.pastMedicalHistory.atrialFibrillation = true;
              }
              if (pastMedicalHistoryItem === "GI Bleed") {
                this.pastMedicalHistory.giBleed = true;
              }
              if (pastMedicalHistoryItem === "Cerebral Aneurysm") {
                this.pastMedicalHistory.cerebralAneurysm = true;
              }
              if (pastMedicalHistoryItem === "Congestive Heart Failure") {
                this.pastMedicalHistory.congestiveHeartFailure = true;
              }

              if (pastMedicalHistoryItem === "MI") {
                this.pastMedicalHistory.mi = true;
              }
              if (pastMedicalHistoryItem === "DM") {
                this.pastMedicalHistory.dm = true;
              }
              if (pastMedicalHistoryItem === "Prior Stroke") {
                this.pastMedicalHistory.priorStroke = true;
              }
              if (pastMedicalHistoryItem === "Dementia") {
                this.pastMedicalHistory.dementia = true;
              }
              if (pastMedicalHistoryItem === "CKD") {
                this.pastMedicalHistory.ckd = true;
              }
              if (pastMedicalHistoryItem === "Brain Tumor") {
                this.pastMedicalHistory.brainTumor = true;
              }
              if (pastMedicalHistoryItem === "Seizure") {
                this.pastMedicalHistory.seizure = true;
              }
              if (pastMedicalHistoryItem === "Migraine") {
                this.pastMedicalHistory.migraine = true;
              }
              if (pastMedicalHistoryItem === "Cancer") {
                this.pastMedicalHistory.cancer = true;
              }
              if (
                pastMedicalHistoryItem !== "Unknown" &&
                pastMedicalHistoryItem !== "None" &&
                pastMedicalHistoryItem !== "HTN" &&
                pastMedicalHistoryItem !== "HLD" &&
                pastMedicalHistoryItem !== "Atrial Fibrillation" &&
                pastMedicalHistoryItem !== "GI Bleed" &&
                pastMedicalHistoryItem !== "Brain Tumor" &&
                pastMedicalHistoryItem !== "MI" &&
                pastMedicalHistoryItem !== "DM" &&
                pastMedicalHistoryItem !== "Prior Stroke" &&
                pastMedicalHistoryItem !== "Dementia" &&
                pastMedicalHistoryItem !== "Cerebral Aneurysm" &&
                pastMedicalHistoryItem !=="CKD" &&
                pastMedicalHistoryItem !== "Congestive Heart Failure" &&
                pastMedicalHistoryItem !== "Seizure" &&
                pastMedicalHistoryItem !== "Migraine" &&
                pastMedicalHistoryItem !== "Cancer"
              ) {
                this.pastMedicalHistory.other = pastMedicalHistoryItem;
              }
            }
          }
          ref.detectChanges();
        }
      });

    /*============== Listner for  Surgical History ============*/
    firebase
      .database()
      .ref(
        "intakeData/stroke/" + this.receivedCallIdFromQuery + "/surgicalHistory"
      )
      .on("value", (Snapshot) => {
        if (Snapshot.val() !== null) {
          if (JSON.parse(Snapshot.val()).length !== 0) {
            for (let pastSurgicalHistoryItem of JSON.parse(Snapshot.val())) {
              if (pastSurgicalHistoryItem === "Unknown") {
                this.pastSurgicalHistory.surgicalHistory = "Unknown";
                this.pastSurgicalHistory.other = "";
              }
              if (pastSurgicalHistoryItem === "None") {
                this.pastSurgicalHistory.surgicalHistory = "None";
                this.pastSurgicalHistory.other = "";
              }
              if (
                pastSurgicalHistoryItem !== "Unknown" &&
                pastSurgicalHistoryItem !== "None" &&
                pastSurgicalHistoryItem !== "false"
              ) {
                this.pastSurgicalHistory.other = pastSurgicalHistoryItem;
                this.surgicalHistoryControl.setValue("false");
              }
            }
          } else {
            this.pastSurgicalHistory.other = "";
            this.surgicalHistoryControl.setValue("false");
          }
          ref.detectChanges();
        }
      });

    /*============== Listner for  Social History ============*/
    firebase
      .database()
      .ref(
        "intakeData/stroke/" + this.receivedCallIdFromQuery + "/socialHistory"
      )
      .on("value", (Snapshot) => {
        this.socialHistory = {
          none: false,
          alcohol: false,
          drugs: false,
          tobacco: false,
        };
        if (Snapshot.val() !== null) {
          if (JSON.parse(Snapshot.val()).length !== 0) {
            for (let socialHistoryItem of JSON.parse(Snapshot.val())) {
              console.log("social", socialHistoryItem);
              // if (socialHistoryItem === "Unknown") {
              //   this.socialHistory.unknown = true;
              // }
              if (socialHistoryItem === "None") {
                this.socialHistory.none = true;
              }
              if (socialHistoryItem === "Alcohol") {
                this.socialHistory.alcohol = true;
              }
              if (socialHistoryItem === "Drugs") {
                this.socialHistory.drugs = true;
              }
              if (socialHistoryItem === "Tobacco") {
                this.socialHistory.tobacco = true;
              }
            }
          }
          ref.detectChanges();
        }
      });

    /*============== Listner for  Family History ============*/
    firebase
      .database()
      .ref(
        "intakeData/stroke/" + this.receivedCallIdFromQuery + "/familyHistory"
      )
      .on("value", (Snapshot) => {
        this.familyHistory = {
          none: false,
          unknown: false,
          mi: false,
          stroke: false,
          cancer: false,
          seizure: false,
          other:""
        };
        if (Snapshot.val() !== null) {
          if (JSON.parse(Snapshot.val()).length !== 0) {
            for (let familyHistoryItem of JSON.parse(Snapshot.val())) {
              console.log("family", familyHistoryItem);
              if (familyHistoryItem === "Unknown") {
                this.familyHistory.unknown = true;
              }
              if (familyHistoryItem === "None") {
                this.familyHistory.none = true;
              }
              if (familyHistoryItem === "MI") {
                this.familyHistory.mi = true;
              }
              if (familyHistoryItem === "Stroke") {
                this.familyHistory.stroke = true;
              }
              if (familyHistoryItem === "Cancer") {
                this.familyHistory.cancer = true;
              }
              if (familyHistoryItem === "Seizure") {
                this.familyHistory.seizure = true;
              }
              if (
                familyHistoryItem !== "Unknown" &&
                familyHistoryItem !== "None" &&
                familyHistoryItem !== "MI" &&
                familyHistoryItem !== "Stroke" &&
                familyHistoryItem !== "Cancer" &&
                familyHistoryItem !== "Seizure"
                
              ) {
                this.familyHistory.other = familyHistoryItem;
              }
            }
          }
          ref.detectChanges();
        }
      });

      /*============== Listner for  Family History ============*/
  firebase
  .database()
  .ref(
    "intakeData/stroke/" + this.receivedCallIdFromQuery + "/tobacco"
  )
  .on("value", (Snapshot) => {
    this.tobacco = {
      none: false,
      chewing: false,
      vapepen: false,
      unknown: false,
      cigarettes: false,
      other:""
    };
    if (Snapshot.val() !== null) {
      if (JSON.parse(Snapshot.val()).length !== 0) {
        for (let tobaccoItem of JSON.parse(Snapshot.val())) {
        
          console.log("tobacco", tobaccoItem);
          if (tobaccoItem === "None") {
            this.tobacco.none = true;
          }
          if (tobaccoItem === "Chewing") {
            this.tobacco.chewing = true;
          }
          if (tobaccoItem === "Vape Pen") {
            this.tobacco.vapepen = true;
          }
          if (tobaccoItem === "Unknown") {
            this.tobacco.unknown = true;
          }
      
          if (tobaccoItem === "Cigarettes") {
            this.tobacco.cigarettes = true;
            // this.cigaretteSection = true;
          }
          
          if (
            tobaccoItem !== "None" &&
            tobaccoItem !== "Chewing" &&
            tobaccoItem !== "Vape Pen" &&
            tobaccoItem !== "Unknown" &&
            tobaccoItem !== "Cigarettes"
            
          ) {
            this.tobacco.other = tobaccoItem;
          }
        }
      }
      ref.detectChanges();
    }
  });

        /*============== Listner for  Family History ============*/
        firebase
        .database()
        .ref(
          "intakeData/stroke/" + this.receivedCallIdFromQuery + "/drugs"
        )
        .on("value", (Snapshot) => {
          this.drugs = {
            none: false,
            cocaine: false,
            lsd: false,
            marijuana: false,
            heroine: false,
            methamphetamine: false,
            unknown: false,
            other:""
          };
          if (Snapshot.val() !== null) {
            if (JSON.parse(Snapshot.val()).length !== 0) {
              for (let drugsItem of JSON.parse(Snapshot.val())) {
                console.log("drugs", drugsItem);
                if (drugsItem === "None") {
                  this.drugs.none = true;
                }
                if (drugsItem === "Cocaine") {
                  this.drugs.cocaine = true;
                }
                if (drugsItem === "LSD") {
                  this.drugs.lsd = true;
                }
                if (drugsItem === "Marijuana") {
                  this.drugs.marijuana = true;
                }
                if (drugsItem === "Heroine") {
                  this.drugs.heroine = true;
                }
                if (drugsItem === "Methamphetamine") {
                  this.drugs.methamphetamine = true;
                }
                if (drugsItem === "Unknown") {
                  this.drugs.unknown = true;
                }
                if (
                  drugsItem !== "None" &&
                  drugsItem !== "Cocaine" &&
                  drugsItem !== "LSD" &&
                  drugsItem !== "Marijuana" &&
                  drugsItem !== "Heroine" &&
                  drugsItem !== "Methamphetamine" &&
                  drugsItem !== "Unknown"
                ) {
                  this.drugs.other = drugsItem;
                }
              
              }
            }
            ref.detectChanges();
          }
        });

  }

  

  ngOnInit() {
    if(localStorage.patientSetting_patientOnAntiplateletTherapy == "Yes"){
      this.antiPlateletTherapyMenu = true;
    }
    if(localStorage.patientSetting_patientOnAntiplateletTherapy == "No"){
      this.antiPlateletTherapyMenu = false;
    }
    if(localStorage.patientSetting_patientOnAntiplateletTherapy == "Unknown"){
      this.antiPlateletTherapyMenu = false;
    }
    if(localStorage.patientSetting_patientOnAnticoagulationTherapy == "Yes"){
      this.antiCoagulationTherapyMenu = true;
    }
   
    if(localStorage.patientSetting_patientOnAnticoagulationTherapy == "No"){
      this.antiCoagulationTherapyMenu = false;
    }
   
    if(localStorage.patientSetting_patientOnAnticoagulationTherapy == "Unknown"){
      this.antiCoagulationTherapyMenu = false;
    }
    this.setPatientInfo();

    localStorage.clear();
    localStorage.setItem('nurseFullName', this.nurseFullName);
    
    console.log("offsetTime",this.offset)
    
  }
 

  /*============== browser back host listner ============*/
  @HostListener("window:popstate", ["$event"])
  onPopState(event) {
    const res = confirm("Do you want to end the current session?");
    if (res === true) {
      this.authService.EndCallFromCanDeactive(this.receivedCallIdFromQuery);
      const callEndRef: firebase.database.Reference = firebase
        .database()
        .ref(
          "videoCallActions/stroke/" + this.receivedCallIdFromQuery + "/status"
        );
      window.location.assign("/stroke-code");
    } else {
      // history.pushState(null, null, null);
      this.authService.setToggle(false);
      window.history.forward();
    }
  }
  ngAfterViewInit() {
    this.inputEl.nativeElement.focus();
    if (localStorage.getItem("patientSentTOCT") === "true") {
      this.changeToSentToCt();
    }
    if (localStorage.getItem("initiateStrokCode") === "true") {
      this.changeToCT();
    }
    if (localStorage.getItem("patientReadyForVideoNotify") === "true") {
      this.changeToReady();
    }
    
  }
  checkboxValidate() {
    // alert('control');
    // this.validateReasons();
  }

  createPatientForm() {
    this.patientForm = this.fb.group({
      nurse: [""],
      physician: [""],
      historian: [""],
      hospitalCampus: [""],
      cardiacArrest: [""],
      possibleStroke: ["", Validators.requiredTrue],
      reasonForConsultOther: [""],
      rightSidedWeakness: [""],
      leftSidedWeakness: [""],
      rightSidedNumbness: [""],
      leftSidedNumbness: [""],
      leftGazePreference: [""],
      rightGazePreference: [""],
      leftFacialDroop: [""],
      rightFacialDroop: [""],
      aphasia: [""],
      slurredSpeech: [""],
      blurryVision: [""],
      doubleVision: [""],
      peripheralVisionLoss: [""],
      alteredMentalStatus: [""],
      headache: [""],
      dizziness: [""],
      unsteadyGait: [""],
      vertigo: [""],
      visionLoss: [""],
      seizure: [""],
      symptomsOther: [""],
      patientOnAntiplateletTherapyOtherMedication: [""],
      patientOnAnticoagulationTherapyOtherMedication: [""],
      familyHistoryControl: [""],
      tobaccoOtherControl: [""],
      drugsOtherControl: [""],
      bloodPressure: [""],
      weight: [""],
      oxygenSaturation: [""],
      heartRate: [""],
      temperature: [""],
      weightUnitFormControl: [""],
      tempUnitFormControl: [""],
      pastMedicalHistoryOther: [""],
      allergiesCustom: [""],
      medicationsCustom: [""],
      medical_unknown: [""],
      medical_none: [""],
      medical_htn: [""],
      medical_hld: [""],
      medical_atrialFibrillation: [""],
      AntiplateletTherapy_aspirin: [""],
      AntiplateletTherapy_clopidogrel: [""],
      AntiplateletTherapy_aspirinAndClopidogrel: [""],
      AntiplateletTherapy_ticagrelor: [""],
      AntiplateletTherapy_unableToDetermine: [""],
      AntiplateletTherapy_none: [""],
      AntiplateletTherapy_unknown: [""],
      AnticoagulationTherapy_warfarin: [""],
      AnticoagulationTherapy_apixaban: [""],
      AnticoagulationTherapy_dabigatran: [""],
      AnticoagulationTherapy_rivaroxaban: [""],
      AnticoagulationTherapy_enoxaparin: [""],
      AnticoagulationTherapy_heparin: [""],
      AnticoagulationTherapy_unknown: [""],
      medical_giBleed: [""],
      medical_cerebralAneurysm: [""],
      medical_brainTumor: [""],
      medical_mi: [""],
      medical_dm: [""],
      medical_priorStroke: [""],
      medical_dementia: [""],
      medical_ckd: [""],
      medical_congestiveHeartFailure: [""],
      medical_seizure: [""],
      medical_migraine: [""],
      medical_cancer: [""],
      surgicalHistory: [""],
      pastSurgicalHistoryCustom: [""],
      meansOfArrivalCustom: [""],
      allergiesOptions: [""],
      medicationsOptions: [""],
      socialHistory_none: [""],
      socialHistory_unknown: [""],
      socialHistory_alcohol: [""],
      socialHistory_drugs: [""],
      socialHistory_tobacco: [""],
      familyHistory_none: [""],
      familyHistory_unknown: [""],
      familyHistory_mi: [""],
      familyHistory_stroke: [""],
      familyHistory_cancer: [""],
      familyHistory_seizure: [""],
      tobacco_none: [""],
      tobacco_chewing: [""],
      tobacco_vapepen: [""],
      tobacco_unknown: [""],
      tobacco_cigarettes: [""],
      drugs_none: [""],
      drugs_cocaine: [""],
      drugs_lsd: [""],
      drugs_marijuana :[""],
      drugs_heroine: [""],
      drugs_methamphetamine: [""],
      drugs_unknown: [""],
      //familyHistory_htn: [""],
      miInPrevious3Weeks: ["", Validators.required],
      strokeInPrevious3Months: ["", Validators.required],
      historyOfIch: [""],
      tookCEPX: [""],
      historyOfNeoplasm: [""],
      isIntracranial: [""],
      tpaEligibilityUnknown: [""],
      familyAtBedside: [""],
      setLastKnownNormal: [""],
      arrivalToEdDate: [""],
      arrivalToEdTime: [""],
      lastKnownNormalDate: [""],
      lastKnownNormalTime: [""],
    });
  }
  /*============== methods to create array for checkboxes =============*/
  createSymptomsArray() {
    
    this.symptomsArray = [];
    if (this.symptoms.rightSidedWeakness) {
      this.symptomsArray.push("Right sided weakness");
    }
    if (this.symptoms.leftSidedWeakness) {
      this.symptomsArray.push("Left sided weakness");
    }
    if (this.symptoms.rightSidedNumbness) {
      this.symptomsArray.push("Right sided numbness");
    }
    if (this.symptoms.leftSidedNumbness) {
      this.symptomsArray.push("Left sided numbness");
    }
    if (this.symptoms.leftGazePreference) {
      this.symptomsArray.push("Left gaze preference");
    }
    if (this.symptoms.rightGazePreference) {
      this.symptomsArray.push("Right gaze preference");
    }
    if (this.symptoms.leftFacialDroop) {
      this.symptomsArray.push("Left facial droop");
    }
    if (this.symptoms.rightFacialDroop) {
      this.symptomsArray.push("Right facial droop");
    }
    if (this.symptoms.aphasia) {
      this.symptomsArray.push("Aphasia");
    }
    if (this.symptoms.slurredSpeech) {
      this.symptomsArray.push("Slurred speech");
    }
    if (this.symptoms.blurryVision) {
      this.symptomsArray.push("Blurry vision");
    }
    if (this.symptoms.doubleVision) {
      this.symptomsArray.push("Double vision");
    }
    if (this.symptoms.peripheralVisionLoss) {
      this.symptomsArray.push("Peripheral vision loss");
    }
    if (this.symptoms.alteredMentalStatus) {
      this.symptomsArray.push("Altered mental status");
    }
    if (this.symptoms.headache) {
      this.symptomsArray.push("Headache");
    }
    if (this.symptoms.dizziness) {
      this.symptomsArray.push("Dizziness");
    }
    if (this.symptoms.unsteadyGait) {
      this.symptomsArray.push("Unsteady gait");
    }
    if (this.symptoms.vertigo) {
      this.symptomsArray.push("Vertigo");
    }
    if (this.symptoms.visionLoss) {
      this.symptomsArray.push("Vision loss");
    }
    if (this.symptoms.seizure) {
      this.symptomsArray.push("Seizure");
    }
    if (this.symptoms.other) {
      this.symptomsArray.push(this.symptoms.other);
    }
    console.log(this.symptomsArray);
  }
 

  createMedicationsForAnticoagulationTherapyArray() {
    this.medicationsForAnticoagulationTherapyArray = [];
    if (this.antiCoagulationTherapyMedications.warfarin) {
      this.medicationsForAnticoagulationTherapyArray.push("Warfarin");
    }
    if (this.antiCoagulationTherapyMedications.apixaban) {
      this.medicationsForAnticoagulationTherapyArray.push("Apixaban");
    }
    if (this.antiCoagulationTherapyMedications.dabigatran) {
      this.medicationsForAnticoagulationTherapyArray.push("Dabigatran");
    }
    if (this.antiCoagulationTherapyMedications.rivaroxaban) {
      this.medicationsForAnticoagulationTherapyArray.push("Rivaroxaban");
    }
    if (this.antiCoagulationTherapyMedications.enoxaparin) {
      this.medicationsForAnticoagulationTherapyArray.push("Enoxaparin");
    }
    if (this.antiCoagulationTherapyMedications.heparin) {
      this.medicationsForAnticoagulationTherapyArray.push("Heparin");
    }
    if (this.antiCoagulationTherapyMedications.unknown) {
      this.medicationsForAnticoagulationTherapyArray.push("Unknown");
    }
    if (this.antiCoagulationTherapyMedications.other) {
      this.medicationsForAnticoagulationTherapyArray.push(this.antiCoagulationTherapyMedications.other);
    }
    
    console.log(this.medicationsForAnticoagulationTherapyArray);
  }

  createMedicationsForAntiplateletTherapyArray() {
    this.medicationsForAntiplateletTherapyArray = [];
    if (this.antiPlateletTherapyMedications.aspirin) {
      this.medicationsForAntiplateletTherapyArray.push("Aspirin");
    }
    if (this.antiPlateletTherapyMedications.clopidogrel) {
      this.medicationsForAntiplateletTherapyArray.push("Clopidogrel");
    }
    if (this.antiPlateletTherapyMedications.aspirinAndClopidogrel) {
      this.medicationsForAntiplateletTherapyArray.push("Aspirin & Clopidogrel");
    }
    if (this.antiPlateletTherapyMedications.ticagrelor) {
      this.medicationsForAntiplateletTherapyArray.push("Ticagrelor");
    }
    if (this.antiPlateletTherapyMedications.unableToDetermine) {
      this.medicationsForAntiplateletTherapyArray.push("Unable to determine");
    }
    if (this.antiPlateletTherapyMedications.none) {
      this.medicationsForAntiplateletTherapyArray.push("None");
    }
    if (this.antiPlateletTherapyMedications.unknown) {
      this.medicationsForAntiplateletTherapyArray.push("Unknown");
    }
    if (this.antiPlateletTherapyMedications.other) {
      this.medicationsForAntiplateletTherapyArray.push(this.antiPlateletTherapyMedications.other);
    }
    
    console.log(this.medicationsForAntiplateletTherapyArray);
  }
  
  createPastMedicalHistoryArray() {
    this.pastMedicalHistoryArray = [];
    if (this.pastMedicalHistory.unknown) {
      this.pastMedicalHistoryArray.push("Unknown");
    }
    if (this.pastMedicalHistory.none) {
      this.pastMedicalHistoryArray.push("None");
    }
    if (this.pastMedicalHistory.htn) {
      this.pastMedicalHistoryArray.push("HTN");
    }
    if (this.pastMedicalHistory.hld) {
      this.pastMedicalHistoryArray.push("HLD");
    }
    if (this.pastMedicalHistory.atrialFibrillation) {
      this.pastMedicalHistoryArray.push("Atrial Fibrillation");
    }
    if (this.pastMedicalHistory.giBleed) {
      this.pastMedicalHistoryArray.push("GI Bleed");
    }
    if (this.pastMedicalHistory.cerebralAneurysm) {
      this.pastMedicalHistoryArray.push("Cerebral Aneurysm");
    }
    if (this.pastMedicalHistory.brainTumor) {
      this.pastMedicalHistoryArray.push("Brain Tumor");
    }

    if (this.pastMedicalHistory.mi) {
      this.pastMedicalHistoryArray.push("MI");
    }
    if (this.pastMedicalHistory.dm) {
      this.pastMedicalHistoryArray.push("DM");
    }
    if (this.pastMedicalHistory.priorStroke) {
      this.pastMedicalHistoryArray.push("Prior Stroke");
    }
    if (this.pastMedicalHistory.dementia) {
      this.pastMedicalHistoryArray.push("Dementia");
    }
    if (this.pastMedicalHistory.ckd) {
      this.pastMedicalHistoryArray.push("CKD");
    }

    if (this.pastMedicalHistory.congestiveHeartFailure) {
      this.pastMedicalHistoryArray.push( "Congestive Heart Failure");
    }

    if (this.pastMedicalHistory.seizure) {
      this.pastMedicalHistoryArray.push("Seizure");
    }
    if (this.pastMedicalHistory.migraine) {
      this.pastMedicalHistoryArray.push("Migraine");
    }
    if (this.pastMedicalHistory.cancer) {
      this.pastMedicalHistoryArray.push("Cancer");
    }
    if (this.pastMedicalHistory.other) {
      this.pastMedicalHistoryArray.push(this.pastMedicalHistory.other);
    }
    console.log(this.pastMedicalHistoryArray);
  }
  createPastSurgicalHistoryArray() {
    this.pastSurgicalHistoryArray = [];
    if (this.pastSurgicalHistory.surgicalHistory) {
      if (this.pastSurgicalHistory.surgicalHistory !== "false") {
        this.pastSurgicalHistoryArray.push(
          this.pastSurgicalHistory.surgicalHistory
        );
      }
    }
    if (this.pastSurgicalHistory.other) {
      this.pastSurgicalHistoryCustomVariable = this.pastSurgicalHistory.other.split("\n").join(",");
      this.pastSurgicalHistoryArray.push(this.pastSurgicalHistoryCustomVariable);
    }
    console.log(this.pastSurgicalHistoryArray);
  }
  createAllergiesArray() {
    this.allergiesArray = [];
    if (this.allergies.allergiesCustom) {
      this.allergiesCustomVariable = this.allergies.allergiesCustom.split('\n').join(',');
      this.allergiesArray.push(this.allergiesCustomVariable);
    }
    if (this.allergies.allergiesOptions) {
      if (this.allergies.allergiesOptions !== "false") {
        this.allergiesArray.push(this.allergies.allergiesOptions);
      }
    }
    console.log(this.allergiesArray);
  }

  createMeansOfArrival(){

    if(this.patientSettings.meansOfArrivalOptions === "Private Vehicle"){
    
      this.meansOfArrivalObj["privateVehicle"] = this.patientSettings.meansOfArrivalOptions;
      this.meansOfArrivalObj["inHouseAttack"]="";
      this.meansOfArrivalObj["Ambulance"]="";
    
    } else { this.meansOfArrivalObj["privateVehicle"] = ""; }
   
    if(this.patientSettings.meansOfArrivalOptions === "In-house Attack"){
    
     this.meansOfArrivalObj["privateVehicle"]="";
     this.meansOfArrivalObj["inHouseAttack"] = this.patientSettings.meansOfArrivalOptions;
     this.meansOfArrivalObj["Ambulance"]="";
   
   } else { this.meansOfArrivalObj["inHouseAttack"] = ""; }
   
    if(this.patientSettings.meansOfArrivalOptions === "Ambulance"){
     this.meansOfArrivalObj["privateVehicle"]="";
     this.meansOfArrivalObj["inHouseAttack"]="";
      this.meansOfArrivalObj["Ambulance"] = this.patientSettings.meansOfArrivalCustom;
    } else  this.meansOfArrivalObj["Ambulance"] = ""; 
   
   
   
     }

  createMedicationsArray() {
    this.medicationsArray = [];
    if (this.medications.medicationsCustom) {
      this.medicationsArray.push(this.medications.medicationsCustom);
    }
    if (this.medications.medicationsOptions) {
      if (this.medications.medicationsOptions !== "false") {
        this.medicationsArray.push(this.medications.medicationsOptions);
      }
    }
    console.log(this.medicationsArray);
  }

  
  createDrugsArray(){
    this.drugsArray = [];
    if (this.drugs.none) {
      this.drugsArray.push("None");
    }
    if (this.drugs.cocaine) {
      this.drugsArray.push("Cocaine");
    }
    if (this.drugs.lsd) {
      this.drugsArray.push("LSD");
    }
    if (this.drugs.marijuana) {
      this.drugsArray.push("Marijuana");
    }
    if (this.drugs.heroine) {
      this.drugsArray.push("Heroine");
    }
    if (this.drugs.methamphetamine) {
      this.drugsArray.push("Methamphetamine");
    }
    if (this.drugs.unknown) {
      this.drugsArray.push("Unknown");
    }
    if (this.drugs.other){
      this.drugsArray.push(this.drugs.other);
    }
    console.log(this.drugsArray);
  }

  createFamilHistoryArray() {
    this.familyHistoryArray = [];
    if (this.familyHistory.none) {
      this.familyHistoryArray.push("None");
    }
    if (this.familyHistory.unknown) {
      this.familyHistoryArray.push("Unknown");
    }
    if (this.familyHistory.mi) {
      this.familyHistoryArray.push("MI");
    }
    if (this.familyHistory.stroke) {
      this.familyHistoryArray.push("Stroke");
    }
    if (this.familyHistory.cancer) {
      this.familyHistoryArray.push("Cancer");
    }
    if (this.familyHistory.seizure) {
      this.familyHistoryArray.push("Seizure");
    }
    if (this.familyHistory.other) {
      this.familyHistoryArray.push(this.familyHistory.other);
    }
    console.log(this.familyHistoryArray);
  }
  createSocialHistoryArray() {
    this.socialHistoryArray = [];
    if (this.socialHistory.none) {
      this.socialHistoryArray.push("None");
    }
    // if (this.socialHistory.unknown) {
    //   this.socialHistoryArray.push("Unknown");
    // }
    if (this.socialHistory.alcohol) {
      this.socialHistoryArray.push("Alcohol");
    }
    if (this.socialHistory.drugs) {
      this.socialHistoryArray.push("Drugs");
    }
    if (this.socialHistory.tobacco) {
      this.socialHistoryArray.push("Tobacco");
    }
    console.log(this.socialHistoryArray);
  }
  createReasonForConsultArray() {
    this.reasonForConsultArray = [];
    if (this.reasonForConsult.cardiacArrest) {
      this.reasonForConsultArray.push("Cardiac Arrest");
    }
    if (this.reasonForConsult.possibleStroke) {
      this.reasonForConsultArray.push("Possible Stroke");
    }
    if (this.reasonForConsult.other) {
      this.reasonForConsultArray.push(this.reasonForConsult.other);
    }
    console.log(this.reasonForConsultArray);
    console.log(this.patientSettings.nurse);
    console.log(this.patientSettings.physician);
    console.log(this.patientSettings.historian);
    console.log(this.patientSettings.hospitalCampus);
  }
  // Retreive Patient Data
  patientDataRetrieve(patientId) {
    this.loader.start();
    this.loader.set(10);
    const params = new HttpParams().set("patientId", patientId).set("callId",this.receivedCallIdFromQuery);
    this.http
      .get(this.authService.baseURL + "/PatientDataRetrieveTest", {
        params: params,
      })
      .subscribe(
        (res) => {
          if (res["Message"] === "Success") {
            console.log(res);
            this.retrievedData = res["data"];
            // this.patientForm.get("nurse").patchValue(this.retrievedData.nurse);
            // this.patientForm
            //   .get("physician")
            //   .setValue(this.retrievedData.physician);
            // this.patientForm
            //   .get("historian")
            //   .setValue(this.retrievedData.historian);
            // this.patientForm.get('hospitalCampus').setValue(this.retrievedData.hospitalCampus);
            // this.patientSettings.arrivalToEdDate = this.reverseDate(
            //   this.retrievedData.arrivalToEdDate
            // );
            // this.patientSettings.arrivalToEdTime = this.retrievedData.arrivalToEdTime;
            // this.patientSettings.lastKnownNormalDate = this.reverseDate(
            //   this.retrievedData.lastKnownNormalDate
            // );
            // this.patientSettings.lastKnownNormalTime = this.retrievedData.lastKnownNormalTime;
            // this.bloodPressureControl.setValue(
            //   this.retrievedData.bloodPressure
            // );
            // this.rrControl.setValue(this.retrievedData.rr);
            // this.bloodGlucoseControl.setValue(this.retrievedData.bloodGlucose);
            if (this.retrievedData.weightUnit === "kg") {
              this.patientSettings.weightUnit = "kg";
              this.patientSettings.weight = this.retrievedData.weight;
              this.radioChangeWeightUnit(event);
            } else {
              this.patientSettings.weightUnit = "lbs";
              this.patientSettings.weight = this.retrievedData.weight;
              this.radioChangeWeightUnit(event);
            }
            if (this.retrievedData.tempUnit === "celsius") {
              this.patientSettings.tempUnit = "celsius";
              this.patientSettings.temp = this.retrievedData.temp;
              this.radioChangeTempUnit(event);
            } else {
              this.patientSettings.tempUnit = "fahrenheit";
              this.patientSettings.temp = this.retrievedData.temp;
              this.radioChangeTempUnit(event);
            }
            // // this.patientSettings.oxygenSat = this.retrievedData.oxygenSat;
            // this.oxygenSaturationControl.setValue(this.retrievedData.oxygenSat);
            // // this.patientSettings.heartRate = this.retrievedData.heartRate;
            // this.heartRateControl.setValue(this.retrievedData.heartRate);
            this.patientSettings.medicationsCustom = this.retrievedData.medicationsCustom;

            // if (this.retrievedData.tpaEligibilityUnknown === true) {
            //   this.patientSettings.tpaEligibilityUnknown = true;
            //   this.statusLastKnownNormal = true;
            //   this.patientSettings.lastKnownNormalDate = this.defaultDate;
            //   this.patientSettings.lastKnownNormalTime = "";
            // } else {
            //   this.patientSettings.tpaEligibilityUnknown = false;
            //   this.statusLastKnownNormal = false;
            // }
            // this.patientSettings.familyAtBedside = this.retrievedData.isFamilyBedside;
            // this.patientSettings.miInPrevious3Weeks = this.retrievedData.miInPrevious3Weeks;
            // this.patientSettings.strokeInPrevious3Months = this.retrievedData.strokeInPrevious3Months;
            // this.patientSettings.historyOfIch = this.retrievedData.isICHHistory;
            // this.patientSettings.tookCEPX = this.retrievedData.tookCEPX;
            // this.patientSettings.historyOfNeoplasm = this.retrievedData.isNeoplasm;
            // this.patientSettings.isIntracranial = this.retrievedData.isIntracranial;
            // if (
            //   this.patientSettings.miInPrevious3Weeks !== "" &&
            //   this.patientSettings.strokeInPrevious3Months !== "" &&
            //   this.patientSettings.historyOfIch !== "" &&
            //   this.patientSettings.historyOfNeoplasm !== "" &&
            //   this.patientSettings.tookCEPX !== "" &&
            //   !this.bloodGlucoseControl.invalid
            // ) {
            //   this.displayCheckTpaEligibility = true;
            //   this.displayErrorTpaEligibility = false;
            // } else {
            //   this.displayCheckTpaEligibility = false;
            //   this.displayErrorTpaEligibility = true;
            // }
            if (this.retrievedData["reason"] !== "" && this.retrievedData["reason"] !== undefined) {
              this.retrieveArrayForReasonForConsult = JSON.parse(
                this.retrievedData["reason"]
              );
            }
            //  alert(this.retrieveArrayForReasonForConsult.length);

            if (
              this.patientSettings.nurse !== "" &&
              this.reasonForConsult.other !== "" &&
              this.patientSettings.physician !== "" &&
              this.patientSettings.hospitalCampus !== "" &&
              !this.bloodPressureControl.invalid &&
              !this.heartRateControl.invalid &&
              !this.oxygenSaturationControl.invalid &&
              !this.rrControl.invalid &&
              this.patientSettings.weight !== "" &&
              this.patientSettings.temp !== ""
            ) {
              this.displayErrorsReason = false;
              this.displayCheckReason = true;
            } else {
              this.displayCheckReason = false;
              this.displayErrorsReason = true;
            }

            if (this.retrievedData["reason"] !== "" && this.retrievedData["reason"] !== undefined) {
              for (let reason of this.retrieveArrayForReasonForConsult) {
                if (reason === "Cardiac Arrest") {
                  this.reasonForConsult.cardiacArrest = "true";
                }
                if (reason === "Possible Stroke") {
                   this.reasonForConsult.possibleStroke = 'true';
                  ///this.patientForm.get('possibleStroke').setValue('true');
                  // this.patientForm.get('possibleStroke').patchValue('true');
                  this.patientForm.get("possibleStroke").setValue("true");
                  this.ref.detectChanges();
                }
                if (
                  reason !== "Cardiac Arrest" &&
                  reason !== "Possible Stroke"
                ) {
                  // this.reasonForConsult.other = reason;
                  this.patientForm
                    .get("reasonForConsultOther")
                    .setValue(reason);
                }
              }
            }
            // re-initialize so that if we untick checkboxes they are updated
            this.symptoms = {
              rightSidedWeakness: "",
              leftSidedWeakness: "",
              rightSidedNumbness: "",
              leftSidedNumbness: "",
              leftGazePreference: "",
              rightGazePreference: "",
              leftFacialDroop: "",
              rightFacialDroop: "",
              aphasia: "",
              slurredSpeech: "",
              blurryVision: "",
              doubleVision: "",
              peripheralVisionLoss: "",
              alteredMentalStatus: "",
              headache: "",
              dizziness: "",
              unsteadyGait: "",
              vertigo: "",
              visionLoss: "",
              seizure: "",
              other: "",
            };
            this.antiPlateletTherapyMedications = {
              aspirin: false,
              clopidogrel: false,
              aspirinAndClopidogrel: false,
              ticagrelor: false,
              unableToDetermine: false,
              none: false,
              unknown: false,
              other: "",
            };
            
            this.antiCoagulationTherapyMedications = {
              warfarin: false,
              apixaban: false,
              dabigatran: false,
              rivaroxaban: false,
              enoxaparin: false,
              heparin: false,
              unknown: false,
              other: ""
            };
            this.pastMedicalHistory = {
              ckd: false,
              unknown: false,
               none: false,
               htn: false,
               hld: false,
               atrialFibrillation: false,
               giBleed: false,
               congestiveHeartFailure: false,
               cerebralAneurysm: false,
               mi: false,
               dm: false,
               priorStroke: false,
               dementia: false,
               brainTumor: false,
               seizure: false,
               migraine: false,
                cancer: false,
              other: "",
            };
            this.socialHistory = {
              none: false,
              alcohol: false,
              drugs: false,
              tobacco: false,
            };
            this.familyHistory = {
              none: false,
              unknown: false,
              mi: false,
              stroke: false,
              cancer: false,
              seizure: false,
              other: ""
            };
            this.tobacco = {
              none: false,
              chewing: false,
              vapepen: false,
              unknown: false,
              cigarettes: false,
              other: ""
            };
            this.drugs = {
              none: false,
              cocaine: false,
              lsd: false,
              marijuana: false,
              heroine: false,
              methamphetamine: false,
              unknown: false,
              other: ""
            };
            if (this.retrievedData["symptoms"]!== "" && this.retrievedData["symptoms"]!== undefined) {
              for (let symptom of JSON.parse(this.retrievedData.symptoms)) {
                console.log(symptom);
                if (symptom === "Right sided weakness") {
                  this.symptoms.rightSidedWeakness = "true";
                }
                if (symptom === "Left sided weakness") {
                  this.symptoms.leftSidedWeakness = "true";
                }
                if (symptom === "Right sided numbness") {
                  this.symptoms.rightSidedNumbness = "true";
                }
                if (symptom === "Left sided numbness") {
                  this.symptoms.leftSidedNumbness = "true";
                }
                if (symptom === "Left gaze preference") {
                  this.symptoms.leftGazePreference = "true";
                }
                if (symptom === "Right gaze preference") {
                  this.symptoms.rightGazePreference = "true";
                }
                if (symptom === "Left facial droop") {
                  this.symptoms.leftFacialDroop = "true";
                }
                if (symptom === "Right facial droop") {
                  this.symptoms.rightFacialDroop = "true";
                }
                if (symptom === "Aphasia") {
                  this.symptoms.aphasia = "true";
                }
                if (symptom === "Slurred speech") {
                  this.symptoms.slurredSpeech = "true";
                }
                if (symptom === "Blurry vision") {
                  this.symptoms.blurryVision = "true";
                }
                if (symptom === "Double vision") {
                  this.symptoms.doubleVision = "true";
                }
                if (symptom === "Peripheral vision loss") {
                  this.symptoms.peripheralVisionLoss = "true";
                }
                if (symptom === "Altered mental status") {
                  this.symptoms.alteredMentalStatus = "true";
                }
                if (symptom === "Headache") {
                  this.symptoms.headache = "true";
                }
                if (symptom === "Dizziness") {
                  this.symptoms.dizziness = "true";
                }
                if (symptom === "Unsteady gait") {
                  this.symptoms.unsteadyGait = "true";
                }
                if (symptom === "Vertigo") {
                  this.symptoms.vertigo = "true";
                }
                if (symptom === "Vision loss") {
                  this.symptoms.visionLoss = "true";
                }
                if (symptom === "Seizure") {
                  this.symptoms.seizure = "true";
                }
                if (
              symptom !== "Right side weakness" &&
              symptom !== "Left side weakness" &&
              symptom !== "Right sided numbness" &&
              symptom !== "Left sided numbness" &&
              symptom !== "Left gaze preference" &&
              symptom !== "Right gaze preference" &&
              symptom !== "Left facial droop" &&
              symptom !== "Right facial droop" &&
              symptom !== "Aphasia" &&
              symptom !== "Slurred speech" &&
              symptom !== "Blurry vision" &&
              symptom !== "Double vision" &&
              symptom !== "Peripheral vision loss" &&
              symptom !== "Altered mental status" &&
              symptom !== "Headache" &&
              symptom !== "Dizziness" &&
              symptom !== "Unsteady gait" &&
              symptom !== "Vertigo" &&
              symptom !== "Vision loss" &&
              symptom !== "Seizure"
                ) {
                  this.symptoms.other = symptom;
                }
              }
            }
            if (this.retrievedData["medicalHistory"]!== "" && this.retrievedData["medicalHistory"] !== undefined) {
              for (let pastMedicalHistoryItem of JSON.parse(
                this.retrievedData.medicalHistory
              )) {
                if (pastMedicalHistoryItem === "Unknown") {
                  this.pastMedicalHistory.unknown = true;
                }
                if (pastMedicalHistoryItem === "None") {
                  this.pastMedicalHistory.none = true;
                }
                if (pastMedicalHistoryItem === "HTN") {
                  this.pastMedicalHistory.htn = true;
                }
                if (pastMedicalHistoryItem === "HLD") {
                  this.pastMedicalHistory.hld = true;
                }
                if (pastMedicalHistoryItem === "Atrial Fibrillation") {
                  this.pastMedicalHistory.atrialFibrillation = true;
                }
                if (pastMedicalHistoryItem === "GI Bleed") {
                  this.pastMedicalHistory.giBleed = true;
                }
                if (pastMedicalHistoryItem === "Cerebral Aneurysm") {
                  this.pastMedicalHistory.cerebralAneurysm = true;
                }
                if (pastMedicalHistoryItem === "Brain Tumor") {
                  this.pastMedicalHistory.brainTumor = true;
                }
            
                if (pastMedicalHistoryItem === "MI") {
                  this.pastMedicalHistory.mi = true;
                }
                if (pastMedicalHistoryItem === "DM") {
                  this.pastMedicalHistory.dm = true;
                }
                if (pastMedicalHistoryItem === "Prior Stroke") {
                  this.pastMedicalHistory.priorStroke = true;
                }
                if (pastMedicalHistoryItem === "Dementia") {
                  this.pastMedicalHistory.dementia = true;
                }
                if (pastMedicalHistoryItem === "CKD") {
                  this.pastMedicalHistory.ckd = true;
                }

                if (pastMedicalHistoryItem === "Congestive Heart Failure") {
                  this.pastMedicalHistory.congestiveHeartFailure = true;
                }
            
                if (pastMedicalHistoryItem === "Seizure") {
                  this.pastMedicalHistory.seizure = true;
                }
                if (pastMedicalHistoryItem === "Migraine") {
                  this.pastMedicalHistory.migraine = true;
                }
                if (pastMedicalHistoryItem === "Cancer") {
                  this.pastMedicalHistory.cancer = true;
                }
                if (
                  pastMedicalHistoryItem !== "Unknown" &&
                  pastMedicalHistoryItem !== "None" &&
                  pastMedicalHistoryItem !== "HTN" &&
                  pastMedicalHistoryItem !== "HLD" &&
                  pastMedicalHistoryItem !== "Atrial Fibrillation" &&
                  pastMedicalHistoryItem !== "GI Bleed" &&
                  pastMedicalHistoryItem !== "Brain Tumor" &&
                  pastMedicalHistoryItem !== "MI" &&
                  pastMedicalHistoryItem !== "DM" &&
                  pastMedicalHistoryItem !== "Prior Stroke" &&
                  pastMedicalHistoryItem !== "Dementia" &&
                  pastMedicalHistoryItem !== "Cerebral Aneurysm" &&
                  pastMedicalHistoryItem !== "CKD" &&
                  pastMedicalHistoryItem !== "Congestive Heart Failure" &&
                  pastMedicalHistoryItem !== "Seizure" &&
                  pastMedicalHistoryItem !== "Migraine" &&
                  pastMedicalHistoryItem !== "Cancer"
                ) {
                  this.pastMedicalHistory.other = pastMedicalHistoryItem;
                }
              }
            }


            if (this.retrievedData["medicationsForAntiplateletTherapy"]!== "" && this.retrievedData["medicationsForAntiplateletTherapy"] !== undefined) {
              for (let medicationsForAntiplateletTherapyItem of JSON.parse(
                this.retrievedData.medicationsForAntiplateletTherapy
              )) {
                if (medicationsForAntiplateletTherapyItem === "Aspirin") {
                  this.antiPlateletTherapyMedications.aspirin = true;
                }
                if (medicationsForAntiplateletTherapyItem === "Clopidogrel") {
                  this.antiPlateletTherapyMedications.clopidogrel = true;
                }
                if (medicationsForAntiplateletTherapyItem === "Aspirin & Clopidogrel") {
                  this.antiPlateletTherapyMedications.aspirinAndClopidogrel = true;
                }
                if (medicationsForAntiplateletTherapyItem === "Ticagrelor") {
                  this.antiPlateletTherapyMedications.ticagrelor = true;
                }
                if (medicationsForAntiplateletTherapyItem === "Unable to determine") {
                  this.antiPlateletTherapyMedications.unableToDetermine = true;
                }
                if (medicationsForAntiplateletTherapyItem === "None") {
                  this.antiPlateletTherapyMedications.none = true;
                }
                if (medicationsForAntiplateletTherapyItem === "Unknown") {
                  this.antiPlateletTherapyMedications.unknown = true;
                }
                
                if (
                  medicationsForAntiplateletTherapyItem !== "Aspirin" &&
                  medicationsForAntiplateletTherapyItem !== "Clopidogrel" &&
                  medicationsForAntiplateletTherapyItem !== "Aspirin & Clopidogrel" &&
                  medicationsForAntiplateletTherapyItem !== "Ticagrelor" &&
                  medicationsForAntiplateletTherapyItem !== "Unable to determine" &&
                  medicationsForAntiplateletTherapyItem !== "None" &&
                  medicationsForAntiplateletTherapyItem !== "Unknown" 
                 
                ) {
                  this.antiPlateletTherapyMedications.other = medicationsForAntiplateletTherapyItem;
                }
              }
            }


            if (this.retrievedData["medicationsForAnticoagulationTherapy"]!== "" && this.retrievedData["medicationsForAnticoagulationTherapy"] !== undefined) {
              for (let medicationsForAnticoagulationTherapyItem of JSON.parse(
                this.retrievedData.medicationsForAntiplateletTherapy
              )) {
                if (medicationsForAnticoagulationTherapyItem === "Warfarin") {
                  this.antiCoagulationTherapyMedications.warfarin = true;
                }
                if (medicationsForAnticoagulationTherapyItem === "Apixaban") {
                  this.antiCoagulationTherapyMedications.apixaban = true;
                }
                if (medicationsForAnticoagulationTherapyItem === "Dabigatran") {
                  this.antiCoagulationTherapyMedications.dabigatran = true;
                }
                if (medicationsForAnticoagulationTherapyItem === "Rivaroxaban") {
                  this.antiCoagulationTherapyMedications.rivaroxaban = true;
                }
                if (medicationsForAnticoagulationTherapyItem === "Enoxaparin") {
                  this.antiCoagulationTherapyMedications.enoxaparin = true;
                }
                if (medicationsForAnticoagulationTherapyItem === "Heparin") {
                  this.antiCoagulationTherapyMedications.heparin = true;
                }
                if (medicationsForAnticoagulationTherapyItem === "Unknown") {
                  this.antiCoagulationTherapyMedications.unknown = true;
                }
                
                if (
                  medicationsForAnticoagulationTherapyItem !== "Warfarin" &&
                  medicationsForAnticoagulationTherapyItem !== "Apixaban" &&
                  medicationsForAnticoagulationTherapyItem !== "Dabigatran" &&
                  medicationsForAnticoagulationTherapyItem !== "Rivaroxaban" &&
                  medicationsForAnticoagulationTherapyItem !== "Enoxaparin" &&
                  medicationsForAnticoagulationTherapyItem !== "Heparin" &&
                  medicationsForAnticoagulationTherapyItem !== "Unknown" 
                 
                ) {
                  this.antiCoagulationTherapyMedications.other = medicationsForAnticoagulationTherapyItem;
                }
              }
            }


              // if (this.retrievedData["surgicalHistory"] !== "" && this.retrievedData["surgicalHistory"] !== undefined && this.retrievedData["surgicalHistory"] !== null) {
              //   for (let pastSurgicalHistoryItem of JSON.parse(
              //     this.retrievedData.surgicalHistory
              //   )) {
              //     if (pastSurgicalHistoryItem === "Unknown") {
              //       this.pastSurgicalHistory.surgicalHistory = "Unknown";
              //       this.pastSurgicalHistory.other = "";
              //     }
              //     if (pastSurgicalHistoryItem === "None") {
              //       this.pastSurgicalHistory.surgicalHistory = "None";
              //       this.pastSurgicalHistory.other = "";
              //     }
              //     if (
              //       pastSurgicalHistoryItem !== "Unknown" &&
              //       pastSurgicalHistoryItem !== "None" &&
              //       pastSurgicalHistoryItem !== "false"
              //     ) {
              //       this.pastSurgicalHistory.other = pastSurgicalHistoryItem;
              //       this.surgicalHistoryControl.setValue("false");
              //     }
              //   }
              // }
              // if (
              //   this.retrievedData["medicalHistory"].length > 0 &&
              //   (this.pastSurgicalHistory.other !== "" ||
              //     (this.pastSurgicalHistory.surgicalHistory !== "" &&
              //       this.pastSurgicalHistory.surgicalHistory !== "false"))
              // ) {
              //   this.displayCheckPastMedical = true;
              //   this.displayErrorPastMedical = false;
              // } else {
              //   this.displayCheckPastMedical = false;
              //   this.displayErrorPastMedical = true;
              // }

            if (this.retrievedData["currentMedications"] !== "" && this.retrievedData["currentMedications"] !== undefined && this.retrievedData["currentMedications"] !== null) {
              for (let currentMedicationsItem of JSON.parse(
                this.retrievedData.currentMedications
              )) {
                if (currentMedicationsItem === "Unknown") {
                  this.medications.medicationsOptions = "Unknown";
                  this.medications.medicationsCustom = "";
                }
                if (currentMedicationsItem === "None") {
                  this.medications.medicationsOptions = "None";
                  this.medications.medicationsCustom = "";
                }
                if (
                  currentMedicationsItem !== "Unknown" &&
                  currentMedicationsItem !== "None" &&
                  currentMedicationsItem !== "false"
                ) {
                  this.medications.medicationsCustom = currentMedicationsItem;
                  this.medicationsOptionsControl.setValue("false");
                }
              }
            }
            if (this.retrievedData["allergies"] !== "" && this.retrievedData["allergies"] !== undefined && this.retrievedData["allergies"] !== null) {
              for (let allergiesItem of JSON.parse(
                this.retrievedData.allergies
              )) {
                if (allergiesItem === "Unknown") {
                  this.allergies.allergiesOptions = "Unknown";
                  this.allergies.allergiesCustom = "";
                }
                if (allergiesItem === "NKDA") {
                  this.allergies.allergiesOptions = "NKDA";
                  this.allergies.allergiesCustom = "";
                }
                if (
                  allergiesItem !== "Unknown" &&
                  allergiesItem !== "NKDA" &&
                  allergiesItem !== "false"
                ) {
                  this.allergies.allergiesCustom = allergiesItem;
                  this.allergiesOptionsControl.setValue("false");
                }
              }
            }
            if (
              (this.allergies.allergiesCustom === "" &&
                (this.allergies.allergiesOptions === "" ||
                  this.allergies.allergiesOptions === "false")) ||
              (this.medications.medicationsCustom === "" &&
                (this.medications.medicationsOptions === "" ||
                  this.medications.medicationsOptions === "false"))
            ) {
              this.displayCheckMedications = false;
              this.displayErrorMedicatons = true;
            } else {
              this.displayCheckMedications = true;
              this.displayErrorMedicatons = false;
            }
            if (this.retrievedData["familyHistory"] !== "" && this.retrievedData["familyHistory"] !== undefined && this.retrievedData["familyHistory"] !== null) {
              for (let familyHistoryItem of JSON.parse(
                this.retrievedData.familyHistory
              )) {
                console.log("family", familyHistoryItem);
                if (familyHistoryItem === "Unknown") {
                  this.familyHistory.unknown = true;
                }
                if (familyHistoryItem === "None") {
                  this.familyHistory.none = true;
                }
                if (familyHistoryItem === "MI") {
                  this.familyHistory.mi = true;
                }
                if (familyHistoryItem === "Stroke") {
                  this.familyHistory.stroke = true;
                }
                if (familyHistoryItem === "Cancer") {
                  this.familyHistory.cancer = true;
                }
                if (familyHistoryItem === "Seizure") {
                  this.familyHistory.seizure = true;
                }
                if (
                  familyHistoryItem !== "Unknown" &&
                  familyHistoryItem !== "None" &&
                  familyHistoryItem !== "MI" &&
                  familyHistoryItem !== "Stroke" &&
                  familyHistoryItem !== "Cancer" &&
                  familyHistoryItem !== "Seizure"
                  
                ) {
                  this.familyHistory.other = familyHistoryItem;
                }
                
              }
            }

            if (this.retrievedData["patientTobaccoConsumption"] !== "" && this.retrievedData["patientTobaccoConsumption"] !== undefined && this.retrievedData["patientTobaccoConsumption"] !== null) {
              for (let tobaccoItem of JSON.parse(
                this.retrievedData.tobaccoItem
              )) {
                console.log("tobacco", tobaccoItem);
                if (tobaccoItem === "None") {
                  this.tobacco.none = true;
                }
                if (tobaccoItem === "Chewing") {
                  this.tobacco.chewing = true;
                }
                if (tobaccoItem === "Vape Pen") {
                  this.tobacco.vapepen = true;
                }
                if (tobaccoItem === "Unknown") {
                  this.tobacco.unknown = true;
                }
                if (tobaccoItem === "Cigarettes") {
                
                  this.tobacco.cigarettes = true;
                  // this.cigaretteSection = true;
                }
               
                if (
                  tobaccoItem !== "None" &&
                  tobaccoItem !== "Chewing" &&
                  tobaccoItem !== "Vape Pen" &&
                  tobaccoItem !== "Unknown" &&
                  tobaccoItem !== "Cigarettes"
                  
                ) {
                  this.tobacco.other = tobaccoItem;
                }
                
              }
            }

            if (this.retrievedData["patientDrugsConsumption"] !== "" && this.retrievedData["patientDrugsConsumption"] !== undefined && this.retrievedData["patientDrugsConsumption"] !== null) {
              for (let drugsItem of JSON.parse(
                this.retrievedData.drugsItem
              )) {
                console.log("drugs", drugsItem);
                if (drugsItem === "None") {
                  this.drugs.none = true;
                }
                if (drugsItem === "Cocaine") {
                  this.drugs.cocaine = true;
                }
                if (drugsItem === "LSD") {
                  this.drugs.lsd = true;
                }
                if (drugsItem === "Marijuana") {
                  this.drugs.marijuana = true;
                }
                if (drugsItem === "Heroine") {
                  this.drugs.heroine = true;
                }
                if (drugsItem === "Methamphetamine") {
                  this.drugs.methamphetamine = true;
                }
                if (drugsItem === "Unknown") {
                  this.drugs.unknown = true;
                }
                if (
                  drugsItem !== "None" &&
                  drugsItem !== "Cocaine" &&
                  drugsItem !== "LSD" &&
                  drugsItem !== "Marijuana" &&
                  drugsItem !== "Heroine" &&
                  drugsItem !== "Methamphetamine" &&
                  drugsItem !== "Unknown"
                ) {
                  this.drugs.other = drugsItem;
                }
                
              }
            }

            if (this.retrievedData["socialHistory"]!== ""  && this.retrievedData["socialHistory"]!== undefined && this.retrievedData["socialHistory"]!== null) {
              for (let socialHistoryItem of JSON.parse(
                this.retrievedData.socialHistory
              )) {
                console.log("social", socialHistoryItem);
                // if (socialHistoryItem === "Unknown") {
                //   this.socialHistory.unknown = true;
                // }
                if (socialHistoryItem === "None") {
                  this.socialHistory.none = true;
                }
                if (socialHistoryItem === "Alcohol") {
                  this.socialHistory.alcohol = true;
                }
                if (socialHistoryItem === "Drugs") {
                  this.socialHistory.drugs = true;
                }
                if (socialHistoryItem === "Tobacco") {
                  this.socialHistory.tobacco = true;
                }
              }
            }
            if (
              this.retrievedData["familyHistory"].length > 0 &&
              this.retrievedData["socialHistory"].length > 0
            ) 
            {
              // this.displayCheckFamily = true;
              // this.displayErrorFamily = false;
            } else 
            {
              // this.displayCheckFamily = false;
              // this.displayErrorFamily = true;
            }
            this.loader.complete();
            this.getHospitalCampus();
            this.setFirebaseFormDetails();
            this.validateSymptoms();
          } else {
            this.loader.complete();
            this.patientSettings = {
              familyContactNumber: "",
              meansOfArrivalOptions: "",
              meansOfArrivalCustom: "",
              preArrivalOption: "",
              patientOnAntiplatelet: "",
              patientOnAnticoagulation: "",
              cigarettesPerDay: "",
              cigarettesPerDayQuantity: "",
              alcoholConsumption: "",
              nurse: this.loggedInUser,
              nurseFullName: localStorage.getItem('nurseFullName'),
              physician: "",
              historian: "",
              hospitalCampus: "",
              setLastKnownNormal: "false",
              arrivalToEdDate: this.defaultDate,
              arrivalToEdTime: "",
              lastKnownNormalDate: this.defaultDate,
              lastKnownNormalTime: "",
              tpaEligibilityUnknown: false,
              familyAtBedside: "",
              bloodPressure: "",
              weight: "",
              oxygenSat: "",
              heartRate: "",
              temp: "",
              rr: "",
              weightUnit: "lbs",
              tempUnit: "fahrenheit",
              bloodGlucose: "",
              medicationsCustom: "",
              miInPrevious3Weeks: "",
              strokeInPrevious3Months: "",
              historyOfIch: "",
              tookCEPX: "",
              historyOfNeoplasm: "",
              isIntracranial: "",
            };
            this.getHospitalCampus();
            this.setFirebaseFormDetails();
          }
        },
        (err) => {
          this.loader.complete();
        }
      );
  }
  reverseDate(date) {
    if (date !== null || date !== "" || date !== undefined) {
      const dateArr = date.split("/");
      const modifiedDate = dateArr[0] + "/" + dateArr[1] + "/" + dateArr[2];
      var convertedDate = new Date(modifiedDate);
      date = moment(convertedDate);
      return date;
    }
  }

  /*=================  Start Video ===============*/
  startVideoCall() {
    this.setFirebaseFormDetails();
    // this.getSessionDetails().then(res => this.setFormDetails());
  }
  // calculate age
  CalculateAge(receivedDate, receivedMonth, receivedYear) {
    var yearThen = receivedYear;
    var monthThen = receivedMonth;
    var dayThen = receivedDate;
    var today = new Date();
    var birthday = new Date(yearThen, monthThen - 1, dayThen);
    var differenceInMilisecond = today.valueOf() - birthday.valueOf();
    var year_age = Math.floor(differenceInMilisecond / 31536000000);
    var day_age = Math.floor((differenceInMilisecond % 31536000000) / 86400000);
    return year_age;
  }
 
  patientSentTOCT() {
      this.cigaretteConsumption = this.patientSettings.cigarettesPerDay + " "+this.patientSettings.cigarettesPerDayQuantity;
    // if(this.patientSettings.lastKnownNormalTime === ""){
    //   this.patientSettings.lastKnownNormalTime = "Unknown";
    // }

    this.loader.start();
    this.loader.set(10);
    const headers = new HttpHeaders().set(
      "Content-Type",
      "application/x-www-form-urlencoded"
    );
    const params = new HttpParams()
    .set("allergies", JSON.stringify(this.allergiesArray))
    .set("arrivalToEdDate", JSON.stringify(this.patientSettings.arrivalToEdDate))
    .set("arrivalToEdTime", this.patientSettings.arrivalToEdTime)
    .set("offset", JSON.stringify(this.offset))
    .set("bloodGlucose", this.patientSettings.bloodGlucose)
    .set("bloodPressure", this.patientSettings.bloodPressure)
    .set("callId", this.receivedCallIdFromQuery)
    .set("currentMedications", JSON.stringify(this.medicationsArray))
    .set("familyContactNumber", this.patientSettings.familyContactNumber)
    .set("familyHistory", JSON.stringify(this.familyHistoryArray))
    .set("heartRate", this.patientSettings.heartRate)
    .set("historian", this.patientSettings.historian)
    .set("hospitalCampus", this.patientSettings.hospitalCampus)
    .set("isFamilyBedside", this.patientSettings.familyAtBedside)
    .set("isICHHistory", this.patientSettings.historyOfIch)
    .set("isIntracranial", this.patientSettings.isIntracranial)
    .set("isNeoplasm", this.patientSettings.historyOfNeoplasm)
    .set("lastKnownNormalDate", JSON.stringify(this.patientSettings.lastKnownNormalDate))
    .set("lastKnownNormalTime", this.patientSettings.lastKnownNormalTime)
    .set("meansOfArrival", JSON.stringify(this.meansOfArrivalObj))
    .set("medicalHistory", JSON.stringify(this.pastMedicalHistoryArray))
    .set("milnPrevious3Weeks", this.patientSettings.miInPrevious3Weeks)
    .set("nurse", this.loggedInUser)
    .set("oxygenSat", this.patientSettings.oxygenSat)
    .set("patientId", this.receivedPatientId)
    .set("physician", this.patientSettings.physician)
    .set("preArrivalNotification", this.patientSettings.preArrivalOption)
    .set("isPatientOnAntiplatelet", this.patientSettings.patientOnAntiplatelet)
    .set("wasPatientOnAnticoagulation", this.patientSettings.patientOnAnticoagulation)
    .set("doesPatientConsumeAlcohol", this.patientSettings.alcoholConsumption)
    .set("cigaretteCount", this.patientSettings.cigarettesPerDay + " " +this.patientSettings.cigarettesPerDayQuantity)
    .set("medicationForAntiplateletTherapy", JSON.stringify(this.medicationsForAntiplateletTherapyArray))
    .set("medicationForAnticoagulationTherapy", JSON.stringify(this.medicationsForAnticoagulationTherapyArray))
    .set("patientDrugsConsumption", JSON.stringify(this.drugsArray))
    .set("reason", JSON.stringify(this.reasonForConsultArray))
    .set("rr", this.patientSettings.rr)
    .set("socialHistory", JSON.stringify(this.tobaccoArray))
    .set("strokeInPrevious3Months", this.patientSettings.strokeInPrevious3Months)
    .set("surgicalHistory", JSON.stringify(this.pastSurgicalHistoryArray))
    .set("symptoms", JSON.stringify(this.symptomsArray))
    .set("temp", this.patientSettings.temp)
    .set("tempUnit", this.patientSettings.tempUnit) 
    .set("tookCEPX", this.patientSettings.tookCEPX)
    .set("tpaEligibilityUnknown", JSON.stringify(this.patientSettings.tpaEligibilityUnknown))
    .set("userId", this.loggedInUser)
    .set("fullName", this.patientSettings.nurseFullName)
    .set("videoSessionId", '')
    .set("videoToken", '')
    .set("weight", this.patientSettings.weight)
    .set("weightUnit", this.patientSettings.weightUnit)
    .set("type", "patientSentToCt");
      // .set("userId", this.loggedInUser)
      // .set("callId", this.receivedCallIdFromQuery)
      // .set("type", "patientSentToCt")
      // .set("patientId", this.receivedPatientId);
    // this.isloading = true; // Loader Start
    this.http
      .post(this.authService.baseURL + "/IntiateStrokeNotification", params, {
        headers: headers,
      })
      .subscribe(
        (response) => {
          console.log("response " + response);
          if (response["message"] === "success") {
            // this.openSuccessDialog();
            this.openSnackBar("Notified !!");
            this.loader.complete();
            // persist button status
            localStorage.setItem("patientSentTOCT", "true");
          } else {
            this.openPatientSentToCTErrorDialog();
            this.loader.complete();
          }
        },
        (err) => {
          this.openPatientSentToCTErrorDialog();
          this.loader.complete();
        }
      );
    // this.changeToActive();
  }

  createTobaccoArray(){
    this.tobaccoArray = [];

    if (this.tobacco.none) {
      this.tobaccoArray.push("None");
    }
    if (this.tobacco.chewing) {
      this.tobaccoArray.push("Chewing");
    
    } 
    if (this.tobacco.vapepen) {
      this.tobaccoArray.push("Vape Pen");
  
    }
    if (this.tobacco.unknown) {
      this.tobaccoArray.push("Unknown");
   
    } 
    if (this.tobacco.cigarettes) {

      this.tobaccoArray.push("Cigarettes");
  
    } 
    if (this.tobacco.other){
      // this.socialHistoryObj["other"]=this.tobacco.other;
      this.tobaccoArray.push(this.tobacco.other);
    }
    console.log(this.tobaccoArray);
  }


  openErrorDialog() {
    const dialogRef = this.errorDialog.open(ErrorDialogComponent, {
      height: "72px",
      data: "Some error occurred, please try again.",
    });
  }

  openSuccessDialog() {
    const dialogRef = this.successDialog.open(SuccessDialogComponent, {
      height: "72px",
      data: "Notification sent successfully.",
    });
  }

  openPatientSentToCTErrorDialog() {
    const dialogRef = this.errorDialog.open(ErrorDialogComponent, {
      height: "72px",
      data: "Some error occurred, please try again.",
    });
  }

  focusOnLastKnownNormal() {
    // this.patientSettings.tpaEligibilityUnknown = '';
    if (
      new Date(this.patientSettings.lastKnownNormalDate).getTime() >
      new Date(this.patientSettings.arrivalToEdDate).getTime()
    ) {
      // alert('Last Known Normal is greater than Arrival to ED');
      this.lastKnownNormalDateFormControl.setErrors(Validators.requiredTrue);
      this.showError = true;
      this.ref.detectChanges();
      return;
    } else if (
      new Date(this.patientSettings.lastKnownNormalDate).getTime() ===
      new Date(this.patientSettings.arrivalToEdDate).getTime()
    ) {
      // alert('The dates are equal will wait and check for time');
      // this.checkTime();
      if (
        this.patientSettings.lastKnownNormalTime !== "Unknown" ||
        this.patientSettings.arrivalToEdTime !== ""
      ) {
        if (
          this.patientSettings.lastKnownNormalTime >
          this.patientSettings.arrivalToEdTime
        ) {
          //  alert('Last Known Normal is greater than Arrival to ED');
          this.lastKnownNormalTimeControl.setErrors(Validators.requiredTrue);
          this.showError = true;
          this.ref.detectChanges();
          return;
        } else if (
          this.patientSettings.lastKnownNormalTime <
          this.patientSettings.arrivalToEdTime
        ) {
          //  alert('Date Format is OK');
          this.lastKnownNormalTimeControl.setErrors({ requiredTrue: null });
          this.lastKnownNormalTimeControl.updateValueAndValidity();
          this.showError = false;
          this.ref.detectChanges();
          return;
        } else {
          // alert('Arrival To ED and Last Known Normal are equal..R u sure u entered the correct data');
          this.showError = false;
          this.ref.detectChanges();
          return;
        }
        // return;
      }
      return;
    } else {
      // alert('Date Format is OK');
      // this.lastKnownNormalDateFormControl.setErrors(null);
      this.lastKnownNormalDateFormControl.setErrors({ requiredTrue: null });
      this.lastKnownNormalDateFormControl.updateValueAndValidity();
      this.lastKnownNormalTimeControl.setErrors({ requiredTrue: null });
      this.lastKnownNormalTimeControl.updateValueAndValidity();
      this.showError = false;
      this.ref.detectChanges();
      return;
    }
  }
  checkTime() {
    if (
      this.patientSettings.lastKnownNormalTime >
      this.patientSettings.arrivalToEdTime
    ) {
      // alert('Last Known Normal is greater than Arrival to ED');
      this.showError = true;
      return;
    } else {
      this.showError = false;
      return;
    }
  }
  focusOnUnkown() {
    // this.patientSettings.lastKnownNormalDate = '';
    // this.patientSettings.lastKnownNormalTime = '';
    if (
      this.patientSettings.lastKnownNormalTime !== "Unknown" &&
      this.patientSettings.arrivalToEdTime !== ""
    ) {
      this.focusOnLastKnownNormal();
    }
  }
  // scrollTPA(){
  //   $('li').scrollTop();
  // }

  validateMeansOfArrival() {

    if (
      (this.patientSettings.meansOfArrivalCustom === "" &&
        (this.patientSettings.meansOfArrivalOptions === "Ambulance" || this.patientSettings.meansOfArrivalOptions === "" ||
          this.patientSettings.meansOfArrivalOptions === "false")) ||
        (this.patientSettings.preArrivalOption === "" ||
          this.patientSettings.preArrivalOption === "false") ||
          (this.patientSettings.familyContactNumber === ""))
           {
      this.displayCheckMeansOfArrival = false;
      this.displayErrorsMeansOfArrival = true;
    } else {
      this.displayCheckMeansOfArrival = true;
      this.displayErrorsMeansOfArrival = false;
    }
  }
  validateReasons() {
    /*if (this.patientForm.get('physician').hasError('required')
    || this.patientForm.get('nurse').hasError('required')
    || this.patientForm.get('hospitalCampus').hasError('required')
     )*/

    if (
      this.patientForm.get("reasonForConsultOther").hasError("required") ||
      this.patientForm.get("physician").hasError("required") ||
      this.patientForm.get("hospitalCampus").hasError("required") ||
      this.patientForm.get("nurse").hasError("required") ||
      this.heartRateControl.invalid ||
      this.bloodPressureControl.invalid ||
      this.weightControl.invalid ||
      this.oxygenSaturationControl.invalid ||
      this.tempControl.invalid ||
      this.rrControl.invalid
    ) {
      this.displayErrorsReason = true;
      this.displayCheckReason = false;
      // return true;
      return;
    } else {
      this.displayCheckReason = true;
      this.displayErrorsReason = false;
      // return false;
      return;
    }
  }
  validateSymptoms() {
    if (this.patientSettings.tpaEligibilityUnknown === false) {
      if (
        (!this.symptoms.rightSidedWeakness &&
          !this.symptoms.leftSidedWeakness &&
          !this.symptoms.rightSidedNumbness &&
          !this.symptoms.leftSidedNumbness &&
          !this.symptoms.leftGazePreference &&
          !this.symptoms.rightGazePreference &&
          !this.symptoms.leftFacialDroop &&
          !this.symptoms.rightFacialDroop &&
          !this.symptoms.aphasia &&
          !this.symptoms.slurredSpeech &&
          !this.symptoms.blurryVision && 
          !this.symptoms.doubleVision && 
          !this.symptoms.peripheralVisionLoss &&
          !this.symptoms.alteredMentalStatus &&
          !this.symptoms.headache &&
          !this.symptoms.dizziness &&
          !this.symptoms.unsteadyGait &&
          !this.symptoms.vertigo &&
          !this.symptoms.visionLoss &&
          !this.symptoms.seizure && !this.symptoms.other) ||
        this.lastKnownNormalDateFormControl.invalid ||
        this.lastKnownNormalTimeControl.invalid ||
        this.arrivalToEdDateFormControl.invalid ||
        this.arrivalToEdTimeControl.invalid
      ) {
        this.displayCheckSymptoms = false;
        this.displayErrorsSymptoms = true;
      } else {
        this.displayCheckSymptoms = true;
        this.displayErrorsSymptoms = false;
      }
    } else if (this.patientSettings.tpaEligibilityUnknown === true) {
      if (
        (!this.symptoms.rightSidedWeakness &&
          !this.symptoms.leftSidedWeakness &&
          !this.symptoms.rightSidedNumbness &&
          !this.symptoms.leftSidedNumbness &&
          !this.symptoms.leftGazePreference &&
          !this.symptoms.rightGazePreference &&
          !this.symptoms.leftFacialDroop &&
          !this.symptoms.rightFacialDroop &&
          !this.symptoms.aphasia &&
          !this.symptoms.slurredSpeech &&
          !this.symptoms.blurryVision && 
          !this.symptoms.doubleVision && 
          !this.symptoms.peripheralVisionLoss &&
          !this.symptoms.alteredMentalStatus &&
          !this.symptoms.headache &&
          !this.symptoms.dizziness &&
          !this.symptoms.unsteadyGait &&
          !this.symptoms.vertigo &&
          !this.symptoms.visionLoss &&
          !this.symptoms.seizure && !this.symptoms.other) ||
        this.arrivalToEdDateFormControl.invalid ||
        this.arrivalToEdTimeControl.invalid
      ) {
        this.displayCheckSymptoms = false;
        this.displayErrorsSymptoms = true;
      } else {
        this.displayCheckSymptoms = true;
        this.displayErrorsSymptoms = false;
      }
    }
  }
  validateTPA() {
    var ele =document.getElementById("tpa_nav");
      ele.scrollTop=0;
    if (
      this.patientSettings.miInPrevious3Weeks === "" ||
      this.patientSettings.strokeInPrevious3Months === "" ||
      this.patientSettings.historyOfIch === "" ||
      this.patientSettings.tookCEPX === "" ||
      this.patientSettings.historyOfNeoplasm === "" ||
      this.bloodGlucoseControl.invalid
    ) {
      this.displayErrorTpaEligibility = true;
      this.displayCheckTpaEligibility = false;
    } else {
      this.displayErrorTpaEligibility = false;
      this.displayCheckTpaEligibility = true;
    }
  }
  validateVitalSigns() {
    if (
      this.heartRateControl.hasError("required") ||
      this.bloodPressureControl.hasError("required") ||
      this.weightControl.hasError("required")
    ) {
      // alert('false');
      this.displayErrorVitalSigns = true;
      this.displayCheckVitalSigns = false;
    } else {
      // alert('true');
      this.displayCheckVitalSigns = true;
      this.displayErrorVitalSigns = false;
    }
  }
  validateMedical() {
    var ele =document.getElementById("medandallergies_nav");
    ele.scrollTop=0;
    if (
      !this.pastMedicalHistory.none &&
        !this.pastMedicalHistory.unknown &&
        !this.pastMedicalHistory.cerebralAneurysm &&
        !this.pastMedicalHistory.seizure &&
        !this.pastMedicalHistory.htn &&
        !this.pastMedicalHistory.mi &&
        !this.pastMedicalHistory.migraine &&
        !this.pastMedicalHistory.cancer &&
        !this.pastMedicalHistory.hld &&
        !this.pastMedicalHistory.dm &&
        !this.pastMedicalHistory.ckd &&
        !this.pastMedicalHistory.dementia &&
        !this.pastMedicalHistory.atrialFibrillation &&
        !this.pastMedicalHistory.priorStroke &&
        !this.pastMedicalHistory.congestiveHeartFailure &&
        !this.pastMedicalHistory.giBleed &&
        !this.pastMedicalHistory.brainTumor &&
        !this.pastMedicalHistory.other ||
        this.allergies.allergiesCustom === "" &&
        (this.allergies.allergiesOptions === "" || this.allergies.allergiesOptions === "false" ||
        this.patientSettings.patientOnAntiplatelet === "Yes" &&
        (!this.antiPlateletTherapyMedications.aspirin &&
          !this.antiPlateletTherapyMedications.clopidogrel &&
          !this.antiPlateletTherapyMedications.aspirinAndClopidogrel &&
          !this.antiPlateletTherapyMedications.ticagrelor &&
          !this.antiPlateletTherapyMedications.unableToDetermine &&
          !this.antiPlateletTherapyMedications.none &&
          !this.antiPlateletTherapyMedications.unknown &&
          !this.antiPlateletTherapyMedications.other) ||
        this.patientSettings.patientOnAnticoagulation === "Yes"  &&
        (!this.antiCoagulationTherapyMedications.warfarin &&
          !this.antiCoagulationTherapyMedications.apixaban &&
          !this.antiCoagulationTherapyMedications.dabigatran &&
          !this.antiCoagulationTherapyMedications.rivaroxaban &&
          !this.antiCoagulationTherapyMedications.enoxaparin &&
          !this.antiCoagulationTherapyMedications.heparin &&
          !this.antiCoagulationTherapyMedications.unknown &&
          !this.antiCoagulationTherapyMedications.other) )
        )
      {
      this.displayErrorPastMedical = true;
      this.displayCheckPastMedical = false;
    }
    else {
      this.displayErrorPastMedical = false;
      this.displayCheckPastMedical = true;
    }
  }
  validateSurgicalHistory(){
    
    // console.log(
    //   "radio",
    //   this.patientForm.get("surgicalHistory").hasError("required")
    // );
    // console.log(
    //   "other",
    //   this.patientForm.get("pastSurgicalHistoryCustom").hasError("required")
    // );
    // console.log("surgical", this.pastSurgicalHistory.surgicalHistory);
    if(this.pastSurgicalHistory.other === "" &&
     (this.pastSurgicalHistory.surgicalHistory === "" || this.pastSurgicalHistory.surgicalHistory === "false")
    ){
      this.displayErrorSurgicalHistory = true;
      this.displayCheckSurgicalHistory = false;
    } else {
      this.displayErrorSurgicalHistory = false;
      this.displayCheckSurgicalHistory = true;
    }
  }
  validateMedications() {
    if (
      (this.allergies.allergiesCustom === "" &&
        (this.allergies.allergiesOptions === "" ||
          this.allergies.allergiesOptions === "false")) ||
      (this.medications.medicationsCustom === "" &&
        (this.medications.medicationsOptions === "" ||
          this.medications.medicationsOptions === "false"))
    ) {
      this.displayCheckMedications = false;
      this.displayErrorMedicatons = true;
    } else {
      this.displayCheckMedications = true;
      this.displayErrorMedicatons = false;
    }
    // this.ref.detectChanges();
  }
  validateFamily() {
    var ele =document.getElementById("family_socialHistory_nav");
      ele.scrollTop=0;
    if (
      (!this.familyHistory.none &&
        !this.familyHistory.stroke &&
        !this.familyHistory.mi &&
        !this.familyHistory.cancer &&
        !this.familyHistory.seizure &&
        !this.familyHistory.unknown &&
        !this.familyHistory.other) ||
      (!this.tobacco.none &&
        !this.tobacco.chewing &&
        !this.tobacco.vapepen &&
        !this.tobacco.unknown&&
        !this.tobacco.cigarettes &&
        !this.tobacco.other)||
        (!this.drugs.none &&
          !this.drugs.cocaine &&
          !this.drugs.lsd &&
          !this.drugs.marijuana&&
          !this.drugs.heroine &&
          !this.drugs.methamphetamine &&
          !this.drugs.unknown &&
          !this.drugs.other
          ) || this.patientSettings.alcoholConsumption === "" ||
          (this.tobacco.cigarettes && 
          this.patientSettings.cigarettesPerDay===""
          )
    ) {
      this.displayErrorFamily = true;
      this.displayCheckFamily = false;
    } else {
      this.displayCheckFamily = true;
      this.displayErrorFamily = false;
    }
  }
  clearRadioOptionsMeansOfArrival(){
    this.meansOfArrivalOptionsControl.setValue("false");
  }
  clearRadioOptionsPreArrival(){
    this.preArrivalOptionsControl.setValue("false");
  }
  clearRadioOptionsPatientAntiPlatelet(){
    this.patientOnAntiPlateletTherapyControl.setValue("false");
  }
  clearRadioOptionsPatientAntiCoagulation(){
    this.patientOnAntiCoagulationTherapyControl.setValue("false");
  }
  clearRadioOptionsMedications() {
    this.medicationsOptionsControl.setValue("false");
  }
  clearRadioOptionsAllergies() {
    this.allergiesOptionsControl.setValue("false");
  }
  clearRadioOptionsSurgicalHistory() {
    this.surgicalHistoryControl.setValue("false");
  }
  // ct done
  initiateStrokCode() {
    
    this.loader.start();
    this.loader.set(10);
    const headers = new HttpHeaders().set(
      "Content-Type",
      "application/x-www-form-urlencoded"
    );
    const params = new HttpParams()
    .set("allergies", JSON.stringify(this.allergiesArray))
    .set("arrivalToEdDate", JSON.stringify(this.patientSettings.arrivalToEdDate))
    .set("arrivalToEdTime", this.patientSettings.arrivalToEdTime)
    .set("offset", JSON.stringify(this.offset))
    .set("bloodGlucose", this.patientSettings.bloodGlucose)
    .set("bloodPressure", this.patientSettings.bloodPressure)
    .set("callId", this.receivedCallIdFromQuery)
    .set("currentMedications", JSON.stringify(this.medicationsArray))
    .set("familyContactNumber", this.patientSettings.familyContactNumber)
    .set("familyHistory", JSON.stringify(this.familyHistoryArray))
    .set("heartRate", this.patientSettings.heartRate)
    .set("historian", this.patientSettings.historian)
    .set("hospitalCampus", this.patientSettings.hospitalCampus)
    .set("isFamilyBedside", this.patientSettings.familyAtBedside)
    .set("isICHHistory", this.patientSettings.historyOfIch)
    .set("isIntracranial", this.patientSettings.isIntracranial)
    .set("isNeoplasm", this.patientSettings.historyOfNeoplasm)
    .set("lastKnownNormalDate", JSON.stringify(this.patientSettings.lastKnownNormalDate))
    .set("lastKnownNormalTime", this.patientSettings.lastKnownNormalTime)
    .set("meansOfArrival", JSON.stringify(this.meansOfArrivalObj))
    .set("medicalHistory", JSON.stringify(this.pastMedicalHistoryArray))
    .set("milnPrevious3Weeks", this.patientSettings.miInPrevious3Weeks)
    .set("nurse", this.loggedInUser)
    .set("oxygenSat", this.patientSettings.oxygenSat)
    .set("patientId", this.receivedPatientId)
    .set("physician", this.patientSettings.physician)
    .set("preArrivalNotification", this.patientSettings.preArrivalOption)
    .set("isPatientOnAntiplatelet", this.patientSettings.patientOnAntiplatelet)
    .set("wasPatientOnAnticoagulation", this.patientSettings.patientOnAnticoagulation)
    .set("doesPatientConsumeAlcohol", this.patientSettings.alcoholConsumption)
    .set("cigaretteCount", this.patientSettings.cigarettesPerDay + " " +this.patientSettings.cigarettesPerDayQuantity)
    .set("medicationForAntiplateletTherapy", JSON.stringify(this.medicationsForAntiplateletTherapyArray))
    .set("medicationForAnticoagulationTherapy", JSON.stringify(this.medicationsForAnticoagulationTherapyArray))
    .set("patientDrugsConsumption", JSON.stringify(this.drugsArray))
    .set("reason", JSON.stringify(this.reasonForConsultArray))
    .set("rr", this.patientSettings.rr)
    .set("socialHistory", JSON.stringify(this.tobaccoArray))
    .set("strokeInPrevious3Months", this.patientSettings.strokeInPrevious3Months)
    .set("surgicalHistory", JSON.stringify(this.pastSurgicalHistoryArray))
    .set("symptoms", JSON.stringify(this.symptomsArray))
    .set("temp", this.patientSettings.temp)
    .set("tempUnit", this.patientSettings.tempUnit) 
    .set("tookCEPX", this.patientSettings.tookCEPX)
    .set("tpaEligibilityUnknown", JSON.stringify(this.patientSettings.tpaEligibilityUnknown))
    .set("userId", this.loggedInUser)
    .set("fullName", this.patientSettings.nurseFullName)
    .set("videoSessionId", '')
    .set("videoToken", '')
    .set("weight", this.patientSettings.weight)
    .set("weightUnit", this.patientSettings.weightUnit)
    .set("type", "ctDone");
      // .set("userId", this.loggedInUser)
      // .set("callId", this.receivedCallIdFromQuery)
      // .set("type", "ctDone")
      // .set("patientId", this.receivedPatientId);
    // this.isloading = true; // Loader Start
    this.http
      .post(this.authService.baseURL + "/IntiateStrokeNotification", params, {
        headers: headers,
      })
      .subscribe(
        (response) => {
          // console.log(response);
          console.log("response " + response);
          if (response["message"] === "success") {
            // this.isloading = false; // Loader Stop
            // this.openSuccessDialog();
            this.openSnackBar("Notified !!");
            localStorage.setItem("initiateStrokCode", "true");
          } else {
            this.openErrorDialog();
          }
          this.loader.complete();
        },
        (err) => {
          this.openErrorDialog();
          this.loader.complete();
        }
      );
  }
  // patient ready for video
  patientReadyForVideoNotify() {
    
    this.loader.start();
    this.loader.set(10);
    const headers = new HttpHeaders().set(
      "Content-Type",
      "application/x-www-form-urlencoded"
    );
    const params = new HttpParams()
    .set("allergies", JSON.stringify(this.allergiesArray))
    .set("arrivalToEdDate", JSON.stringify(this.patientSettings.arrivalToEdDate))
    .set("arrivalToEdTime", this.patientSettings.arrivalToEdTime)
    .set("offset", JSON.stringify(this.offset))
    .set("bloodGlucose", this.patientSettings.bloodGlucose)
    .set("bloodPressure", this.patientSettings.bloodPressure)
    .set("callId", this.receivedCallIdFromQuery)
    .set("currentMedications", JSON.stringify(this.medicationsArray))
    .set("familyHistory", JSON.stringify(this.familyHistoryArray))
    .set("familyContactNumber", this.patientSettings.familyContactNumber)
    .set("heartRate", this.patientSettings.heartRate)
    .set("historian", this.patientSettings.historian)
    .set("hospitalCampus", this.patientSettings.hospitalCampus)
    .set("isFamilyBedside", this.patientSettings.familyAtBedside)
    .set("isICHHistory", this.patientSettings.historyOfIch)
    .set("isIntracranial", this.patientSettings.isIntracranial)
    .set("isNeoplasm", this.patientSettings.historyOfNeoplasm)
    .set("lastKnownNormalDate", JSON.stringify(this.patientSettings.lastKnownNormalDate))
    .set("lastKnownNormalTime", this.patientSettings.lastKnownNormalTime)
    .set("meansOfArrival", JSON.stringify(this.meansOfArrivalObj))
    .set("medicalHistory", JSON.stringify(this.pastMedicalHistoryArray))
    .set("milnPrevious3Weeks", this.patientSettings.miInPrevious3Weeks)
    .set("nurse", this.loggedInUser)
    .set("oxygenSat", this.patientSettings.oxygenSat)
    .set("patientId", this.receivedPatientId)
    .set("physician", this.patientSettings.physician)
    .set("preArrivalNotification", this.patientSettings.preArrivalOption)
    .set("isPatientOnAntiplatelet", this.patientSettings.patientOnAntiplatelet)
    .set("wasPatientOnAnticoagulation", this.patientSettings.patientOnAnticoagulation)
    .set("doesPatientConsumeAlcohol", this.patientSettings.alcoholConsumption)
    .set("cigaretteCount", this.patientSettings.cigarettesPerDay + " " +this.patientSettings.cigarettesPerDayQuantity)
    .set("medicationForAntiplateletTherapy", JSON.stringify(this.medicationsForAntiplateletTherapyArray))
    .set("medicationForAnticoagulationTherapy", JSON.stringify(this.medicationsForAnticoagulationTherapyArray))
    .set("patientDrugsConsumption", JSON.stringify(this.drugsArray))
    .set("reason", JSON.stringify(this.reasonForConsultArray))
    .set("rr", this.patientSettings.rr)
    .set("socialHistory", JSON.stringify(this.tobaccoArray))
    .set("strokeInPrevious3Months", this.patientSettings.strokeInPrevious3Months)
    .set("surgicalHistory", JSON.stringify(this.pastSurgicalHistoryArray))
    .set("symptoms", JSON.stringify(this.symptomsArray))
    .set("temp", this.patientSettings.temp)
    .set("tempUnit", this.patientSettings.tempUnit) 
    .set("tookCEPX", this.patientSettings.tookCEPX)
    .set("tpaEligibilityUnknown", JSON.stringify(this.patientSettings.tpaEligibilityUnknown))
    .set("userId", this.loggedInUser)
    .set("fullName", this.patientSettings.nurseFullName)
    .set("videoSessionId", '')
    .set("videoToken", '')
    .set("weight", this.patientSettings.weight)
    .set("weightUnit", this.patientSettings.weightUnit)
    .set("type", "patientReadyForVideo");
        // .set("userId", this.loggedInUser)
      // .set("callId", this.receivedCallIdFromQuery)
      // .set("type", "patientReadyForVideo")
      // .set("patientId", this.receivedPatientId);
    // this.isloading = true; // Loader Start
    this.http
      .post(this.authService.baseURL + "/IntiateStrokeNotification", params, {
        headers: headers,
      })
      .subscribe(
        (response) => {
          // console.log(response);
          console.log("response " + response);
          if (response["message"] === "success") {
            // this.isloading = false; // Loader Stop
            // this.openSuccessDialog();
            this.openSnackBar("Notified !!");
            localStorage.setItem("patientReadyForVideoNotify", "true");
          } else {
            this.openErrorDialog();
          }
          this.loader.complete();
        },
        (err) => {
          this.openErrorDialog();
          this.loader.complete();
        }
      );
  }

  addActiveClass(prev, next) {
    var classId2 = document.getElementById(prev);
    classId2.classList.remove("active");
    var classId = document.getElementById(next);
    classId.classList.add("active");

    if(prev === "zero") {
      this.validateMeansOfArrival();
    }
    if (prev === "one") {
      this.validateReasons();
    }
    if (prev === "two") {
      this.validateSymptoms();
    }
    if (prev === "three") {
      this.validateTPA();
    }
    if (prev === "four") {
      this.validateMedical();
    }
    if (prev === "five") {
      this.validateSurgicalHistory();
      // this.validateMedical();
    }
    if (prev === "six") {
      this.validateFamily();
    }
  }
  showTab(forwardPath) {
    $('.nav-tabs a[href="#' + forwardPath + '"]').tab("show");
  }
  showTabForUnknwon(forwardPath) {
    if (this.patientSettings.tpaEligibilityUnknown) {
      $('.nav-tabs a[href="#' + forwardPath + '"]').tab("show");
    }
  }
  changeToSentToCt() {
    // let e1 = document.getElementById('patientToCt');
    // e1.style.background = '#ffffff !important';
    // console.log('changed');
    this.statusForSentToCt = true;
    // event.target.classList.remove('normalBg');
    // event.target.classList.add('changedBg');
    (<HTMLInputElement>document.getElementById("patientToCt")).classList.remove(
      "normalBg"
    );
    (<HTMLInputElement>document.getElementById("patientToCt")).classList.add(
      "changedBg"
    );

    (<HTMLInputElement>document.getElementById("patientToCt")).disabled = true;
    if (
      this.statusForReadyForVideo === true &&
      this.statusForCtDone === false
    ) {
      (<HTMLInputElement>(
        document.getElementById("patientReadyForVideo")
      )).classList.remove("changedBg");
      (<HTMLInputElement>(
        document.getElementById("patientReadyForVideo")
      )).classList.add("greyBg");
    }
  }
  changeToCT() {
    this.statusForCtDone = true;
    // event.target.classList.remove('normalBg');
    // event.target.classList.add('changedBg');
    (<HTMLInputElement>document.getElementById("ctDone")).classList.remove(
      "normalBg"
    );
    (<HTMLInputElement>document.getElementById("ctDone")).classList.add(
      "changedBg"
    );

    (<HTMLInputElement>document.getElementById("patientToCt")).classList.remove(
      "changedBg"
    );
    (<HTMLInputElement>document.getElementById("patientToCt")).classList.add(
      "greyBg"
    );
    (<HTMLInputElement>document.getElementById("patientToCt")).disabled = true;
    (<HTMLInputElement>document.getElementById("ctDone")).disabled = true;
    if (this.statusForReadyForVideo === true) {
      (<HTMLInputElement>(
        document.getElementById("patientReadyForVideo")
      )).classList.remove("changedBg");
      (<HTMLInputElement>(
        document.getElementById("patientReadyForVideo")
      )).classList.add("greyBg");
    }
  }
  changeToReady() {
    this.statusForReadyForVideo = true;
    // event.target.classList.remove('normalBg');
    // event.target.classList.add('changedBg');
    (<HTMLInputElement>(
      document.getElementById("patientReadyForVideo")
    )).classList.remove("normalBg");
    (<HTMLInputElement>(
      document.getElementById("patientReadyForVideo")
    )).classList.add("changedBg");

    (<HTMLInputElement>(
      document.getElementById("patientReadyForVideo")
    )).disabled = true;
    if (this.statusForCtDone === true && this.statusForSentToCt === false) {
      (<HTMLInputElement>document.getElementById("ctDone")).classList.remove(
        "changedBg"
      );
      (<HTMLInputElement>document.getElementById("ctDone")).classList.add(
        "greyBg"
      );
    } else {
      if (this.statusForCtDone === false && this.statusForSentToCt === true) {
        (<HTMLInputElement>(
          document.getElementById("patientToCt")
        )).classList.remove("changedBg");
        (<HTMLInputElement>(
          document.getElementById("patientToCt")
        )).classList.add("greyBg");
      } else if (
        this.statusForCtDone === true &&
        this.statusForSentToCt === true
      ) {
        (<HTMLInputElement>document.getElementById("ctDone")).classList.remove(
          "changedBg"
        );
        (<HTMLInputElement>document.getElementById("ctDone")).classList.add(
          "greyBg"
        );
      }
    }
  }
  openSnackBar(message: string) {
    this.snackBar.open(message, "", {
      duration: 2000,
      panelClass: ["blue-snackbar"],
    });
  }
  focusOnBtn(id) {
    // (<HTMLInputElement> document.getElementById(id)).classList.remove('normalBg');
    (<HTMLInputElement>document.getElementById(id)).classList.add("tabActive");
  }

  // ------------------Firebase Functions-------------------
  setFirebaseFormDetails() {

    // this.loader.start();
    // this.loader.set(10);
    this.createSymptomsArray();
    this.createPastMedicalHistoryArray();
    this.createMedicationsForAntiplateletTherapyArray();
    this.createMedicationsForAnticoagulationTherapyArray();
    this.createPastSurgicalHistoryArray();
    this.createAllergiesArray();
    this.createMeansOfArrival();
    this.createMedicationsArray();
    this.createFamilHistoryArray();
    this.createSocialHistoryArray();
    this.createReasonForConsultArray();
    if (this.patientSettings.arrivalToEdDate === null) {
      var arrivalDate = new Date();
      this.date = arrivalDate.getDate();
      this.month = arrivalDate.getMonth() + 1;
      this.year = arrivalDate.getFullYear();
      console.log(String(this.date).length);
      if (String(this.date).length === 1) {
        this.date = "0" + this.date;
      }
      if (String(this.month).length === 1) {
        this.month = "0" + this.month;
      }
      console.log(this.date);
      var completeDateArrivalDate =
        this.month + "/" + this.date + "/" + this.year;
    } else {
      let d = new Date(this.patientSettings.arrivalToEdDate);
      this.date = d.getDate();
      this.month = d.getMonth() + 1;
      this.year = d.getFullYear();
      console.log(String(this.date).length);
      if (String(this.date).length === 1) {
        this.date = "0" + this.date;
      }
      if (String(this.month).length === 1) {
        this.month = "0" + this.month;
      }
      console.log(this.date);
      var completeDateArrivalDate =
        this.month + "/" + this.date + "/" + this.year;
      // this.patientSettings.arrivalToEdDate = completeDate;
    }
    if (this.patientSettings.tpaEligibilityUnknown === true) {
      completeDateLastKnownNormalDate = "";
      this.patientSettings.lastKnownNormalTime = "";
    } else {
      // alert(this.patientSettings.lastKnownNormalDate);
      if (this.patientSettings.lastKnownNormalDate === null) {
        //  alert('if');
        var lastKnownNormalDateVar = new Date();
        this.date = lastKnownNormalDateVar.getDate();
        this.month = lastKnownNormalDateVar.getMonth() + 1;
        this.year = lastKnownNormalDateVar.getFullYear();
        console.log(String(this.date).length);
        if (String(this.date).length === 1) {
          this.date = "0" + this.date;
        }
        if (String(this.month).length === 1) {
          this.month = "0" + this.month;
        }
        console.log(this.date);
        var completeDateLastKnownNormalDate =
          this.month + "/" + this.date + "/" + this.year;
        // this.patientSettings.lastKnownNormalDate = completeDate;
      } else {
        let da = new Date(this.patientSettings.lastKnownNormalDate);
        this.date = da.getDate();
        this.month = da.getMonth() + 1;
        this.year = da.getFullYear();
        console.log(String(this.date).length);
        if (String(this.date).length === 1) {
          this.date = "0" + this.date;
        }
        if (String(this.month).length === 1) {
          this.month = "0" + this.month;
        }
        console.log(this.date);
        var completeDateLastKnownNormalDate =
          this.month + "/" + this.date + "/" + this.year;
      }
    }
    var convertedWeight;
    if (
      this.patientSettings.weight !== "" &&
      this.patientSettings.weight !== "null"
    ) {
      convertedWeight = this.patientSettings.weight;
    } else {
      convertedWeight = "";
      this.patientSettings.weight = "";
    }
    var convertedTemp;
    if (
      this.patientSettings.temp !== "" &&
      this.patientSettings.temp !== "null"
    ) {
      convertedTemp = this.patientSettings.temp;
    } else {
      convertedTemp = "";
      this.patientSettings.temp = "";
    }
    var firebaseFormDetails = firebase
      .database()
      .ref("intakeData/stroke/" + this.receivedCallIdFromQuery);
    firebaseFormDetails.update({
      nurse: this.patientSettings.nurse,
      physician: this.patientSettings.physician,
      historian: this.patientSettings.historian,
      hospitalCampus: this.patientSettings.hospitalCampus,
      reason: JSON.stringify(this.reasonForConsultArray),
      symptoms: JSON.stringify(this.symptomsArray),
      bloodPressure: this.patientSettings.bloodPressure,
      weight: String(convertedWeight),
      oxygenSat: this.patientSettings.oxygenSat,
      heartRate: this.patientSettings.heartRate,
      temp: String(convertedTemp),
      rr: this.patientSettings.rr,
      weightUnit: this.patientSettings.weightUnit,
      tempUnit: this.patientSettings.tempUnit,
      bloodGlucose: this.patientSettings.bloodGlucose,
      arrivalToEdDate: completeDateArrivalDate,
      arrivalToEdTime: this.patientSettings.arrivalToEdTime,
      lastKnownNormalDate: completeDateLastKnownNormalDate,
      lastKnownNormalTime: this.patientSettings.lastKnownNormalTime,
      tpaEligibilityUnknown: String(this.patientSettings.tpaEligibilityUnknown),
      isFamilyBedside: this.patientSettings.familyAtBedside,
      miInPrevious3Weeks: this.patientSettings.miInPrevious3Weeks,
      strokeInPrevious3Months: this.patientSettings.strokeInPrevious3Months,
      isICHHistory: this.patientSettings.historyOfIch,
      tookCEPX: this.patientSettings.tookCEPX,
      isNeoplasm: this.patientSettings.historyOfNeoplasm,
      isIntracranial: this.patientSettings.isIntracranial,
      meansOfArrival: JSON.stringify(this.meansOfArrivalObj),
      preArrival: this.patientSettings.preArrivalOption,
      patientOnAntiplatelet: this.patientSettings.patientOnAntiplatelet,
      patientOnAnticoagulation: this.patientSettings.patientOnAnticoagulation,
      alcoholConsumption: this.patientSettings.alcoholConsumption,
      cigarettesPerDay: this.patientSettings.cigarettesPerDay,
      cigarettesPerDayQuantity: this.patientSettings.cigarettesPerDayQuantity,
      medicationsForAnticoagulationTherapy: this.medicationsForAnticoagulationTherapyArray,
      medicationsForAntiplateletTherapy: this.medicationsForAntiplateletTherapyArray,
      medicalHistory: JSON.stringify(this.pastMedicalHistoryArray),
      surgicalHistory: JSON.stringify(this.pastSurgicalHistoryArray),
      allergies: JSON.stringify(this.allergiesArray),
      currentMedications: JSON.stringify(this.medicationsArray),
      socialHistory: JSON.stringify(this.socialHistoryArray),
      familyHistory: JSON.stringify(this.familyHistoryArray),
      tobacco: JSON.stringify(this.tobaccoArray),
      drugs: JSON.stringify(this.drugsArray),
      patientId: this.receivedPatientId,
      userId: this.loggedInUser,
      callId: this.receivedCallIdFromQuery,
      emergencyCall: "false",
    });
  }

  /*============== On Page load get Patient Info ============*/
  setPatientInfo() {
    const callIdRef: firebase.database.Reference = firebase
      .database()
      .ref("patientInfo/stroke/" + this.receivedCallIdFromQuery);
    callIdRef.on("value", (snapshot) => {
      this.ref.detectChanges();
      this.receivedPatientId = snapshot.val()["PatientId"];
      this.receivedPatientName = snapshot.val()["name"];
      this.receivedPatientGender = snapshot.val()["gender"];
      this.receivedPatientDOB = snapshot.val()["dob"];
      var receivedDateDetails = this.receivedPatientDOB.split("/");
      this.receivedMonth = receivedDateDetails[0];
      this.receivedDate = receivedDateDetails[1];
      this.receivedYear = receivedDateDetails[2];
      console.log(this.receivedPatientDOB);
      this.age = this.CalculateAge(
        this.receivedDate,
        this.receivedMonth,
        this.receivedYear
      );
      this.ref.detectChanges();
      this.patientDataRetrieve(this.receivedPatientId);
      this.setFirebaseFormDetails(); // new
    });
  }

  /*============== Get Hospital Campus ===============*/
  getHospitalCampus() {
    firebase
      .database()
      .ref("hospitalCampus/" + this.loggedInUser.toLowerCase() + "/campus")
      .on("value", (snapshot) => {
        if (snapshot.val().address !== null) {
          this.patientSettings.hospitalCampus = snapshot.val().address;
        }
      });
  }
  // Maulik's function to open intake form edit function from icon click
  openEditIntake() {
    this.editStatus=false;
    localStorage.setItem('patientSetting_physician',this.patientSettings.physician)
    localStorage.setItem('patientSetting_historian',this.patientSettings.historian)
    localStorage.setItem('patientSetting_bloodPressure',this.patientSettings.bloodPressure)
    localStorage.setItem('patientSetting_heartRate',this.patientSettings.heartRate)
    localStorage.setItem('patientSetting_oxygenSat',this.patientSettings.oxygenSat)
    localStorage.setItem('patientSetting_rr',this.patientSettings.rr)
    localStorage.setItem('patientSetting_weight',this.patientSettings.weight)
    localStorage.setItem('patientSetting_temp',this.patientSettings.temp)
    localStorage.setItem('patientSetting_bloodGlucose',this.patientSettings.bloodGlucose)
    localStorage.setItem('patientSetting_arrivalToEDTime',this.patientSettings.arrivalToEdTime)
    localStorage.setItem('patientSetting_lastKnownNormalTime',this.patientSettings.lastKnownNormalTime)
    localStorage.setItem('patientSetting_miInPrevious3Weeks',this.patientSettings.miInPrevious3Weeks)
    localStorage.setItem('patientSetting_strokeInPrevious3Months',this.patientSettings.strokeInPrevious3Months)
    localStorage.setItem('patientSetting_historyOfIch',this.patientSettings.historyOfIch)
    localStorage.setItem('patientSetting_tookCEPX', this.patientSettings.tookCEPX)
    localStorage.setItem('patientSetting_historyOfNeoplasm',this.patientSettings.historyOfNeoplasm)
    localStorage.setItem('patientSetting_tempUnit',this.patientSettings.tempUnit)
    localStorage.setItem('patientSetting_weightUnit',this.patientSettings.weightUnit);
    const dialogRef = this.editintakeDialog.open(EditintakeDialogComponent, {
      width: "530px",
      data: {
        patientName: this.receivedPatientName,
        gender: this.receivedPatientGender,
        patientDOB: this.receivedPatientDOB,
        callId: this.receivedCallIdFromQuery,
        user: this.loggedInUser,
        mrn: this.receivedPatientId,
      },
    });
  }
  getDate() {
    return new Date(this.patientSettings.arrivalToEdDate);
  }

  /*========== on focusOut Save data to firebase ============*/
  focusOutFunctionForTextField(textField) {

    var fbRef = firebase
      .database()
      .ref("intakeData/stroke/" + this.receivedCallIdFromQuery);
    switch (textField) {
     
      case "ambulance":
        
          this.createMeansOfArrival();
          fbRef.update({
            meansOfArrival: JSON.stringify(this.meansOfArrivalObj),
          });
          break;
  
        // case"PreArrival":
        //   fbRef.update({
        //     preArrival: this.patientSettings.preArrivalOption,
        //   })
        //   break;

        // case "patientOnAntiplatelet":
        // fbRef.update({
        //   patientOnAntiplatelet: this.patientSettings.patientOnAntiplatelet,
        // })
        // break;

        case "alcoholConsumption":
          fbRef.update({
            alcoholConsumption: this.patientSettings.alcoholConsumption,
          })
          break;

        case "cigarettesPerDay":
          fbRef.update({
            cigarettesPerDay: this.patientSettings.cigarettesPerDay,
          })

        case"familyContactNumber":
          fbRef.update({
            familyContactNumber: this.patientSettings.familyContactNumber,
          })
          break;

      case "physician":
        fbRef.update({
          physician: this.patientSettings.physician,
        });
        break;

      case "nurse":
        fbRef.update({
          nurse: this.patientSettings.nurse,
          nurseFullName: this.patientSettings.nurseFullName,
        });
        break;

      case "reasonForConsult":
        var reasonForConsultArr = [];
        reasonForConsultArr.push(this.reasonForConsult.other);
        fbRef.update({
          reason: JSON.stringify(reasonForConsultArr),
        });
        break;

      case "historian":
        fbRef.update({
          historian: this.patientSettings.historian,
        });
        break;

      case "hospitalCampus":
        fbRef.update({
          hospitalCampus: this.patientSettings.hospitalCampus,
        });
        break;

      // vital Signs
      case "bloodPressure":
        var regex = /^\d{1,3}\/\d{1,3}$/;
        if (
          regex.test(this.patientSettings.bloodPressure) ||
          this.patientSettings.bloodPressure === ""
        ) {
          fbRef.update({
            bloodPressure: this.patientSettings.bloodPressure,
          });
        }
        break;

      case "heartRate":
        var regex = /^\d{1,3}$/;
        if (
          regex.test(this.patientSettings.heartRate) ||
          this.patientSettings.heartRate === ""
        ) {
          fbRef.update({
            heartRate: this.patientSettings.heartRate,
          });
        }
        break;

      case "oxygenSat":
        var regex = /[0-9]+(\.[0-9]{0,2})?%?/;
        if (
          regex.test(this.patientSettings.oxygenSat) ||
          this.patientSettings.oxygenSat === ""
        ) {
          fbRef.update({
            oxygenSat: this.patientSettings.oxygenSat,
          });
        }
        break;

      case "rr":
        var regex = /^(2[0-5]|1[0-9]|[1-9])$/;
        if (
          regex.test(this.patientSettings.rr) ||
          this.patientSettings.rr === ""
        ) {
          fbRef.update({
            rr: this.patientSettings.rr,
          });
        }
        break;

      case "weight":
        if (this.patientSettings.weight === null) {
          this.patientSettings.weight = "";
        }
        fbRef.update({
          weight: this.patientSettings.weight,
        });
        break;

      case "temp":
        if (this.patientSettings.temp === null) {
          this.patientSettings.temp = "";
        }
        fbRef.update({
          temp: this.patientSettings.temp,
        });
        break;

      case "otherSymptoms":
        this.createSymptomsArray();
        fbRef.update({
          symptoms: JSON.stringify(this.symptomsArray),
        });
        break;

      case "patientOnAntiplateletTherapyOther":
        this.createMedicationsForAntiplateletTherapyArray();
        fbRef.update({
          medicationsForAntiplateletTherapy: JSON.stringify(this.medicationsForAntiplateletTherapyArray),
        });
        
        if(this.antiPlateletTherapyMedications.none === true){
          this.antiPlateletTherapyMedications.none = false;
          this.patientForm.patchValue({
            AntiplateletTherapy_none: false,
          });
        }
        break;

        case "patientOnAnticoagulationTherapyOther":
          this.createMedicationsForAnticoagulationTherapyArray();
         
          fbRef.update({
            medicationsForAnticoagulationTherapy: JSON.stringify(this.medicationsForAnticoagulationTherapyArray),
          });
          break;

        case "patientTobaccoConsumption":
            this.createTobaccoArray();
            if(this.tobacco.none === true)
            {
                this.tobacco.none = false;
                this.patientForm.patchValue({
                 tobacco_none: false,
               });
              }
            fbRef.update({
             tobacco :JSON.stringify(this.tobaccoArray),
            });
          break;

        case "patientDrugsConsumption":
              this.createDrugsArray();
              if(this.drugs.none === true)
              {
                  this.drugs.none = false;
                  this.patientForm.patchValue({
                   drugs_none: false,
                 });
                }
              fbRef.update({
               drugs :JSON.stringify(this.drugsArray),
              });
          break;

        case "familyHistoryOther":
          this.createFamilHistoryArray();
          if(this.familyHistory.none === true)
          {
              this.familyHistory.none = false;
              this.patientForm.patchValue({
                familyHistory_none: false,
             });
            }
          fbRef.update({
           familyHistory :JSON.stringify(this.familyHistoryArray),
          });
          break;

      

      case "otherMedicalHistory":
        this.createPastMedicalHistoryArray();
        if(this.pastMedicalHistory.none === true)
        {
            this.pastMedicalHistory.none = false;
            this.patientForm.patchValue({
             medical_none: false,
           });
          }
        fbRef.update({
          medicalHistory: JSON.stringify(this.pastMedicalHistoryArray),
        });
        break;


      case "bloodGlucose":
        if (!this.bloodGlucoseControl.invalid) {
          fbRef.update({
            bloodGlucose: this.patientSettings.bloodGlucose,
          });
        } else if (this.patientSettings.bloodGlucose === "") {
          fbRef.update({
            bloodGlucose: this.patientSettings.bloodGlucose,
          });
        }
        break;

      case "customMedications":
        this.createMedicationsArray();
        fbRef.update({
          currentMedications: JSON.stringify(this.medicationsArray),
        });
        break;

      case "customAllergies":
        this.createAllergiesArray();
        fbRef.update({
          allergies: JSON.stringify(this.allergiesArray),
        });
        break;

      case "customPastSurgicalHistory":
        this.createPastSurgicalHistoryArray();
        fbRef.update({
          surgicalHistory: JSON.stringify(this.pastSurgicalHistoryArray),
        });
        break;

      case "arrivalTOEDTime":
        if (!this.arrivalToEdTimeControl.invalid) {
          fbRef.update({
            arrivalToEdTime: this.patientSettings.arrivalToEdTime,
          });
        } else if (this.patientSettings.arrivalToEdTime === "") {
          fbRef.update({
            arrivalToEdTime: this.patientSettings.arrivalToEdTime,
          });
        }
        break;

      case "lastKnownNormalTime":
        if (!this.lastKnownNormalTimeControl.invalid) {
          fbRef.update({
            lastKnownNormalTime: this.patientSettings.lastKnownNormalTime,
          });
        } 
        else if (this.patientSettings.lastKnownNormalTime === "") {
          fbRef.update({
            lastKnownNormalTime: this.patientSettings.lastKnownNormalTime,
          });
        }
        if (this.patientSettings.lastKnownNormalDate === null) {
          var lastKnownNormalDateVar = new Date();
          this.date = lastKnownNormalDateVar.getDate();
          this.month = lastKnownNormalDateVar.getMonth() + 1;
          this.year = lastKnownNormalDateVar.getFullYear();
          console.log(String(this.date).length);
          if (String(this.date).length === 1) {
            this.date = "0" + this.date;
          }
          if (String(this.month).length === 1) {
            this.month = "0" + this.month;
          }
          console.log(this.date);
          var completeDateLastKnownNormalDate =
            this.month + "/" + this.date + "/" + this.year;
          if (!this.lastKnownNormalDateFormControl.invalid) {
            fbRef.update({
              lastKnownNormalDate: completeDateLastKnownNormalDate,
            });
          }
        } else {
          let da = new Date(this.patientSettings.lastKnownNormalDate);
          this.date = da.getDate();
          this.month = da.getMonth() + 1;
          this.year = da.getFullYear();
          console.log(String(this.date).length);
          if (String(this.date).length === 1) {
            this.date = "0" + this.date;
          }
          if (String(this.month).length === 1) {
            this.month = "0" + this.month;
          }
          console.log(this.date);
          var completeDateLastKnownNormalDate =
            this.month + "/" + this.date + "/" + this.year;
          if (!this.lastKnownNormalDateFormControl.invalid) {
            fbRef.update({
              lastKnownNormalDate: completeDateLastKnownNormalDate,
            });
          }
        }
    }
  }

   /*================== Means of Arrival Radio changed =================*/
   radioChangeMeansOfArrival(event){
     
    if(event.value == "Ambulance"){
      this.ambulance_option = true;
      var fbRef = firebase
      .database()
      .ref("intakeData/stroke/" + this.receivedCallIdFromQuery);
    fbRef.update({
      meansOfArrivalChecked: true,
    });
    }
    if(event.value == "Private Vehicle")
    {
      var fbRef = firebase
      .database()
      .ref("intakeData/stroke/" + this.receivedCallIdFromQuery);
    fbRef.update({
      meansOfArrivalChecked: true,
    });
      this.ambulance_option = false;
      this.patientSettings.meansOfArrivalCustom = "";
    }
    if(event.value == "In-house Attack")
    {
      var fbRef = firebase
      .database()
      .ref("intakeData/stroke/" + this.receivedCallIdFromQuery);
    fbRef.update({
      meansOfArrivalChecked: true,
    });
      this.ambulance_option = false;
      this.patientSettings.meansOfArrivalCustom = "";
    }
    
    this.createMeansOfArrival();
    var fbRef = firebase
      .database()
      .ref("intakeData/stroke/" + this.receivedCallIdFromQuery);
    fbRef.update({
      meansOfArrival: JSON.stringify(this.meansOfArrivalObj),
    });
  }



  /*================= Pre Arrival Radio changed ==================*/
  radioChangePreArrival(event){

    var fbRef = firebase.database().ref("intakeData/stroke/" + this.receivedCallIdFromQuery);
    fbRef.update({
      preArrival: this.patientSettings.preArrivalOption,
    });
  }



   /*================= Patient On Anti Platelet Therapy Radio changed ==================*/
   radioChangeForPatientOnAntiplateletTherapy(event){
     if(event.value!== 'undefined' && event.value!== "" && event.value!== null){
     if(event.value === "Yes"){
       this.antiPlateletTherapyMenu = true;
     }
     if(event.value === "No"){
       this.antiPlateletTherapyMenu = false;
     }
     
   this.patientForm.patchValue({
    AntiplateletTherapy_aspirin: false,
    AntiplateletTherapy_clopidogrel: false,
    AntiplateletTherapy_aspirinAndClopidogrel: false,
    AntiplateletTherapy_ticagrelor: false,
    AntiplateletTherapy_unableToDetermine: false,
    AntiplateletTherapy_none: false,
    AntiplateletTherapy_unknown: false,
        });
     
     }
     if(event.value === "Unknown"){
       this.antiPlateletTherapyMenu = false;
     
   this.patientForm.patchValue({
    AntiplateletTherapy_aspirin: false,
    AntiplateletTherapy_clopidogrel: false,
    AntiplateletTherapy_aspirinAndClopidogrel: false,
    AntiplateletTherapy_ticagrelor: false,
    AntiplateletTherapy_unableToDetermine: false,
    AntiplateletTherapy_none: false,
    AntiplateletTherapy_unknown: false,
        });
      
     }
  
    var fbRef = firebase.database().ref("intakeData/stroke/" + this.receivedCallIdFromQuery);
    fbRef.update({
      patientOnAntiplatelet: this.patientSettings.patientOnAntiplatelet,
      medicationsForAntiplateletTherapy: [],
    });
  }

   /*================= Patient On Alcohol Consumption Radio changed ==================*/
    radioChangeForAlcoholConsumption(event){
      var fbRef = firebase.database().ref("intakeData/stroke/" + this.receivedCallIdFromQuery);
      fbRef.update({
        alcoholConsumption: this.patientSettings.alcoholConsumption,
      });
    }
  
   /*================= Patient On Anti Coagulation Therapy Radio changed ==================*/
   radioChangeForPatientOnAnticoagulationTherapy(event){
    if(event.value === "Yes"){
      this.antiCoagulationTherapyMenu  = true;
    }
    if(event.value === "No"){
      this.antiCoagulationTherapyMenu  = false;
      this.patientForm.patchValue({
        AnticoagulationTherapy_warfarin: false,
        AnticoagulationTherapy_apixaban: false,
        AnticoagulationTherapy_dabigatran: false,
        AnticoagulationTherapy_rivaroxaban: false,
        AnticoagulationTherapy_heparin: false,
        AntiplateletTherapy_unknown: false,
            });
      
    }
    if(event.value === "Unknown"){
      this.antiCoagulationTherapyMenu  = false;
      this.patientForm.patchValue({
        AnticoagulationTherapy_warfarin: false,
        AnticoagulationTherapy_apixaban: false,
        AnticoagulationTherapy_dabigatran: false,
        AnticoagulationTherapy_rivaroxaban: false,
        AnticoagulationTherapy_heparin: false,
        AntiplateletTherapy_unknown: false,
            });
    }
  
    var fbRef = firebase.database().ref("intakeData/stroke/" + this.receivedCallIdFromQuery);
    fbRef.update({
      patientOnAnticoagulation: this.patientSettings.patientOnAnticoagulation,
      medicationsForAnticoagulationTherapy:""
    });
  }


  
   /*================= Patient On Anti Coagulation Therapy Radio changed ==================*/
   radioChangeForCigarettePerDayQuantity(event){
    var fbRef = firebase.database().ref("intakeData/stroke/" + this.receivedCallIdFromQuery);
    fbRef.update({
      cigarettesPerDayQuantity: this.patientSettings.cigarettesPerDayQuantity,
    });
  }



  /*=============== Change radio for weight unit =======================*/
  radioChangeWeightUnit(event) {
    var fbRef = firebase
      .database()
      .ref("intakeData/stroke/" + this.receivedCallIdFromQuery);
    var assignConvertedWeight = "";
    console.log("Weight", this.patientSettings.weight);
    if (this.patientSettings.weight !== "") {
      if (this.patientSettings.weightUnit === "kg") {
        assignConvertedWeight = (
          Number(this.patientSettings.weight) / 2.2046
        ).toFixed(2);
        this.patientSettings.weight = String(assignConvertedWeight);
      } else {
        assignConvertedWeight = (
          Number(this.patientSettings.weight) * 2.2046
        ).toFixed(2);
        this.patientSettings.weight = String(assignConvertedWeight);
      }
      // set updated data to firebase
      this.patientSettings.weight = String(assignConvertedWeight);
      console.log("Weight", this.patientSettings.weight);
      fbRef.update({
        weight: String(assignConvertedWeight),
        weightUnit: this.patientSettings.weightUnit,
      });
    } else {
      fbRef.update({
        weightUnit: this.patientSettings.weightUnit,
      });
    }
  }
  radioChangeTempUnit(event) {
    var fbRef = firebase
      .database()
      .ref("intakeData/stroke/" + this.receivedCallIdFromQuery);
    if (this.patientSettings.temp !== "") {
      if (this.patientSettings.tempUnit === "celsius") {
        var assignConvertedTemp = (
          (Number(this.patientSettings.temp) - 32) /
          1.8
        ).toFixed(2);
        this.patientSettings.temp = String(assignConvertedTemp);
      } else {
        var assignConvertedTemp = (
          Number(this.patientSettings.temp) * 1.8 +
          32
        ).toFixed(2);
        this.patientSettings.temp = String(assignConvertedTemp);
      }
      // set updated data to firebase
      fbRef.update({
        temp: this.patientSettings.temp,
        tempUnit: this.patientSettings.tempUnit,
      });
    } else {
      fbRef.update({
        tempUnit: this.patientSettings.tempUnit,
      });
    }
  }
  /*================== Collect Symptoms Data ================*/
  handleChangeForSymptoms(e) {
    
    this.createSymptomsArray();
    var fbRef = firebase
      .database()
      .ref("intakeData/stroke/" + this.receivedCallIdFromQuery);
    fbRef.update({
      symptoms: JSON.stringify(this.symptomsArray),
    });
  }

  /*================== Collect Past Medical History ================*/
  // handleChangeForPastMedicalHistory(e) {
  //   this.createPastMedicalHistoryArray();
  //   var fbRef = firebase
  //     .database()
  //     .ref("intakeData/stroke/" + this.receivedCallIdFromQuery);
  //   fbRef.update({
  //     medicalHistory: JSON.stringify(this.pastMedicalHistoryArray),
  //   });
  // }

 

  handleChangeForPastMedicalHistory(e, field) {
    // if (field === "none") {
    //   for (let k in this.pastMedicalHistory) {
    //     this.pastMedicalHistory[k] = false;
    //   }
    //   this.socialHistory.none = true;
    //   this.patientForm.patchValue({
    //     medical_unknown: false,
    //     medical_cerebralAneurysm: false,
    //     medical_seizure: false,
    //     medical_htn: false,
    //     medical_mi: false,
    //     medical_migraine: false,
    //     medical_cancer: false,
    //     medical_hld: false,
    //     medical_dm: false,
    //     medical_ckd: false,
    //     medical_dementia: false,
    //     medical_atrialFibrillation: false,
    //     medical_giBleed: false,
    //     medical_priorStroke: false,
    //     medical_none: true,
    //   });
    // } else {
    //   this.socialHistory.none = false;
    //   this.patientForm.patchValue({
    //     medical_none: false,
    //     ["medical_" + field]: true,
    //   });
    // }
    
    if (field === "none" && this.isMedicaNoneCheck===false) {
      this.pastMedicalHistory = {
        ckd: false,
        unknown: false,
        none: true,
        htn: false,
        hld: false,
        atrialFibrillation: false,
        giBleed: false,
        congestiveHeartFailure: false,
        cerebralAneurysm: false,
        mi: false,
        dm: false,
        priorStroke: false,
        dementia: false,
        brainTumor: false,
        seizure: false,
        migraine: false,
        cancer: false,
        other:"",
      };
        this.patientForm.patchValue({
        Medical_unknown: false,
        Medical_cancer: false,
        Medical_seizure: false,
        Medical_mi: false,
        Medical_htn: false,
        Medical_dm: false,
        medical_brainTumor: false,
        medical_priorStroke: false,
        medical_giBleed: false,
        medical_dementia: false,
        medical_congestiveHeartFailure: false,
        medical_cerebralAneurysm: false,
        medical_ckd: false,
        medical_migraine: false,
        medical_hld: false,
        medical_atrialFibrillation: false,
        Medical_none: true,
      });
        this.isMedicalUnknownCheck = false;
        this.isMedicalCancerCheck = false;
        this.isMedicalSeizureCheck = false;
        this.isMedicalMiCheck = false;
        this.isMedicalhtnCheck = false;
        this.isMedicalDmCheck = false;
        this.isMedicalCongestiveHeartFailureCheck = false;
        this.isMedicalCerebralAneurysmCheck = false;
        this.isMedicalCkdCheck = false;
        this.isMedicalPriorStrokeCheck = false;
        this.isMedicalgiBleedCheck = false;
        this.isMedicalDementiaCheck = false;
        this.isMedicalBrainTumorCheck = false;
        this.isMedicalMigraineCheck = false;
        this.isMedicalHldCheck = false;
        this.isMedicalAtrialFibrillationCheck = false;
        this.isMedicaNoneCheck = true;

    }
    else if(field === "none" && this.isMedicaNoneCheck===true){
      this.pastMedicalHistory = {
        ckd: false,
        unknown: false,
        none: false,
        htn: false,
        hld: false,
        atrialFibrillation: false,
        cerebralAneurysm: false,
        giBleed: false,
        brainTumor: false,
        mi: false,
        dm: false,
        priorStroke: false,
        dementia: false,
        congestiveHeartFailure: false,
        seizure: false,
        migraine: false,
        cancer: false,
        other:""
      };
     
   this.patientForm.patchValue({
    Medical_unknown: false,
    Medical_cancer: false,
    Medical_seizure: false,
    Medical_mi: false,
    Medical_htn: false,
    Medical_dm: false,
    medical_brainTumor: false,
    medical_priorStroke: false,
    medical_giBleed: false,
    medical_dementia: false,
    medical_congestiveHeartFailure: false,
    medical_cerebralAneurysm: false,
    medical_ckd: false,
    medical_migraine: false,
    medical_hld: false,
    medical_atrialFibrillation: false,
    Medical_none: false,
        });
    this.isMedicaNoneCheck= false;
    this.isMedicalUnknownCheck= true;
  }



  if (field === "unknown" && this.isMedicalUnknownCheck===false) {
    this.pastMedicalHistory.unknown =true;
    this.pastMedicalHistory.none=false;
    this.patientForm.patchValue({
      Medical_unknown: true,
      Medical_none: false,
    });
    this.isMedicalUnknownCheck= true;
    this.isMedicaNoneCheck= false;
  }

  else if(field === "unknown" && this.isMedicalUnknownCheck===true){
    this.pastMedicalHistory.unknown =false;
    this.pastMedicalHistory.none=false;
    this.patientForm.patchValue({
      Medical_unknown: false,
      Medical_none: false,
    });
  this.isMedicalUnknownCheck= false;
}

if (field === "cerebralAneurysm" && this.isMedicalCerebralAneurysmCheck===false) {
  this.pastMedicalHistory.cerebralAneurysm =true;
  this.pastMedicalHistory.none=false;
  this.patientForm.patchValue({
    Medical_cerebralAneurysm: true,
    Medical_none: false,
  });
  this.isMedicalCerebralAneurysmCheck= true;
  this.isMedicaNoneCheck= false;
}

else if(field === "cerebralAneurysm" && this.isMedicalCerebralAneurysmCheck===true){
  this.pastMedicalHistory.cerebralAneurysm =false;
  this.pastMedicalHistory.none=false;
  this.patientForm.patchValue({
    Medical_cerebralAneurysm: false,
    Medical_none: false,
  });
this.isMedicalCerebralAneurysmCheck= false;
}

if (field === "congestiveHeartFailure" && this.isMedicalCongestiveHeartFailureCheck===false) {
  this.pastMedicalHistory.congestiveHeartFailure =true;
  this.pastMedicalHistory.none=false;
  this.patientForm.patchValue({
    medical_congestiveHeartFailure: true,
    Medical_none: false,
  });
  this.isMedicalCongestiveHeartFailureCheck= true;
  this.isMedicaNoneCheck= false;
}

else if(field === "congestiveHeartFailure" && this.isMedicalCongestiveHeartFailureCheck===true){
  this.pastMedicalHistory.congestiveHeartFailure =false;
  this.pastMedicalHistory.none=false;
  this.patientForm.patchValue({
    medical_congestiveHeartFailure: false,
    Medical_none: false,
  });
this.isMedicalCongestiveHeartFailureCheck= false;
}
if (field === "seizure" && this.isMedicalSeizureCheck===false) {
  this.pastMedicalHistory.seizure =true;
  this.pastMedicalHistory.none=false;
  this.patientForm.patchValue({
    Medical_seizure: true,
    Medical_none: false,
  });
  this.isMedicalSeizureCheck= true;
  this.isMedicaNoneCheck= false;
}

else if(field === "seizure" && this.isMedicalSeizureCheck===true){
  this.pastMedicalHistory.seizure =false;
  this.pastMedicalHistory.none=false;
  this.patientForm.patchValue({
    Medical_seizure: false,
    Medical_none: false,
  });
this.isMedicalSeizureCheck= false;
}

if (field === "htn" && this.isMedicalhtnCheck===false) {
  this.pastMedicalHistory.htn =true;
  this.pastMedicalHistory.none=false;
  this.patientForm.patchValue({
    Medical_htn: true,
    Medical_none: false,
  });
  this.isMedicalhtnCheck= true;
  this.isMedicaNoneCheck= false;
}

else if(field === "htn" && this.isMedicalhtnCheck===true){
  this.pastMedicalHistory.htn =false;
  this.pastMedicalHistory.none=false;
  this.patientForm.patchValue({
    Medical_htn: false,
    Medical_none: false,
  });
this.isMedicalhtnCheck= false;
}

if (field === "mi" && this.isMedicalMiCheck===false) {
  this.pastMedicalHistory.mi =true;
  this.pastMedicalHistory.none=false;
  this.patientForm.patchValue({
    Medical_mi: true,
    Medical_none: false,
  });
  this.isMedicalMiCheck= true;
  this.isMedicaNoneCheck= false;
}

else if(field === "mi" && this.isMedicalMiCheck===true){
  this.pastMedicalHistory.mi =false;
  this.pastMedicalHistory.none=false;
  this.patientForm.patchValue({
    Medical_mi: false,
    Medical_none: false,
  });
this.isMedicalMiCheck= false;
}

if (field === "migraine" && this.isMedicalMigraineCheck===false) {
  this.pastMedicalHistory.migraine =true;
  this.pastMedicalHistory.none=false;
  this.patientForm.patchValue({
    Medical_migraine: true,
    Medical_none: false,
  });
  this.isMedicalMigraineCheck= true;
  this.isMedicaNoneCheck= false;
}

else if(field === "migraine" && this.isMedicalMigraineCheck===true){
  this.pastMedicalHistory.migraine =false;
  this.pastMedicalHistory.none=false;
  this.patientForm.patchValue({
    Medical_migraine: false,
    Medical_none: false,
  });
this.isMedicalMigraineCheck= false;
}

if (field === "cancer" && this.isMedicalCancerCheck===false) {
  this.pastMedicalHistory.cancer =true;
  this.pastMedicalHistory.none=false;
  this.patientForm.patchValue({
    Medical_cancer: true,
    Medical_none: false,
  });
  this.isMedicalCancerCheck= true;
  this.isMedicaNoneCheck= false;
}

else if(field === "cancer" && this.isMedicalCancerCheck===true){
  this.pastMedicalHistory.cancer =false;
  this.pastMedicalHistory.none=false;
  this.patientForm.patchValue({
    Medical_cancer: false,
    Medical_none: false,
  });
this.isMedicalCancerCheck= false;
}

if (field === "hld" && this.isMedicalHldCheck===false) {
  this.pastMedicalHistory.hld =true;
  this.pastMedicalHistory.none=false;
  this.patientForm.patchValue({
    Medical_hld: true,
    Medical_none: false,
  });
  this.isMedicalHldCheck= true;
  this.isMedicaNoneCheck= false;
}

else if(field === "hld" && this.isMedicalHldCheck===true){
  this.pastMedicalHistory.hld =false;
  this.pastMedicalHistory.none=false;
  this.patientForm.patchValue({
    Medical_hld: false,
    Medical_none: false,
  });
this.isMedicalHldCheck= false;
}

if (field === "dm" && this.isMedicalDmCheck===false) {
  this.pastMedicalHistory.dm =true;
  this.pastMedicalHistory.none=false;
  this.patientForm.patchValue({
    Medical_dm: true,
    Medical_none: false,
  });
  this.isMedicalDmCheck= true;
  this.isMedicaNoneCheck= false;
}

else if(field === "dm" && this.isMedicalDmCheck===true){
  this.pastMedicalHistory.dm =false;
  this.pastMedicalHistory.none=false;
  this.patientForm.patchValue({
    Medical_dm: false,
    Medical_none: false,
  });
this.isMedicalDmCheck= false;
}

if (field === "ckd" && this.isMedicalCkdCheck===false) {
  this.pastMedicalHistory.ckd =true;
  this.pastMedicalHistory.none=false;
  this.patientForm.patchValue({
    Medical_ckd: true,
    Medical_none: false,
  });
  this.isMedicalCkdCheck= true;
  this.isMedicaNoneCheck= false;
}

else if(field === "ckd" && this.isMedicalCkdCheck===true){
  this.pastMedicalHistory.ckd =false;
  this.pastMedicalHistory.none=false;
  this.patientForm.patchValue({
    Medical_ckd: false,
    Medical_none: false,
  });
this.isMedicalCkdCheck= false;
}


if (field === "brainTumor" && this.isMedicalBrainTumorCheck===false) {
  this.pastMedicalHistory.brainTumor =true;
  this.pastMedicalHistory.none=false;
  this.patientForm.patchValue({
    medical_brainTumor: true,
    Medical_none: false,
  });
  this.isMedicalBrainTumorCheck= true;
  this.isMedicaNoneCheck= false;
}

else if(field === "brainTumor" && this.isMedicalBrainTumorCheck===true){
  this.pastMedicalHistory.brainTumor =false;
  this.pastMedicalHistory.none=false;
  this.patientForm.patchValue({
    medical_brainTumor: false,
    Medical_none: false,
  });
this.isMedicalBrainTumorCheck= false;
}

if (field === "dementia" && this.isMedicalDementiaCheck===false) {
  this.pastMedicalHistory.dementia =true;
  this.pastMedicalHistory.none=false;
  this.patientForm.patchValue({
    Medical_dementia: true,
    Medical_none: false,
  });
  this.isMedicalDementiaCheck= true;
  this.isMedicaNoneCheck= false;
}

else if(field === "dementia" && this.isMedicalDementiaCheck===true){
  this.pastMedicalHistory.dementia =false;
  this.pastMedicalHistory.none=false;
  this.patientForm.patchValue({
    Medical_dementia: false,
    Medical_none: false,
  });
this.isMedicalDementiaCheck= false;
}

if (field === "atrialFibrillation" && this.isMedicalAtrialFibrillationCheck===false) {
  this.pastMedicalHistory.atrialFibrillation =true;
  this.pastMedicalHistory.none=false;
  this.patientForm.patchValue({
    Medical_atrialFibrillation: true,
    Medical_none: false,
  });
  this.isMedicalAtrialFibrillationCheck= true;
  this.isMedicaNoneCheck= false;
}

else if(field === "atrialFibrillation" && this.isMedicalAtrialFibrillationCheck===true){
  this.pastMedicalHistory.atrialFibrillation =false;
  this.pastMedicalHistory.none=false;
  this.patientForm.patchValue({
    Medical_atrialFibrillation: false,
    Medical_none: false,
  });
this.isMedicalAtrialFibrillationCheck= false;
}

if (field === "priorStroke" && this.isMedicalPriorStrokeCheck===false) {
  this.pastMedicalHistory.priorStroke =true;
  this.pastMedicalHistory.none=false;
  this.patientForm.patchValue({
    Medical_priorStroke: true,
    Medical_none: false,
  });
  this.isMedicalPriorStrokeCheck= true;
  this.isMedicaNoneCheck= false;
}

else if(field === "priorStroke" && this.isMedicalPriorStrokeCheck===true){
  this.pastMedicalHistory.priorStroke =false;
  this.pastMedicalHistory.none=false;
  this.patientForm.patchValue({
    Medical_priorStroke: false,
    Medical_none: false,
  });
this.isMedicalPriorStrokeCheck= false;
}

if (field === "giBleed" && this.isMedicalgiBleedCheck===false) {
  this.pastMedicalHistory.giBleed =true;
  this.pastMedicalHistory.none=false;
  this.patientForm.patchValue({
    Medical_giBleed: true,
    Medical_none: false,
  });
  this.isMedicalgiBleedCheck= true;
  this.isMedicaNoneCheck= false;
}

else if(field === "giBleed" && this.isMedicalgiBleedCheck===true){
  this.pastMedicalHistory.giBleed =false;
  this.pastMedicalHistory.none=false;
  this.patientForm.patchValue({
    Medical_giBleed: false,
    Medical_none: false,
  });
this.isMedicalgiBleedCheck= false;
}

    this.createPastMedicalHistoryArray();
    var fbRef = firebase
      .database()
      .ref("intakeData/stroke/" + this.receivedCallIdFromQuery);
    fbRef.update({
      medicalHistory: JSON.stringify(this.pastMedicalHistoryArray),
    });
  }


  handleChangeForMedicationsOfAntiPlateletTherapy(e, field) {

    if (field === "none" && this.isAntiPlateletTherapyNoneCheck===false) {
      this.antiPlateletTherapyMedications = {
        aspirin: false,
        clopidogrel: false,
        aspirinAndClopidogrel: false,
        ticagrelor: false,
        unableToDetermine: false,
        none: true,
        unknown: false,
        other: ""
      };
        this.patientForm.patchValue({
        
        AntiplateletTherapy_aspirin: false,
        AntiplateletTherapy_clopidogrel: false,
        AntiplateletTherapy_aspirinAndClopidogrel: false,
        AntiplateletTherapy_ticagrelor: false,
        AntiplateletTherapy_unableToDetermine: false,
        AntiplateletTherapy_none: true,
        AntiplateletTherapy_unknown: false,
      });
        this.isAspirinCheck = false,
        this.isClopidogrelCheck = false,
        this.isAspirinAndClopidogrelCheck = false,
        this.isTicagrelorCheck = false,
        this.isUnableToDetermineCheck = false,
        this.isAntiPlateletTherapyNoneCheck = true,
        this.isAntiPlateletTherapyUnknownCheck = false
    }
    else if(field === "none" && this.isAntiPlateletTherapyNoneCheck===true){
      this.antiPlateletTherapyMedications = {
        aspirin: false,
        clopidogrel: false,
        aspirinAndClopidogrel: false,
        ticagrelor: false,
        unableToDetermine: false,
        none: false,
        unknown: false,
        other: ""
      }
     
   this.patientForm.patchValue({
    AntiplateletTherapy_aspirin: false,
    AntiplateletTherapy_clopidogrel: false,
    AntiplateletTherapy_aspirinAndClopidogrel: false,
    AntiplateletTherapy_ticagrelor: false,
    AntiplateletTherapy_unableToDetermine: false,
    AntiplateletTherapy_none: false,
    AntiplateletTherapy_unknown: false,
        });
    this.isAntiPlateletTherapyNoneCheck= false;
    this.isAntiPlateletTherapyUnknownCheck= true;
  }

if (field === "aspirin" && this.isAspirinCheck===false) {
  this.antiPlateletTherapyMedications.aspirin =true;
  this.antiPlateletTherapyMedications.none=false;
  this.patientForm.patchValue({
    AntiplateletTherapy_aspirin: true,
    AntiplateletTherapy_none: false,
  });
  this.isAspirinCheck= true;
  this.isAntiPlateletTherapyNoneCheck= false;
}

else if(field === "aspirin" && this.isAspirinCheck===true){
  this.antiPlateletTherapyMedications.aspirin =false;
  this.antiPlateletTherapyMedications.none=false;
  this.patientForm.patchValue({
    AntiplateletTherapy_aspirin: false,
    AntiplateletTherapy_none: false,
  });
this.isAspirinCheck= false;
}

if (field === "clopidogrel" && this.isClopidogrelCheck===false) {
  this.antiPlateletTherapyMedications.clopidogrel =true;
  this.antiPlateletTherapyMedications.none=false;
  this.patientForm.patchValue({
    AntiplateletTherapy_clopidogrel: true,
    AntiplateletTherapy_none: false,
  });
  this.isClopidogrelCheck= true;
  this.isAntiPlateletTherapyNoneCheck= false;
}

else if(field === "clopidogrel" && this.isClopidogrelCheck===true){
  this.antiPlateletTherapyMedications.clopidogrel =false;
  this.antiPlateletTherapyMedications.none=false;
  this.patientForm.patchValue({
    AntiplateletTherapy_clopidogrel: false,
    AntiplateletTherapy_none: false,
  });
this.isClopidogrelCheck= false;
}
if (field === "aspirinAndClopidogrel" && this.isAspirinAndClopidogrelCheck===false) {
  this.antiPlateletTherapyMedications.aspirinAndClopidogrel =true;
  this.antiPlateletTherapyMedications.none=false;
  this.patientForm.patchValue({
    AntiplateletTherapy_aspirinAndClopidogrel: true,
    AntiplateletTherapy_none: false,
  });
  this.isAspirinAndClopidogrelCheck= true;
  this.isAntiPlateletTherapyNoneCheck= false;
}

else if(field === "aspirinAndClopidogrel" && this.isAspirinAndClopidogrelCheck===true){
  this.antiPlateletTherapyMedications.aspirinAndClopidogrel =false;
  this.antiPlateletTherapyMedications.none=false;
  this.patientForm.patchValue({
    AntiplateletTherapy_aspirinAndClopidogrel: false,
    AntiplateletTherapy_none: false,
  });
this.isAspirinAndClopidogrelCheck= false;
}

if (field === "ticagrelor" && this.isTicagrelorCheck===false) {
  this.antiPlateletTherapyMedications.ticagrelor =true;
  this.antiPlateletTherapyMedications.none=false;
  this.patientForm.patchValue({
    AntiplateletTherapy_ticagrelor: true,
    AntiplateletTherapy_none: false,
  });
  this.isTicagrelorCheck= true;
  this.isAntiPlateletTherapyNoneCheck= false;
}

else if(field === "ticagrelor" && this.isTicagrelorCheck===true){
  this.antiPlateletTherapyMedications.ticagrelor =false;
  this.antiPlateletTherapyMedications.none=false;
  this.patientForm.patchValue({
    AntiplateletTherapy_ticagrelor: false,
    AntiplateletTherapy_none: false,
  });
this.isTicagrelorCheck= false;
}

if (field === "unableToDetermine" && this.isUnableToDetermineCheck===false) {
  this.antiPlateletTherapyMedications.unableToDetermine =true;
  this.antiPlateletTherapyMedications.none=false;
  this.patientForm.patchValue({
    AntiplateletTherapy_unableToDetermine: true,
    AntiplateletTherapy_none: false,
  });
  this.isUnableToDetermineCheck= true;
  this.isAntiPlateletTherapyNoneCheck= false;
}

else if(field === "unableToDetermine" && this.isUnableToDetermineCheck===true){
  this.antiPlateletTherapyMedications.unableToDetermine =false;
  this.antiPlateletTherapyMedications.none=false;
  this.patientForm.patchValue({
    AntiplateletTherapy_unableToDetermine: false,
    AntiplateletTherapy_none: false,
  });
this.isUnableToDetermineCheck= false;
}

if (field === "unknown" && this.isAntiPlateletTherapyUnknownCheck===false) {
  this.antiPlateletTherapyMedications.unknown =true;
  this.antiPlateletTherapyMedications.none=false;
  this.patientForm.patchValue({
    AntiplateletTherapy_unknown: true,
    AntiplateletTherapy_none: false,
  });
  this.isAntiPlateletTherapyUnknownCheck= true;
  this.isAntiPlateletTherapyNoneCheck= false;
}

else if(field === "unknown" && this.isAntiPlateletTherapyUnknownCheck===true){
  this.antiPlateletTherapyMedications.unknown =false;
  this.antiPlateletTherapyMedications.none=false;
  this.patientForm.patchValue({
    AntiplateletTherapy_unknown: false,
    AntiplateletTherapy_none: false,
  });
this.isAntiPlateletTherapyUnknownCheck= false;
}



    this.createMedicationsForAntiplateletTherapyArray();
    var fbRef = firebase
      .database()
      .ref("intakeData/stroke/" + this.receivedCallIdFromQuery);
    fbRef.update({
      medicationsForAntiplateletTherapy: JSON.stringify(this.medicationsForAntiplateletTherapyArray),
    });
  }


  handleChangeForMedicationsOfAntiCoagulationTherapy(e, field) {

  //   if (field === "none" && this.isAntiCoagulationTherapyNoneCheck===false) {
  //     this.antiPlateletTherapyMedications = {
  //       aspirin: false,
  //       clopidogrel: false,
  //       aspirinAndClopidogrel: false,
  //       ticagrelor: false,
  //       unableToDetermine: false,
  //       none: true,
  //       unknown: false,
  //       other: ""
  //     };
  //       this.patientForm.patchValue({
        
  //       AntiplateletTherapy_aspirin: false,
  //       AntiplateletTherapy_clopidogrel: false,
  //       AntiplateletTherapy_aspirinAndClopidogrel: false,
  //       AntiplateletTherapy_ticagrelor: false,
  //       AntiplateletTherapy_unableToDetermine: false,
  //       AntiplateletTherapy_none: true,
  //       AntiplateletTherapy_unknown: false,
  //     });
  //       this.isAspirinCheck = false,
  //       this.isClopidogrelCheck = false,
  //       this.isAspirinAndClopidogrelCheck = false,
  //       this.isTicagrelorCheck = false,
  //       this.isUnableToDetermineCheck = false,
  //       this.isAntiPlateletTherapyNoneCheck = true,
  //       this.isAntiPlateletTherapyUnknownCheck = false
  //   }
  //   else if(field === "none" && this.isAntiPlateletTherapyNoneCheck===true){
  //     this.antiPlateletTherapyMedications = {
  //       aspirin: false,
  //       clopidogrel: false,
  //       aspirinAndClopidogrel: false,
  //       ticagrelor: false,
  //       unableToDetermine: false,
  //       none: false,
  //       unknown: false,
  //       other: ""
  //     }
     
  //  this.patientForm.patchValue({
  //   AntiplateletTherapy_aspirin: false,
  //   AntiplateletTherapy_clopidogrel: false,
  //   AntiplateletTherapy_aspirinAndClopidogrel: false,
  //   AntiplateletTherapy_ticagrelor: false,
  //   AntiplateletTherapy_unableToDetermine: false,
  //   AntiplateletTherapy_none: false,
  //   AntiplateletTherapy_unknown: false,
  //       });
  //   this.isAntiPlateletTherapyNoneCheck= false;
  //   this.isAntiPlateletTherapyUnknownCheck= true;
  // }

if (field === "warfarin" && this.isWarfarinCheck===false) {
  this.antiCoagulationTherapyMedications.warfarin =true;
  // this.antiCoagulationTherapyMedications.none=false;
  this.patientForm.patchValue({
    AnticoagulationTherapy_warfarin: true,
    // AntiplateletTherapy_none: false,
  });
  this.isWarfarinCheck= true;
  // this.isAntiPlateletTherapyNoneCheck= false;
}

else if(field === "warfarin" && this.isWarfarinCheck===true){
  this.antiCoagulationTherapyMedications.warfarin =false;
  // this.antiPlateletTherapyMedications.none=false;
  this.patientForm.patchValue({
    AnticoagulationTherapy_warfarin: false,
    // AntiplateletTherapy_none: false,
  });
this.isWarfarinCheck= false;
}

if (field === "apixaban" && this.isApixabanCheck===false) {
  this.antiCoagulationTherapyMedications.apixaban =true;
  // this.antiPlateletTherapyMedications.none=false;
  this.patientForm.patchValue({
    AnticoagulationTherapy_apixaban: true,
    // AntiplateletTherapy_none: false,
  });
  this.isApixabanCheck= true;
  // this.isAntiPlateletTherapyNoneCheck= false;
}

else if(field === "apixaban" && this.isApixabanCheck===true){
  this.antiCoagulationTherapyMedications.apixaban =false;
  // this.antiCoagulationTherapyMedications.none=false;
  this.patientForm.patchValue({
    AnticoagulationTherapy_apixaban: false,
    // AntiplateletTherapy_none: false,
  });
this.isApixabanCheck= false;
}
if (field === "dabigatran" && this.isDabigatranCheck===false) {
  this.antiCoagulationTherapyMedications.dabigatran =true;
  // this.antiPlateletTherapyMedications.none=false;
  this.patientForm.patchValue({
    AnticoagulationTherapy_dabigatran: true,
    // AntiplateletTherapy_none: false,
  });
  this.isDabigatranCheck= true;
  // this.isAntiPlateletTherapyNoneCheck= false;
}

else if(field === "dabigatran" && this.isDabigatranCheck===true){
  this.antiCoagulationTherapyMedications.dabigatran =false;
  // this.antiCoagulationTherapyMedications.none=false;
  this.patientForm.patchValue({
    AnticoagulationTherapy_dabigatran: false,
    // AntiplateletTherapy_none: false,
  });
this.isDabigatranCheck= false;
}

if (field === "rivaroxaban" && this.isRivaroxabanCheck===false) {
  this.antiCoagulationTherapyMedications.rivaroxaban =true;
  // this.antiPlateletTherapyMedications.none=false;
  this.patientForm.patchValue({
    AnticoagulationTherapy_rivaroxaban: true,
    // AntiplateletTherapy_none: false,
  });
  this.isRivaroxabanCheck= true;
  // this.isAntiPlateletTherapyNoneCheck= false;
}

else if(field === "rivaroxaban" && this.isRivaroxabanCheck===true){
  this.antiCoagulationTherapyMedications.rivaroxaban =false;
  // this.antiCoagulationTherapyMedications.none=false;
  this.patientForm.patchValue({
    AnticoagulationTherapy_rivaroxaban: false,
    // AntiplateletTherapy_none: false,
  });
this.isRivaroxabanCheck= false;
}

if (field === "enoxaparin" && this.isEnoxaparinCheck===false) {
  this.antiCoagulationTherapyMedications.enoxaparin =true;
  // this.antiCoagulationTherapyMedications.none=false;
  this.patientForm.patchValue({
    AnticoagulationTherapy_enoxaparin: true,
    // AntiplateletTherapy_none: false,
  });
  this.isEnoxaparinCheck= true;
  // this.isAntiPlateletTherapyNoneCheck= false;
}

else if(field === "enoxaparin" && this.isEnoxaparinCheck===true){
  this.antiCoagulationTherapyMedications.enoxaparin =false;
  // this.antiCoagulationTherapyMedications.none=false;
  this.patientForm.patchValue({
    AnticoagulationTherapy_enoxaparin: false,
    // AntiplateletTherapy_none: false,
  });
this.isEnoxaparinCheck= false;
}

if (field === "heparin" && this.isHeparinCheck===false) {
  this.antiCoagulationTherapyMedications.heparin =true;
  // this.antiCoagulationTherapyMedications.none=false;
  this.patientForm.patchValue({
    AnticoagulationTherapy_heparin: true,
    // AntiplateletTherapy_none: false,
  });
  this.isHeparinCheck= true;
  // this.isAntiPlateletTherapyNoneCheck= false;
}

else if(field === "heparin" && this.isHeparinCheck===true){
  this.antiCoagulationTherapyMedications.heparin =false;
  // this.antiCoagulationTherapyMedications.none=false;
  this.patientForm.patchValue({
    AnticoagulationTherapy_heparin: false,
    // AntiplateletTherapy_none: false,
  });
this.isAntiCoagulationTherapyUnknownCheck= false;
}



    this.createMedicationsForAnticoagulationTherapyArray();
    var fbRef = firebase
      .database()
      .ref("intakeData/stroke/" + this.receivedCallIdFromQuery);
    fbRef.update({
      medicationsForAnticoagulationTherapy: JSON.stringify(this.medicationsForAnticoagulationTherapyArray),
    });
  }


  handleChangeFortobacco(e, field) {

    if (field === "none" && this.isTobaccoNoneCheck===false) {
      this.cigaretteSection = false;
      this.patientSettings.cigarettesPerDay = "";
      this.patientSettings.cigarettesPerDayQuantity = "";
      localStorage.setItem("patientSetting_cigarettesPerDay", "");
      localStorage.setItem("patientSetting_cigarettesPerDayQuantity","");
      var fbRef = firebase
      .database()
      .ref("intakeData/stroke/" + this.receivedCallIdFromQuery);
    fbRef.update({
      cigarettesPerDay: "",
      cigarettesPerDayQuantity: "",
    });
      this.tobacco = {
        none: true,
        chewing: false,
        vapepen: false,
        unknown: false,
        cigarettes: false,
        other: ""
      };
        this.patientForm.patchValue({
        
        tobacco_none: true,
        tobacco_chewing: false,
        tobacco_vapepen: false,
        tobacco_unknown: false,
        tobacco_cigarettes: false,
      });
        this.isTobaccoNoneCheck = true,
        this.isTobaccoChewingCheck = false,
        this.isTobaccoVapepenCheck = false,
        this.isTobaccoUnknownCheck = false,
        this.isTobaccoCigarettesCheck = false
    }
    else if(field === "none" && this.isTobaccoNoneCheck===true){
     
      this.tobacco = {
        none: false,
        chewing: false,
        vapepen: false,
        unknown: false,
        cigarettes: false,
        other: ""
      };
      
     
   this.patientForm.patchValue({
    tobacco_none: false,
    tobacco_chewing: false,
    tobacco_vapepen: false,
    tobacco_unknown: false,
    tobacco_cigarettes: false,
        });
    this.isTobaccoNoneCheck= false;
    this.isTobaccoUnknownCheck= true;
      }

if (field === "chewing" && this.isTobaccoChewingCheck===false) {
  this.tobacco.chewing =true;
  this.tobacco.none=false;
  this.patientForm.patchValue({
    tobacco_chewing: true,
    tobacco_none: false,
  });
  this.isTobaccoChewingCheck= true;
  this.isTobaccoNoneCheck= false;
}
else if(field === "chewing" && this.isTobaccoChewingCheck===true){
  this.tobacco.chewing =false;
  this.tobacco.none=false;
  this.patientForm.patchValue({
    tobacco_chewing: false,
    tobacco_none: false,
  });
this.isTobaccoChewingCheck= false;
}
if (field === "vapepen" && this.isTobaccoVapepenCheck===false) {
  this.tobacco.vapepen =true;
  this.tobacco.none=false;
  this.patientForm.patchValue({
    tobacco_vapepen: true,
    tobacco_none: false,
  });
  this.isTobaccoVapepenCheck= true;
  this.isTobaccoNoneCheck= false;
}
else if(field === "vapepen" && this.isTobaccoVapepenCheck===true){
  this.tobacco.vapepen =false;
  this.tobacco.none=false;
  this.patientForm.patchValue({
    tobacco_vapepen: false,
    tobacco_none: false,
  });
this.isTobaccoVapepenCheck= false;
}

if (field === "unknown" && this.isTobaccoUnknownCheck===false) {
  this.tobacco.unknown =true;
  this.tobacco.none=false;
  this.patientForm.patchValue({
    tobacco_unknown: true,
    tobacco_none: false,
  });
  this.isTobaccoUnknownCheck= true;
  this.isTobaccoNoneCheck= false;
}

else if(field === "unknown" && this.isTobaccoUnknownCheck===true){
  this.tobacco.unknown =false;
  this.tobacco.none=false;
  this.patientForm.patchValue({
    tobacco_unknown: false,
    tobacco_none: false,
  });
this.isTobaccoUnknownCheck= false;
}
if (field === "cigarettes" && this.isTobaccoCigarettesCheck===false) {
  var fbRef = firebase
  .database()
  .ref("intakeData/stroke/" + this.receivedCallIdFromQuery);
fbRef.update({
  cigaretteCheck: true,
});
  this.cigaretteSection = true;
  this.patientSettings.cigarettesPerDayQuantity="Individual Cigarette";
  this.tobacco.cigarettes =true;
  this.tobacco.none=false;
  this.patientForm.patchValue({
    tobacco_cigarettes: true,
    tobacco_none: false,
  });
  this.isTobaccoCigarettesCheck= true;
  this.isTobaccoNoneCheck= false;
}

else if(field === "cigarettes" && this.isTobaccoCigarettesCheck===true){
  var fbRef = firebase
  .database()
  .ref("intakeData/stroke/" + this.receivedCallIdFromQuery);
fbRef.update({
  cigaretteCheck: false,
});
  this.cigaretteSection = false;
  this.patientSettings.cigarettesPerDay = "";
  this.patientSettings.cigarettesPerDayQuantity = "";
  localStorage.setItem("patientSetting_cigarettesPerDay", "");
  localStorage.setItem("patientSetting_cigarettesPerDayQuantity","");
  var fbRef = firebase
  .database()
  .ref("intakeData/stroke/" + this.receivedCallIdFromQuery);
fbRef.update({
  cigarettesPerDay: "",
  cigarettesPerDayQuantity: "",
});
  this.tobacco.cigarettes =false;
  this.tobacco.none=false;
  this.patientForm.patchValue({
    tobacco_cigarettes: false,
    tobacco_none: false,
  });
this.isTobaccoCigarettesCheck= false;
}



    this.createTobaccoArray();
    var fbRef = firebase
      .database()
      .ref("intakeData/stroke/" + this.receivedCallIdFromQuery);
    fbRef.update({
      tobacco: JSON.stringify(this.tobaccoArray),
    });
  }


  handleChangeFordrugs(e, field) {

    if (field === "none" && this.isDrugsNoneCheck===false) {
      this.drugs = {
        none: true,
        cocaine: false,
        lsd: false,
        marijuana:false,
        heroine: false,
        methamphetamine: false,
        unknown: false,
        other: ""
      };
        this.patientForm.patchValue({
        
        drugs_none: true,
        drugs_cocaine: false,
        drugs_lsd: false,
        drugs_marijuana: false,
        drugs_heroine: false,
        drugs_methamphetamine: false,
        drugs_unknown: false
      });
        this.isDrugsNoneCheck = true,
        this.isDrugsCocaineCheck = false,
        this.isDrugsLsdCheck = false,
        this.isDrugsMarijuanaCheck = false,
        this.isDrugsHeroineCheck = false,
        this.isDrugsMethamphetamineCheck = false,
        this.isDrugsUnknownCheck = false
    }
    else if(field === "none" && this.isDrugsNoneCheck===true){
      this.drugs = {
        none: false,
        cocaine: false,
        lsd: false,
        marijuana:false,
        heroine: false,
        methamphetamine: false,
        unknown: false,
        other: ""
      };
      
     
   this.patientForm.patchValue({
    drugs_none: false,
    drugs_cocaine: false,
    drugs_lsd: false,
    drugs_marijuana: false,
    drugs_heroine: false,
    drugs_methamphetamine: false,
    drugs_unknown: false
        });
    this.isDrugsNoneCheck= false;
    this.isDrugsUnknownCheck= true;
      }

if (field === "cocaine" && this.isDrugsCocaineCheck===false) {
  this.drugs.cocaine =true;
  this.drugs.none=false;
  this.patientForm.patchValue({
    drugs_cocaine: true,
    drugs_none: false,
  });
  this.isDrugsCocaineCheck= true;
  this.isDrugsNoneCheck= false;

    }
else if(field === "cocaine" && this.isDrugsCocaineCheck===true){
  this.drugs.cocaine =false;
  this.drugs.none=false;
  this.patientForm.patchValue({
    drugs_cocaine: false,
    tobacco_none: false,
  });
this.isDrugsCocaineCheck= false;
}

if (field === "lsd" && this.isDrugsLsdCheck===false) {
  this.drugs.lsd =true;
  this.drugs.none=false;
  this.patientForm.patchValue({
    drugs_lsd: true,
    drugs_none: false,
  });
  this.isDrugsUnknownCheck= true;
  this.isDrugsNoneCheck= false;
}

else if(field === "lsd" && this.isDrugsLsdCheck===true){
  this.drugs.lsd =false;
  this.drugs.none=false;
  this.patientForm.patchValue({
    drugs_lsd: false,
    drugs_none: false,
  });
this.isDrugsUnknownCheck= false;
}
if (field === "marijuana" && this.isDrugsMarijuanaCheck===false) {
  this.drugs.marijuana =true;
  this.drugs.none=false;
  this.patientForm.patchValue({
    drugs_marijuana: true,
    drugs_none: false,
  });
  this.isDrugsMarijuanaCheck= true;
  this.isDrugsNoneCheck= false;
}

else if(field === "marijuana" && this.isDrugsMarijuanaCheck===true){
  this.drugs.marijuana =false;
  this.drugs.none=false;
  this.patientForm.patchValue({
    drugs_marijuana: false,
    drugs_none: false,
  });
this.isDrugsMarijuanaCheck= false;
}
if (field === "heroine" && this.isDrugsHeroineCheck===false) {
  this.drugs.heroine =true;
  this.drugs.none=false;
  this.patientForm.patchValue({
    drugs_heroine: true,
    drugs_none: false,
  });
  this.isDrugsHeroineCheck= true;
  this.isDrugsNoneCheck= false;
}

else if(field === "heroine" && this.isDrugsHeroineCheck===true){
  this.drugs.heroine =false;
  this.drugs.none=false;
  this.patientForm.patchValue({
    drugs_heroine: false,
    drugs_none: false,
  });
this.isDrugsHeroineCheck= false;
}
if (field === "methamphetamine" && this.isDrugsMethamphetamineCheck===false) {
  this.drugs.methamphetamine =true;
  this.drugs.none=false;
  this.patientForm.patchValue({
    drugs_methamphetamine: true,
    drugs_none: false,
  });
  this.isDrugsMethamphetamineCheck= true;
  this.isDrugsNoneCheck= false;
}

else if(field === "methamphetamine" && this.isDrugsMethamphetamineCheck===true){
  this.drugs.methamphetamine =false;
  this.drugs.none=false;
  this.patientForm.patchValue({
    drugs_methamphetamine: false,
    drugs_none: false,
  });
this.isDrugsMethamphetamineCheck= false;
}
if (field === "unknown" && this.isDrugsUnknownCheck===false) {
  this.drugs.unknown =true;
  this.drugs.none=false;
  this.patientForm.patchValue({
    drugs_unknown: true,
    drugs_none: false,
  });
  this.isDrugsUnknownCheck= true;
  this.isDrugsNoneCheck= false;
}

else if(field === "unknown" && this.isDrugsUnknownCheck===true){
  this.drugs.unknown =false;
  this.drugs.none=false;
  this.patientForm.patchValue({
    drugs_unknown: false,
    drugs_none: false,
  });
this.isDrugsUnknownCheck= false;
}



    this.createDrugsArray();
    var fbRef = firebase
      .database()
      .ref("intakeData/stroke/" + this.receivedCallIdFromQuery);
    fbRef.update({
      drugs: JSON.stringify(this.drugsArray),
    });
  }



  /*================== Collect Past Social History ================*/
  // handleChangeForSocialHistory(e) {
  //   this.createSocialHistoryArray();
  //   var fbRef = firebase
  //     .database()
  //     .ref("intakeData/stroke/" + this.receivedCallIdFromQuery);
  //   fbRef.update({
  //     socialHistory: JSON.stringify(this.socialHistoryArray),
  //   });
  // }


  handleChangeForSocialHistory(e, field) {
    // if (field === "none") {
    //   this.socialHistory = {
    //     alcohol: false,
    //     drugs: false,
    //     none: true,
    //     tobacco: false,
    //     unknown: true,
    //   };
    //   this.patientForm.patchValue({
    //     socialHistory_drugs: false,
    //     socialHistory_tobacco: false,
    //     socialHistory_alcohol: false,
    //     socialHistory_none: true,
    //   });
    // } else {
    //   this.socialHistory.none = false;
    //   this.patientForm.patchValue({
    //     socialHistory_none: false,
    //     ["socialHistory_" + field]: true,
    //   });
    // }

    if (field === "none" && this.isNoneCheck===false) {
      this.socialHistory = {
        alcohol: false,
        drugs: false,
        none: true,
        tobacco: false,
      };
        this.patientForm.patchValue({
        socialHistory_drugs: false,
        socialHistory_tobacco: false,
        socialHistory_alcohol: false,
        socialHistory_none: true,
      });
      this.isNoneCheck= true;
      this.isAlcoholCheck= false;
      this.isTobaccoCheck= false;
      this.isDrugCheck= false;
    }
    else if(field === "none" && this.isNoneCheck===true){
       this.socialHistory = {
      alcohol: false,
      drugs: false,
      none: false,
      tobacco: false,
    };
      this.patientForm.patchValue({
      socialHistory_drugs: false,
      socialHistory_tobacco: false,
      socialHistory_alcohol: false,
      socialHistory_none: false,
    });
    this.isNoneCheck= false;
    this.isAlcoholCheck= true;
  }
  if (field === "alcohol" && this.isAlcoholCheck===false) {
    this.socialHistory.alcohol =true;
    this.socialHistory.none=false;
    this.patientForm.patchValue({
      socialHistory_alcohol: true,
      socialHistory_none: false,
    });
    this.isAlcoholCheck= true;
    this.isNoneCheck= false;
  }

  else if(field === "alcohol" && this.isAlcoholCheck===true){
    this.socialHistory.alcohol =false;
    this.socialHistory.none=false;
    this.patientForm.patchValue({
      socialHistory_alcohol: false,
      socialHistory_none: false,
    });
  this.isAlcoholCheck= false;

}

if (field === "tobacco" && this.isTobaccoCheck===false) {
  this.socialHistory.tobacco =true;
  this.socialHistory.none=false;
  this.patientForm.patchValue({
    socialHistory_tobacco: true,
    socialHistory_none: false,
  });
  this.isTobaccoCheck= true;
  this.isNoneCheck= false;
}

else if(field === "tobacco" && this.isTobaccoCheck===true){
  this.socialHistory.tobacco =false;
  this.socialHistory.none=false;
  this.patientForm.patchValue({
    socialHistory_tobacco: false,
    socialHistory_none: false,
  });
this.isTobaccoCheck= false;
}

if (field === "drugs" && this.isDrugCheck===false) {
  this.socialHistory.drugs =true;
  this.socialHistory.none=false;
  this.patientForm.patchValue({
    socialHistory_drugs: true,
    socialHistory_none: false,
  });
  this.isDrugCheck= true;
  this.isNoneCheck= false;
}

else if(field === "drugs" && this.isDrugCheck===true){
  this.socialHistory.drugs =false;
  this.socialHistory.none=false;
  this.patientForm.patchValue({
    socialHistory_drugs: false,
    socialHistory_none: false,
  });
this.isDrugCheck= false;
}
    this.createSocialHistoryArray();
    console.log("ARRAY", this.socialHistoryArray);
    var fbRef = firebase
      .database()
      .ref("intakeData/stroke/" + this.receivedCallIdFromQuery);
    fbRef.update({
      socialHistory: JSON.stringify(this.socialHistoryArray),
    });
  }
  /*================== Collect Past Medical History ================*/
  // handleChangeForFamilyHistory(e) {
  //   this.createFamilHistoryArray();
  //   var fbRef = firebase
  //     .database()
  //     .ref("intakeData/stroke/" + this.receivedCallIdFromQuery);
  //   fbRef.update({
  //     familyHistory: JSON.stringify(this.familyHistoryArray),
  //   });
  // }

  handleChangeForFamilyHistory(e, field) {
    // if (field === "none") {
    //   for (let k in this.familyHistory) {
    //     this.familyHistory[k] = false;
    //   }
    //   this.familyHistory.none = true;
    //   this.patientForm.patchValue({
    //     familyHistory_unknown: false,
    //     familyHistory_mi: false,
    //     familyHistory_seizure: false,
    //     familyHistory_cancer: false,
    //     familyHistory_stroke: false,
    //     familyHistory_none: true,
    //   });
    // } else {
    //   this.familyHistory.none = false;
    //   this.patientForm.patchValue({
    //     familyHistory_none: false,
    //     ["familyHistory_" + field]: true,
    //   });
    // }

    if (field === "none" && this.isFamilyNoneCheck===false) {
      this.familyHistory = {
        stroke: false,
        cancer: false,
        none: true,
        seizure: false,
        mi:false,
        unknown: false,
        other:""
      };
        this.patientForm.patchValue({
        familyHistory_stroke: false,
        familyHistory_cancer: false,
        familyHistory_seizure: false,
        familyHistory_mi: false,
        familyHistory_unknown: false,
        familyHistory_none: true,
      });
      this.isFamilyNoneCheck= true;
      this.isStrokeCheck= false;
      this.isCancerCheck= false;
      this.isSeizureCheck= false;
      this.isMiCheck= false;
      this.isUnknownCheck= false;

    }
    else if(field === "none" && this.isFamilyNoneCheck===true){
      this.familyHistory = {
        stroke: false,
        cancer: false,
        none: false,
        seizure: false,
        mi:false,
        unknown: false,
        other: ""
      };
     
   this.patientForm.patchValue({
          familyHistory_stroke: false,
          familyHistory_cancer: false,
          familyHistory_seizure: false,
          familyHistory_mi: false,
          familyHistory_unknown: false,
          familyHistory_none: false,
        });
    this.isFamilyNoneCheck= false;
    this.isStrokeCheck= true;
  }



  if (field === "stroke" && this.isStrokeCheck===false) {
    this.familyHistory.stroke =true;
    this.familyHistory.none=false;
    this.patientForm.patchValue({
      familyHistory_stroke: true,
      familyHistory_none: false,
    });
    this.isStrokeCheck= true;
    this.isFamilyNoneCheck= false;
  }

  else if(field === "stroke" && this.isStrokeCheck===true){
    this.familyHistory.stroke =false;
    this.familyHistory.none=false;
    this.patientForm.patchValue({
      familyHistory_alcohol: false,
      familyHistory_none: false,
    });
  this.isStrokeCheck= false;
}

if (field === "cancer" && this.isCancerCheck===false) {
  this.familyHistory.cancer =true;
  this.familyHistory.none=false;
  this.patientForm.patchValue({
    familyHistory_cancer: true,
    familyHistory_none: false,
  });
  this.isCancerCheck= true;
  this.isFamilyNoneCheck= false;
}
else if(field === "cancer" && this.isCancerCheck===true){
  this.familyHistory.cancer =false;
  this.familyHistory.none=false;
  this.patientForm.patchValue({
    familyHistory_cancer: false,
    familyHistory_none: false,
  });
this.isCancerCheck= false;
}

if (field === "seizure" && this.isSeizureCheck===false) {
  this.familyHistory.seizure =true;
  this.familyHistory.none=false;
  this.patientForm.patchValue({
    familyHistory_seizure: true,
    familyHistory_none: false,
  });
  this.isSeizureCheck= true;
  this.isFamilyNoneCheck= false;
}

else if(field === "seizure" && this.isSeizureCheck===true){
  this.familyHistory.seizure =false;
  this.familyHistory.none=false;
  this.patientForm.patchValue({
    familyHistory_seizure: false,
    familyHistory_none: false,
  });
this.isSeizureCheck= false;
}

if (field === "mi" && this.isMiCheck===false) {
  this.familyHistory.mi =true;
  this.familyHistory.none=false;
  this.patientForm.patchValue({
    familyHistory_mi: true,
    familyHistory_none: false,
  });
  this.isMiCheck= true;
  this.isFamilyNoneCheck= false;
}

else if(field === "mi" && this.isMiCheck===true){
  this.familyHistory.mi =false;
  this.familyHistory.none=false;
  this.patientForm.patchValue({
    familyHistory_mi: false,
    familyHistory_none: false,
  });
this.isMiCheck= false;
}
 
if (field === "unknown" && this.isUnknownCheck===false) {
  this.familyHistory.unknown =true;
  this.familyHistory.none=false;
  this.patientForm.patchValue({
    familyHistory_unknown: true,
    familyHistory_none: false,
  });
  this.isUnknownCheck= true;
  this.isFamilyNoneCheck= false;
}

else if(field === "unknown" && this.isUnknownCheck===true){
  this.familyHistory.unknown =false;
  this.familyHistory.none=false;
  this.patientForm.patchValue({
    familyHistory_unknown: false,
    familyHistory_none: false,
    });
     this.isUnknownCheck= false;
     }
    this.createFamilHistoryArray();
    var fbRef = firebase
      .database()
      .ref("intakeData/stroke/" + this.receivedCallIdFromQuery);
    fbRef.update({
      familyHistory: JSON.stringify(this.familyHistoryArray),
    });
  }

  

  /*================== TPA Eligibility Block Radio change ================*/
  handleChangeForMI(e) {
    var fbRef = firebase
      .database()
      .ref("intakeData/stroke/" + this.receivedCallIdFromQuery);
    fbRef.update({
      miInPrevious3Weeks: this.patientSettings.miInPrevious3Weeks,
    });
  }

  handleChangeForStroke(e) {
    var fbRef = firebase
      .database()
      .ref("intakeData/stroke/" + this.receivedCallIdFromQuery);
    fbRef.update({
      strokeInPrevious3Months: this.patientSettings.strokeInPrevious3Months,
    });
  }

  handleChangeForHistoryOfICH(e) {
    var fbRef = firebase
      .database()
      .ref("intakeData/stroke/" + this.receivedCallIdFromQuery);
    fbRef.update({
      isICHHistory: this.patientSettings.historyOfIch,
    });
  }
  handleChangeForTookCEPX(e) {
    var fbRef = firebase
      .database()
      .ref("intakeData/stroke/" + this.receivedCallIdFromQuery);
    fbRef.update({
      tookCEPX: this.patientSettings.tookCEPX,
    });
  }

  handleChangeForHistoryOfNeoplasm(e) {
    var fbRef = firebase
      .database()
      .ref("intakeData/stroke/" + this.receivedCallIdFromQuery);
    fbRef.update({
      isNeoplasm: this.patientSettings.historyOfNeoplasm,
    });
  }

  /*==================  Current Home Medications Radio changed ==================*/
  radioChangeMedications(event) {
    // alert(event);
    this.medications.medicationsCustom = "";
    this.createMedicationsArray();
    var fbRef = firebase
      .database()
      .ref("intakeData/stroke/" + this.receivedCallIdFromQuery);
    fbRef.update({
      currentMedications: JSON.stringify(this.medicationsArray),
    });
  }

  /*================== Allergies Radio changed ==================*/
  radioChangeAllergies(event) {
    // alert(event);
    this.allergies.allergiesCustom = "";
    this.createAllergiesArray();
    var fbRef = firebase
      .database()
      .ref("intakeData/stroke/" + this.receivedCallIdFromQuery);
    fbRef.update({
      allergies: JSON.stringify(this.allergiesArray),
    });
  }

  /*================== Past Surggical Radio changed ================*/
  radioChangeSurgicalHistory(event) {
    // alert(event);
    this.pastSurgicalHistory.other = "";
    this.createPastSurgicalHistoryArray();
    var fbRef = firebase
      .database()
      .ref("intakeData/stroke/" + this.receivedCallIdFromQuery);
    fbRef.update({
      surgicalHistory: JSON.stringify(this.pastSurgicalHistoryArray),
    });
  }

  /*================== On Change of Arrival to ED ===================*/
  addEvent(event: MatDatepickerInputEvent<Date>) {
    this.focusOnLastKnownNormal();
    var fbRef = firebase
      .database()
      .ref("intakeData/stroke/" + this.receivedCallIdFromQuery);
    if (this.patientSettings.arrivalToEdDate === null) {
      var arrivalDate = new Date();
      this.date = arrivalDate.getDate();
      this.month = arrivalDate.getMonth() + 1;
      this.year = arrivalDate.getFullYear();
      console.log(String(this.date).length);
      if (String(this.date).length === 1) {
        this.date = "0" + this.date;
      }
      if (String(this.month).length === 1) {
        this.month = "0" + this.month;
      }
      console.log(this.date);
      var completeDateArrivalDate =
        this.month + "/" + this.date + "/" + this.year;
      fbRef.update({
        arrivalToEdDate: completeDateArrivalDate,
      });
    } else {
      let d = new Date(this.patientSettings.arrivalToEdDate);
      this.date = d.getDate();
      this.month = d.getMonth() + 1;
      this.year = d.getFullYear();
      console.log(String(this.date).length);
      if (String(this.date).length === 1) {
        this.date = "0" + this.date;
      }
      if (String(this.month).length === 1) {
        this.month = "0" + this.month;
      }
      console.log(this.date);
      var completeDateArrivalDate =
        this.month + "/" + this.date + "/" + this.year;
      if (!this.arrivalToEdDateFormControl.invalid) {
        fbRef.update({
          arrivalToEdDate: completeDateArrivalDate,
        });
      }
    }
  }

  /*=========== on change of Last Known normal Checkbox=======*/
  changeStatus(event) {
    let status = event.checked;
    var fbRef = firebase
      .database()
      .ref("intakeData/stroke/" + this.receivedCallIdFromQuery);
    if (status) {
      this.statusLastKnownNormal = true;
      fbRef.update({
        lastKnownNormalDate: "",
        lastKnownNormalTime: "",
        tpaEligibilityUnknown: true,
      });
    } else {
      this.statusLastKnownNormal = false;
      fbRef.update({
        tpaEligibilityUnknown: false,
        lastKnownNormalDate: "",
        lastKnownNormalTime: "",
      });
      this.patientSettings.lastKnownNormalDate = null;
    }
  }

  /*================== On Change of Last Known Normal ===================*/
  addEventlastKnownNormal(event: MatDatepickerInputEvent<Date>) {
    this.focusOnLastKnownNormal();
    var fbRef = firebase
      .database()
      .ref("intakeData/stroke/" + this.receivedCallIdFromQuery);
    if (this.patientSettings.lastKnownNormalDate === null) {
      var lastKnownNormalDateVar = new Date();
      this.date = lastKnownNormalDateVar.getDate();
      this.month = lastKnownNormalDateVar.getMonth() + 1;
      this.year = lastKnownNormalDateVar.getFullYear();
      console.log(String(this.date).length);
      if (String(this.date).length === 1) {
        this.date = "0" + this.date;
      }
      if (String(this.month).length === 1) {
        this.month = "0" + this.month;
      }
      console.log(this.date);
      var completeDateLastKnownNormalDate =
        this.month + "/" + this.date + "/" + this.year;
      fbRef.update({
        lastKnownNormalDate: completeDateLastKnownNormalDate,
      });
    } else {
      let da = new Date(this.patientSettings.lastKnownNormalDate);
      this.date = da.getDate();
      this.month = da.getMonth() + 1;
      this.year = da.getFullYear();
      console.log(String(this.date).length);
      if (String(this.date).length === 1) {
        this.date = "0" + this.date;
      }
      if (String(this.month).length === 1) {
        this.month = "0" + this.month;
      }
      console.log(this.date);
      var completeDateLastKnownNormalDate =
        this.month + "/" + this.date + "/" + this.year;
      if (!this.lastKnownNormalDateFormControl.invalid) {
        fbRef.update({
          lastKnownNormalDate: completeDateLastKnownNormalDate,
        });
      }
    }
  }
}

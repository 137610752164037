import { Component, OnInit } from '@angular/core';
import {FormControl, FormGroupDirective, NgForm, Validators} from '@angular/forms';
import {ErrorStateMatcher} from '@angular/material/core';
import { SuccessDialogComponent } from '../success-dialog/success-dialog.component';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { ErrorDialogComponent } from '../error-dialog/error-dialog.component';
import { HttpParams, HttpClient, HttpHeaders, HttpErrorResponse} from '@angular/common/http';
import { AuthService } from '../services/auth.service';
import { DialogForProfileComponent } from '../dialog-for-profile/dialog-for-profile.component';
import {MatDialog} from '@angular/material';
import { Content } from '@angular/compiler/src/render3/r3_ast';
import { ContentType } from '@angular/http/src/enums';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit {
  passwordDetails = {newPassword: '', confirmPassword: '', userName: ''};
  isValidLink = false;
  newPasswordFormControl = new FormControl('', [
    Validators.required,
    Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&#])[A-Za-z\d$@$!%*?&#].{7,}')
    // Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])[A-Za-z\d].{8,}')
  ]);

  confirmPasswordFormControl = new FormControl('', [
    Validators.required,
    Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&#])[A-Za-z\d$@$!%*?&#].{7,}')
    // Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])[A-Za-z\d].{8,}')
  ]);

  userNameFormControl = new FormControl('', [
    Validators.required
  ]);

  constructor(public loader: LoadingBarService , public http: HttpClient ,
    public authService: AuthService, public errorDialog: MatDialog , public successDialog: MatDialog,
    public dialogForProfile: MatDialog) {
     }

  ngOnInit() {
    this.checkIsValidLink();
  }
  checkValidUserName() {
    if (this.passwordDetails.userName) {
    const url = window.location.search;
    const urlparam = url.split('=');
    const uniqueId = urlparam[1];
    const headers = new HttpHeaders().set('Content-Type' , 'application/x-www-form-urlencoded');
    const param = new HttpParams().set('uniqueId', uniqueId).set('password', this.passwordDetails.newPassword)
    .set('userName', this.passwordDetails.userName)
    .set('uniqueId', uniqueId);
    this.http.post(this.authService.baseURL + '/ResetPassword', param, {headers: headers}).subscribe(
      response => {
        if (response['message'] === 'success') {
        } else {
          this.userNameFormControl.setErrors(Validators.requiredTrue);
        }
      });
    }
  }
  ResetPassword() {
    this.loader.start();
    this.loader.set(10);
    const url = window.location.search;
    const urlparam = url.split('=');
    const uniqueId = urlparam[1];
    const headers = new HttpHeaders().set('Content-Type' , 'application/x-www-form-urlencoded');
    const param = new HttpParams().set('uniqueId', uniqueId).set('password', this.passwordDetails.newPassword).
    set('userName', this.passwordDetails.userName);
    if (this.passwordDetails.newPassword === this.passwordDetails.confirmPassword) {
        this.http.post(this.authService.baseURL + '/ResetPassword', param, {headers: headers}).subscribe(
          response => {
            if (response['message'] === 'success') {
                this.loader.complete();
                this.opendialogForProfile('Password is changed successfully! Please login again.');
                // window.open('https://stathealthmdhospital-test.appspot.com', '_self');
                // window.open('https://stathealthmd-hospital.appspot.com', '_self');
            } else if (response['message'] === 'link is invalid') {

              this.loader.complete();
              this.openErrorDialog('Forgot password link has been expired.');

            } else if (response['message'] === 'user not present') {

              this.loader.complete();
              this.openErrorDialog('User not present.');

            } else {

              this.loader.complete();
              this.openErrorDialog('Some error occurred, please try again.');
            }
          },
          err => {
            this.openErrorDialog('Some error occurred, please try again.');
            this.loader.complete();
            console.log(err);

          });
    } else {

        this.openErrorDialog('Password do not match.');
        this.loader.complete();
    }
  }

  checkIsValidLink() {
    const urlParam = window.location.search;
    const receivedUniqueId = urlParam.split('=');
    const uniqueId = receivedUniqueId[1];
    const headers = new HttpHeaders().set('Content-Type' , 'application/x-www-form-urlencoded');
    const param = new HttpParams().set('uniqueId', uniqueId);
    this.http.post(this.authService.baseURL + '/CheckLinkValidation', param, {headers: headers}).subscribe(
      response => {
        if (response['message'] === 'success') {

          if (response['status'] === false) {
            this.isValidLink = false;
            document.write('Forgot password link has been expired.');
          } else if (response['status'] === true) {
            this.isValidLink = true;
          }

        } else {
          document.write('Forgot password link has been expired.');
        }
      },
      err => {
        document.write('Forgot password link has been expired.');
      });
  }

  openErrorDialog(msg) {
    const dialogRef = this.errorDialog.open(ErrorDialogComponent , {
      height: '72px',
      // data : 'Problem While Reset Password'
      data: msg
    });
  }
  opendialogForProfile(message) {
    const dialogRef = this.dialogForProfile.open(DialogForProfileComponent , {
      height: '124px',
      data : message ,
      disableClose: true,
      hasBackdrop: true
    });
  }

}

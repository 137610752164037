import { Component , OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import { MessagingService } from './messaging.service';
import * as firebase from 'firebase/app';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, ActivatedRoute , Router} from '@angular/router';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'app';
  options: FormGroup;
  message;
  receivedCallIdFromQuery;
  constructor(fb: FormBuilder, private msgService: MessagingService, private route: ActivatedRoute,
    private router: Router) {
    this.options = fb.group({
      color: 'primary',
      fontSize: [16, Validators.min(10)],
    });
  }

  ngOnInit() {
    this.msgService.getPermission();
    this.msgService.receiveMessage();
    this.message = this.msgService.currentMessage;
  }

  getFontSize() {
    return Math.max(10, this.options.value.fontSize);
  }
}

import { Component, OnInit, AfterViewInit, ViewChildren } from "@angular/core";
import { HttpParams, HttpClient, HttpHeaders } from "@angular/common/http";
import { Http, Headers, Response, RequestOptions } from "@angular/http";
import { AuthService } from "../services/auth.service";
import { DicomComponent } from "../dicom/dicom.component";
import { MatDialog } from "@angular/material";
import { MatRadioChange } from "@angular/material";
import { SuccessDialogComponent } from "../success-dialog/success-dialog.component";
import { LoadingBarService } from "@ngx-loading-bar/core";
import { ErrorDialogComponent } from "../error-dialog/error-dialog.component";
import {
  FormControl,
  FormGroupDirective,
  NgForm,
  Validators,
} from "@angular/forms";
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
  ErrorStateMatcher,
} from "@angular/material/core";
import { MomentDateAdapter } from "@angular/material-moment-adapter";
import { MatDatepickerInputEvent } from "@angular/material/datepicker";
import { PlatformLocation } from "@angular/common";
import { HostListener } from "@angular/core";
import * as firebase from "firebase/app";
import { MatExpansionPanel } from "@angular/material/expansion";
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
  NavigationEnd,
  ActivatedRoute,
} from "@angular/router";
import { AngularFireDatabase } from "angularfire2/database";
import { environment } from "src/environments/environment";
import { FindValueSubscriber } from "rxjs/internal/operators/find";

export const MY_FORMATS = {
  parse: {
    dateInput: "MM/DD/YYYY",
    // dateInput: {month: 'short', year: 'numeric', day: 'numeric'},
  },
  display: {
    dateInput: "MM/DD/YYYY",
    monthYearLabel: "MMM YYYY",
    dateA11yLabel: "LL",
    monthYearA11yLabel: "MMMM YYYY",
  },
};
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(
    control: FormControl | null,
    form: FormGroupDirective | NgForm | null
  ): boolean {
    const isSubmitted = form && form.submitted;
    return !!(
      control &&
      control.invalid &&
      (control.dirty || control.touched || isSubmitted)
    );
  }
}

@Component({
  selector: "app-nurse-patient-records",
  templateUrl: "./nurse-patient-records.component.html",
  styleUrls: ["./nurse-patient-records.component.css"],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class NursePatientRecordsComponent implements OnInit {
  isHemorrhage;
  nonHemorrhageSelectSide;
  nonHemorrhageSelectOrgan;
  nonHemorrhageCtaInterpretation;
  hemorrhageOrNonHemorrhage;
  hemorrhageOrNonHemorrhageObj;
  hemorrhageTypeArr = [];
  aspectScoreDoesNotApplyArr = [];
  aspectSubganglionicArr = [];
  aspectSupraganglionicArr = [];
  aspectBasalGangliaArr = [];
  nonHemorrhageSelectTypeArr = [];
  nonHemorrhageSelectSideArr = [];
  doctorFullName;
  meansOfArrivalObj;
  ambulance_option:Boolean = false;
  cigarette_section:Boolean = false;
  antiPlatelet_section:Boolean = false;
  antiCoagulation_section:Boolean = false;
  nurseFullName = localStorage.getItem('nurseFullName');
  @ViewChildren("input") vc;
  matcher = new MyErrorStateMatcher();
  patientSearchDiv: Boolean = true;
  patientDetailsDiv: Boolean = false;
  patientGeneralInfoDiv: Boolean = true;
  patientAllInfoDiv: Boolean = false;
  searchType = "name";
  searchValue: any = "";
  PatientDOB: any = "";
  today = new Date();
  isValidSearch: boolean;
  public resultList: string[] = [];
  public resultMaleList: string[] = [];
  public resultFemaleList: string[] = [];
  dobstring;
  dobArrangedString;
  convertedDob;
  patientObj = { name: "", mrn: "", gender: "", dob: "" };
  public receivedPreviousRecord: any[] = [];
  public receivedPreviousFiles: any[] = [];
  public receivedPreviousConsultNotes: any[] = [];
  files: FileList;
  resultDiv = false;
  searchCategory;
  private reader = new FileReader();
  showSearchResult = false;
  showMalePatientList = false;
  showFemalePatientList = false;
  retrievedData;
  showingResultForDOB;
  timeDiffInMilli;
  showDataNotFound;
  completeDateVar;
  date;
  month;
  year;
  completeDate;
  receivedMonth;
  receivedDate;
  receivedYear;
  age;
  detectSearchSuccessfull: Boolean = false;
  showParticularRecord: Boolean = false;
  showParticularConsultNote: Boolean = false;
  showCallLog: Boolean = true;
  particularCallId;
  particularDoctor;
  particularTime;
  particularEndCallTime;
  particularPickupTime;
  getConsultNotesArray;
  showResults;
  callIdReceivedFromQuery;
  // i agree t & c
  iagreetnc: Boolean = false;
  iagreeReceivedDate = new Date();
  iagreeDate;
  iagreeMonth;
  iagreeYear;
  iagreePostLetter;
  monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  postLetters = {
    1: "st",
    2: "nd",
    3: "rd",
    4: "th",
    5: "th",
    6: "th",
    7: "th",
    8: "th",
    9: "th",
    10: "th",
    11: "th",
    12: "th",
    13: "th",
    14: "th",
    15: "th",
    16: "th",
    17: "th",
    18: "th",
    19: "th",
    20: "th",
    21: "st",
    22: "nd",
    23: "rd",
    24: "th",
    25: "th",
    26: "th",
    27: "th",
    28: "th",
    29: "th",
    30: "th",
    31: "st",
  };
  // tslint:disable-next-line:member-ordering
  dateFormControl = new FormControl("", [Validators.required]);
  /*consultNoteData = {
    assessment: '', comments: '', complaint: '', doctor_id: '', isComplete: ''
    , labs: '', patient: '', physical_exam: '', presenting_illness: '', requested_call: '' ,
    speciality_id: '', timestamp: ''};*/
  giveTpa = {
    totalDose: "",
    bolusDose: "",
    infusionDose: "",
    timeToGiveTpa: "",
    agree: false,
  };
  selectedReasonList: any = [];
  selectedReasonEmbolectomy: any[];
  doNotGiveTpaObj = {
    reasonForNotGivingTpa: this.selectedReasonList,
  };
  timePoints = {
    timeForConsultRequest: "",
    timePatientAtCtScan: "",
    timePatientReadyForVideo: "",
    timeProviderOnVideo: "",
    timeOfCtRead: "",
    timeOfCta: "",
    timeOfCtaRead: "",
    timeOfDecision: "",
    timeOfTpaBolus: "",
    timeOfArrivalToEd: "",
    timeOfLastKnownNormal: "",
  };
  addWorkup = {
    cth: false,
    cthValue: "",
    mriBrain: false,
    mriBrainValue: "",
    ctaHeadAndNeck: false,
    ctaHeadAndNeckValue: "",
    eeg: false,
    eegValue: "",
    mraHeadAndNeck: false,
    mraHeadAndNeckValue: "",
    lp: false,
    lpValue: "",
  };
  ctInterpretation = "";
  ctaInterpretation = "";
  embolectomyObjectForDoNotGiveTpa = {};
  // doNotGiveTpaSelected: this.selectedReasonEmbolectomy

  giveOrDoNotGiveTpa;
  considerOrDoNotConsiderEmbolectomy;
  public hemorrhage = {
    CTH: "",
    InterpretationTime: "",
    InterpretationDate: "",
    HemorrhageType: ""
  }
  public nonHemorrhage = {
    CT: "",
    InterpretationTime: "",
    InterpretationDate: "",
    AspectScore: "",
    CTAInterpretation: "",
  }
  public aspectSubganglionicNuclei = {
    frontalOperculumM1: "",
    AnteriorTemporalLobeM2: "",
    frontalOperculumM1Posterior: ""
  }
  public aspectSupraganglionicNuclei = {
    anteriorMCAM4: "",
    lateralMCAM5: "",
    frontalMCAM6: ""
  }
  public aspectBasalGanglia = {
    caudate: "",
    lentiformNucleus: "",
    insula: "",
    internalCapsule: ""
  }
  public consultNotes = {
    chiefComplaint: "a",
    historyOfIllness: "",
    reviewOfSystems: "",
    /*vitalSigns: '', giveTPA: '',
    notes: '', doNotgiveTPA: '', */ physicalExam:
      "",
    giveTPA: false,
    doNotgiveTPA: false,
    considerOrDoNotConsiderEmbolectomy: "",
    thermobectomyRecommendation: "",
    nihss: "",
    imaging: "",
    assessment: "",
    recommendations: {},
    userId: "",
    doctorId: "",
    patient: "",
    speciality_id: "",
    isComplete: "",
    requested_call: "",
    timestamp: "",
    downloadUrl: "",
  };

  public particularConsultNotesDetails = {
    chiefComplaint: "a",
    historyOfIllness: "",
    reviewOfSystems: "",
    /*vitalSigns: '', giveTPA: '',
      notes: '', doNotgiveTPA: '', */ physicalExam:
      "",
    imaging: "",
    assessment: "",
    recommendations: "",
    userId: "",
    doctorId: "",
    patient: "",
    speciality_id: "",
    isComplete: "",
    requested_call: "",
    timestamp: "",
    downloadUrl: "",
  };
  public nihss = {
    levelOfConsiousness: "",
    responseToLocQue: "",
    responseToLocComm: "",
    bestGaze: "",
    visual: "",
    facialPalsy: "",
    motorLeftArm: "",
    motorRightArm: "",
    motorLeftLeg: "",
    motorRightLeg: "",
    limbAtaxia: "",
    sensory: "",
    bestLanguage: "",
    dysarthria: "",
    extinctionAndInattention: "",
    totalScore: 0,
  };
  patientSettings = {
    nurse: "",
    physician: "",
    familyContactNumber:"",
    meansOfArrival:"",
    preArrivalNotification:"",
    meansOfArrivalOptions:"",
    meansOfArrivalCustom:"",
    isPatientOnAntiplateletTherapy:"",
    wasPatientOnAnticoagulationTherapy:"",
    medicationsForAntiplatelet:"",
    medicationsForAnticoagulation:"",
    historian: "",
    hospitalCampus: "",
    arrivalToEdDate: "",
    arrivalToEdTime: "",
    lastKnownNormalDate: "",
    lastKnownNormalTime: "",
    tpaEligibilityUnknown: false,
    familyAtBedside: "",
    bloodPressure: "",
    cigarettesPerDay: "",
    cigarettesPerDayQuantity: "",
    alcoholConsumption: "",
    weight: "",
    oxygenSat: "",
    heartRate: "",
    temp: "",
    rr: "",
    bloodGlucose: "",
    weightUnit: "",
    tempUnit: "",
    medicationsCustom: "",
    miInPrevious3Weeks: "",
    strokeInPrevious3Months: "",
    historyOfIch: "",
    tookCEPX: "",
    historyOfNeoplasm: "",
    isIntracranial: "",
    ReasonForConsult: "",
    symptoms: "",
    pastMedicalHistory: "",
    pastSurgicalHistory: "",
    medications: "",
    allergies: "",
    socialHistory: "",
    tobaccoHistory: "",
    drugsHistory: "",
    familyHistory: "",
  };
  show = false;
  showLoader = false;

  // data isolation

  hospital_id;
  loggedInUser;
  constructor(
    private http: HttpClient,
    public authService: AuthService,
    public router: Router,
    public dialog: MatDialog,
    public errorDialog: MatDialog,
    public successDialog: MatDialog,
    public loader: LoadingBarService,
    location: PlatformLocation,
    private afDB: AngularFireDatabase
  ) {
    this.authService
      .getUserFromstatechange()
      .then((val) => (this.loggedInUser = val))
      .then(() => this.getHospitalId());
    location.onPopState(() => {
      if (this.patientDetailsDiv === true) {
        window.history.forward();
        this.patientGeneralInfoDiv = true;
        this.patientAllInfoDiv = false;
        this.patientDetailsDiv = false;
        this.patientSearchDiv = true;
      }
    });
    if (window.location.search !== "") {
      const url = window.location.search;
      const urlparam = url.split("&");
      const receivedCallId = urlparam[0].split("=");
      this.callIdReceivedFromQuery = receivedCallId[1];
      const callIdRef: firebase.database.Reference = firebase
        .database()
        .ref(
          "intakesData/stroke/" +
            this.callIdReceivedFromQuery +
            "/videoCallDetails/"
        );
      callIdRef.on("value", (snapshot) => {
        if (window.location.pathname === "/charts-records") {
          if (snapshot.val()["endCallStatus"] === true) {
            alert("This session is ended");
            this.router.navigate(["/stroke-code"]);
          }
        }
      });
    }
  }
  ngOnInit() {
    this.receivedPreviousFiles = [];
  }
  // tslint:disable-next-line:use-life-cycle-interface
  ngAfterViewInit() {
    this.vc.first.nativeElement.focus();
  }
  getTimeDiff(endCallTime, pickUpTime) {
    const timeDiffInMilli = endCallTime - pickUpTime;
    const seconds = Math.round((timeDiffInMilli / 1000) % 60);
    const minutes = Math.round((timeDiffInMilli / (1000 * 60)) % 60);
    const hours = Math.round((timeDiffInMilli / (1000 * 60 * 60)) % 24);
    const time = hours + ":" + minutes + ":" + seconds;
    return time;
  }
  getHospitalId() {
    firebase
      .database()
      .ref(
        "hospitalCampus/" +
          this.loggedInUser.toLowerCase() +
          "/campus/hospitalid/"
      )
      .once("value", (snapshot) => {
        console.log("hospital Id", snapshot.val());
        this.hospital_id = snapshot.val();
      });
  }
  showSelectedRecordDetails(name, mrn, gender, dob) {
    // mrn = this.receivedPatientData.mrn;
    this.patientObj.name = name;
    this.patientObj.mrn = mrn;
    this.patientObj.gender = gender;
    this.patientObj.dob = dob;
    var receivedDateDetails = this.patientObj.dob.split("/");
    this.receivedMonth = receivedDateDetails[0];
    this.receivedDate = receivedDateDetails[1];
    this.receivedYear = receivedDateDetails[2];
    this.age = this.CalculateAge(
      this.receivedDate,
      this.receivedMonth,
      this.receivedYear
    );
    this.patientSearchDiv = false;
    this.patientDetailsDiv = true;
    this.showCallLog = true;
    this.detectSearchSuccessfull = true;
    const headers = new HttpHeaders().set(
      "Content-Type",
      "application/x-www-form-urlencoded"
    );
    const param = new HttpParams().set("patientId", this.patientObj.mrn);
    this.loader.start();
    this.loader.set(10);
    /*=============== Get Previous History ======================*/
    this.http
      .post(this.authService.baseURL + "/GetPreviousRecords", param, {
        headers: headers,
      })
      .subscribe(
        (response) => {
          if (response["Message"] === "success") {
            this.loader.complete();
            this.receivedPreviousRecord = response["data"];
            // this.doctorFullName = this.receivedPreviousRecord["fullName"];
          } else {
            this.loader.complete();
            this.receivedPreviousRecord = [];
          }
        },
        (err) => {
          this.loader.complete();
          console.log(err);
        }
      );

    /*=============== Get Previous Files ======================*/

    this.http
      .post(this.authService.baseURL + "/GetPreviousFiles", param, {
        headers: headers,
      })
      .subscribe(
        (response) => {
          if (response["Message"] === "success") {
            this.receivedPreviousFiles = response["data"];
          } else {
            this.receivedPreviousFiles = [];
          }
        },
        (err) => {
          console.log(err);
        }
      );

    /*=============== Get Previous Consult Notes ======================*/

    this.http
      .post(this.authService.baseURL + "/GetConsultNotesForHospital", param, {
        headers: headers,
      })
      .subscribe(
        (response) => {
          if (response["Message"] === "success") {
            this.receivedPreviousConsultNotes = response["data"];
            localStorage.setItem("doctorFullName", this.receivedPreviousConsultNotes[0]["fullName"]);
            this.doctorFullName = localStorage.getItem('doctorFullName');
          } else {
            this.receivedPreviousConsultNotes = [];
          }
        },
        (err) => {
          console.log(err);
        }
      );
  }
  gotoSearch() {
    this.patientGeneralInfoDiv = true;
    this.patientAllInfoDiv = false;
    this.patientDetailsDiv = false;
    this.patientSearchDiv = true;
    this.showParticularRecord = false;
  }
  showDetailsinfoDiv() {
    this.patientGeneralInfoDiv = false;
    this.patientAllInfoDiv = true;
  }
  CalculateAge(receivedDate, receivedMonth, receivedYear) {
    // console.log('ageee', receivedDate, receivedMonth, receivedYear);
    // if (this.patientObj.dob) {
    //   var today = new Date();
    //   var birthDate = new Date(receivedYear, receivedMonth, receivedDate);
    //   var age = today.getFullYear() - birthDate.getFullYear();
    //   var m = today.getMonth() - birthDate.getMonth();
    //   if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    //     age--;
    //   }
    //   if (age < 0) {
    //     age = 0;
    //   }
    //   return age;
    // }
    var yearThen = receivedYear;
    var monthThen = receivedMonth;
    var dayThen = receivedDate;
    var today = new Date();
    var birthday = new Date(yearThen, monthThen - 1, dayThen);
    var differenceInMilisecond = today.valueOf() - birthday.valueOf();
    var year_age = Math.floor(differenceInMilisecond / 31536000000);
    var day_age = Math.floor((differenceInMilisecond % 31536000000) / 86400000);
    return year_age;
  }
  // Show Particular Record Call History Section
  showParticularRecordDiv(
    callId,
    pickUpDoctor,
    time,
    endCallTime,
    pickUpTime,
    isComplete
  ) {
    if (isComplete === true || pickUpTime !== 0) {
      this.showParticularRecord = true;
      this.showParticularConsultNote = true;
      this.showCallLog = false;
      this.particularCallId = callId;
      this.particularDoctor = pickUpDoctor;
      this.particularTime = time;
      this.particularEndCallTime = endCallTime;
      this.particularPickupTime = pickUpTime;
      this.patientDataRetrieve(this.particularCallId);
      this.getParticularConsultNote(this.particularCallId);
    }
  }
  getParticularConsultNote(callId) {
    const headers = new HttpHeaders().set(
      "Content-Type",
      "application/x-www-form-urlencoded"
    );
    const params = new HttpParams().set("callId", callId);
    this.http
      .post(this.authService.baseURL + "/GetConsultNoteForDoctor", params, {
        headers: headers,
      })
      .subscribe((response) => {
        if (response["Message"] === "success") {
          
          console.log(response);
          this.getConsultNotesArray = response["data"];
          this.getConsultNotesArray = this.getConsultNotesArray[0];
          this.consultNoteDetails(
            "callLogNotes",
            this.getConsultNotesArray.complaint,
            this.getConsultNotesArray.presenting_illness,
            this.getConsultNotesArray.reviewOfSystems,
            this.getConsultNotesArray.nihss,
            this.getConsultNotesArray.imaging,
            this.getConsultNotesArray.assessment,
            this.getConsultNotesArray.physical_exam,
            this.getConsultNotesArray.recommendationsNew,
            this.getConsultNotesArray.userId,
            this.getConsultNotesArray.doctorId,
            this.getConsultNotesArray.patient,
            this.getConsultNotesArray.speciality_id,
            this.getConsultNotesArray.isComplete,
            this.getConsultNotesArray.requested_call,
            this.getConsultNotesArray.timestamp,
            this.getConsultNotesArray.downloadUrl,
            this.getConsultNotesArray.giveTPA,
            this.getConsultNotesArray.hemorrhageOrNonHemorrhage,
            this.getConsultNotesArray.doNotgiveTPA,
            this.getConsultNotesArray.thermobectomyRecommendation,
            this.getConsultNotesArray.considerOrDoNotConsiderEmbolectomy,
            this.getConsultNotesArray.telestrokeDocumentation,
            this.getConsultNotesArray.additionalWorkup,
            this.getConsultNotesArray.CTInterpretation,
            this.getConsultNotesArray.CTAInterpretation,
            this.getConsultNotesArray.IsHemorrhage,
            this.getConsultNotesArray.iagreetnc
          );
        }
      });
  }
  goBackToCallLog() {
    this.showParticularRecord = false;
    this.showParticularConsultNote = false;
    this.showCallLog = true;
  }

  /*=================== Selected Consult Note Details ========================*/
  consultNoteDetails(
    status,
    complaint,
    presenting_illness,
    reviewOfSystems,
    nihss,
    imaging,
    assessment,
    physical_exam,
    recommendations,
    userId,
    doctorId,
    patient,
    speciality_id,
    isComplete,
    requested_call,
    timestamp,
    downloadUrl,
    giveTPA,
    hemorrhageOrNonHemorrhage,
    doNotgiveTPA,
    thermobectomyRecommendation,
    considerOrDoNotConsiderEmbolectomy,
    telestrokeDocumentation,
    additionalWorkup,
    CTInterpretation,
    CTAInterpretation,
    IsHemorrhage,
    iagreetnc
  ) {
    this.hemorrhageTypeArr = [];
    this.aspectScoreDoesNotApplyArr = [];
    this.aspectSubganglionicArr = [];
    this.aspectSupraganglionicArr = [];
    this.aspectBasalGangliaArr = [];
    this.nonHemorrhageSelectTypeArr = [];
    this.nonHemorrhageSelectSideArr = [];
    this.consultNotes.chiefComplaint = complaint;
    this.consultNotes.historyOfIllness = presenting_illness;
    this.consultNotes.reviewOfSystems = reviewOfSystems;
    /*this.consultNotes.vitalSigns = vitalSigns;
    this.consultNotes.giveTPA = giveTPA;
    if (giveTPA === true) {
      this.consultNotes.giveTPA = 'Yes';
    } else {
      this.consultNotes.giveTPA = 'No';
    }
    if (doNotGiveTPA === true) {
      this.consultNotes.doNotgiveTPA = 'Yes';
    } else {
      this.consultNotes.doNotgiveTPA = 'No';
    }
    this.consultNotes.notes = notes;*/
    if (giveTPA === true) {
      this.giveOrDoNotGiveTpa = true;
      this.giveTpa = recommendations;
    } else {
      // alert('from false');
      this.giveOrDoNotGiveTpa = false;
      this.doNotGiveTpaObj = recommendations;
    }
    
    this.consultNotes.giveTPA = giveTPA;
    this.hemorrhageOrNonHemorrhage = hemorrhageOrNonHemorrhage;
    this.consultNotes.doNotgiveTPA = doNotgiveTPA;
    // this.embolectomyObjectForDoNotGiveTpa = thermobectomyRecommendation;
    // alert('nihss' + nihss);
    this.nihss = nihss;
   
    if (considerOrDoNotConsiderEmbolectomy === "true") {
      this.considerOrDoNotConsiderEmbolectomy = true;
    } else {
      if (considerOrDoNotConsiderEmbolectomy === "false") {
        this.considerOrDoNotConsiderEmbolectomy = false;
        this.embolectomyObjectForDoNotGiveTpa = thermobectomyRecommendation;
      } else {
        this.considerOrDoNotConsiderEmbolectomy = "";
      }
    }
    this.iagreeReceivedDate = new Date(timestamp);
    if (iagreetnc === "true") {
      this.iagreetnc = true;
    } else {
      this.iagreetnc = false;
    }
    this.iagreeDate = this.iagreeReceivedDate.getDate();
    this.iagreePostLetter = this.postLetters[this.iagreeDate];
    this.iagreeMonth = this.monthNames[this.iagreeReceivedDate.getMonth()];
    this.iagreeYear = this.iagreeReceivedDate.getFullYear();
    // this.consultNotes.considerOrDoNotConsiderEmbolectomy = considerOrDoNotConsiderEmbolectomy;
    this.timePoints = telestrokeDocumentation;
    this.addWorkup = additionalWorkup;
    this.ctInterpretation = CTInterpretation;
    this.ctaInterpretation = CTAInterpretation;
    this.hemorrhageTypeArr=[];
    this.aspectScoreDoesNotApplyArr=[];
    this.nonHemorrhageSelectTypeArr=[];
    this.isHemorrhage = IsHemorrhage;
    if(this.isHemorrhage == true){
      this.hemorrhage = JSON.parse(hemorrhageOrNonHemorrhage);
      this.hemorrhageOrNonHemorrhageObj = JSON.parse(hemorrhageOrNonHemorrhage);
    }
    if(this.isHemorrhage == false){
      this.nonHemorrhage = JSON.parse(hemorrhageOrNonHemorrhage);
      this.hemorrhageOrNonHemorrhageObj = JSON.parse(hemorrhageOrNonHemorrhage);
    }
    if(this.isHemorrhage == true){
    // for (const [key, value] of Object.entries(this.hemorrhageOrNonHemorrhageObj.HemorrhageType)) {
    //   if(`${value}` === 'true' ){
    //     this.hemorrhageTypeArr.push(`${key}`);
    //   }
    // }

    for (const [key, value] of Object.entries(this.hemorrhageOrNonHemorrhageObj.HemorrhageType)) {
      if(`${value}` === 'true' && `${key}`== "IntraparenchymalHemorrhage"){
        this.hemorrhageTypeArr.push('Intraparenchymal Hemorrhage');
      }
      if(`${value}` === 'true' && `${key}`== "SubarachnoidHemorrhage"){
        this.hemorrhageTypeArr.push('Subarachnoid Hemorrhage');
      }
      if(`${value}` === 'true' && `${key}`== "EpiduralHemorrhage"){
        this.hemorrhageTypeArr.push('Epidural Hemorrhage');
      }
      
      if(`${value}` === 'true' && `${key}`== "SubduralHemorrhage"){
        this.hemorrhageTypeArr.push('Subdural Hemorrhage');
      }
      if(`${value}` === 'true' && `${key}`== "IntraventricularHemorrhage"){
        this.hemorrhageTypeArr.push('Intraventricular Hemorrhage');
      }
    }


  }
  if(this.isHemorrhage == false){
  
    if(this.hemorrhageOrNonHemorrhageObj.AspectScore==='AspectScoreDoesNotApply')
    {
      this.hemorrhageOrNonHemorrhageObj.AspectScore="Aspect Score Does Not Apply";
    }
    for (const [key, value] of Object.entries(this.hemorrhageOrNonHemorrhageObj.AspectScoreDoesNotApply)) {
      if(`${value}` === 'true' && `${key}`== "SuspectPosteriorCirculationStroke"){
        this.aspectScoreDoesNotApplyArr.push('Suspect Posterior Circulation Stroke ');
      }
      if(`${value}` === 'true' && `${key}`== "MetatasticBrainDisease"){
        this.aspectScoreDoesNotApplyArr.push('Metatastic Brain Disease ');
      }
      if(`${value}` === 'true' && `${key}`== "EpiduralHemorrhage"){
        this.aspectScoreDoesNotApplyArr.push('Epidural Hemorrhage');
      }
      if(`${value}` === 'true' && `${key}`== "PrimaryCNSNeoplasm"){
        this.aspectScoreDoesNotApplyArr.push('Primary CNS Neoplasm ');
      }
      if(`${value}` === 'true' && `${key}`== "Seizure"){
        this.aspectScoreDoesNotApplyArr.push('Seizure');
      }
      if(`${value}` === 'true' && `${key}`== "MigraineWithAura"){
        this.aspectScoreDoesNotApplyArr.push('Migraine With Aura');
      }
      if(`${value}` === 'true' && `${key}`== "ConversionDisorder"){
        this.aspectScoreDoesNotApplyArr.push('Conversion Disorder');
      }
      if(`${value}` === 'true' && `${key}`== "Other"){
        this.aspectScoreDoesNotApplyArr.push('Other');
      }
    }


    for (const [key, value] of Object.entries(this.hemorrhageOrNonHemorrhageObj.AspectSubganglionicNuclei)) {
      if(`${key}`== 'FrontalOperaculumM1' && `${value}` === '1' ){
          this.aspectSubganglionicNuclei.frontalOperculumM1 = "Frontal Operaculum M1";
      } if(`${key}`== 'FrontalOperaculumM1' && `${value}` === '0' ){
        this.aspectSubganglionicNuclei.frontalOperculumM1 = "No";
    }
      if(`${key}`== 'AnteriorTemporalLobeM2' && `${value}` === '1' ){
        this.aspectSubganglionicNuclei.AnteriorTemporalLobeM2 = "Anterior TemporalLobe M2";
    }  if(`${key}`== 'AnteriorTemporalLobeM2' && `${value}` === '0' ){
      this.aspectSubganglionicNuclei.AnteriorTemporalLobeM2 = "No";
  }
  if(`${key}`== 'PosteriorTemporalLobeM1' && `${value}` === '1' ){
    this.aspectSubganglionicNuclei.frontalOperculumM1Posterior = "Posterior TemporalLobe M1";
}
if(`${key}`== 'PosteriorTemporalLobeM1' && `${value}` === '0' ){
  this.aspectSubganglionicNuclei.frontalOperculumM1Posterior = "No";
}
      // console.log(`${key}: ${value}`);
    }
    for (const [key, value] of Object.entries(this.hemorrhageOrNonHemorrhageObj.AspectSupraganglionicNuclei)) {
      if(`${key}`== 'AnteriorMCAM4' && `${value}` === '1' ){
        this.aspectSupraganglionicNuclei.anteriorMCAM4 = "Anterior MCA M4";
    } if(`${key}`== 'AnteriorMCAM4' && `${value}` === '0' ){
      this.aspectSupraganglionicNuclei.anteriorMCAM4 = "No";
  }
    if(`${key}`== 'LateralMCAM5' && `${value}` === '1' ){
      this.aspectSupraganglionicNuclei.lateralMCAM5 = "Lateral MCA M5";
  }  if(`${key}`== 'LateralMCAM5' && `${value}` === '0' ){
    this.aspectSupraganglionicNuclei.lateralMCAM5 = "No";
}
if(`${key}`== 'FrontalMCAM6' && `${value}` === '1' ){
  this.aspectSupraganglionicNuclei.frontalMCAM6 = "Frontal MCA M6";
}
if(`${key}`== 'FrontalMCAM6' && `${value}` === '0' ){
this.aspectSupraganglionicNuclei.frontalMCAM6 = "No";
}
    }
    for (const [key, value] of Object.entries(this.hemorrhageOrNonHemorrhageObj.AspectBasalGanglia)) {
      if(`${key}`== 'Caudate' && `${value}` === '1' ){
        this.aspectBasalGanglia.caudate = "Caudate";
    } if(`${key}`== 'Caudate' && `${value}` === '0' ){
      this.aspectBasalGanglia.caudate = "No";
  }
    if(`${key}`== 'LentiformNucleus' && `${value}` === '1' ){
      this.aspectBasalGanglia.lentiformNucleus = "Lentiform Nucleus";
  }  if(`${key}`== 'LentiformNucleus' && `${value}` === '0' ){
    this.aspectBasalGanglia.lentiformNucleus = "No";
  }
    if(`${key}`== 'Insula' && `${value}` === '1' ){
  this.aspectBasalGanglia.insula = "Insula";
  }
    if(`${key}`== 'Insula' && `${value}` === '0' ){
  this.aspectBasalGanglia.insula = "No";
  }
    if(`${key}`== 'InternalCapsule' && `${value}` === '1' ){
  this.aspectBasalGanglia.internalCapsule = "Internal Capsule";
  }
    if(`${key}`== 'InternalCapsule' && `${value}` === '0' ){
  this.aspectBasalGanglia.internalCapsule = "No";
  }
    }

    for (const [key, value] of Object.entries(this.hemorrhageOrNonHemorrhageObj.CtaInterpretation.SelectType)) {
      if(`${value}` === 'true'){
            this.nonHemorrhageSelectTypeArr.push(`${key}`);        
      }
  }

var nonHemorrhageSelectSide = [];
  this.nonHemorrhageSelectTypeArr.forEach(function (value) {
    if(value==="Occlusion"){
        nonHemorrhageSelectSide.push("Occlusion");
    }
    if(value==="Dissection"){
      nonHemorrhageSelectSide.push("Dissection");
    }
     if(value==="CervicalIcaMildStenosis"){
      nonHemorrhageSelectSide.push("Cervical ICA Mild Stenosis");
       }
     if(value==="CervicalIcaModerateStenosis"){
      nonHemorrhageSelectSide.push("Cervical ICA Moderate Stenosis");      
     }
    if(value==="CervicalIcaSevereStenosis"){
     nonHemorrhageSelectSide.push("Cervical ICA Severe Stenosis");
      }

    if(value==="IntracranialIca"){
    nonHemorrhageSelectSide.push("Intracranial ICA");    }


   if(value==="CervicalVertebralOcclusion"){
    nonHemorrhageSelectSide.push("Cervical Vertebral Artery Occlusion");    }

    if(value==="CervicalVertebralArteryStenosis"){
    nonHemorrhageSelectSide.push("Cervical Vertebral Artery Stenosis");    }

    if(value==="CervicalVertebralArteryDissection"){
     nonHemorrhageSelectSide.push("Cervical Vertebral Artery Dissection");    }

      if(value==="CervicalIntracranialArteryStenosis"){
      nonHemorrhageSelectSide.push("Cervical Intracranial Artery Stenosis");    }

      if(value==="PcaStenosis"){
      nonHemorrhageSelectSide.push("PCA Stenosis");    }

      if(value==="PcaOcclusion"){
       nonHemorrhageSelectSide.push("PCA Occlusion");    }

       if(value==="BasiliarArteryStenosis"){
        nonHemorrhageSelectSide.push("Basiliar Artery Stenosis");    }

         if(value==="DiffuseIntracranialAtherosclerosis"){
        nonHemorrhageSelectSide.push("Diffuse Intracranial Athero Sclerosis");    }
      
   
});
     this.nonHemorrhageSelectTypeArr = nonHemorrhageSelectSide;

  // for (const [key, value] of Object.entries(this.hemorrhageOrNonHemorrhageObj.CtaInterpretation.SelectType)) {
  //       if(`${value}` === 'true' ){
  //         this.nonHemorrhageSelectTypeArr.push(`${key}`);
  //       }
  //   }
    this.nonHemorrhageSelectSide = this.hemorrhageOrNonHemorrhageObj.CtaInterpretation.SelectSide;
    this.nonHemorrhageSelectOrgan = this.hemorrhageOrNonHemorrhageObj.CtaInterpretation.SelectOrgan;
    this.nonHemorrhageCtaInterpretation = this.hemorrhageOrNonHemorrhageObj.CtaInterpretation.CTAInterpretationValue;
  }
    this.consultNotes.imaging = imaging;
    this.consultNotes.assessment = assessment;
    this.consultNotes.physicalExam = physical_exam;
    // this.consultNotes.recommendations = recommendationsNew;
    this.consultNotes.userId = userId;
    this.consultNotes.doctorId = doctorId;
    this.consultNotes.patient = patient;
    this.consultNotes.speciality_id = speciality_id;
    this.consultNotes.isComplete = isComplete;
    this.consultNotes.requested_call = requested_call;
    this.consultNotes.timestamp = timestamp;
    // alert(downloadUrl);
    this.consultNotes.downloadUrl = downloadUrl;
    // alert(this.consultNotes.downloadUrl);
    this.patientDataRetrieve(this.consultNotes.requested_call);
    // this.patientGeneralInfoDiv = false;
    // this.patientAllInfoDiv = true;
    if (status === "Notes") {
      this.patientGeneralInfoDiv = false;
      this.patientAllInfoDiv = true;
    }
  }

  gobacktoGeneralInfo() {
    this.patientAllInfoDiv = false;
    this.patientGeneralInfoDiv = true;
  }

  focusOnSearch() {
    this.PatientDOB = "";
    this.dateFormControl.setValue("");
    this.showingResultForDOB = false;
    this.dateFormControl.clearValidators();
    this.dateFormControl.updateValueAndValidity();
  }
  focusOnDOB() {
    this.searchValue = "";
    this.showingResultForDOB = true;
    this.dateFormControl.setValidators(Validators.required);
    this.dateFormControl.updateValueAndValidity();
  }

  SearchPatients() {
    this.showDataNotFound = false;
    this.isValidSearch = false;
    if (this.showingResultForDOB) {
      this.searchCategory = "dob";
    } else {
      if (isNaN(this.searchValue)) {
        this.searchCategory = "name";
      } else {
        this.searchCategory = "mrn";
      }
    }
    this.resultDiv = true;
    // this.isValidSearch = false;
    let params;
    this.resultList = [];
    this.showSearchResult = false;
    if (this.searchCategory === "name" && this.searchValue !== "") {
      this.isValidSearch = true;
      this.completeDate = "";
      params = new HttpParams()
        .set("category", this.searchCategory)
        .set("name", this.searchValue)
        .set("hospital_id", this.hospital_id)
        .set("type", "ER");
    }

    if (this.searchCategory === "mrn" && this.searchValue !== "") {
      this.isValidSearch = true;
      params = new HttpParams()
        .set("category", this.searchCategory)
        .set("mrn", this.searchValue)
        .set("hospital_id", this.hospital_id)
        .set("type", "ER");
    } else if (this.searchCategory === "dob" && !this.dateFormControl.invalid) {
      // if (this.PatientDOB.length === 10 && this.firstNameFormControl.valid) {
      let d = new Date(this.PatientDOB);
      console.log(d.getDate());
      console.log(d.getMonth() + 1);
      console.log(d.getFullYear());
      this.date = d.getDate();
      this.month = d.getMonth() + 1;
      this.year = d.getFullYear();
      console.log(String(this.date).length);
      if (String(this.date).length === 1) {
        this.date = "0" + this.date;
      }
      if (String(this.month).length === 1) {
        this.month = "0" + this.month;
      }
      console.log(this.date);
      this.completeDate = this.month + "/" + this.date + "/" + this.year;
      // Check for Valid date
      if (!isNaN(this.date) && this.completeDate !== "01/01/1970") {
        this.isValidSearch = true;
      }
      params = new HttpParams()
        .set("category", this.searchCategory)
        .set("dob", this.completeDate)
        .set("hospital_id", this.hospital_id)
        .set("type", "ER");

      // }
    }
    // this.loader.set(60);
    if (this.isValidSearch) {
      this.showResults = this.searchValue;
      this.loader.start();
      this.loader.set(10);
      const headers = new HttpHeaders().set(
        "Content-Type",
        "application/x-www-form-urlencoded"
      );
      this.http
        .post(this.authService.baseURL + "/PatientSearch", params, {
          headers: headers,
        })
        .subscribe(
          (response) => {
            if (response["Message"] === "Success") {
              this.loader.complete();
              this.showSearchResult = true;
              this.showResults = this.searchValue;
              console.log(response["Data"]);
              this.resultList = response["Data"];
            } else {
              this.loader.complete();
              this.showDataNotFound = true;
            }
          },
          (err) => {
            this.loader.complete();
            console.log(err);
          }
        );
    }
  }

  /*================= Get and Upload DICOM File =======================*/
  getDICOMFiles(event) {
    this.files = event.target.files;
    const file = this.files[0];
    this.reader.readAsDataURL(file);
    this.uploadDICOMFile(file);
  }

  uploadDICOMFile(file) {
    this.loader.start();
    this.loader.set(10);
    const formData: FormData = new FormData();
    formData.append("patientId", this.patientObj.mrn);
    formData.append("file", file);
    // this.showLoader = true;
    this.http
      .post(this.authService.baseURL + "/DICOMUpload", formData)
      .subscribe(
        (response) => {
          if (response["Message"] === "success") {
            console.log("upload successfull", response);
            this.showLoader = false;
            response["timestamp"] = new Date().getTime();
            this.receivedPreviousFiles.push(response);
            this.loader.complete();
            this.openSuccessDialog();
          } else {
            // this.showLoader = false;
            this.loader.complete();
            this.openErrorDialog();
          }
        },
        (err) => {
          this.loader.complete();
          this.openErrorDialog();
        }
      );
  }
  
  //function to get stringDate 
  getStringDate(date:Date):String{
    let d = new Date(date);
    this.date = d.getDate();
    this.month = d.getMonth() + 1;
    this.year = d.getFullYear();
    if (String(this.date).length === 1) {
      this.date = "0" + this.date;
    }
    if (String(this.month).length === 1) {
      this.month = "0" + this.month;
    }
    var stringDate  =this.month + "/" + this.date + "/" + this.year;
    return stringDate;
  }

  /*================= Get and Upload Normal File =======================*/

  getNormalFiles(event) {
    this.files = event.target.files;
    const file = this.files[0];
    this.reader.readAsDataURL(file);
    this.uploadNormalFile(file);
  }

  uploadNormalFile(file) {
    this.loader.start();
    this.loader.set(10);
    const formData: FormData = new FormData();
    formData.append("patientId", this.patientObj.mrn);
    formData.append("file", file);
    // this.showLoader = true;
    this.http
      .post(this.authService.baseURL + "/FileUpload", formData)
      .subscribe(
        (response) => {
          console.log("upload successfull", response);
          if (response["Message"] === "success") {
            console.log("upload successfull", response);
            this.showLoader = false;
            response["timestamp"] = new Date().getTime();
            this.receivedPreviousFiles.push(response);
            this.loader.complete();
            this.openSuccessDialog();
          } else {
            // this.showLoader = false;
            this.loader.complete();
            this.openErrorDialog();
          }
        },
        (err) => {
          this.loader.complete();
          this.openErrorDialog();
        }
      );
  }

  /*=============== open Diacom files in viewer =====================*/
  viewImage(fileid, filetype, fileurl) {
    if (filetype === "Normal") {
      console.log("normal", fileurl);
      window.open(fileurl, "_blank");
    } else {
      console.log("fileid to be given to auth SErvice", fileid);
      this.authService.setValue(fileid);
      const dialogRef = this.dialog.open(DicomComponent, {
        height: "400px",
        width: "900px",
      });
    }
  }

  /*============== Show search result according to filter =============*/

  radioChange(event: MatRadioChange) {
    console.log(event.value);
    if (event.value === "male") {
      this.showSearchResult = false;
      this.showMalePatientList = true;
      this.showFemalePatientList = false;
    }
    if (event.value === "female") {
      this.showSearchResult = false;
      this.showMalePatientList = false;
      this.showFemalePatientList = true;
    }
    if (event.value === "all") {
      this.showMalePatientList = false;
      this.showFemalePatientList = false;
      this.showSearchResult = true;
    }
  }

  /*================ Set Intake Form Details =====================*/
  patientDataRetrieve(callId) {
    const params = new HttpParams().set("callId", callId);
    const headers = new HttpHeaders().set(
      "Content-Type",
      "application/x-www-form-urlencoded"
    );
    this.http
      .get(
        `${environment.firebase.databaseURL}/intakeData/stroke/${callId}.json`
      )
      .subscribe(
        // this.http.post(this.authService.baseURL + '/GetVideoTokenDetailsTest', params , {headers: headers}).subscribe(
        (res) => {
          console.log("TEST DATA", res);
          // if (res['Message'] === 'Success') {
          this.retrievedData = res;
          this.patientSettings.nurse = this.retrievedData.nurse;
          this.patientSettings.physician = this.retrievedData.physician;
          this.patientSettings.familyContactNumber = this.retrievedData.familyContactNumber;
          this.patientSettings.preArrivalNotification = this.retrievedData.preArrival;
          this.meansOfArrivalObj = JSON.parse(this.retrievedData.meansOfArrival);
          if(this.meansOfArrivalObj["privateVehicle"] !== ""){
            this.ambulance_option = false;
            this.patientSettings.meansOfArrivalOptions = this.meansOfArrivalObj["privateVehicle"];
            this.patientSettings.meansOfArrivalCustom = "";
          }
          if(this.meansOfArrivalObj["inHouseAttack"] !== ""){
            this.ambulance_option = false;
            this.patientSettings.meansOfArrivalOptions = this.meansOfArrivalObj["inHouseAttack"];
            this.patientSettings.meansOfArrivalCustom = "";
          }
          if(this.meansOfArrivalObj["privateVehicle"] == "" && this.meansOfArrivalObj["inHouseAttack"] == ""){
            this.ambulance_option = true;
            this.patientSettings.meansOfArrivalOptions = "Ambulance";
            this.patientSettings.meansOfArrivalCustom = this.meansOfArrivalObj["Ambulance"];
          }
          this.patientSettings.historian = this.retrievedData.historian;
          this.patientSettings.hospitalCampus = this.retrievedData.hospitalCampus;
          this.patientSettings.arrivalToEdDate = this.retrievedData.arrivalToEdDate;
          this.patientSettings.arrivalToEdTime = this.retrievedData.arrivalToEdTime;
          this.patientSettings.lastKnownNormalDate = this.retrievedData.lastKnownNormalDate;
          this.patientSettings.lastKnownNormalTime = this.retrievedData.lastKnownNormalTime;
          this.patientSettings.bloodPressure = this.retrievedData.bloodPressure;
          // this.patientSettings.weight = this.retrievedData.weight;
          if (this.retrievedData.weightUnit === "kg") {
            this.patientSettings.weightUnit = "kg";
            this.patientSettings.weight=this.retrievedData.weight;
            // var retrivedWeightConverted = (
            //   Number(this.retrievedData.weight) / 2.2046
            // ).toFixed(2);
            // this.patientSettings.weight = String(retrivedWeightConverted);
          } else {
            this.patientSettings.weightUnit = "lbs";
            this.patientSettings.weight = this.retrievedData.weight;
          }
          if (this.retrievedData.tempUnit === "celsius") {
            this.patientSettings.tempUnit = "celsius";
            this.patientSettings.temp=this.retrievedData.temp;
            // var retrivedTemp = (Number(this.retrievedData.temp) - 32) / 1.8;
            // var retrivedTempConverted = retrivedTemp.toFixed(2);
            // // alert(retrivedTempConverted);
            // this.patientSettings.temp = String(retrivedTempConverted);
          } else {
            this.patientSettings.tempUnit = "fahrenheit";
            this.patientSettings.temp = this.retrievedData.temp;
          }
          this.patientSettings.oxygenSat = this.retrievedData.oxygenSat;
          this.patientSettings.heartRate = this.retrievedData.heartRate;
          // this.patientSettings.temp = this.retrievedData.temp;
          this.patientSettings.rr = this.retrievedData.rr;
          this.patientSettings.bloodGlucose = this.retrievedData.bloodGlucose;
          // this.patientSettings.weightUnit = this.retrievedData.weightUnit;
          // this.patientSettings.tempUnit = this.retrievedData.tempUnit;
          this.patientSettings.medicationsCustom = this.retrievedData.medicationsCustom;
          this.patientSettings.cigarettesPerDay = this.retrievedData.cigarettesPerDay;
          this.patientSettings.cigarettesPerDayQuantity = this.retrievedData.cigarettesPerDayQuantity;
          if(this.patientSettings.cigarettesPerDay!== " "){
            this.cigarette_section = true;
          }
          if(this.patientSettings.cigarettesPerDay == " "){
            this.cigarette_section = false;
          }
          this.patientSettings.alcoholConsumption = this.retrievedData.alcoholConsumption;
          this.patientSettings.tpaEligibilityUnknown = this.retrievedData.tpaEligibilityUnknown;
          this.patientSettings.familyAtBedside = this.retrievedData.isFamilyBedside;
          this.patientSettings.miInPrevious3Weeks = this.retrievedData.miInPrevious3Weeks;
          this.patientSettings.strokeInPrevious3Months = this.retrievedData.strokeInPrevious3Months;
          this.patientSettings.historyOfIch = this.retrievedData.isICHHistory;
          this.patientSettings.tookCEPX = this.retrievedData.tookCEPX;
          this.patientSettings.historyOfNeoplasm = this.retrievedData.isNeoplasm;
          this.patientSettings.isIntracranial = this.retrievedData.isIntracranial;
          this.patientSettings.isPatientOnAntiplateletTherapy = this.retrievedData.patientOnAntiplatelet;
          this.patientSettings.wasPatientOnAnticoagulationTherapy = this.retrievedData.patientOnAnticoagulation;
          
          
          if(this.retrievedData.reason!==null && this.retrievedData.reason!==undefined){
          this.patientSettings.ReasonForConsult = JSON.parse(this.retrievedData.reason)}


          if(this.retrievedData.symptoms!==null && this.retrievedData.symptoms!==undefined){
          this.patientSettings.symptoms = JSON.parse(this.retrievedData.symptoms)}

          if(this.retrievedData.medicalHistory!==null && this.retrievedData.medicalHistory!==undefined){
          this.patientSettings.pastMedicalHistory = JSON.parse(this.retrievedData.medicalHistory)}

          if(this.retrievedData.medicationsForAntiplateletTherapy!==null && this.retrievedData.medicationsForAntiplateletTherapy!==undefined && this.retrievedData.medicationsForAntiplateletTherapy!==""){
            this.patientSettings.medicationsForAntiplatelet = JSON.parse(this.retrievedData.medicationsForAntiplateletTherapy)}
          if(this.patientSettings.isPatientOnAntiplateletTherapy == "Yes" && this.patientSettings.medicationsForAntiplatelet.length !==0){
            this.antiPlatelet_section = true;
          }
          if(this.retrievedData.medicationsForAnticoagulationTherapy!==null && this.retrievedData.medicationsForAnticoagulationTherapy!==undefined&& this.retrievedData.medicationsForAnticoagulationTherapy!==""){
            this.patientSettings.medicationsForAnticoagulation = JSON.parse(this.retrievedData.medicationsForAnticoagulationTherapy)}
            if(this.patientSettings.wasPatientOnAnticoagulationTherapy == "Yes" && this.patientSettings.medicationsForAnticoagulation.length !==0){
              this.antiCoagulation_section = true;
            }

          if(this.retrievedData.surgicalHistory!==null && this.retrievedData.surgicalHistory!==undefined){
          this.patientSettings.pastSurgicalHistory = JSON.parse(this.retrievedData.surgicalHistory)}
          
          if(this.retrievedData.currentMedications!==null && this.retrievedData.currentMedications!==undefined){
            this.patientSettings.medications = JSON.parse(this.retrievedData.currentMedications)}

          if(this.retrievedData.allergies!==null && this.retrievedData.allergies!==undefined){
          this.patientSettings.allergies = JSON.parse(this.retrievedData.allergies)}


          if(this.retrievedData.familyHistory!==null && this.retrievedData.familyHistory!==undefined){
          this.patientSettings.familyHistory = JSON.parse(this.retrievedData.familyHistory)}


          if(this.retrievedData.socialHistory!==null && this.retrievedData.socialHistory!==undefined){
          this.patientSettings.socialHistory = JSON.parse(this.retrievedData.socialHistory)}

          if(this.retrievedData.tobacco!==null && this.retrievedData.tobacco!==undefined){
            this.patientSettings.tobaccoHistory = JSON.parse(this.retrievedData.tobacco)}

          if(this.retrievedData.drugs!==null && this.retrievedData.drugs!==undefined){
              this.patientSettings.drugsHistory = JSON.parse(this.retrievedData.drugs)}
          // }
          // else {
          //   this.patientSettings = {
          //     nurse: '', physician: '', historian: '', hospitalCampus: '',
          //     arrivalToEdDate: '', arrivalToEdTime: '', lastKnownNormalDate: '', lastKnownNormalTime: '',
          //     tpaEligibilityUnknown: false, familyAtBedside: '',
          //     bloodPressure: '', weight: '', oxygenSat: '',
          //     heartRate: '', temp: '', rr: '' , bloodGlucose: '', weightUnit: '' , tempUnit: '' , medicationsCustom: '',
          //     miInPrevious3Weeks: '', strokeInPrevious3Months: '', historyOfIch: '', tookCEPX: '', historyOfNeoplasm: '',
          //     isIntracranial: '',
          //     ReasonForConsult: '', symptoms: '', pastMedicalHistory: '', pastSurgicalHistory: '', medications: '',
          //     allergies: '', socialHistory: '', familyHistory: ''
          //   };
          // }
        }
      );
  }

  openErrorDialog() {
    const dialogRef = this.errorDialog.open(ErrorDialogComponent, {
      height: "72px",
      data: "Some error occurred, please try again.",
    });
  }

  openSuccessDialog() {
    const dialogRef = this.successDialog.open(SuccessDialogComponent, {
      height: "72px",
      data: "File uploaded successfully.",
    });
  }

  addEvent(event: MatDatepickerInputEvent<Date>) {
    this.focusOnDOB();
  }
}

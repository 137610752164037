import { Component, OnInit, ChangeDetectorRef , HostListener, OnDestroy} from '@angular/core';
import { OpentokService } from '../opentok.service';
import { HttpParams, HttpClient, HttpHeaders, HttpErrorResponse} from '@angular/common/http';
import * as OT from '@opentok/client';
import { AuthService } from '../services/auth.service';
import { CommonFunctionsService } from '../services/common-functions.service';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, NavigationEnd, ActivatedRoute} from '@angular/router';
import { PlatformLocation } from '@angular/common';
import { Location } from '@angular/common';
import {MatDialog} from '@angular/material';
import {DialogForEndcallComponent} from '../dialog-for-endcall/dialog-for-endcall.component';
import * as firebase from 'firebase/app';
import {MatSnackBar} from '@angular/material';

@Component({
  selector: 'app-video',
  templateUrl: './video.component.html',
  styleUrls: ['./video.component.css'],
  providers: [ OpentokService ]
})
export class VideoComponent implements OnInit, OnDestroy {
  session: OT.Session;
  streams: Array<OT.Stream> = [];
  changeDetectorRef: ChangeDetectorRef;
  video = {'token': '', 'sessionId': ''};
  callId;
  timerMin = 15;
  isCallInitiated;
  loggedInUser;
  getStatus;
  receivedCallIdFromQuery;
  constructor(private ref: ChangeDetectorRef, private opentokService: OpentokService,
    private http: HttpClient, public authService: AuthService, public commonFunctionsService: CommonFunctionsService,
    private router: Router,  private route: ActivatedRoute, public openDialogForEndCall: MatDialog,
    public dialogForEndCall: MatDialog, public snackBar: MatSnackBar) {
    // history.pushState(null, null, location.href);
    // window.onpopstate = function () {
    //     history.go(1);
    // };
    const url = window.location.search;
    const urlparam = url.split('&');
    const receivedCallId = (urlparam[0]).split('=');
    const initiated = (urlparam[1]).split('=');
    this.receivedCallIdFromQuery =  receivedCallId[1];
    this.isCallInitiated = initiated[1];
    this.getStatus = this.authService.getStatus();
    this.changeDetectorRef = ref;
    this.router.events
    .filter(event => (event instanceof NavigationEnd))
    .subscribe((routeData: any) => {
      if (routeData.urlAfterRedirects === '/') {
        this.session.disconnect();
      }
        if (routeData.urlAfterRedirects === '/stroke-code') {
          this.session.disconnect();
          // alert('stop');
        }
        if (routeData.urlAfterRedirects === '/create-patient') {
         this.session.disconnect();
        //  alert('stop');
      }
      if (routeData.urlAfterRedirects === '/intake-form') {
         this.session.disconnect();
        //  alert('stop');
        }
      if (routeData.urlAfterRedirects === '/search-patient') {
        this.session.disconnect();
        // alert('stop');
        }
      if (routeData.urlAfterRedirects === '/charts-records') {
        this.session.disconnect();
        // alert('stop');
        }
      if (routeData.urlAfterRedirects === '/nurse-profile') {
         this.session.disconnect();
            }
      });

      const callIdRef: firebase.database.Reference = firebase.database().
      ref('videoCallActions/stroke/' + this.receivedCallIdFromQuery + '/status');
      callIdRef.on('value', snapshot => {
        if (this.isCallInitiated === 'true') {
          if (snapshot.val() === 'endedByNurse') {
              window.close();
          }
        }
      });
  }

  ngOnInit () {
    // this.updateVideoToken(this.callId);
    this.authService.getUserFromstatechange().then((val) => this.loggedInUser = val).then(() =>
    this.checkISCallInitiated());
    this.getDoctorsVideoActions();
  }
  ngOnDestroy () {
    alert('destroyed');
  }
  @HostListener('window:beforeunload', ['$event'])
  onPopState(event) {
    if (this.getStatus._value !== 'fromEndCallButton' && this.getStatus._value !== 'callRejected'
    && this.getStatus._value !== 'callEnded') {
      var confirmationMessage = '\o/';
      (event || window.event).returnValue = confirmationMessage;
      return confirmationMessage;
    }
  }
  // @HostListener('window:unload', ['$event'])
  // onPopStateUnload(event) {
  //     const statusForEndCall: firebase.database.Reference =
  //     firebase.database().ref('intakeData/stroke/' + this.receivedCallIdFromQuery + '/videoCallDetails/');
  //     statusForEndCall.update({
  //      endCallStatus: true
  //     });
  //     this.authService.setStatus('tabClosed');
  //     return undefined;
  // }
  /*================== Start Session Video Call =============*/
  StartVideoSession() {
    debugger;
    return new Promise((resolve, reject) => {
        this.opentokService.initSession()
          .then((session: OT.Session) => {
            this.session = session;
            // alert("received session Details");
            console.log('came', session);
            this.video.token = session['token'];
            this.video.sessionId = session['sessionId'];
            localStorage.setItem("sessionID",this.video.sessionId);
            localStorage.setItem("token",this.video.token);
            console.log("videoToken",this.video.token);
            console.log("sessionId",this.video.sessionId);
          // alert(this.session["sessionId"]);
          // alert(this.session["token"]);
            this.session.on('streamCreated', (event) => {
              this.streams.push(event.stream);
              this.changeDetectorRef.detectChanges();
            });
            this.session.on('streamDestroyed', (event) => {
              const idx = this.streams.indexOf(event.stream);
              if (idx > -1) {
                this.streams.splice(idx, 1);
                this.changeDetectorRef.detectChanges();
              }
            });
            setTimeout(() => {
               // intakeData/stroke/callId/videoCallDetails/connectedBy
                let callIdRef = firebase.database().ref('intakesData/stroke/' + this.callId + '/videoCallDetails/connectedBy');
                callIdRef.on('value', snapshot => {
                    if (snapshot.val() === null) {
                      this.removeFromList(this.callId);
                    }
                });
            }, this.timerMin * 60 * 1000);
            resolve();
        })
        .then(() => this.opentokService.connect())
        .catch((err) => {
          console.error(err);
          alert('Unable to connect. Make sure you have updated the config.ts file with your OpenTok details.');

        });
    });
  }

  removeFromList(callId) : any {
    console.log('Call ' + callId + ' from timer');
    // let object = this.waitingCallsList.find(o => Object.entries(o).find(([k, value]) => k === '
    // callId' && value === callId) !== undefined);
    const params = new HttpParams()
      .set('callId', callId);
      const headers =  new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
      this.http.post(this.authService.baseURL + 'fifteenMinutesMissedCall', params, {headers: headers}).subscribe(
      data => {
        if (data['Message'] === 'Success') {
          console.log('This is response from timer : ', data);
          // const dialogRef = this.dialogForEndCall.open(DialogForEndcallComponent, {
          //   height: '125px',
          //   data: 'timeLimitExceed'
          // });
        }
      });
  }

  updateVideoToken(callId) {
    debugger;
    var firebaseFormDetails = firebase
    .database()
    .ref("intakesData/stroke/" + callId+'/videoCallDetails/');
  firebaseFormDetails.update({
    sessionId: this.video.sessionId,
      videoToken: this.video.token,
      time: firebase.database.ServerValue.TIMESTAMP
  });
    
    var rejectedByArr = [];
    const videoCallActionStatusRef: firebase.database.Reference = firebase.database().ref('/videoCallActions/stroke/' + callId);
    videoCallActionStatusRef.set({
      status: 'initiated',
      rejectedBy: JSON.stringify(rejectedByArr)
    });
    const videoCallActionRef: firebase.database.Reference = firebase.database().ref('/videoCallActions/stroke/' + callId + '/actions/');
    videoCallActionRef.push({
      action: 'initiated',
      fullName: localStorage.getItem('nurseFullName'),
      info: 'Call is initiated by Nurse',
      timestamp: firebase.database.ServerValue.TIMESTAMP,
      user: this.loggedInUser
    });
  }
  getVideoTokenDetails(callId) {
    return new Promise((resolve, reject) => {
      const params = new HttpParams()
      .set('callId', callId);
      const headers =  new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
      this.http.post(this.authService.baseURL + '/GetVideoTokenDetailsTest', params, {headers: headers}).subscribe(
      data => {
        if (data['Message'] === 'Success') {
          console.log('this is query params data', data);
          this.video.token = data['data']['videoToken'];
          this.video.sessionId = data['data']['videoSessionId'];
          firebase.database().ref('intakesData/stroke/' + callId + '/videoCallDetails').once('value', snapshot => {
            this.video.token = snapshot.val()['videoToken'];
            this.video.sessionId = snapshot.val()['sessionId'];
               // set received Patient Id to set Charts and records for Ongoing call
            this.commonFunctionsService.PatientId = data['data']['patientId'];
            this.getPatientInfo(this.commonFunctionsService.PatientId).then(() => this.ReceiveCallInitiatedByDoctor());
            resolve();
          });
          // set received Patient Id to set Charts and records for Ongoing call
          // this.commonFunctionsService.PatientId = data['data']['patientId'];
          // this.getPatientInfo(this.commonFunctionsService.PatientId).then(() => this.ReceivedCall());
          // resolve();
        }
      });
    });
  }

  startCallBackVideoSession() {
    // this.opentokService.joinSession(sid, token)
    // alert('Token' + this.video.token + 'Session' + this.video.sessionId);
    this.opentokService.joinSession(this.video.sessionId, this.video.token)
    .then((session: OT.Session) => {
        this.session = session;
        this.session.on('streamCreated', (event) => {
          this.streams.push(event.stream);
          this.changeDetectorRef.detectChanges();
        });
        this.session.on('streamDestroyed', (event) => {
          const idx = this.streams.indexOf(event.stream);
          if (idx > -1) {
            this.streams.splice(idx, 1);
            this.changeDetectorRef.detectChanges();
          }
        });
      })
      .then(() =>
        this.opentokService.connect())
      .catch((err) => {
        console.error(err);
        alert('Unable to connect. Make sure you have updated the config.ts file with your OpenTok details.');
      });
  }

  checkISCallInitiated() {
    const url = window.location.search;
    const urlparam = url.split('&');
    const receivedCallId = (urlparam[0]).split('=');
    const isInitiated = (urlparam[1]).split('=');
    this.callId =  receivedCallId[1];
    this.isCallInitiated = isInitiated[1];
    console.log('callId', this.callId);
    if (this.isCallInitiated === 'true') {

      this.StartVideoSession().then(res => this.updateVideoToken(this.callId));

    } else {
      this.getVideoTokenDetails(this.callId).then(res => this.startCallBackVideoSession());
    }
  }

  ReceivedCall() {
    const headers =  new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
    const params = new HttpParams().set('callId', this.callId)
    .set('userId', this.loggedInUser);
    this.http.post( this.authService.baseURL + '/ReceiveCall', params, {headers: headers}).subscribe(
    data => {
      console.log('receive call', data);
      if (data['Message'] === 'Success' ) {
        // alert('video Session Started');
      }
    });
  }

  ReceiveCallInitiatedByDoctor() {
    const headers =  new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
    const params = new HttpParams().set('callId', this.callId)
    .set('userId', this.loggedInUser);
    this.http.post( this.authService.baseURL + '/CallBackReceive', params, {headers: headers}).subscribe(
    data => {
      console.log('receive call', data);
      if (data['Message'] === 'Success' ) {
        // alert('video Session Started');
      }
    });
  }

  getPatientInfo(patientId) {
    return new Promise((resolve, reject) => {
      const headers =  new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
      const params = new HttpParams()
      .set('mrn', patientId );
      this.http.post(this.authService.baseURL + '/GetPatientInfo', params, {headers: headers}).subscribe(
      data => {
      console.log('get info', data);
      if (data['message'] === 'success') {
        this.commonFunctionsService.PatientName = data['data']['name'];
      }
      resolve();
      });
    });
  }
  // Notify user when doctor accepts or rejects call
  getDoctorsVideoActions() {
    const url = window.location.search;
    const urlparam = url.split('&');
    const receivedCallId = (urlparam[0]).split('=');
    const isInitiated = (urlparam[1]).split('=');
    this.callId =  receivedCallId[1];
    const callIdRef: firebase.database.Reference = firebase.database().ref('videoCallActions/stroke/' + this.callId + '/actions/');
    callIdRef.on('child_added', snapshot => {
        if (snapshot.val()['action'] === 'accepted') {
          // this.openSnackBar('Call is received by ' + snapshot.val()['doctorName']);
          // this.authService.setDoctorNameForAction(snapshot.val()['doctorName']);
          this.authService.doctorName = snapshot.val()['user'];
          const dialogRef = this.dialogForEndCall.open(DialogForEndcallComponent, {
            height: '125px',
            data: 'callAccepted'
          });
        } else {
          if (snapshot.val()['action'] === 'rejected') {
            // this.openSnackBar('Call is rejected by ' + snapshot.val()['doctorName']);
            // this.authService.setDoctorNameForAction(snapshot.val()['doctorName']);
            this.authService.doctorName = snapshot.val()['user'];
            const dialogRef = this.dialogForEndCall.open(DialogForEndcallComponent, {
              height: '135px',
              data: 'callRejected'
            });
          } else {
            if (snapshot.val()['action'] === 'endedByDoctor') {
              // this.openSnackBar('Call is rejected by ' + snapshot.val()['doctorName']);
              // this.authService.setDoctorNameForAction(snapshot.val()['doctorName']);
              this.authService.doctorName = snapshot.val()['user'];
              const dialogRef = this.dialogForEndCall.open(DialogForEndcallComponent, {
                height: '125px',
                data: 'callEnded'
              });
            }
          }
        }
    });
  }
  openSnackBar(message: string) {
    this.snackBar.open(message, '', {
      duration: 15000,
      panelClass: ['blue-snackbar']
    });
  }
}

/*Main imports*/
import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { AngularFireModule } from "angularfire2";
import { AngularFireDatabaseModule } from "angularfire2/database";
import { AngularFireAuthModule } from "angularfire2/auth";
import { environment } from "../environments/environment";
import { HttpClientModule } from "@angular/common/http";
import { HttpModule } from "@angular/http";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { LoadingBarModule, LoadingBarService } from "@ngx-loading-bar/core";
import { MatTooltipModule } from "@angular/material/tooltip";
import { NgxPasswordToggleModule } from "ngx-password-toggle";

/*Services*/
import { AuthService } from "./services/auth.service";
import { MessagingService } from "./messaging.service";
import { SigninGuard } from "./services/signin.guard";
import { IntakeDeactivateGuard } from "./services/intake-deactivate.guard";
import { ActivateSearchGuard } from "./services/activate-search.guard";

/*Angular material components*/
import { MatTabsModule } from "@angular/material/tabs";
import { MatButtonModule } from "@angular/material/button";
import { MatRadioModule } from "@angular/material/radio";
import { MatFormFieldModule, MatInputModule } from "@angular/material";
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatMenuModule } from "@angular/material/menu";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatSelectModule } from "@angular/material/select";
import { MatIconModule } from "@angular/material/icon";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatDialogModule } from "@angular/material/dialog";
import { MatListModule } from "@angular/material/list";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatNativeDateModule } from "@angular/material";
import { MatMomentDateModule } from "@angular/material-moment-adapter";

/*Components*/
import { AppComponent } from "./app.component";
import { HeaderComponent } from "./header/header.component";
import { SignUpComponent } from "./sign-up/sign-up.component";
import { AppRoutingModule } from ".//app-routing.module";
import { InitiateStrokeCodeComponent } from "./initiate-stroke-code/initiate-stroke-code.component";
import { NewPatientComponent } from "./new-patient/new-patient.component";
import { SidebarUIComponent } from "./sidebar-ui/sidebar-ui.component";
import { SearchPatientComponent } from "./search-patient/search-patient.component";
import { VideoComponent } from "./video/video.component";
import { PublisherComponent } from "./publisher/publisher.component";
import { SubscriberComponent } from "./subscriber/subscriber.component";
import { DicomComponent } from "./dicom/dicom.component";
import { NursePatientRecordsComponent } from "./nurse-patient-records/nurse-patient-records.component";
import { ChartsRecordExpansionComponent } from "./charts-record-expansion/charts-record-expansion.component";
import { ErrorDialogComponent } from "./error-dialog/error-dialog.component";
import { SuccessDialogComponent } from "./success-dialog/success-dialog.component";
import { ForgotPasswordComponent } from "./forgot-password/forgot-password.component";
import { ResetPasswordComponent } from "./reset-password/reset-password.component";
import { NurseProfileComponent } from "./nurse-profile/nurse-profile.component";
import { DialogForProfileComponent } from "./dialog-for-profile/dialog-for-profile.component";
import { TelemedicineConsultComponent } from "./telemedicine-consult/telemedicine-consult.component";
import { DialogForEndcallComponent } from "./dialog-for-endcall/dialog-for-endcall.component";
import { DirectcallComponent } from "./directcall/directcall.component";
import { EditintakeDialogComponent } from "./editintake-dialog/editintake-dialog.component";

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    SignUpComponent,
    InitiateStrokeCodeComponent,
    NewPatientComponent,
    SidebarUIComponent,
    SearchPatientComponent,
    VideoComponent,
    PublisherComponent,
    SubscriberComponent,
    DicomComponent,
    NursePatientRecordsComponent,
    ChartsRecordExpansionComponent,
    ErrorDialogComponent,
    SuccessDialogComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    NurseProfileComponent,
    DialogForProfileComponent,
    TelemedicineConsultComponent,
    DialogForEndcallComponent,
    DirectcallComponent,
    EditintakeDialogComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    MatTabsModule,
    MatButtonModule,
    MatRadioModule,
    MatFormFieldModule,
    MatInputModule,
    MatToolbarModule,
    FormsModule,
    ReactiveFormsModule,
    MatMenuModule,
    MatAutocompleteModule,
    MatExpansionModule,
    MatSelectModule,
    MatIconModule,
    AngularFireModule,
    AngularFireDatabaseModule,
    AngularFireAuthModule,
    AngularFireModule.initializeApp(environment.firebase),
    HttpModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    MatCheckboxModule,
    MatDialogModule,
    MatListModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatMomentDateModule,
    LoadingBarModule,
    MatProgressBarModule,
    NgxPasswordToggleModule,
    MatSnackBarModule,
    MatTooltipModule,
  ],
  providers: [
    AuthService,
    MessagingService,
    SigninGuard,
    IntakeDeactivateGuard,
    ActivateSearchGuard,
    LoadingBarService,
  ],
  bootstrap: [AppComponent],
  entryComponents: [
    DicomComponent,
    ErrorDialogComponent,
    SuccessDialogComponent,
    DialogForProfileComponent,
    DialogForEndcallComponent,
    EditintakeDialogComponent,
  ],
})
export class AppModule {}

import { Injectable , ChangeDetectorRef} from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, NavigationEnd, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { CanDeactivate } from '@angular/router';
import {AuthService} from '../services/auth.service';
import { AngularFireAuth } from 'angularfire2/auth';
import * as firebase from 'firebase/app';

export interface CanComponentDeactivate {
  canDeactivate: () => Observable<boolean> | Promise<boolean> | boolean;
 }
@Injectable({
  providedIn: 'root'
})
export class IntakeDeactivateGuard implements CanDeactivate<CanComponentDeactivate> {
  confirmationStatus;
  callIdReceivedFromQuery;
  constructor (private router: Router, private authService: AuthService, private _firebaseAuth: AngularFireAuth) {
    this.confirmationStatus = this.authService.getFalseToDeactivate();
  }
  // Deactive guard and handle Navigation Scenarios
  canDeactivate(component: CanComponentDeactivate) {
   if (this.confirmationStatus._value === 'true') {
      return true;
   } else {
    if (this.confirmationStatus._value === 'forStrokeCode') {
    this.authService.setFalseToDeactivate('true');
    // var res = confirm('Do you want to save changes and end the current session?');
    var res = confirm('Do you want to end the current session?');
    if (res === true) {
      const url = window.location.search;
      const urlparam = url.split('&');
      const receivedCallId = (urlparam[0]).split('=');
      this.callIdReceivedFromQuery =  receivedCallId[1];
      this.authService.EndCallFromCanDeactive(this.callIdReceivedFromQuery);
      // this.authService.setToggle(true);
      return true;
    } else {
      return false;
    }
  } else if (this.confirmationStatus._value === 'forCreatePatient') {
    this.authService.setFalseToDeactivate('true');
    const res1 = confirm('Do you want to end the current session?');
    if (res1 === true) {
      const url = window.location.search;
      const urlparam = url.split('&');
      const receivedCallId = (urlparam[0]).split('=');
      this.callIdReceivedFromQuery =  receivedCallId[1];
      this.authService.EndCallFromCanDeactive(this.callIdReceivedFromQuery);
      const callEndRef: firebase.database.Reference = firebase.database().
      ref('videoCallActions/stroke/' + this.callIdReceivedFromQuery + '/status');
      callEndRef.once('value', snapshot => {
          console.log('Snapshot value is :: -->' + snapshot.val());
          if (snapshot.val() === 'endedByNurse') {
            this.router.navigate(['/stroke-code']);
          } else {
            this.router.navigate(['/create-patient'], { queryParams: { callId: this.callIdReceivedFromQuery , initiated: true } });
          }
      });
      this.authService.setToggle(false);
      return true;
    } else {
      return false;
    }

  } else if (this.confirmationStatus._value === 'forSearchPatient') {
    this.authService.setFalseToDeactivate('true');
    const res2 = confirm('Do you want to end the current session?');
    if (res2 === true) {
      const url = window.location.search;
      const urlparam = url.split('&');
      const receivedCallId = (urlparam[0]).split('=');
      this.callIdReceivedFromQuery =  receivedCallId[1];
      this.authService.EndCallFromCanDeactive(this.callIdReceivedFromQuery);
      // this.authService.setToggle(false);
      return true;
    } else {
      return false;
    }
  } else {
    if (this.confirmationStatus._value === 'forRecordsAndProfile') {
      this.authService.setFalseToDeactivate('true');
      var res = confirm('Do you want to end the current session?');
      if (res === true) {
      const url = window.location.search;
      const urlparam = url.split('&');
      const receivedCallId = (urlparam[0]).split('=');
      this.callIdReceivedFromQuery =  receivedCallId[1];
      this.authService.EndCallFromCanDeactive(this.callIdReceivedFromQuery);
      const callEndRef: firebase.database.Reference = firebase.database().
        ref('videoCallActions/stroke/' + this.callIdReceivedFromQuery + '/status');
        callEndRef.once('value', snapshot => {
            if (snapshot.val() === 'endedByNurse') {
              this.router.navigate(['/stroke-code']);
            } else {
              this.router.navigate(['/charts-records'], { queryParams: { callId: this.callIdReceivedFromQuery , initiated: true } });
            }
        });

      /// this.authService.setToggle(false);
        return true;
      } else {
        return false;
      }
    } else if (this.confirmationStatus._value === 'forRecordsAndProfileheader') {
      this.authService.setFalseToDeactivate('true');
      // var res = confirm('Do you want to save changes and leave the page?');
      var res = confirm('Do you want to leave the page?');
      const url = window.location.search;
      const urlparam = url.split('&');
      const receivedCallId = (urlparam[0]).split('=');
      this.callIdReceivedFromQuery =  receivedCallId[1];
      if (res === true) {
      // this.authService.EndCallFromCanDeactive(this.callIdReceivedFromQuery);
      this.router.navigate(['/charts-records'], { queryParams: { callId: this.callIdReceivedFromQuery , initiated: true } });
        // this.authService.setToggle(true);
        return true;
      } else {
        this.router.navigate(['/charts-records'], { queryParams: { callId: this.callIdReceivedFromQuery , initiated: true } });
        // this.authService.setToggle(false);
        return true;
      }
    } else if (this.confirmationStatus._value === 'forProfile') {
      this.authService.setFalseToDeactivate('true');
      var res = confirm('Do you want to end the current session?');
      if (res === true) {
      const url = window.location.search;
      const urlparam = url.split('&');
      const receivedCallId = (urlparam[0]).split('=');
      this.callIdReceivedFromQuery =  receivedCallId[1];
      this.authService.EndCallFromCanDeactive(this.callIdReceivedFromQuery);
      const callEndRef: firebase.database.Reference = firebase.database().
        ref('videoCallActions/stroke/' + this.callIdReceivedFromQuery + '/status');
        callEndRef.once('value', snapshot => {
            if (snapshot.val() === 'endedByNurse') {
              this.router.navigate(['/stroke-code']);
            } else {
              this.router.navigate(['/nurse-profile'], { queryParams: { callId: this.callIdReceivedFromQuery , initiated: true } });
            }
        });
        // this.authService.setToggle(false);
        return true;
      } else {
        return false;
      }
    } else if (this.confirmationStatus._value === 'forMenuProfile') {
      this.authService.setFalseToDeactivate('true');
      // var res = confirm('Do you want to save changes and leave the page?');
      var res = confirm('Do you want to leave the page?');
      const url = window.location.search;
      const urlparam = url.split('&');
      const receivedCallId = (urlparam[0]).split('=');
      this.callIdReceivedFromQuery =  receivedCallId[1];
      if (res === true) {
      this.router.navigate(['/nurse-profile'], { queryParams: { callId: this.callIdReceivedFromQuery , initiated: true } });
        // this.authService.setToggle(true);
        return true;
      } else {
        this.router.navigate(['/nurse-profile'], { queryParams: { callId: this.callIdReceivedFromQuery , initiated: true } });
        return true;
      }
    } else if (this.confirmationStatus._value === 'forInitiateStrokeBtn') {
      this.authService.setFalseToDeactivate('true');
      var res = confirm('Do you want to end the current session?');
      if (res === true) {
      const url = window.location.search;
      const urlparam = url.split('&');
      const receivedCallId = (urlparam[0]).split('=');
      this.callIdReceivedFromQuery =  receivedCallId[1];
      this.authService.EndCallFromCanDeactive(this.callIdReceivedFromQuery);
      const callEndRef: firebase.database.Reference = firebase.database().
        ref('videoCallActions/stroke/' + this.callIdReceivedFromQuery + '/status');
        this.router.navigate(['/stroke-code']);
        // this.authService.setToggle(false);
        return true;
      } else {
        return false;
      } // Logout click functionality
    } else if (this.confirmationStatus._value === 'fromAccordianLogout') {
      this.authService.setFalseToDeactivate('true');
      // const logoutRes = confirm('Do you want to save changes and end the current session?');
      const logoutRes = confirm('Do you want to end the current session?');
      if (logoutRes === true) {
      const url = window.location.search;
      const urlparam = url.split('&');
      const receivedCallId = (urlparam[0]).split('=');
      this.callIdReceivedFromQuery =  receivedCallId[1];
      this.authService.EndCallFromCanDeactive(this.callIdReceivedFromQuery);
      const callEndRef: firebase.database.Reference = firebase.database().
      ref('videoCallActions/stroke/' + this.callIdReceivedFromQuery + '/status');
        // this.router.navigate(['/']);

        this._firebaseAuth.auth.signOut().then(res => {
           setTimeout(() => { this.router.navigate(['/']); } , 1000);
         });

      // this.authService.setToggle(true);
        return true;
      } else {
        return false;
      }
    } else if (this.confirmationStatus._value === 'fromOtherLogout') {
      this.authService.setFalseToDeactivate('true');
      const logoutRes = confirm('Do you want to end the current session?');
      if (logoutRes === true) {
      const url = window.location.search;
      const urlparam = url.split('&');
      const receivedCallId = (urlparam[0]).split('=');
      this.callIdReceivedFromQuery =  receivedCallId[1];
      this.authService.EndCallFromCanDeactive(this.callIdReceivedFromQuery);
      const callEndRef: firebase.database.Reference = firebase.database().
        ref('videoCallActions/stroke/' + this.callIdReceivedFromQuery + '/status');
        // this.router.navigate(['/']);

        this._firebaseAuth.auth.signOut().then(res => {
           setTimeout(() => { this.router.navigate(['/']); } , 1000);
         });

      // this.authService.setToggle(false);
        return true;
      } else {
        return false;
      }
    }
  }
   }
  }
}

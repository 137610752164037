import { Http } from '@angular/http';
import { HttpEventType, HttpRequest, HttpHeaders, HttpParams , HttpClient} from '@angular/common/http';
import { AuthService } from '../services/auth.service';
import { FormControl, Validators, NgForm, FormGroupDirective } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';
import {MatDialog} from '@angular/material';
import { Inject } from '@angular/core';
import { AngularFireAuth } from 'angularfire2/auth';
import * as firebase from 'firebase/app';
// import {FormControl} from '@angular/forms';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE, ErrorStateMatcher} from '@angular/material/core';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, ActivatedRoute , Router} from '@angular/router';

export const MY_FORMATS = {
  parse: {
    dateInput: 'MM/DD/YYYY',
    // dateInput: {month: 'short', year: 'numeric', day: 'numeric'},
},
display: {
    dateInput: 'MM/DD/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
},
};
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}


@Component({
  selector: 'app-editintake-dialog',
  templateUrl: './editintake-dialog.component.html',
  styleUrls: ['./editintake-dialog.component.css'],
  providers: [
    {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},
    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
  ]
})
export class EditintakeDialogComponent implements OnInit {

  patientName;
  gender;
  patientDOB;
  callId;
  user;
  mrn;
  today = new Date();
  date;
  month;
  year;
  hospital_id;
  loggedInUser;

  matcher = new MyErrorStateMatcher();
  public editPatientData = {patientName: '', gender: 'Male', dob: '', mrn: '', callId: '', user: ''};
  patientNameFormControl = new FormControl('', [
   Validators.required
 ]);
 dobFormControl = new FormControl('', [
   Validators.required
 ]);
 mrnFormControl = new FormControl('', [
   Validators.required
 ]);
 genderOptionsformControl = new FormControl('', [
   Validators.required
 ]);

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public closedialog: MatDialog,
  public dialogRef: MatDialogRef<EditintakeDialogComponent>, private _firebaseAuth: AngularFireAuth, private router: Router, 
  private authService: AuthService, private http: HttpClient) {
    this.authService.getUserFromstatechange().then((val) => this.loggedInUser = val).then(() => this.getHospitalId());
    this.editPatientData.patientName = data.patientName;
    this.editPatientData.gender = data.gender;
    this.editPatientData.callId = data.callId;
    this.editPatientData.user = data.user;
    this.editPatientData.mrn = data.mrn;
    this.patientDOB = new FormControl(new Date(data.patientDOB));
  }

  ngOnInit() {
  }

  // Save edited patient info
  getHospitalId() {
    firebase.database().ref('hospitalCampus/' + this.loggedInUser.toLowerCase() + '/campus/hospitalid/')
    .once('value', snapshot => {
        console.log('hospital Id', snapshot.val());
        this.hospital_id = snapshot.val();
    });
  }

  saveEditedinfo() {

    console.log('Pateint DOB is --> ' + this.patientDOB.value);

    const d = new Date(this.patientDOB.value);
    this.date = d.getDate();
    this.month = d.getMonth() + 1;
    this.year = d.getFullYear();
    //  console.log(String(this.date).length);
      if (String(this.date).length === 1) {
          this.date = '0' + this.date;
      }
      if (String(this.month).length === 1) {
        this.month = '0' + this.month;
      }
     // console.log(this.date);
      const completeDate = this.month + '/' + this.date + '/' + this.year;
     // console.log('Complete date is :---> ' + completeDate);

    const headers =  new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
    const param = new HttpParams().set('mrn', this.editPatientData.mrn)
    .set('name', this.editPatientData.patientName)
    .set('gender', this.editPatientData.gender)
    .set('dob', completeDate)
    .set('callId', this.editPatientData.callId)
    .set('user', this.editPatientData.user)
    .set('hospital_id', this.hospital_id);

    this.http.post(this.authService.baseURL + '/Editpatientdata', param, {headers: headers}).subscribe(
      response => {
        if (response['Message'] === 'success') {
          // Call set data to Firebase function
          this.setPatientInfoToFB();
          this.closeDialog();
        } else {
          this.closeDialog();
        }
      },
      err => {
        console.log(err);
        this.closeDialog();
      });
  }

   // Set data to Firebase function

   setPatientInfoToFB() {

    const d = new Date(this.patientDOB.value);
    this.date = d.getDate();
    this.month = d.getMonth() + 1;
    this.year = d.getFullYear();
      // console.log(String(this.date).length);
      if (String(this.date).length === 1) {
          this.date = '0' + this.date;
      }
      if (String(this.month).length === 1) {
        this.month = '0' + this.month;
      }
      // console.log(this.date);
      const completeDate = this.month + '/' + this.date + '/' + this.year;

    // tslint:disable-next-line:max-line-length
    const hospitalCampusRef: firebase.database.Reference = firebase.database().ref('patientInfo/stroke/' + this.editPatientData.callId);
    hospitalCampusRef.update({
      'dob': completeDate,
      'gender': this.editPatientData.gender,
      'name': this.editPatientData.patientName
    });
  }

 // ------------------ Function to close popup ------------------- //
  closeDialog() {
    this.dialogRef.close();
  }

  // valdidateFunction(event) {

  //   const fullDate = new Date(this.patientDOB);
  //   const fullDay = fullDate.getDate();
  //   const fullMonth = fullDate.getMonth();
  //   const fullYear = fullDate.getFullYear();
  //   const currentDate = new Date();
  //   const currentYear = currentDate.getFullYear();
  //   console.log('date fullyear', fullYear);
  //   if (fullYear.toString().length === 2 || fullYear.toString().length === 4 ) {
  //     if (fullYear > currentYear) {
  //       const newYear = fullYear - 100;
  //       this.patientDOB = (new Date(newYear, fullMonth, fullDay)).toISOString();
  //     }
  //   } else {
  //     this.patientDOB = '';
  //   }
  // }


}

import { Component, OnInit } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, ActivatedRoute , Router} from '@angular/router';
import { AngularFireAuth } from 'angularfire2/auth';
import * as firebase from 'firebase/app';
import { CommonFunctionsService } from '../services/common-functions.service';
import { HttpHeaders, HttpParams, HttpClient } from '@angular/common/http';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { ErrorDialogComponent } from '../error-dialog/error-dialog.component';
import {MatDialog} from '@angular/material';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  nurseFullName = '';
  public loggedInUser;
  showStrokeCodeBtn: Boolean = false;
  showActiveVideoCall: Boolean = false;
  showChartsRecords: Boolean = true;
  showStrokeCode: Boolean = false;
  showProgressBarForStroke: Boolean = false;
  showProgressBarForRegister: Boolean = false;
  showProgressBarForIntake: Boolean = false;
  showProgressBarForVideo: Boolean = false;
  hideDropdownForVideo: Boolean = true;
  receivedCallIdFromQuery;
  patientInfo = {name : '' , dob : '' , gender : '' , mrn: '' , age : 0 };
  receivedMonth;
  receivedDate;
  receivedYear;
  callId;
  activeCallPatientName = 'Not updated';
  logoForVideo: Boolean = false;
  constructor(private authService: AuthService, private route: ActivatedRoute, private router: Router,
     private _firebaseAuth: AngularFireAuth , public commonFunctionsService: CommonFunctionsService, private http: HttpClient ,
     private loader: LoadingBarService , public errorDialog: MatDialog, public openDialogForEndCall: MatDialog) {
    // function to get Current Logged In User
    this.nurseFullName = localStorage.getItem('nurseFullName');
    this.authService.getUserFromstatechange().then((val) => this.loggedInUser = val);
    if (window.location.search !== '') {
      const url = window.location.search;
      const urlparam = url.split('&');
      const receivedCallId = (urlparam[0]).split('=');
      const isInitiated = (urlparam[1]).split('=');
      this.callId =  receivedCallId[1];
      const callIdRef: firebase.database.Reference = firebase.database().ref('patientInfo/stroke/');
      callIdRef.on('value', snapshot => {
        if (snapshot.val()[this.callId]) {
          this.activeCallPatientName = snapshot.val()[this.callId]['name'];
        }
      });
    }
   }
  ngOnInit() {
    this.headerContent();
    localStorage.setItem('nurseFullName', this.nurseFullName);
  }
  // Logout functionality handle from different tabs
  logout() {
    const constPath = this.route.snapshot.url[0].path.toString();
    if ( constPath === 'intake-form' ) {
      this.authService.setFalseToDeactivate('fromAccordianLogout');
      // this.authService.setStatus('fromLogout');
      // this.router.navigate(['/']);
      this.authService.setFalseToDeactivate('fromOtherLogout');
      this.authService.setStatus('fromLogout');
      this.router.navigate(['/']);
    } else if (constPath === 'create-patient' || constPath === 'search-patient') {
      this.authService.setFalseToDeactivate('fromOtherLogout');
      this.authService.setStatus('fromLogout');
      this.router.navigate(['/']);
    } else if (constPath === 'charts-records' || constPath === 'stroke-code' || constPath === 'nurse-profile') {
      const url = window.location.search;
        if (url === '') {
          this.authService.setStatus('');
          this._firebaseAuth.auth.signOut().then(res => {
            setTimeout(() => { this.router.navigate(['/']); } , 1000);
          });
        } else {
        this.authService.setFalseToDeactivate('fromOtherLogout');
        this.authService.setStatus('fromLogout');
        this.router.navigate(['/']);
        }
    } else {
      this.authService.setStatus('');
      this._firebaseAuth.auth.signOut().then(res => {
        setTimeout(() => { this.router.navigate(['/']); } , 1000);
      });
    }
  }
  headerContent() {
    if (this.route.snapshot.url[0].path.toString() === 'charts-records') {
      this.showStrokeCodeBtn = true;
    }
    if (this.route.snapshot.url[0].path.toString() === 'video') {
      this.showActiveVideoCall = false;
      this.showChartsRecords = false;
      this.showProgressBarForVideo = true;
      this.hideDropdownForVideo = false;
    }
    if (this.route.snapshot.url[0].path.toString() === 'stroke-code') {
      this.showStrokeCode = false;
      this.showProgressBarForStroke = true;
    }
    if (this.route.snapshot.url[0].path.toString() === 'nurse-profile') {
      this.showChartsRecords = true;
      this.showStrokeCode = false;
      this.showActiveVideoCall = false;
    }
    if (this.route.snapshot.url[0].path.toString() === 'intake-form') {
      this.showProgressBarForIntake = true;
    }
    if (this.route.snapshot.url[0].path.toString() === 'create-patient') {
      this.showProgressBarForRegister = true;
      this.showStrokeCode = true;
    }
    if (this.route.snapshot.url[0].path.toString() === 'search-patient') {
      this.showStrokeCode = true;
      this.showProgressBarForRegister = true;
    }
    if (this.route.snapshot.url[0].path.toString() === 'emergency-call') {
      this.showActiveVideoCall = false;
      this.showChartsRecords = false;
      this.hideDropdownForVideo = false;
    }
    if (this.route.snapshot.url[0].path.toString() === 'video') {
        this.logoForVideo = true;
    }
    if (this.route.snapshot.url[0].path.toString() === 'emergency-call') {
      this.logoForVideo = true;
    }
  }

  navigateToinitiateStrokCode() {
    const param1 = window.location.search;
    if (param1 === '') {
      this.router.navigate(['/stroke-code']);
    } else {
      this.authService.setFalseToDeactivate('forInitiateStrokeBtn');
      this.router.navigate(['/stroke-code']);
    }

  }

  openErrorDialog() {
    const dialogRef = this.errorDialog.open(ErrorDialogComponent , {
      height: '72px',
      data : 'Some error occurred, please try again.'
    });
  }

  // When you click on logo from different pages this function is called

  navigateToStrokeCode() {
    if (this.route.snapshot.url[0].path.toString() === 'intake-form') {
      this.authService.setFalseToDeactivate('forStrokeCode');
      this.router.navigate(['/stroke-code']);
    } else if (this.route.snapshot.url[0].path.toString() === 'create-patient') {
      this.authService.setFalseToDeactivate('forCreatePatient');
      this.router.navigate(['/stroke-code']);
    } else if (this.route.snapshot.url[0].path.toString() === 'search-patient') {
      this.authService.setFalseToDeactivate('forSearchPatient');
      this.router.navigate(['/stroke-code']);
    } else if (this.route.snapshot.url[0].path.toString() === 'charts-records') {
      const param1 = window.location.search;
      if (param1 === '') {
        this.router.navigate(['/stroke-code']);
      } else {
        this.authService.setFalseToDeactivate('forRecordsAndProfile');
        this.router.navigate(['/stroke-code']);
      }
    } else if (this.route.snapshot.url[0].path.toString() === 'nurse-profile') {
      const param2 = window.location.search;
      if (param2 === '') {
        this.router.navigate(['/stroke-code']);
      } else {
        this.authService.setFalseToDeactivate('forProfile');
        this.router.navigate(['/stroke-code']);
        // alert('param present');
      }
    } else {
      if (this.route.snapshot.url[0].path.toString() !== 'video') {
      this.router.navigate(['/stroke-code']);
      }
    }
  }

  getPatientInfoByFirebase(callId) {
    const callIdRef: firebase.database.Reference = firebase.database().ref('patientInfo/stroke/' + callId);
    callIdRef.on('value', snapshot => {
      this.patientInfo.mrn = snapshot.val()['PatientId'];
      this.patientInfo.name = snapshot.val()['name'];
      this.patientInfo.gender = snapshot.val()['gender'];
      this.patientInfo.dob = snapshot.val()['dob'];
      var receivedDateDetails = this.patientInfo.dob.split('/');
      this.receivedMonth = receivedDateDetails[0];
      this.receivedDate = receivedDateDetails[1];
      this.receivedYear = receivedDateDetails[2];
      this.patientInfo.age = this.CalculateAge(this.receivedDate, this.receivedMonth, this.receivedYear);
    });
  }

  CalculateAge(receivedDate, receivedMonth, receivedYear) {
    var yearThen = receivedYear;
    var monthThen = receivedMonth;
    var dayThen = receivedDate;
    var today = new Date();
    var birthday = new Date(yearThen, monthThen - 1, dayThen);
    var differenceInMilisecond = today.valueOf() - birthday.valueOf();
    var year_age = Math.floor(differenceInMilisecond / 31536000000);
    var day_age = Math.floor((differenceInMilisecond % 31536000000) / 86400000);
    return year_age;
  }

  // When you click on charts & Records from header
  navigateToChartsRecords() {
    if (this.route.snapshot.url[0].path.toString() === 'intake-form') {
      // this.authService.setFalseToDeactivate('forRecordsAndProfileheader');
      // this.router.navigate(['/charts-records']);
      this.router.navigate(['/charts-records'], { queryParams: { callId: this.callId , initiated: true }});
    } else {
      if (window.location.search !== '') {
        this.router.navigate(['/charts-records'], { queryParams: { callId: this.callId , initiated: true }});
      } else {
        this.router.navigate(['/charts-records']);
      }

    }
  }

  // When you click on Profile from header
  navigateToProfile() {
    if (this.route.snapshot.url[0].path.toString() === 'intake-form') {
      // this.authService.setFalseToDeactivate('forMenuProfile');
      // this.router.navigate(['/nurse-profile']);
      this.router.navigate(['/nurse-profile'], { queryParams: { callId: this.callId , initiated: true }});

    } else {
      if (window.location.search !== '') {
        this.router.navigate(['/nurse-profile'], { queryParams: { callId: this.callId , initiated: true }});
      } else {
        this.router.navigate(['/nurse-profile']);
      }
    }
  }
}

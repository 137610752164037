import { Component, OnInit } from '@angular/core';
import { HttpParams, HttpClient, HttpHeaders, HttpErrorResponse} from '@angular/common/http';
import { AuthService } from '../services/auth.service';
import { CommonFunctionsService } from '../services/common-functions.service';
import { DicomComponent } from '../dicom/dicom.component';
import {MatDialog} from '@angular/material';
import { SuccessDialogComponent } from '../success-dialog/success-dialog.component';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { ErrorDialogComponent } from '../error-dialog/error-dialog.component';
import {MatExpansionPanel} from '@angular/material/expansion';
import * as firebase from 'firebase/app';
import { AngularFireDatabase } from '@angular/fire/database';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-charts-record-expansion',
  templateUrl: './charts-record-expansion.component.html',
  styleUrls: ['./charts-record-expansion.component.css']
})
export class ChartsRecordExpansionComponent implements OnInit {
  public receivedPreviousRecord;
  public receivedPreviousFiles: any[] = [];
  public receivedPreviousConsultNotes;
  patientId;
  PatientName;
  files: FileList;
  private reader = new FileReader();
  showConsultNoteList = true;
  showConsultNoteDetails = false;
  retrievedData;
  public availableDoctors: any[];
  doctorAvailability: Boolean = true;
  panelOpenState: Boolean = false;
  items: Observable<any[]>;
  /*consultNoteData = {
    assessment: '', comments: '', complaint: '', doctor_id: '', isComplete: '',
    labs: '', patient: '', physical_exam: '', presenting_illness: '', requested_call: '' ,
    speciality_id: '', timestamp: ''
  };*/
  // new
  public consultNotes = {chiefComplaint: 'a', historyOfIllness: '', reviewOfSystems: '', vitalSigns: '', giveTPA: '',
    notes: '', doNotgiveTPA: '', physicalExam: '',
    nihss: '', imaging: '', assessment: '', recommendations: '', userId: '', doctorId: '', patient: ''
    , speciality_id: '', isComplete: '', requested_call: '', timestamp: ''};

  patientSettings = {
    nurse: '', physician: '', historian: '', hospitalCampus: '',
    arrivalToEdDate: '', arrivalToEdTime: '', lastKnownNormalDate: '', lastKnownNormalTime: '',
    tpaEligibilityUnknown: '', familyAtBedside: '',
    bloodPressure: '', weight: '', oxygenSat: '',
    heartRate: '', temp: '', medicationsCustom: '',
    miInPrevious3Weeks: '', strokeInPrevious3Months: '', historyOfIch: '', tookCEPX: '', historyOfNeoplasm: '',
    isIntracranial: '',
    ReasonForConsult: '', symptoms: '', pastMedicalHistory: '', pastSurgicalHistory: '', medications: '',
    allergies: '', socialHistory: '', familyHistory: ''
  };

  showLoader = false;

  constructor(private http: HttpClient, public authService: AuthService, public commonFunctionsService: CommonFunctionsService,
    public dialog: MatDialog , public loader: LoadingBarService , public errorDialog: MatDialog , public successDialog: MatDialog,
    public db: AngularFireDatabase) { }

  ngOnInit() {
      this.getAvailableDoctors();
      this.receivedPreviousFiles = [];
      this.patientId = this.commonFunctionsService.PatientId;
      this.PatientName = this.commonFunctionsService.PatientName;
      const headers =  new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
      console.log(this.patientId);
      const param = new HttpParams().set('patientId', this.patientId);

      /*=============== Get Previous History ======================*/
      this.http.post(this.authService.baseURL + '/GetPreviousRecords', param, {headers: headers}).subscribe(
      response => {
        
        if (response['Message'] === 'success') {
          this.receivedPreviousRecord = response['data'];
  
        }
      },
      err => {

        console.log(err);

      });

      /*=============== Get Previous Files ======================*/

      this.http.post(this.authService.baseURL + '/GetPreviousFiles', param, {headers: headers}).subscribe(
      response => {
        if (response['Message'] === 'success') {

          this.receivedPreviousFiles = response['data'];
        }
      },
      err => {

        console.log(err);

      });

      /*=============== Get Previous Consult Notes ======================*/

      this.http.post(this.authService.baseURL + '/GetConsultNotesForHospital', param, {headers: headers}).subscribe(
        response => {

          if (response['Message'] === 'success') {
            this.receivedPreviousConsultNotes = response['data'];
            // this.doctorFullName = this.receivedPreviousConsultNotes[0]["fullName"];
          }
        },
        err => {

         console.log(err);

        });
  }
  getTimeDiff(endCallTime, pickUpTime) {
    const timeDiffInMilli = endCallTime - pickUpTime;
    const seconds = Math.round((timeDiffInMilli / 1000) % 60);
    const minutes = Math.round((timeDiffInMilli / (1000 * 60)) % 60);
    const hours = Math.round((timeDiffInMilli / (1000 * 60 * 60)) % 24);
    const time = hours + ':' + minutes + ':' + seconds;
    return time;
  }
  /*================= Get and Upload DICOM File =======================*/
  getDICOMFiles(event) {

    this.files = event.target.files;
    const file = this.files[0];
    this.reader.readAsDataURL(file);
    this.uploadDICOMFile(file);
  }

  uploadDICOMFile(file) {
    this.loader.start();
    this.loader.set(10);
    const formData: FormData = new FormData();
    formData.append('patientId', this.patientId);
    formData.append('file', file);
    // this.showLoader = true;
    this.http.post(this.authService.baseURL + '/DICOMUpload', formData).subscribe( response => {
      if (response['Message'] === 'success') {
        // this.showLoader = false;
        console.log('upload successfull', response);
        response['timestamp'] = new Date().getTime();
        this.receivedPreviousFiles.push(response);
        this.loader.complete();
        this.openSuccessDialog();

      } else {

        // this.showLoader = false;
        this.loader.complete();
        this.openErrorDialog();

      }
    },
    err => {
      this.loader.complete();
      this.openErrorDialog();
    });
  }

  /*================= Get and Upload Normal File =======================*/

  getNormalFiles(event) {

    this.files = event.target.files;
    const file = this.files[0];
    this.reader.readAsDataURL(file);
    this.uploadNormalFile(file);

  }

  uploadNormalFile(file) {
    this.loader.start();
    this.loader.set(10);
    const formData: FormData = new FormData();
    formData.append('patientId', this.patientId);
    formData.append('file', file);
    // this.showLoader = true;
    this.http.post(this.authService.baseURL + '/FileUpload', formData).subscribe( response => {
      console.log('upload successfull', response);
      if (response['Message'] === 'success') {
        console.log('upload successfull', response);
        // this.showLoader = false;
        response['timestamp'] = new Date().getTime();
        this.receivedPreviousFiles.push(response);
        this.loader.complete();
        this.openSuccessDialog();

      } else {
        // this.showLoader = false;
        this.loader.complete();
        this.openErrorDialog();

      }
    },
    err => {
        this.loader.complete();
        this.openErrorDialog();
    });

  }

  /*=================== Selected Consult Note Details ========================*/
  consultNoteDetails(complaint, presenting_illness, reviewOfSystems, vitalSigns, giveTPA, notes,
    doNotGiveTPA, nihss, imaging, assessment, physical_exam, recommendations, userId,
    doctorId, patient, speciality_id, isComplete, requested_call, timestamp) {
    this.showConsultNoteList =  false;
    this.showConsultNoteDetails = true;
    this.consultNotes.chiefComplaint = complaint;
    this.consultNotes.historyOfIllness = presenting_illness;
    this.consultNotes.reviewOfSystems = reviewOfSystems;
    this.consultNotes.vitalSigns = vitalSigns;
    // this.consultNotes.giveTPA = giveTPA;
    if (giveTPA === true) {
      this.consultNotes.giveTPA = 'Yes';
    } else {
      this.consultNotes.giveTPA = 'No';
    }
    if (doNotGiveTPA === true) {
      this.consultNotes.doNotgiveTPA = 'Yes';
    } else {
      this.consultNotes.doNotgiveTPA = 'No';
    }
    this.consultNotes.notes = notes;
    // this.consultNotes.doNotgiveTPA = doNotGiveTPA;
    this.consultNotes.nihss = nihss;
    this.consultNotes.imaging = imaging;
    this.consultNotes.assessment = assessment;
    this.consultNotes.physicalExam = physical_exam;
    this.consultNotes.recommendations = recommendations;
    this.consultNotes.userId = userId;
    this.consultNotes.doctorId = doctorId;
    this.consultNotes.patient = patient;
    this.consultNotes.speciality_id = speciality_id;
    this.consultNotes.isComplete = isComplete;
    this.consultNotes.requested_call = requested_call;
    this.consultNotes.timestamp = timestamp;
    this.patientDataRetrieve();
    this.showConsultNoteDetails = true;
    this.showConsultNoteList =  false;
  }

  goBackToConsultNoteList() {
    this.showConsultNoteDetails = false;
    this.showConsultNoteList =  true;
  }

  /*================ Set Intake Form Details =====================*/
  patientDataRetrieve() {
    const headers =  new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
    const params = new HttpParams().set('callId', this.consultNotes.requested_call);
    this.http.post(this.authService.baseURL + '/GetVideoTokenDetailsTest', params, {headers: headers}).subscribe(
      res => {
        console.log(res);
        this.retrievedData = res['data'];
        this.patientSettings.nurse = this.retrievedData.nurse;
        this.patientSettings.physician = this.retrievedData.physician;
        this.patientSettings.historian = this.retrievedData.historian;
        this.patientSettings.hospitalCampus = this.retrievedData.hospitalCampus;
        this.patientSettings.arrivalToEdDate = this.retrievedData.arrivalToEdDate;
        this.patientSettings.arrivalToEdTime = this.retrievedData.arrivalToEdTime;
        this.patientSettings.lastKnownNormalDate = this.retrievedData.lastKnownNormalDate;
        this.patientSettings.lastKnownNormalTime = this.retrievedData.lastKnownNormalTime;
        this.patientSettings.bloodPressure = this.retrievedData.bloodPressure;
        this.patientSettings.weight = this.retrievedData.weight;
        this.patientSettings.oxygenSat = this.retrievedData.oxygenSat;
        this.patientSettings.heartRate = this.retrievedData.heartRate;
        this.patientSettings.temp = this.retrievedData.temp;
        this.patientSettings.medicationsCustom = this.retrievedData.medicationsCustom;
        this.patientSettings.tpaEligibilityUnknown = this.retrievedData.tpaEligibilityUnknown;
        this.patientSettings.familyAtBedside = this.retrievedData.isFamilyBedside;
        this.patientSettings.miInPrevious3Weeks = this.retrievedData.miInPrevious3Weeks;
        this.patientSettings.strokeInPrevious3Months = this.retrievedData.strokeInPrevious3Months;
        this.patientSettings.historyOfIch = this.retrievedData.isICHHistory;
        this.patientSettings.tookCEPX = this.retrievedData.tookCEPX;
        this.patientSettings.historyOfNeoplasm = this.retrievedData.isNeoplasm;
        this.patientSettings.isIntracranial = this.retrievedData.isIntracranial;
        this.patientSettings.ReasonForConsult = JSON.parse(this.retrievedData.reason);
        this.patientSettings.symptoms = JSON.parse(this.retrievedData.symptoms);
        this.patientSettings.pastMedicalHistory = JSON.parse(this.retrievedData.medicalHistory);
        this.patientSettings.pastSurgicalHistory = JSON.parse(this.retrievedData.surgicalHistory);
        this.patientSettings.medications = JSON.parse(this.retrievedData.currentMedications);
        this.patientSettings.allergies = JSON.parse(this.retrievedData.allergies);
        this.patientSettings.familyHistory = JSON.parse(this.retrievedData.familyHistory);
        this.patientSettings.socialHistory = JSON.parse(this.retrievedData.socialHistory);
      });
    }

    /*================== View Dicom Image in Dicom Viewer ==========================*/
    viewImage(fileid, filetype, fileurl) {
      if (filetype === 'Normal') {
        console.log('normal', fileurl);
        window.open(fileurl, '_blank');
      }
      else {
        console.log('fileid to be given to auth SErvice', fileid);
        this.authService.setValue(fileid);
        const dialogRef = this.dialog.open(DicomComponent, {
          height: '400px',
          width: '900px'
        });
      }
    }

    openErrorDialog() {
      const dialogRef = this.errorDialog.open(ErrorDialogComponent , {
        height: '100px',
        data : 'Problem While Uploading File'
      });
    }

    openSuccessDialog() {
      const dialogRef = this.successDialog.open(SuccessDialogComponent , {
        height: '100px',
        data : 'File Uploaded Succssfully'
      });
    }
    getAvailableDoctors() {
      const personRef = this.db.list('doctorAvailability/');
      this.items = personRef.snapshotChanges();
    }
    expandPanel(matExpansionPanel: MatExpansionPanel, event: Event): void {
      event.stopPropagation(); // Preventing event bubbling
      if (!this._isExpansionIndicator(event.target as HTMLElement)) {
        matExpansionPanel.toggle(); // Here's the magic
      }
    }
    private _isExpansionIndicator(target: HTMLElement): boolean {
      // if (this.panelOpenState === false) {
      //   const expansionIndicatorClass1 = 'spriteUp';
      //   return (target.classList && target.classList.contains(expansionIndicatorClass1));
      // } else {
      //   const expansionIndicatorClass2 = 'spriteDown';
      //   return (target.classList && target.classList.contains(expansionIndicatorClass2));
      // }
        const expansionIndicatorClass1 = 'spriteUp';
        const expansionIndicatorClass2 = 'spriteDown';
        return (target.classList && (target.classList.contains(expansionIndicatorClass2) ||
        target.classList.contains(expansionIndicatorClass1)));
    }
}


